import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mesh-library-teaser',
  templateUrl: './mesh-library-teaser.component.html',
  styleUrls: ['./mesh-library-teaser.component.scss']
})
export class MeshLibraryTeaserComponent {

  constructor(
    public dialogRef: MatDialogRef<MeshLibraryTeaserComponent>
  ) { }

  openML() {
    this.dialogRef.close(true);
  }
}
