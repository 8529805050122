import { Pipe, PipeTransform } from '@angular/core';
import { ChatHistory } from '../auth/user';

@Pipe({
  name: 'openUsers',
  pure: false
})
export class OpenUsersPipe implements PipeTransform {

  transform(history: Array<ChatHistory>, max?: number): any {
    if (!history) return history;
    let chats = history;
    if (!(history instanceof Array))
    chats = [];
    for (let key in history) {
      chats.push(history[key]);
    }
    let res = chats.filter(h => h.isOpen);
    res.sort(function (a, b) {
      return b.order - a.order;
    });
    res.sort(function (a, b) {
      return (b.isOpen ? 1 : -1) - (a.isOpen ? 1 : -1);
    });
    if (typeof max === 'number' && res.length > max)
      return res.slice(0, max);
    return res;
  }

}
