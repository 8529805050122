<div class="wrap contrast-wrap" *ngIf="visible">
    <div class="cookie">
        Cookie Policy
    </div>
    <p>
        Please note that on our website we use cookies necessary for the functionality of our website. To learn more about cookies and how we use them please read our <a routerLink="/privacy" class="inline-link">privacy policy</a>.
    </p>
    <div>
        <button mat-raised-button color="primary" (click)="approve()">
            OK
        </button>
    </div>
</div>