import { Injectable } from '@angular/core';
import { UtilsService } from 'app/shared/utils.service';
import { GraphqlService } from 'app/communication/graphql.service';
import { Observable } from 'rxjs';
import { JobTypes, JobTypesUI } from './jot-type';
import { BroadcasterService } from 'ng-broadcaster';
import { map } from 'rxjs/operators';
import { JobsTypes } from 'app/shared/enums';
@Injectable({
  providedIn: 'root'
})
export class JobsTypesService {
  public cachedTypes: Array<JobTypes>;
  private isFetching: boolean;
  private fetch: Observable<Array<JobTypes>>;
  private _iconsDictionary: { [id: string]: string };
  private _imgDictionary: { [id: string]: string };
  private _uiDictionary: { [id: string]: JobTypesUI };
  private _onGetTypes: Array<Function>;

  constructor(
    private utils: UtilsService,
    private gql: GraphqlService,
    private broadcaster: BroadcasterService
  ) {
    this._onGetTypes = [];
    this._iconsDictionary = {};
    this._iconsDictionary[JobsTypes.GEOMETRY] = 'geometry';
    this._iconsDictionary[JobsTypes.TEXTURE] = 'texture';
    this._iconsDictionary[JobsTypes.FIX] = 'build';
    this._iconsDictionary[JobsTypes.RENDR] = 'render';
    // 5: 'rigging',
    // 6:'lighting',
    // 7: 'animation'
    Object.freeze(this._iconsDictionary);
    this._imgDictionary = {};
    this._imgDictionary[JobsTypes.GEOMETRY] = '/assets/job-types/geometry.svg';
    this._imgDictionary[JobsTypes.TEXTURE] = '/assets/job-types/texture.svg';
    this._imgDictionary[JobsTypes.RENDR] = '/assets/job-types/render.svg';
    Object.freeze(this._imgDictionary);
    this.isFetching = false;
    this.getTypes();
  }

  async getTypes(): Promise<Array<JobTypes>> {
    // if (this.isFetching || this.cachedTypes) return;
    // this.isFetching = true;
    return new Promise((resolve, reject) => {
      this._onGetTypes.push(resolve);
      if (this.cachedTypes) {
        this._onGetTypes.forEach(f => f());
        return;
      }
      if (this.isFetching) {
        return;
      }
      this.isFetching = true;
      this.gql.jobsTypes().subscribe(res => {
        this.setCache(this.utils.deepCopyByValue(res?.data?.jobs_types));
        this._onGetTypes.forEach(f => f());
        this.isFetching = false;
      },
        err => { reject(err) }
      );
    });    
  }

  // get iconsDictionary() {
  //   return this._iconsDictionary;
  // }

  get uiDictionary() {
    return this._uiDictionary;
  }

  private setCache(types: Array<JobTypes>) {
    this.cachedTypes = types;
    this._uiDictionary = {};
    this.cachedTypes.forEach(t => {
      let obj = {} as JobTypesUI;
      Object.assign(obj, t);
      obj.icon = this._iconsDictionary[t.id];
      obj.img = this._imgDictionary[t.id];
      obj.description = t.description;
      this._uiDictionary[t.id] = obj;
    })
  }

  async getCachedTypes(): Promise<Array<JobTypes>> {
    return new Promise(async (resolve, reject) => {
      if (!this.cachedTypes) {
        await this.getTypes();
      }
      return resolve(this.cachedTypes);
    });
  }
}
