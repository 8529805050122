import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-custom-notification',
    templateUrl: 'custom-notification.html',
    styles: [`
        .wrap {
            max-height: 90vh;
            overflow: hidden auto;
            padding: 30px;
        }
        .text-wrap {
            min-width: 280px;
        }
    `]
})
export class CustomNotification {

    constructor(
        public dialogRef: MatDialogRef<CustomNotification>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClick(): void {
        this.dialogRef.close(this.data.notificationId);
    }
}