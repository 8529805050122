import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { RolesHelperService } from 'app/auth/roles-helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public isSU: boolean;
  public hexaFacebookURL = 'https://www.facebook.com/Creators3D/';
  public hexaYoutubeURL = 'https://www.youtube.com/channel/UCHvwYUMEzDrwOMJaF_rcIrA';

  constructor(public auth: AuthService, public rolesHelper: RolesHelperService) { }

  ngOnInit() {
    this.isSU = this.rolesHelper.isSULogedin();
  }

}
