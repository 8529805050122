<div class="dropdown-button-container" [ngClass]="'color-' + color">
  <button [disabled]="disabled" class="w-100 {{variant}}" mat-raised-button [color]="color" (click)="onButtonClick()">
    {{ label }}
    <ng-container *ngIf="options?.length">
      <mat-icon *ngIf="!dropdown.focused; else expandLess">expand_more</mat-icon>

      <ng-template #expandLess>
        <mat-icon>expand_less</mat-icon>
      </ng-template>
    </ng-container>
  </button>
    <mat-form-field>
      <mat-select #dropdown (selectionChange)="selectionChange.emit($event.value)">
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option.value | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>
