import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  transform(value: any, target?: string): any {
    if (value && typeof value === 'string') {
      return this.urlify(value, target);
    }
    return value;
  }

  urlify(text, target?: string) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    // var urlRegex = /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/g;
    // text = text.replace(/<br \/>/g, ' ');
    return text.replace(urlRegex, (url) => {
      let res = '<a href="' + url + '"';
      if (target)
        res += ' target="' + target + '"';
      res += '>' + url + '</a>';
      return res;
      // return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }
}
