import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService, IAuthType } from '../auth.service';

@Injectable()
export class ConfirmResolve  {

    constructor(private auth: AuthService) { }

    resolve(route: ActivatedRouteSnapshot) {
        this.auth.authType$.next(IAuthType.CONFIRMED);
        return route.params['token'];
    }
}