import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ArtistJobItem, IJobVariation } from '../../../job/job';

@Component({
    selector: 'app-offer-variations',
    templateUrl: './offer-variations.component.html',
    styleUrls: ['./offer-variations.component.scss']
})
export class OfferVariationsComponent {
    public variationList: IJobVariation[];
    public variations: FormControl;

    @Input() set jobItems(value: Array<ArtistJobItem>) {
        this.setVariationOptions(value);
    }
    @Output() selectedItems: EventEmitter<IJobVariation[]> = new EventEmitter<IJobVariation[]>();

    constructor() {
        this.variations = new FormControl();
    }

    /**
     * On options change method
     */
    public onValueChange(): void {
        this.selectedItems.emit(this.variations.value);
    }

    /**
     * Return the first selection value if exists
     */
    public getSelectionsValue(): string {
        return (!!this.variations?.value && !!this.variations?.value[0]) ? this.variations.value[0].name : ''
    }

    /**
     * Create variation options from artists_items array
     * @param jobItems
     */
    private setVariationOptions(jobItems: Array<ArtistJobItem>) {
        this.variationList = [];
        jobItems.forEach((jobItem) => {
            const jobsResources = this.getJobsResources(jobItem);
            const variation: IJobVariation = {
                id: jobItem.artists_items[0].product_id,
                name: jobItem.artists_items[0].name,
                imgUrl: jobItem.artists_items[0].artists_items_data[0]?.small_image_url,
                resources: jobsResources,
                approved: jobItem.UI?.hasApproved,
                final_approval: jobItem.UI?.hasFinalApproved,
                sent_to_retailers: jobItem.UI?.hasCMS
            };
            this.variationList.push(variation);
        })
    }

    /**
     * convert artists_jobs_resources array to data should pass as part of new offer
     * @param jobItem
     */
    getJobsResources(jobItem: ArtistJobItem): { id: number }[] {
        return jobItem.artists_jobs_resources.map(resource => {
            return { id: resource.id };
        });
    }

    /**
     * Return the all selected variation names as string
     */
    public getVariationValues(): string {
        if (this.variations.value) {
            return (this.variations.value.length > 1) ? this.variations.value.map((val) => val.name).join(', ') : this.variations.value[0]?.name;
        }
    }

    /**
     * Return true if multiple options were selected
     */
    public isMultipleSelection(): boolean {
        return this.variations.value?.length > 1;
    }
}
