export interface Notification {
    text: string,
    action?: string,
    type: NotificationType,
    callback?: Function,
    scope?: any;
    duration?: number;
    autoDismiss?: boolean;
}

export enum  NotificationType {
    Error,
    Success,
    Info,
}