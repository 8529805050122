export interface CreatorsNotification {
    notes: string;
    icon: string;
    title: string;
    // message_type: NotificationType;
    created_at: Date;
    updated_at: Date;
    id: number;
    read: boolean;
    reference_id: number;
    artist_user_id: number;
    notification_type: number;
    url?: string;
    // parents: CreatorsNotificationParents;
}

export interface CreatorsNotificationUI {
    id: number;
    notification_type: number;
    icon: string;
    title: string;
    created_at: Date;
    read: boolean;
    reference_id: number;
    notes: string;
    url?: string;
    artist_user_id?: number;
}

export interface CreatorsNotificationUIGroup {
    created_at: Date;
    items: Array<CreatorsNotificationUI>;
}

export interface CreatorsNotificationListResponse {
    unread_count: number;
    count: number;
    items: Array<CreatorsNotification>
}

// export interface CreatorsNotificationParents {
//     artistsnotificationtypes: Array<ArtistsNotificationType>;
// }

export interface ArtistsNotificationType {
    created_at: Date;
    description: string;
    id: number;
    updated_at: Date;
}

export enum CreatorsNotificationType {
    // ZERO_BASED,
    // VERSION_UPDATE,
    // MODEL_APPROVED,
    ROLES_REFRESHED = 1,
    JOB_ASSIGNED = 2,
    FEEDBACK_RECEIVED = 3,
    CUSTOM_BROADCAST = 4,
    VERSION_UPDATE = 5,
    JOB_DUE_SOON = 6,
    PRIVATE_OFFER = 7, // You've been offered a new item.
    CHAT_MESSAGE = 8, // You've received a new chat message.
    RELEVANT_PUBLIC_OFFER = 9, // A new relevant offer is now available
    FEEDBACK_COMMENT = 10, // A feedback has a new comment
    FEEDBACK_WAITING_FOR_REVIEW = 11, // A feedback has a new comment
    PRIVATE_OFFER_HAS_BEEN_TAKEN = 12, // A private offer has been taken
    OFFER_HAS_UPDATED_ITS_PRICING = 13, // An offer has updated its pricing
    ARTIST_HAS_GROUP_PRIVATE_OFFER = 14, // New private offer/s have been assigned to your group
    JOB_MANAGER_STATUS_CHANGE = 15,
    NEW_JOB_COMMENT = 16, // New private offer/s have been assigned to your group
    SIMILAR_ITEM = 17, // Alert on creation offer from similar item,
    PAYMENT_APPROVED = 18, // Payment service provider has approved the payment
    PAYMENT_CANCELED = 19, // Payment service provider has declined the payment
    TRUST_LEVEL_INCREASED = 20, // Your Trust Level has increased
    TRUST_LEVEL_DECREASED = 21, // Your Trust Level has decreased
    SOURCE_FILE_MATCH = 22, // source file matched
    SOURCE_FILE_NO_MATCH = 23, // source file didn't match
    ONE_SOURCE_FILE_MATCH = 24, // source file matched
    ONE_SOURCE_FILE_NO_MATCH = 25, // source file didn't match
}

export enum CreatorsNotificationChannelType {
    ONLY_NOTIFICATIOM = 1,
    ONLY_MAIL = 2,
    BOTH = 3,
}
