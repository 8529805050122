<div class="color-sampling-status">
  <span class="color-sampling-status__title">Color sample test</span>
  <div class="color-sampling-status__content-container scrollbar">
    <hexa-loader *ngIf="!presets; else content"></hexa-loader>

    <ng-template #content>
      <div class="color-sampling-status__section-box">
        <div class="color-sampling-status__presets row-margin-bottom">
          <span class="color-sampling-status__presets__title">Select Preset</span>
          <a class="hexa-link" [hidden]="!colorSamplingService.selectedPresetHasHdri" (click)="onLinkClick()">Download selected</a>
          <div *ngIf="presets?.length; else presetPlaceholder" class="tiles-select-group">
            <div *ngFor="let preset of presets" (click)="applyPreset(preset)" class="tiles-select-group__tile"
                 [ngClass]="{'tiles-select-group__tile--selected': colorSamplingService.selectedPreset === preset}"
                 [ngStyle]="{'background-image': preset.hdriUrl ? preset.hdriUrl : defaultHdri,
                             'background-size': preset.hdriUrl ? 'cover' : 'auto'}"
                 [matTooltip]="preset.preset_name" matTooltipPosition="above" matTooltipClass="bg-hexa-black"></div>
          </div>
        </div>

        <hexa-model-opacity></hexa-model-opacity>

        <div class="color-sampling-status__position">
          <div>
            <span class="color-sampling-status__position__title">Position</span>
          </div>
          <div class="color-sampling-status__position__toggles-container">
            <hexa-toggle [(ngModel)]="colorSamplingService.aAHS.noDamping" color="primary"
                              (toggle)="colorSamplingService.aAS.toggleNoControlsDamping(colorSamplingService.aAHS.noDamping)">
              Slow camera
            </hexa-toggle>

            <hexa-toggle (toggle)="toggleDistanceLimit($event)" color="primary">No distance limit</hexa-toggle>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="color-sampling-status__section-box flex-1 disp-flex flex-column space-between no-padding-bottom">
        <div class="color-sampling-status__sample-size row-margin-bottom">
          <div class="color-sampling-status__sample-size__title">Sample color size</div>
          <div *ngIf="colorSamplingService.alignmentPixels" class="tiles-select-group">
            <div *ngFor="let pt of colorSamplingService.alignmentPixels" (click)="applySampleSize(pt)"
                 class="tiles-select-group__tile"
                 [ngClass]="{'tiles-select-group__tile--selected': sampleSize === pt}">
              <div *ngIf="pt.key !== 0; else diagonal" class="tiles-select-group__tile__label">{{pt.value}}</div>
              <ng-template #diagonal>
                <div class="tiles-select-group__tile__diagonal"></div>
              </ng-template>
            </div>
          </div>
        </div>

        <div>
          <app-color-sampling-result [sample]="colorSamplingService.alignment?.config_json?.data" [testSucceeded]="colorTestSucceeded"></app-color-sampling-result>
        </div>
      </div>

      <div class="color-sampling-status__buttons alt-bg">
        <div class="disp-flex flex-end">
          <hexa-button color="secondary" (click)="onClose()">CLOSE</hexa-button>
          <hexa-button color="primary" (click)="onSave()" [disabled]="isSaveDisabled">SAVE</hexa-button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #presetPlaceholder>
  <div class="tiles-select-group">
    <div class="tiles-select-group__tile tiles-select-group__tile--selected" [ngStyle]="{ 'background-image': hexaPresetHdri }"
         matTooltip="Hexa preset" matTooltipPosition="above" matTooltipClass="bg-hexa-black"></div>
  </div>
</ng-template>
