export interface PagingFilter {
    order_by: string;
    is_desc: boolean;
    limit?: number;
    offset?: number; 
}

export enum SortDirection {
    Descending = 0,
    Ascending = 1,
}

export interface OrderFiled {
    [key: string]: string;
}