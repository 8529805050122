import { User } from "./user";
import { FilterOptions } from "app/shared/enums";

export interface Group {
    group_leader: number;
    group_name: string;
    id: number;
    // children: GroupChildren;
    artists_groups_users?: Array<ArtistsGroupUser>;
    created_at: Date;
    updated_at: Date;
    increase_percentage: number;
    type: GroupType;
    isExpand?: boolean;
    msg?: string;
    groupLeaderUser: User;
    artists_users?: Array<User>;
    artists_groups_feedback_masters: Array<ArtistsGroupsFmUser>;
}

export enum GroupType {
    FULL_TIMER = 1,
    STUDIO = 2,
    TEST = 3,
    CAMPAIGN = 4,
    REGION = 5,
    TRAINING = 6,
    FIX_JOBS = 7,
    QS_FM_TEAM = 8,
    GENERAL = 9,
    PRIVATE = 10,
    CONTENT = 11,
}

// export interface GroupChildren {
//     artistsgroupusers?: Array<ArtistsGroupUser>;
// }

export interface ArtistsGroupUser {
    artist_user_id: number;
    group_id: number;
    group_leader: number;
    increase_percentage?: number;
    type?: GroupType;
    id?: number;
    group_name?: string;
    created_at?: Date;
    updated_at?: Date;
    artists_users: Array<User>;
    artists_groups?: Array<Group>;
    // parents: ArtistsGroupUserParents;
}



export interface ArtistsGroupsFmUser {
    id: number;
    fm_id: number;
    artist_group_id: number;
    artists_users: Array<User>;
}

export interface ArtistsGroupUserParents {
    artistsusers: Array<User>;
}

export interface GroupsQueryData {
    data: GroupsQuery;
}

export interface GroupsQuery {
    allArtistsGroups: AllArtistGroup;
}

export interface AllArtistGroup {
    rows: Array<Group>;
    count: number;
}

export interface ArtistsGroupsUsersFilter extends FilterOptions {
    group_id: number;
}

export interface GroupUsersQueryData {
    data: GroupUsersQuery;
}

export interface GroupUsersQuery {
    allArtistsGroupsUsers: AllArtistGroupUsers;
}

export interface AllArtistGroupUsers {
    rows: Array<ArtistsGroupUser>;
    count: number;
}

export interface ArtistsGroupsFilter extends FilterOptions {
    id?: Array<number>;
}
