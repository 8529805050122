import { Injectable, Injector } from '@angular/core';
import { RestService } from 'app/communication/rest.service';
import { JobService } from 'app/job/job.service';
import {
  AlignmentDimensions,
  AlignmentRenderOptions,
  AlignmentRenderResponse,
  AlignmentRequestUI,
  ALIGNMENT_STATE,
  ALIGNMENT_SECTION,
  Dimensions,
  ArtistsJobsResourcesGeometryAccuracyUI
} from 'app/job/resource';
import { Coordinates3D, ColorPlatte } from 'app/shared/enums';
import { NotificationType } from 'app/shared/notifications';
import { UtilsService } from 'app/shared/utils.service';
import { AssetAdjustmentsService, AssetCommunication } from 'asset-adjustments';
import { firstValueFrom, Subject } from 'rxjs';
import { CameraControlsState, Collision, CollisionData } from 'asset-adjustments/lib/asset-adjustments.interface';

@Injectable({
  providedIn: 'root'
})
export class CollisionService {
  static DEFAULT_COLOR = ColorPlatte.DEFAULT_COLOR;
  static CURRENT_COLOR = ColorPlatte.PRIMARY_COLOR;
  static EDIT_COLOR = '#2fb2aa';
  static MIN_POINTS = 6;
  static MAX_RES = 1024;
  static ACTIVE_SCALE = 2;
  static MIN_SCORE = 95;
  static MIN_DISTANCE = 0.25;
  static MIN_DISTANCE_AXIS = 0.15;
  public exec$: Subject<void>;
  public alignmentRequest: AlignmentRequestUI;
  public render: string;
  public renderOpacity = 0;
  public scale: { [id: number]: number };
  public ALIGNMENT_STATE = ALIGNMENT_STATE;
  public ALIGNMENT_SECTION = ALIGNMENT_SECTION;
  public afterPointAddedSub: Subject<null>;
  public afterRemoveAllCollisionsSub: Subject<null>;
  public afterRemoveCollisionSub: Subject<null>;
  public afterCollisionsCompareSub: Subject<void>;
  private _index = -1;
  private assetCommunication: AssetCommunication;
  private _color: string;
  private _imageViewDim: AlignmentDimensions;
  private _renderRatio: number;
  private _ARR: AlignmentRenderResponse;
  private _calculating: boolean;
  private _alignmentState: ALIGNMENT_STATE;
  private _alignmentSection: ALIGNMENT_SECTION
  private _currentModelOpacity: number;

  constructor(
    private assetAdjustmentsService: AssetAdjustmentsService,
    private injector: Injector,
    private utils: UtilsService,
    private rest: RestService,
    private jobService: JobService
  ) {
    this._renderRatio = 1;
    this._currentModelOpacity = 1;
    this.initScale();
    this.exec$ = new Subject();
    this.alignmentSection = ALIGNMENT_SECTION.CHOOSE;
    this.afterPointAddedSub = new Subject<null>();
    this.afterRemoveAllCollisionsSub = new Subject<null>();
    this.afterRemoveCollisionSub = new Subject<null>();
    this.afterCollisionsCompareSub = new Subject<void>();
  }

  public get alignmentState(): ALIGNMENT_STATE {
    return this._alignmentState;
  }

  public set alignmentState(value: ALIGNMENT_STATE) {
    this._alignmentState = value;
  }

  public get alignmentSection(): ALIGNMENT_SECTION {
    return this._alignmentSection;
  }

  public set alignmentSection(value: ALIGNMENT_SECTION) {
    this._alignmentSection = value;
    this.alignmentState = this._alignmentState;
  }

  public get index(): number {
    return this._index;
  }

  public set index(value: number) {
    this._index = value;
  }

  public get collisions(): Collision[] {
    return this.assetAdjustmentsService.collisions;
  }

  public get collisionsData(): CollisionData {
    return this.assetAdjustmentsService.collisionsData;
  }

  public get imageViewDim(): AlignmentDimensions {
    return this._imageViewDim;
  }

  public set imageViewDim(value: AlignmentDimensions) {
    this._imageViewDim = value;
  }

  public get minPoints(): number {
    return CollisionService.MIN_POINTS;
  }

  public get minScore(): number {
    return CollisionService.MIN_SCORE;
  }

  public get alignmentRenderResponse(): AlignmentRenderResponse {
    return this._ARR;
  }

  public get calculating(): boolean {
    return this._calculating;
  }

  public init(): void {
    this._ARR = null;
    this.alignmentRequest = {
      generate_renders: false,
      width: 0,
      height: 0,
      image_id: null,
      points_2d: [],
      points_3d: [],
      resource_id: null,
      upload_to_cdn: false,
      colors: []
    };
    if (!this.assetCommunication && document.querySelector(`iframe[src="${this.assetAdjustmentsService.orgSrc}"]`)) {
      this.assetCommunication = new AssetCommunication({
        iframeModel: this.assetAdjustmentsService.iframeModel,
        modelUrl: this.assetAdjustmentsService.orgSrc
      }, this.injector);
    } else if (this.assetCommunication) {
      this.assetCommunication.changeUrl(this.assetAdjustmentsService.orgSrc);
    } else if(!this.assetCommunication){
      this.assetCommunication = new AssetCommunication({
        iframeModel: this.assetAdjustmentsService.iframeModel,
        modelUrl: this.assetAdjustmentsService.orgSrc
      }, this.injector);
    }
  }

  private initScale(): void {
    this.scale = {
      0: 1
    };
  }

  public setAssetData(resourceId: number, imageId: number, dim: Dimensions): void {
    if (!this.alignmentRequest) {
      this.init();
    }
    this.alignmentRequest.resource_id = resourceId;
    this.alignmentRequest.image_id = imageId;
    this.alignmentRequest.width = dim.width;
    this.alignmentRequest.height = dim.height;
  }

  private addColor(): void {
    this._color = CollisionService.CURRENT_COLOR;
    this.index++;
    this.scale[this.index] = 1;
    this.alignmentRequest.colors.push(this._color);
  }

  public startViewerCollision(): void {
    this.addColor();
    this.assetAdjustmentsService.toggleCollision(true, this._color);
  }

  public afterPointAdded(): void {
    for (let i = 0; i < this.alignmentRequest.points_2d.length - 1; i++) {
      this.setCollision(i, CollisionService.DEFAULT_COLOR, 1);
    }
    if (this.alignmentRequest.points_2d.length === this.minPoints &&
      this.alignmentRequest.points_2d.length === this.alignmentRequest.points_3d.length) {
      this.alignmentSection = ALIGNMENT_SECTION.COMPARE;
    }
  }

  public removeCollisions(index: number): void {
    this.assetAdjustmentsService.deleteCollision(index, 1);
    this.alignmentRequest.points_2d.splice(index, 1);
    this.collisions?.splice(index, 1);
    this.index--;
    this.sync();
    this.alignmentState = ALIGNMENT_STATE.IMAGE;

    if (this.alignmentSection === ALIGNMENT_SECTION.COMPARE) {
      this.alignmentSection = ALIGNMENT_SECTION.MATCH;
    }
    this.alignmentRequest.colors.splice(index, 1);
    this.afterRemoveCollisionSub.next(null);
  }

  public removeAllCollisions(): void {
    if (!this.alignmentRequest) {
      return
    }

    this._ARR = null;
    this.assetAdjustmentsService.toggleCollision(false);
    this.alignmentRequest.points_2d = [];
    this.alignmentRequest.points_3d = [];
    this.assetAdjustmentsService.removeAllCollisions();
    this._index = -1;
    this.alignmentState = ALIGNMENT_STATE.IMAGE;

    if (this.alignmentSection === ALIGNMENT_SECTION.COMPARE) {
      this.alignmentSection = ALIGNMENT_SECTION.MATCH;
    }
    this.alignmentRequest.colors.splice(0);
    this.afterRemoveAllCollisionsSub.next(null);
  }

  public async waitForCollisions(): Promise<void> {
    await this.assetCommunication.waitForCollisions();
  }

  public sync(): void {
    this.alignmentRequest.points_3d = [];
    this.collisions.forEach(c => this.alignmentRequest.points_3d.push([c.x, c.y, c.z]));
  }

  private execNormalize(alignmentRequest: AlignmentRequestUI): void {
    const ratio = Number((alignmentRequest.width / this.imageViewDim.width).toFixed(2));

    alignmentRequest.points_2d.map(p => {
      p[0] = ((p[0] - (this.imageViewDim.imgWidthDiff / 2)) * ratio);
      p[1] = ((p[1] - (this.imageViewDim.imgHeightDiff / 2)) * ratio);
    });

    alignmentRequest.points_3d.map(p => {
      const z = -p[2];
      p[2] = p[1];
      p[1] = z;
    });
  }

  public add2D(c: Coordinates3D): void {
    this.alignmentRequest.points_2d.push([c.x, c.y]);
    this.afterPointAddedSub.next(null);
  }

  private normalizeBefore(alignmentRequest: AlignmentRequestUI): void {
    if (this._renderRatio !== 1) {
      alignmentRequest.width *= this._renderRatio;
      alignmentRequest.height *= this._renderRatio;
      alignmentRequest.points_2d.map(p => {
        p[0] *= this._renderRatio;
        p[1] *= this._renderRatio;
      });
    }
  }

  private normalizeAfter(alignmentRequest: AlignmentRequestUI, alignmentRenderResponse: AlignmentRenderResponse): void {
    if (this._renderRatio !== 1 && alignmentRenderResponse?.url) {
      // alignmentRenderResponse.url = alignmentRenderResponse.url.replace('://cdn.hexa3d.io/', '://img-cdn.azureedge.net/');
      alignmentRenderResponse.url = alignmentRenderResponse.url.replace('://cdn.hexa3d.io/', '://himg-cdn.com/');
      alignmentRenderResponse.url += `?w=${alignmentRequest.width / this._renderRatio}&h=${alignmentRequest.height / this._renderRatio}`;
    }
  }

  public async alignmentRender(options: AlignmentRenderOptions): Promise<AlignmentRenderResponse> {
    this._calculating = true;
    const alignmentRequest = this.utils.deepCopyByValue(this.alignmentRequest) as AlignmentRequestUI;
    delete alignmentRequest.colors;
    this.execNormalize(alignmentRequest);
    alignmentRequest.generate_renders = options.generateRenders;
    alignmentRequest.upload_to_cdn = alignmentRequest.generate_renders;
    this._renderRatio = 1;
    if (alignmentRequest.width > CollisionService.MAX_RES || alignmentRequest.height > CollisionService.MAX_RES) {
      const max = Math.max(alignmentRequest.width, alignmentRequest.height);
      this._renderRatio = CollisionService.MAX_RES / max;
      this.normalizeBefore(alignmentRequest);
    }
    try {
      this._ARR = await this.utils.observableToPromise(this.rest.alignmentRender('POST', alignmentRequest)) as AlignmentRenderResponse;
      this._ARR.score = Math.round(this._ARR.score * 100);
    } catch (e) {
      this.utils.notifyUser({text: e.message, type: NotificationType.Error});
      if (this._ARR && alignmentRequest.generate_renders) {
        // request contains generate_renders - means it's the second request and should be saved
        await this.saveGeometryAccuracy();
      }
    }
    this.afterCollisionsCompareSub.next();
    this.normalizeAfter(alignmentRequest, this._ARR);
    if (options.setPosition && this._ARR && this.isSuccess()) {
      options.iframeElement.style.width = `${this.imageViewDim.width}px`;
      options.iframeElement.style.height = `${this.imageViewDim.height}px`;
      await this.utils.setTimeout();
      this.assetAdjustmentsService.toggleCollisionsVisibility(false);
      this.assetAdjustmentsService.toggleNoDistanceLimit(true);
      await this.utils.setTimeout();
      await this.assetCommunication.setControlsPosition({
        camposx: this._ARR.transform.translation[0][0],
        camposy: this._ARR.transform.translation[2][0],
        camposz: -this._ARR.transform.translation[1][0],
        focalLength: this._ARR.transform.focal_length
      } as CameraControlsState, {
        animationDuration: 1,
        force: true
      });
    }
    // Just to preload the image
    if (this._ARR?.url) {
      await this.utils.getImageDim(this._ARR.url);
    }
    if (options.animateOpacity && options.generateRenders && options.imgElement) {
      this.animateOpacity(options.imgElement);
    }
    if (this._ARR?.url) {
       await this.saveGeometryAccuracy();
    }
    this._calculating = false;
    return this._ARR;
  }

  private async saveGeometryAccuracy(): Promise<void> {
    try {
      const a = await firstValueFrom<any>(this.rest.geometryAccuracy('post', {
        score: this._ARR.score,
        model_transform: this._ARR.transform,
        url: this._ARR.url,
        request: this.alignmentRequest,
        resource_id: this.alignmentRequest.resource_id
      }));

      if (!this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_geometry_accuracy) {
        this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_geometry_accuracy = [];
      }

      this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_geometry_accuracy.push(a);
    } catch (e) {
      console.error(e)
    }
  }

  private setCollision(index: number, color: string, scale: number): void {
    this.alignmentRequest.colors[index] = color;
    this.scale[index] = scale;
    if (this.alignmentRequest.points_3d.length - 1 >= index) {
      this.assetAdjustmentsService.postToChild('setCollision', {
        value: index,
        options: {
          color: this.alignmentRequest.colors[index],
          scale
        }
      });
    }
  }

  public highlight(index: number): void {
    if (this.alignmentRequest.points_2d && this.alignmentRequest.points_2d[index]) {
      this.setCollision(index, CollisionService.EDIT_COLOR, CollisionService.ACTIVE_SCALE);
    }
  }

  public blur(index: number): void {
    if (this.alignmentRequest.points_2d[index]) {
      const color = index < this.alignmentRequest.points_2d.length - 1 ? CollisionService.DEFAULT_COLOR : CollisionService.CURRENT_COLOR;
      this.setCollision(index, color, 1);
    }
  }

  private animateOpacity(img: HTMLImageElement): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.renderOpacity = 0;
      img.animate(
        [
          {opacity: '1 !important'}
        ], {
          duration: 1000,
          easing: 'ease-in-out',
          fill: 'none'
        });
      for (let i = 0; i < 100; i++) {
        await this.utils.setTimeout(10);
        this.renderOpacity += 0.01;
      }
      this.renderOpacity = 1;
      img.animate(
        [
          {opacity: '0 !important'}
        ], {
          duration: 1000,
          easing: 'ease-in-out',
          fill: 'none'
        });
      for (let i = 0; i < 100; i++) {
        await this.utils.setTimeout(10);
        this.renderOpacity -= 0.01;
      }
      this.renderOpacity = 0;
      resolve();
    });
  }

  public isDone(): boolean {
    return this.alignmentRequest &&
      this.alignmentRequest.points_2d.length === this.minPoints &&
      this.alignmentRequest.points_2d.length === this.alignmentRequest.points_3d.length;
  }

  public prev(): void {
    (this.alignmentSection as number)--;
    if ((this.alignmentSection as number) < ALIGNMENT_SECTION.CHOOSE) {
      this.alignmentSection = ALIGNMENT_SECTION.CHOOSE;
    }
  }

  public next(): void {
    if ((this.alignmentSection === ALIGNMENT_SECTION.MATCH || this.alignmentSection === ALIGNMENT_SECTION.COMPARE) && !this.isDone()) {
      return;
    }
    (this.alignmentSection as number)++;
    if ((this.alignmentSection as number) > ALIGNMENT_SECTION.COMPARE) {
      this.alignmentSection = ALIGNMENT_SECTION.COMPARE;
    }
    switch (this.alignmentSection) {
      case ALIGNMENT_SECTION.COMPARE: {
        this.exec$.next();
        break;
      }
    }
  }

  public isSuccess(): boolean {
    return this.alignmentRenderResponse?.score >= this.minScore;
  }

  public onInputChange(event: any): void {
    this.renderOpacity = event.value;
  }

  public getExistTests(): Array<ArtistsJobsResourcesGeometryAccuracyUI> {
    const e = this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex]?.artists_jobs_resources_geometry_accuracy || [];
    const res = [];
    e.forEach(a => {
      const n = this.utils.deepCopyByValue(a) as ArtistsJobsResourcesGeometryAccuracyUI;
      n.request = typeof n.request === 'string' ? this.utils.safeParse(n.request) : n.request;
      const image = this.jobService.currentArtistsjobitem.artists_items[0].artists_items_data.find(d => d.id === n.request.image_id);
      if (image)
        n.image_url = image.url;
      n.passed = n.score >= this.minScore;
      res.push(n);
    });
    return res;
  }

  public onDestroy(): void {
    this.alignmentRequest = null;
    this._ARR = null;
    this.render = null;
    this.index = -1;
    this.initScale();
    this.assetCommunication?.destroy();
    this.assetCommunication = null;
    this._alignmentState = null;
    this._alignmentSection = null;
    this._currentModelOpacity = 1;
  }
}
