import { Injectable } from '@angular/core';
import { KeyValuePair } from 'app/shared/enums';
import { TimeUnits, ViewerUrlParams } from 'app/shared/utils';
import { UtilsService } from 'app/shared/utils.service';
import { ArtistLibrary, ArtistMesh, MeshLibraryState, MeshLibraryWrapper } from './mesh-library';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { PixelsService } from 'app/shared/pixels.service';

import { GraphqlService } from 'app/communication/graphql.service';

@Injectable({
  providedIn: 'root'
})
export class MeshLibraryService {
  public _breadcrumbs: Array<KeyValuePair>;
  public selectedPartsDictionary: { [id: number]: boolean };
  private _selectedPartsDictionary: { [id: number]: boolean };
  private _percentage: number;
  private _partPreview: SafeResourceUrl;
  private _state: MeshLibraryState;
  private _selectedParts: Array<ArtistMesh>;

  private _meshLibraries: Array<ArtistLibrary>;

  private _allMeshLibraries: Array<ArtistLibrary>;
  private _subCategoryDesc: string;
  private _currentMeshLibrary: ArtistLibrary;
  private _currentMeshLibrariesPart: ArtistMesh;

  private _totalCategoryMinutes: number;
  private _totalPartsMinutes: number;
  private _timeUnits: TimeUnits;
  private _allPartsDictionary: { [id: number]: ArtistMesh };
  private minuteToModel: number;
  constructor(
    private utils: UtilsService,
    private sanitizer: DomSanitizer,
    private pixels: PixelsService,
    private gql: GraphqlService
  ) {
    this.minuteToModel = 15;
    this._state = MeshLibraryState.SUB_CATEGORY;
    this.setBreadcrumbs();
    this._selectedParts = [];
    this._selectedPartsDictionary = {};
    this._allPartsDictionary = {};
    this.selectedPartsDictionary = new Proxy(this._selectedPartsDictionary, {
      set: (target, key, value) => {
        target[key] = value;
        if (value) {
          // this.addPart(this._allPartsDictionary[key])
          if (!this._selectedParts.find(p => p.id == this._allPartsDictionary[key].id))
            this._selectedParts.push(this._allPartsDictionary[key]);
        }
        else {
          // this.removePart(this._allPartsDictionary[key])
          //  if (this._selectedParts.find(p => p.id == key))
          const keyAny = key as any;
          for (let i = 0; i < this._selectedParts.length; i++) {
            if (this._selectedParts[i].id == keyAny) {
              this._selectedParts.splice(i, 1);
              break;
            }
          }
        }
        this.calcTotalPartsTime();
        return true;
      },
      get: (target, key) => {
        return target[key];
      }
    });
    this.calcTotalCategoryTime();
    this.calcTotalPartsTime();
  }

  get selectedParts(): Array<ArtistMesh> {
    return this._selectedParts;
  }

  get percentage(): number {
    return this._percentage;
  }

  get currentMeshLibrary(): ArtistLibrary {
    return this._currentMeshLibrary;
  }

  set currentMeshLibrary(value: ArtistLibrary) {
    this._currentMeshLibrary = value;
    this._state = MeshLibraryState.SECTIONS;
    this.setBreadcrumbs();
  }

  get currentMeshLibrariesPart(): ArtistMesh {
    return this._currentMeshLibrariesPart;
  }

  set currentMeshLibrariesPart(value: ArtistMesh) {
    this._currentMeshLibrariesPart = value;
    this._state = MeshLibraryState.PARTS;
    this.enablePartPreview();
    this.setBreadcrumbs();
  }

  set currentSelectedMeshLibrariesPart(selectedMesh: ArtistMesh) {
    this._meshLibraries.forEach((library) => {
      const mesh = library.artists_meshes.find((mesh) => mesh === selectedMesh);
      if (!!mesh) {
        this._currentMeshLibrary = library;
        this.currentMeshLibrariesPart = selectedMesh;
        this.addPart(selectedMesh);
      }
    });
  }

  get partPreview(): SafeResourceUrl {
    return this._partPreview;
  }

  get totalCategoryMinutes(): number {
    return this._totalCategoryMinutes;
  }

  get totalPartsMinutes(): number {
    return this._totalPartsMinutes;
  }

  get timeUnits(): TimeUnits {
    return this._timeUnits;
  }

  get subCategoryDesc(): string {
    return this._subCategoryDesc;
  }


  get meshLibraries(): Array<ArtistLibrary> {
    return this._meshLibraries;
  }

  set AllmeshLibraries(value: Array<ArtistLibrary>) {
    this._allMeshLibraries = value;
  }

  get state(): MeshLibraryState {
    return this._state;
  }

  set state(state: MeshLibraryState) {
    this._state = state;
  }

  get breadcrumbs(): Array<KeyValuePair> {
    return this._breadcrumbs;
  }

  init(wrap: MeshLibraryWrapper) {
    this._subCategoryDesc = wrap.subCatdesc;
    this._meshLibraries = wrap.meshLibraries;
    delete this._currentMeshLibrary;
    delete this._currentMeshLibrariesPart;
    this.calcTotalCategoryTime();
    this.calcTotalPartsTime();
    this._allPartsDictionary = {};
    this._selectedPartsDictionary = {};
    this._meshLibraries.forEach(ml => ml.artists_meshes.forEach(p => this._allPartsDictionary[p.id] = p));
    
    if (wrap.selectedMesh) {
      this.currentSelectedMeshLibrariesPart = wrap.selectedMesh;
    }
    this.setBreadcrumbs();
  }

  /**
   * Enables part preview in the iframe
   */
  private enablePartPreview(): void {
    let paraps = new ViewerUrlParams();
    paraps.exposure = 0.2;
    this._partPreview = this.sanitizer.bypassSecurityTrustResourceUrl(this.utils.buildViewerUrl(this._currentMeshLibrariesPart.geometry_url, paraps));
  }
 
  getAllmeshLibraries(): Promise<Array<ArtistLibrary>> {
    return new Promise((resolve, reject) => {
      if (!this._allMeshLibraries) {
        this.gql.meshLibraries().subscribe(
          scat => {
            this._allMeshLibraries = this.utils.deepCopyByValue(scat.data.allLibraries);
            resolve(this._allMeshLibraries)
          }, err => reject(null)
        )
      } else {
        resolve(this._allMeshLibraries);
      }
    })
  }

  async ifNameExistsAllmeshLibraries(al: ArtistLibrary): Promise<boolean> {
    let allLib = await this.getAllmeshLibraries();
    return allLib.some(l => l.name === al.name && l.id != al.id);
  }

  private calcTotalCategoryTime() {
    this._totalCategoryMinutes = 0;
    if (this._meshLibraries) {
      this._meshLibraries.forEach(ml => {
        this._totalCategoryMinutes += this.minuteToModel;
      });
    }
    this.calcPercentage();
  }

  private calcTotalPartsTime() {
    this._totalPartsMinutes = 0;
    let pd = {} as { [id: number]: boolean };
    if (this.selectedParts && this._meshLibraries) {
      this.selectedParts.forEach(p => {
        const ml = this._meshLibraries.find(ml => ml.id == p.library_id);
        if (!pd[ml.id]) {
          pd[ml.id] = true;
          this._totalPartsMinutes += this.minuteToModel;
        }
      });
    }
    this._timeUnits = this.utils.getTimeUnitsFromHours(this._totalPartsMinutes / 60);
    this.calcPercentage();
  }

  private calcPercentage() {
    if (!this.totalPartsMinutes || !this.totalCategoryMinutes)
      this._percentage = 0;
    else
      this._percentage = 100 * (this.totalPartsMinutes / this.totalCategoryMinutes)
  }

  private setBreadcrumbs() {
    if (!this._meshLibraries) {
      this._breadcrumbs = [];
      return;
    }
    this._breadcrumbs = [
      {
        key: MeshLibraryState.SUB_CATEGORY,
        value: this._subCategoryDesc
      }
    ];
    if (this._state > 1) {
      this._breadcrumbs.push({
        key: MeshLibraryState.SECTIONS,
        value: this.currentMeshLibrary.name
      });
      if (this._state > 2) {
        this._breadcrumbs.push({
          key: MeshLibraryState.PARTS,
          value: this.currentMeshLibrariesPart.name
        });
      }
    }
  }

  navToState(bc: KeyValuePair) {
    this.state = bc.key;
    this.setBreadcrumbs();
  }

  back() {
    if (this.state > 1) {
      this.state--;
      this.setBreadcrumbs();
    }
  }

  addPart(part: ArtistMesh) {
    this.selectedPartsDictionary[part.id] = true;
    this.calcTotalPartsTime();
  }

  removePart(index: any) {
    const part = this._selectedParts[index];
    this.selectedPartsDictionary[part.id] = false;
    this.calcTotalPartsTime();
  }

  downloadAll() {
    this.selectedParts.forEach(p => {
      this.pixels.sendPixel({
        event: 'meshLibraryDownload',
        reference_id: p.id
      });
      this.utils.multipleDownloads([p.geometry_url], 'mesh_');
    });
  }

  ngOnDestroy() {
    this._state = MeshLibraryState.SUB_CATEGORY;
    delete this._meshLibraries;
    delete this._subCategoryDesc;
    delete this._currentMeshLibrary;
    delete this._currentMeshLibrariesPart;
    this._selectedPartsDictionary = {};
    for (let i in this.selectedPartsDictionary) {
      this.selectedPartsDictionary[i] = false;
    }
  }
}