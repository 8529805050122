import { PagingFilter } from "./paging";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router, Params } from "@angular/router";
import { UtilsService } from "./utils.service";
import { GraphqlService } from "app/communication/graphql.service";
import { BroadcasterService } from "ng-broadcaster";
import { Notification, NotificationType } from "./notifications";
import { KeyValueAnyPair } from "./enums";
import { BodyService } from "./body.service";
import { NgZone, Injector } from "@angular/core";
import { Subscription } from "rxjs";
import { RestService } from "app/communication/rest.service";
import { Observable } from "rxjs";

export interface IInfiniteScroll {
    ignoreChanges: boolean;
    items: Array<any>;
    itemsCount: number;
    isSearchRequestInProgress: boolean;
    trySearchDuringProgress: boolean;
    lastQuery: PagingFilter;
    scrollOffset: number;
    scrollLimit: number;
    filter: FormGroup;
    rootRoute: string;
    chunkSize: number;
    graphFunction: string;
    restFunction: string;
    fieldTypes: { [id: string]: FieldType };
    initialSortDir: string;
    initialSortField: string;
    getSearchQuery(options?: PagingFilter): PagingFilter;
    initPagination(): void;
    navigateByQuery(options?: PagingFilter): void;
    setStateFromURL(params?: Params): void;
    compareQueries(a: PagingFilter, b: PagingFilter, ignoreLO: boolean): boolean;
    getFilterFromQueryParams(params: Params): PagingFilter;
    normalizeField(kvp: KeyValueAnyPair): any;
    setIsSearchRequestInProgress(state: boolean): void;
    onItemsChange(forceRefresh: boolean): void;
    // for override purpaces, do not delete!
    afterItemsChanged(forceRefresh: boolean): void;
}

export interface InfiniteScrollOptions {
    rootRoute?: string;
    chunkSize: number;
    graphFunction?: string;
    restFunction?: string;
    defaults: InfiniteScrollDefaults;
}

export interface InfiniteScrollDefaults {
    order_by: string;
    is_desc: boolean;
    session_id: string;
}

export enum FieldType {
    Number = 1,
    String = 2,
    Boolean = 3,
    Date = 4,
    NumbersArray = 5,
    StringsArray = 6,
    BooleansArray = 7,
    DatesArray = 8,
    BooleanNullable = 9
}

export class InfiniteScroll implements IInfiniteScroll {
    ignoreChanges: boolean; items: any[];
    itemsCount: number;
    isSearchRequestInProgress: boolean;
    trySearchDuringProgress: boolean;
    lastQuery: PagingFilter;
    scrollOffset: number;
    scrollLimit: number;
    filter: FormGroup;
    rootRoute: string;
    chunkSize: number;
    graphFunction: string;
    restFunction: string;
    fieldTypes: { [id: string]: FieldType };
    initialSortDir: string;
    initialSortField: string;
    scrollTo: any;
    private currentFetch: Subscription;
    private defaults: InfiniteScrollDefaults;
    private utilsInjected: UtilsService;
    private routerInjected: Router;
    private formBuilderInjected: FormBuilder;
    private gqlInjected: GraphqlService;
    private restInjected: RestService;
    private broadcasterServiceInjected: BroadcasterService;
    private bodyServiceInjected: BodyService;
    private ngZoneInjected: NgZone;

    constructor(
        options: InfiniteScrollOptions,
        private injectorInjected: Injector
    ) {
        this.scrollTo = window.scrollTo;
        this.utilsInjected = this.injectorInjected.get(UtilsService);
        this.routerInjected = this.injectorInjected.get(Router);
        this.formBuilderInjected = this.injectorInjected.get(FormBuilder);
        this.gqlInjected = this.injectorInjected.get(GraphqlService);
        this.restInjected = this.injectorInjected.get(RestService);
        this.broadcasterServiceInjected = this.injectorInjected.get(BroadcasterService);
        this.bodyServiceInjected = this.injectorInjected.get(BodyService);
        this.ngZoneInjected = this.injectorInjected.get(NgZone);
        this.setOptions(options || {});
        this.fieldTypes = {};
        this.setIsSearchRequestInProgress(false);
        this.initPagination();
    }

    setOptions(options) {
        const defaults = {
            rootRoute: null,
            chunkSize: 25
            // arrayFieldNames: {}
        } as InfiniteScrollOptions;

        Object.assign(defaults, options);

        this.rootRoute = options.rootRoute;
        this.chunkSize = options.chunkSize;
        this.graphFunction = options.graphFunction;
        this.restFunction = options.restFunction;
        this.defaults = options.defaults;
    }

    setRootRoute(rootRoute: string) {
        this.rootRoute = rootRoute;
    }

    setIsSearchRequestInProgress(state: boolean): void {
        this.isSearchRequestInProgress = state;
        this.bodyServiceInjected.isSearchRequestInProgress = state;
    }

    initFilter() {
        this.filter = this.formBuilderInjected.group({
            order_by: this.defaults.order_by,
            is_desc: this.defaults.is_desc
        });
        this.ignoreChanges = false;
        this.filter.valueChanges.subscribe(data => {
            if (this.ignoreChanges) return;
            this.initPagination();
            this.searchByQuery();
        });
        this.lastQuery = this.getSearchQuery();
    }

    splitSpecialChars(obj: any): any {
        if (typeof obj === 'string' && obj.indexOf('"') > -1) {
            obj = obj.substring(0, obj.indexOf('"'));
        }
        else if (obj instanceof Array) {
            let arrayToAdd = [];
            for (let i = 0; i < obj.length; i++) {
                if (typeof obj[i] === 'string') {
                    let split = obj[i].split(/[\",]+/);
                    obj[i] = split[0];
                    for (let j = 1; j < split.length; j++) {
                        arrayToAdd.push(split[j].trim());
                    }
                }
            }
            arrayToAdd.forEach(i => i && obj.push(i));
        }
        return obj;
    }

    getSearchQuery(options?: PagingFilter): any {
        if (!options) {
            options = {
                limit: this.scrollLimit,
                offset: this.scrollOffset
            } as PagingFilter;
            Object.keys(this.filter.controls).forEach(key => {
                const val = this.filter.controls[key].value;
                if (val === null || (typeof val === 'string' && !val)) { }
                else
                    options[key] = val;
                if (options[key] == null || (options[key] instanceof Array && !options[key].length))
                    delete options[key];
                    // if (key == "hidden_feedback") {
                    //     options[key] = val;
                    // } else {
                    //     delete options[key];
                    // }
                if (options[key]) {
                    options[key] = this.splitSpecialChars(options[key]);
                    options[key] = this.normalizeField({ key: key, value: options[key] });
                }
            });
        }
        if (!options.limit)
            options.limit = this.scrollLimit;
        if (!options.offset)
            options.offset = this.scrollOffset;
        if (typeof options.is_desc === 'string' && options.is_desc)
            options.is_desc = options.is_desc == 'true';
        return this.utilsInjected.deepCopyByValue(options);
    }
    initPagination(): void {
        this.scrollOffset = 0;
        this.scrollLimit = this.chunkSize;
    }
    navigateByQuery(options?: PagingFilter): void {
        let navObj = {} as PagingFilter;
        Object.assign(navObj, options);
        delete navObj.limit;
        delete navObj.offset;
        if (this.rootRoute) {
            // Prevent scrollPositionRestoration: 'enabled' from scrolling up due to angular navigation
            window.scrollTo = () => {};
            this.routerInjected.navigate([this.rootRoute, navObj]).finally(() => {
                // restore window.scrollTo
                setTimeout(() => {
                    window.scrollTo = this.scrollTo;
                });
            });
        }
            
    }
    setStateFromURL(params?: Params): void {
        let filter = this.getSearchQuery(this.getFilterFromQueryParams(params));
        this.ignoreChanges = true;
        for (let i in filter) {
            if (i != 'limit' && i != 'offset') {
                this.filter.controls[i].setValue(this.normalizeField({
                    key: i,
                    value: filter[i]
                }));
            }
        }
        if (this.filter.controls['order_by'].value) {
            this.initialSortField = this.filter.controls['order_by'].value;
        }
        if (typeof this.filter.controls['is_desc'].value === 'boolean') {
            this.initialSortDir = this.filter.controls['is_desc'].value ? 'desc' : 'asc';
        }

        this.lastQuery = this.getSearchQuery();
        this.ignoreChanges = false;
    }
    private onSearchQueryReturn(forceRefresh: boolean, query: any, obj: any): Subscription {
        this.setIsSearchRequestInProgress(false);
        let data = null;
        if (this.graphFunction) {
            if (obj.errors && obj.errors.length) {
                let dataErr: Notification = {
                    text: obj.errors[0].message,
                    type: NotificationType.Error,
                    action: 'OK'
                }
                this.broadcasterServiceInjected.broadcast('notifyUser', dataErr);
                return;
            }
            if (!obj.data) {
                console.warn('No graphQL data object was returned.');
                return;
            }
            data = obj.data[Object.keys(obj.data)[0]].rows;
            this.itemsCount = obj.data[Object.keys(obj.data)[0]].count;
        }
        else {
            data = obj;
        }

        if (!this.items) this.items = [];
        if (forceRefresh || (typeof this.lastQuery !== 'undefined' && !this.compareQueries(this.lastQuery, query, true))) {
            // this.items = this.getItemsFromItemQuery(data.rows) as Array<any>;
            this.items = this.utilsInjected.deepCopyByValue(data, true) as Array<any>;
            this.initPagination();
        }
        else {
            // this.items = this.getItemsFromItemQuery(data.rows) as Array<any>;
            this.items = this.items.concat(this.utilsInjected.deepCopyByValue(data, true));
        }
        this.lastQuery = this.utilsInjected.deepCopyByValue(query);
        if (this.trySearchDuringProgress)
            this.searchByQuery();
        this.onItemsChange(forceRefresh);
    }
    searchByQuery(forceRefresh = false, navigateByQuery = true): Observable<any> | Promise<any> | any {
        if (!this.graphFunction && !this.restFunction) {
            console.warn('No graphQL or REST function was provided.');
            return;
        }
        if (this.graphFunction && !this.gqlInjected[this.graphFunction]) {
            console.warn(`The function ${this.graphFunction} does not exist on graphQL service.`);
            return;
        }
        if (this.restFunction && !this.restInjected[this.restFunction]) {
            console.warn(`The function ${this.graphFunction} does not exist on graphQL service.`);
            return;
        }
        if (this.isSearchRequestInProgress) {
            this.trySearchDuringProgress = true;
            return;
        }
        let query = this.getSearchQuery();
        if (!forceRefresh && this.compareQueries(this.lastQuery, query, false)) return;
        this.trySearchDuringProgress = false;
        this.setIsSearchRequestInProgress(true);

        if (navigateByQuery)
            this.navigateByQuery(query);
        if (this.currentFetch)
            this.currentFetch.unsubscribe();
        if (this.graphFunction) {
            this.currentFetch = this.gqlInjected[this.graphFunction](query).subscribe(
                (obj: any) => {
                    this.onSearchQueryReturn(forceRefresh, query, obj);
                }
            );
        }
        else {
            const queryString = '?' + new URLSearchParams(this.utilsInjected.getURLSearchParamsFromObj(query)).toString();
            this.currentFetch = this.restInjected[this.restFunction]('get', null, queryString).subscribe(
                (obj: any) => {
                    this.onSearchQueryReturn(forceRefresh, query, obj);
                }
            )
        }
        return this.currentFetch;
    }

    onItemsChange(forceRefresh: boolean) {
        this.ngZoneInjected.runOutsideAngular(() => {
            this.items = this.utilsInjected.deepCopyByValue(this.items, true);
            this.afterItemsChanged(forceRefresh);
        });
    }

    afterItemsChanged(forceRefresh: boolean) {

    }

    compareQueries(a: PagingFilter, b: PagingFilter, ignoreLO: boolean) {
        a = this.utilsInjected.deepCopyByValue(a);
        b = this.utilsInjected.deepCopyByValue(b);
        if (ignoreLO) {
            delete a.limit;
            delete b.limit;
            delete a.offset;
            delete b.offset;
        }
        this.utilsInjected.removeApostropheFromArrays(a);
        this.utilsInjected.removeApostropheFromArrays(b);
        return this.utilsInjected.equals(a, b);
    }

    normalizeField(kvp: KeyValueAnyPair): any {
        let value = null as any;
        switch (this.fieldTypes[kvp.key]) {
            case FieldType.Number: {
                value = kvp.value * 1;
                break;
            }
            case FieldType.String: {
                value = String(kvp.value);
                break;
            }
            case FieldType.Boolean: {
                value = kvp.value === 'false' ? false : !!kvp.value;
                break;
            }
            case FieldType.BooleanNullable: {
                if (kvp.value === 'null' || kvp.value === null)
                    break;
                value = kvp.value === 'false' ? false : !!kvp.value;
                break;
            }
            case FieldType.Date: {
                value = new Date(value).getTime();
                break;
            }
            case FieldType.StringsArray: {
                value = this.utilsInjected.parseToArr(kvp.value);
                value = value.map(e => e.toString());
                break;
            }
            case FieldType.NumbersArray: {
                value = this.utilsInjected.parseToArr(kvp.value);
                value = value.map(e => e * 1);
                break;
            }
            case FieldType.BooleansArray: {
                value = this.utilsInjected.parseToArr(kvp.value);
                value = value.map(e => e === 'false' ? false : !!e);
                break;
            }
            case FieldType.DatesArray: {
                value = this.utilsInjected.parseToArr(kvp.value);
                value = value.map(e => new Date(e).getTime());
                break;
            }
            default: {
                value = kvp.value;
            }
        }
        return value;
    }

    public getFilterFromQueryParams(params: Params): PagingFilter {
        let res = {} as PagingFilter;
        for (let i in params) {
            res[i] = this.normalizeField({
                key: i,
                value: params[i]
            });
        }
        return res;
    }

    getCurrentService(): any {
        if (this.graphFunction)
            return this.gqlInjected;
        return this.restInjected;
    }

    onDestroy() {
        this.scrollOffset = 0;
        window.scrollTo = this.scrollTo;
    }
}
