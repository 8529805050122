import { Injectable } from '@angular/core';
import { GraphqlService } from 'app/communication/graphql.service';
import { ArtistsFixReasons } from 'app/offer/offers';
import { UtilsService } from 'app/shared/utils.service';

@Injectable({ providedIn: 'root' })

export class FixReasonsService {
    public cachedFixReasons: Array<ArtistsFixReasons>;
    private isFetching: boolean;
    constructor(private gql: GraphqlService,
        private utils: UtilsService,) {
    }

    async fetch(): Promise<Array<ArtistsFixReasons>> {
        if (this.isFetching) return;
        this.isFetching = true;
        return new Promise((resolve, reject) => {
            this.gql.offersFixReasons().subscribe(
                res => {
                    this.isFetching = false;
                    resolve(res.data.artists_offers_fix_reasons);
                },
                err => {
                    this.isFetching = false;
                    this.utils.httpErrorResponseHandler(err, 'failure getting artists_offers_fix_reasons');
                    reject();
                }
            );
        });
    }

    async getCachedFixReasons() {
        if (!this.cachedFixReasons)
            this.cachedFixReasons = await this.fetch();
        return this.cachedFixReasons;
    }

}