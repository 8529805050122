import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-images-compare',
  templateUrl: './images-compare.component.html',
  styleUrls: ['./images-compare.component.scss']
})
export class ImagesCompareComponent {
  @Input() img1: string;
  @Input() img2: string;
  public opacity: number;
  constructor() {
    this.opacity = 0.5;
  }

  changeValue(value: number) {
    this.opacity = value * 1;
  }

}
