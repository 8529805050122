import { Injectable } from '@angular/core';
import { CategoriesService } from 'app/categories/categories.service';
import { GEOMETRY_HOURLY_PRICE } from 'app/shared/enums';
import { OfferPrice } from './offers';

@Injectable({
  providedIn: 'root'
})
export class OfferCreationHelperService {
  static ADDITIONAL_GEOMETRY_PRICE = 5;
  public isGeometry: boolean;
  public isGeometryOnly: boolean;
  public isTexture: boolean;
  public isTextureOnly: boolean;
  public isRender: boolean;
  public isRenderOnly: boolean;
  constructor(
    private categoriesService: CategoriesService
  ) { }


  async getDefaultPrices(subCat: number, numOfItems: number, geometryComplexityPrice: number, isMultipleOffers: boolean, sameMesh: boolean): Promise<OfferPrice> {
    const res = {} as OfferPrice;
    if (!this.categoriesService.didFetchFull) {
      await this.categoriesService.getFullCategoriesAsync();
    }
    const multiplyGeoItems = !sameMesh && !isMultipleOffers;
    const geometryPrice = ((geometryComplexityPrice ? geometryComplexityPrice * GEOMETRY_HOURLY_PRICE : 0) ||
      this.categoriesService.subCategoriesDictionary[subCat]?.geometry_price || 0);
    if (this.categoriesService.subCategoriesDictionary[subCat]?.additional_texture_price) {
      if (typeof this.categoriesService.subCategoriesDictionary[subCat].texture_price === 'number') {
        res.first_variation_price = this.categoriesService.subCategoriesDictionary[subCat].texture_price;
      }
      if (typeof this.categoriesService.subCategoriesDictionary[subCat].additional_texture_price === 'number') {
        res.additional_variation_price = this.categoriesService.subCategoriesDictionary[subCat].additional_texture_price;
      }
      if (!this.isTextureOnly && (this.isTexture || this.isGeometry)) {
        const gp = this.getBasePriceForGeometryOnly(multiplyGeoItems, geometryPrice, numOfItems);
        if (this.isGeometryOnly) {
          res.base_price = gp;
        } else if (typeof this.categoriesService.subCategoriesDictionary[subCat].texture_price === 'number') {
          res.base_price = this.getBasePriceForTextureOnly(res.first_variation_price, res.additional_variation_price, numOfItems, isMultipleOffers) + gp;
          // res.base_price = (this.categoriesService.subCategoriesDictionary[subCat].texture_price * (isMultipleOffers ? 1 : numOfItems)) + geometryPrice;
        }
      }
    }
    else if (this.isGeometry)
      res.base_price = geometryPrice;
    if (this.isGeometryOnly)
      res.base_price = this.getBasePriceForGeometryOnly(multiplyGeoItems, geometryPrice, numOfItems);;
    // Calc hours_to_complete by price
    if (res.base_price)
      res.base_price = Math.round(res.base_price);
    let bp = res.base_price;
    if (this.isTextureOnly)
      bp = this.getBasePriceForTextureOnly(res.first_variation_price, res.additional_variation_price, numOfItems, isMultipleOffers);
    res.hours_to_complete = (bp / GEOMETRY_HOURLY_PRICE) || 0;
    // Divid hours to complete by days
    res.hours_to_complete = Math.max(3, res.hours_to_complete + 3 + (Math.floor((res.hours_to_complete - 1) / 10) * 14));
    if (this.isRenderOnly)
      res.hours_to_complete = 24;
    return res;
  }

  getBasePriceForGeometryOnly(multiplyGeoItems: boolean, geometryPrice: number, numOfItems: number) {
    return multiplyGeoItems ? geometryPrice + ((numOfItems - 1) * OfferCreationHelperService.ADDITIONAL_GEOMETRY_PRICE) : geometryPrice;
  }

  getBasePriceForTextureOnly(firstVariationPrice: number, additionalVariationPrice: number, numOfItems: number, isMultipleOffers: boolean) {
    if (isMultipleOffers)
      return firstVariationPrice;
    else
      return firstVariationPrice + ((numOfItems - 1) * (additionalVariationPrice || 0));
  }
}
