export class ShareObject {
    constructor() {
        this.title = 'Creators3D';
        this.text = 'This link might interest you';
        this.url = window.location.href;
    }
    title: string;
    text: string;
    url: string;
}
