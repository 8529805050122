import { BehaviorSubject } from "../../../node_modules/rxjs";
import { JobsTypes } from "app/shared/enums";
import { PolyType } from "app/offer/offers";
import { Product, ProductsSubCategoriesAssociation } from "app/product/product";
import { ArtistsItem } from "app/job/job";
import { MeshLibrary } from "app/mesh-library/mesh-library";
import { DimensionsUnits } from "app/shared/utils";
import { AdjustmentsPreset } from "app/ui-components/asset-adjustments";

export interface Category {
    created_at: Date;
    description: string;
    id: number;
    parent_description: string;
    updated_at: string;
    // children: CategoryChildren;
    products_sub_categories: Array<ProductSubCategory>;
    rank?: number;
    children: Array<number>;
    parent_id: number;
  
    // avg_price?: number;
    // isChecked?: boolean;
}

export interface CategoryForEdit extends Category {
    products_sub_categories: Array<ProductSubCategoryForEdit>;
}

// export interface CategoryChildren {
//     productsubcategories: Array<ProductSubCategory>;
// }

export interface ProductSubCategory {
    category_id: number;
    created_at: Date;
    description: string;
    id: number;
    updated_at: Date;
    geometry_price: number;
    texture_price: number;
    additional_geometry_price: number;
    additional_texture_price: number;
    rank?: number;
    products_sub_categories_polygon_specifications: Array<ProductsSubCategoriesPolygonSpecification>;
    products_sub_categories_attachments: Array<ProductsSubCategoriesAttachment>;
    artists_items_sub_categories_associations: Array<ArtistsItemsSubCategoriesAssociation>;
    products_sub_categories_associations: Array<ProductsSubCategoriesAssociation>;
    products_sub_categories_mesh_libraries: Array<MeshLibrary>;
    products_sub_categories_dimensions: Array<ProductsSubCategoryDimensions>;
    artists_users_presets: Array<AdjustmentsPreset>;
    geometry_type?: number;
}

export interface ProductsSubCategoryDimensions {
    id: number;
    sub_category_id: number;
    height: number;
    width: number;
    length: number;
    units: DimensionsUnits;
}

export interface ProductSubCategoryForEdit extends ProductSubCategory {
    isSelected: boolean;
}

export interface ArtistsItemsSubCategoriesAssociation {
    id: number;
    created_at: Date;
    updated_at: Date;
    sub_category_id: number;
    product_id: number;
    products_sub_categories: Array<ProductSubCategory>
    products: Array<Product>
    artists_items: Array<ArtistsItem>;
}

export interface ProductsSubCategoriesAttachment {
    id: number;
    sub_category_id: number;
    title: string;
    attachment: string;
    sort_index: number;
    // file_type: AttachmentFileType;
    attachment_type: AttachmentFileType;
    job_type: JobsTypes;
    text: string;
}

export enum AttachmentFileType {
    // THREE_D_MODEL = 1,
    IMAGE = 2,
    GUIDE_IMAGE = 3,
    // GUIDE_IFRAME = 4,
    GUIDE_DOWNLOAD = 5,
    ZIP = 6,
    SOURCE_3D_FILE = 7,
    RICH_TEXT = 8,
}

export interface ProductsSubCategoriesPolygonSpecification {
    created_at: Date;
    id: number;
    max_poly_count: number;
    min_poly_count: number;
    poly_shape_type: number;
    poly_type: number;
    sub_category_id: number;
    updated_at: Date;
}

// export interface SubCategoryUI extends ProductSubCategory {
//     created_at: Date;
//     id: number;
//     max_poly_count: number;
//     min_poly_count: number;
//     poly_shape_type: number;
//     poly_type: number;
//     sub_category_id: number;
//     updated_at: Date;
// }

export interface CategoriesDictionaries {
    categoriesDictionary: { [id: number]: Category; };
    subCategories: Array<ProductSubCategory>;
    subCategoriesDictionary: { [id: number]: ProductSubCategory; };
}

export interface CategoryDefaultsPolyTypes {
    polyType: PolyType;
    value: Array<CategoryDefaults>;
}

export interface CategoryDefaults {
    jobType: JobsTypes;
    value: CategoryDefaultsInner;
}

export interface CategoryDefaultsInner {
    min: number;// poly
    max: number;// poly
    maxSize: number;// MB
}

export class CategoryNode {
    children: BehaviorSubject<Array<CategoryNode>>;
    description?: string;
    parent_description?: string;
    created_at: Date;
    id: number;
    updated_at: Date;
    products_sub_categories: Array<ProductSubCategory>;
    checked: boolean;
    type: CategoryNodeType;
    constructor(public item: any, children?: Array<CategoryNode>, type?: CategoryNodeType) {
        this.children = new BehaviorSubject(children === undefined ? [] : children);
        item.type = type;
    }
}

export enum CategoryNodeType {
    PARENT = 1,
    CATEGORY = 2,
    SUB = 3,
}

export enum GeometryType {  
    HARD = 1,
    ORGANIC = 2,
    MIXTURE = 3
}

export class SelectedCategories {
    constructor() {
        this.categories = [];
        this.subCategories = [];
    }
    categories?: Array<Category>;
    subCategories?: Array<ProductSubCategory>;
    AddToCategories(c) {
        this.categories.concat(c);
    }
    AddToSubCategories(c) {
        this.subCategories.concat(c);
    }
}