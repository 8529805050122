import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  transform(value: any, method?: string, f?: string, arg1?: any): any {
    const date = Date.parse(value) as any;
    if (!isNaN(date)) {
      switch (method) {
        case 'format': {
          return moment(date).format(f);
        }
        case 'fromNow': {
          let format = 'YYYY-MM-DD HH:mm:ss.SSS';
          // let format = 'YYYY-MM-DDTHH:mm:ss.SSZ';
          if (f) {
            format = f;
          }
          const date1 = moment(date).format(format);
          const stillUtc = moment.utc(date1).toDate();
          const local = moment(stillUtc).local().format(format);
          return moment(local).fromNow(arg1);
        }
        case 'fromNow2': {
          return moment(date).fromNow();
        }
        case 'toNow': {
          return moment(date).toNow(arg1);
        }
      }
      return moment(date).format('MMM Do YY'); //new Date(date).toUTCString();
    }
    return value;
  }
}
