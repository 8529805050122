import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductData } from 'app/product/product';

@Component({
    selector: 'app-offer-creation-enlarge-image',
    templateUrl: 'offer-creation-enlarge-image.component.html',
    styleUrls: ['offer-creation-enlarge-image.component.scss']
})
export class OfferCreationEnlargeImage {

    public url: string;
    public label: string;

    constructor(public dialogRef: MatDialogRef<OfferCreationEnlargeImage>, @Inject(MAT_DIALOG_DATA) private data: { item: ProductData, label: string }) {
        this.url = data.item.url;
        this.label = data.label;
    }

    public onCloseClick(): void {
        this.dialogRef.close();
    }
}

