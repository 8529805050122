import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { GraphqlService } from 'app/communication/graphql.service';
import { Group, ArtistsGroupsUsersFilter, ArtistsGroupUser } from '../group';
import { User } from '../user';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'app-user-groups-select',
  templateUrl: './user-groups-select.component.html',
  styleUrls: ['./user-groups-select.component.scss']
})
export class UserGroupsSelectComponent implements OnInit {
  @Input('placeholder') placeholder: string;
  @Input('clear-on-select') clearOnSelect: boolean;
  @Output('on-group-change') onGroupChange: EventEmitter<Group>;
  public items: Promise<Array<Group>>;
  public artists: Array<User>;
  constructor(
    private utils: UtilsService,
    private gql: GraphqlService
  ) {
    this.onGroupChange = new EventEmitter<Group>();
  }

  ngOnInit() {
    this.placeholder = this.placeholder || 'group name';
    this.refresh();
  }

  refresh() {
    this.gql.artistsGroupsSlim({ limit: 100, offset: 0 }).subscribe(
      data => {
        this.items = this.utils.deepCopyByValue(data.data.allArtistsGroups.rows);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure getting groups')
    );
  }

  setGroup(group: Group) {
    if (group) {
      let o = {
        group_id: group.id,
        limit: 100,
        offset: 0
      } as ArtistsGroupsUsersFilter;
      this.gql.artistsGroupUsers(o).subscribe(
        data => {
          let users = this.utils.deepCopyByValue(data.data.allArtistsGroupsUsers.rows);
          // if (!group.artists_groups_users)
          //   group.artists_groups_users = [];
          // if (!group.artists_groups_users[0])
          //   group.artists_groups_users[0] = {} as ArtistsGroupUser;
          // group.artists_groups_users[0].artists_users = users as Array<User>;
          group.artists_groups_users = users;
          this.onGroupChange.emit(group);
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure getting group users')
      );
    }
  }
}