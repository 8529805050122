import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { UserQueryData } from '../user';
import { Observable } from 'rxjs';

@Injectable()
export class SocialResolve  {

    constructor(private auth: AuthService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const token = route.queryParams.token;
        this.auth.storeToken(token);
        const userId = route.queryParams.user_id;
        if (!userId) return null;
        return this.auth.fetchUser(parseInt(userId, 10)) as Observable<ApolloQueryResult<UserQueryData>>;
    }
}
