<div class="wrap">
    <h3>
        Your upload fails, please make sure:
    </h3>
    <ol>
        <li>
            The directory contains only the relevant files, only one obj + mtl / GLB / glTF + bin / FBX and texture files
        </li>
        <li>
            Textures are only png/jpg format
        </li>
        <li>
            The directory is zipped
        </li>
    </ol>
    <p>
        If you need to fix some of these points, fix it and upload again
    </p>
    <div class="gray" *ngIf="data && data.err && data.err.error">
        <p>
            Additional Data:
        </p>
        <ul>
            <li>
                status: {{data.err.status}} ({{data.err.statusText}})
            </li>
            <li>
                {{data.err.error}}
            </li>
        </ul>
    </div>
    <button mat-raised-button color="primary" (click)="onNoClick()">
        O.K.
    </button>
</div>