import { Injectable } from '@angular/core';
import { CachedOffer, Offer, OfferQueryData, OfferRequest, NativeResourceSet, ArtistsOffersTypes, OfferUIOptions, OfferJobTypes, OfferRequestWrapper, PolyType, OfferResponseData, OfferPrice, KeyValuePoly, ArtistsOffersPrivateComments } from './offers';
import { Subject, map } from 'rxjs';
import { GraphqlService } from '../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { MapperService } from '../shared/mapper.service';
import { RestService } from '../communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { Notification, NotificationType } from '../shared/notifications';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { JobsFilterOptions, ArtistsItemSpec, JobsQueryData, ArtistsItemSpecUI, Job } from '../job/job';
import { PricingHelperService } from '../shared/pricing-helper.service';
import { UtilsService } from '../shared/utils.service';
import { PixelsService } from 'app/shared/pixels.service';
import { ResourceDimensions, MediaTag, ArtistsResourcesFeedback } from 'app/job/resource';
import { CategoryDefaults, AttachmentFileType } from 'app/categories/category';
import { CategoriesService } from 'app/categories/categories.service';
import { JobTypes } from 'app/job-type/jot-type';
import { JobsTypesService } from 'app/job-type/jobs-types.service';
import { RolesHelperService } from 'app/auth/roles-helper.service';
import { KeyValuePair, JobsTypes } from 'app/shared/enums';
import { EnumsService } from 'app/shared/enums.service';
import { ValidateResponse } from 'app/shared/utils';
import { User } from 'app/auth/user';
import { ListRowsResponse } from 'app/communication/custom-request';
import { StorageService } from 'ng-storage-service';
import { Product, ProductFilterOptions, ProductsSubCategoriesAssociation, ResourceType } from 'app/product/product';
import { Group } from 'app/auth/group';
import { MeshLibraryDialogComponent } from '../mesh-library/mesh-library-dialog/mesh-library-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AllLibraries, ArtistLibrary, MeshLibraryWrapper, ArtistMesh } from '../mesh-library/mesh-library';
import { cloneDeep } from '@apollo/client/utilities';
import { ResourceTypesService } from 'app/product/resource-types.service';

@Injectable()
export class OfferService {
  static HOURS_TO_COMPLETE = 12;
  static MAX_OFFER_PRICE = 200;
  static MAX_HOURS_RANK_1 = 24;
  static MAX_OFFER_PRICE_CODE = 'YIGJIG';
  static MAX_SOURCE_TABLE_WEIGHT = 100;
  static IMPORTANT_SOURCE_TABLE_WEIGHT = 50;
  static OFFERS_TAKEN = 'offersTaken';
  static DECLINE_OFFERS = 'declineOffers';
  static MAX_DECLINES_PER_DAY = 3;
  private currentOffer: CachedOffer;
  private jobTypes: Array<JobTypes>;
  private isAdmin: boolean;
  public onOfferChange: Subject<Offer>;
  public artistsItemsDimensions: { [id: number]: ResourceDimensions };
  // public additionalDownloads: Array<ArtistsItemDownload>;
  public priorities: Array<KeyValuePair>;
  public geometrytypes: Array<KeyValuePair>;
  public prioritiesDictionary: { [id: number]: string };
  public manager: User;
  public feedbacker: User;
  public qualitySupervisor: User;
  public fetchingManager: boolean;
  public fetchingQualitySupervisor: boolean;
  public fetchingFeedbacker: boolean;
  public artistsItemsSpecs: Array<ArtistsItemSpecUI>;
  public polyTypesArray: KeyValuePoly[];
  public formatsDictionary: { [id: number]: ResourceType; };
  public allFormats: Array<ResourceType>;
  
  constructor(
    private gql: GraphqlService,
    private mapper: MapperService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private auth: AuthService,
    private pricingHelper: PricingHelperService,
    private utils: UtilsService,
    private pixelsService: PixelsService,
    private categoriesService: CategoriesService,
    private jobsTypesService: JobsTypesService,
    private roles: RolesHelperService,
    private enums: EnumsService,
    private dialog: MatDialog,
    private storage: StorageService,
    private resourceTypesService: ResourceTypesService,
  ) {

    // this.additionalDownloads = [];
    this.artistsItemsDimensions = {};
    this.artistsItemsSpecs = [];
    this.onOfferChange = new Subject<Offer>();
    this.setTypes(true);
    this.isAdmin = this.roles.isAdminLogedin();
    this.priorities = this.enums.getPriorities();
    this.prioritiesDictionary = this.enums.getPrioritiesDictionary();
    this.geometrytypes = this.enums.getGeometryTypes();
    this.polyTypesArray = Object.values(this.enums.getPolyTypesDictionary());

    this.formatsDictionary = {};
    this.allFormats = this.resourceTypesService.getCachedTypes();
    if (this.allFormats) {
      this.allFormats.forEach(f => this.formatsDictionary[f.id] = f);
    } else {
      this.resourceTypesService.getResourceTypes().subscribe(
        () => {
          this.allFormats = this.resourceTypesService.getCachedTypes();
        }
      )
    }
  }

  get totalOffersTaken() {
    return parseInt(this.storage.get(OfferService.OFFERS_TAKEN) || 0) || 0;
  }

  set totalOffersTaken(value: number) {
    this.storage.set(OfferService.OFFERS_TAKEN, value);
  }

  get totalDeclineOffer() {
    let declines = this.storage.get(OfferService.DECLINE_OFFERS);
    if (!declines)
      return 0;
    if (declines.date == this.getTotalDeclineOfferDate())
      return declines.counter;
    return 0;
  }

  set totalDeclineOffer(value: number) {
    let currentDate = this.getTotalDeclineOfferDate();
    let declines = this.storage.get(OfferService.DECLINE_OFFERS) || {
      date: currentDate,
      counter: 0
    };
    if (declines.date != currentDate)
      declines.counter = 1;
    else
      declines.counter++;
    this.storage.set(OfferService.DECLINE_OFFERS, declines);
  }

  private getTotalDeclineOfferDate() {
    const date = new Date();
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
  }

  async setTypes(force: boolean) {
    if (!force && this.jobTypes) {
      return;
    }
    const cachedJobTypes = await this.jobsTypesService.getCachedTypes();
    this.jobTypes = this.utils.deepCopyByValue(cachedJobTypes);
  }

  getCurrent() {
    return this.currentOffer;
  }

  deleteCurrent() {
    delete this.currentOffer;
  }

  setCurrentAndGetFull(offer: Offer) {
    this.currentOffer = offer;
    this.currentOffer.isCached = true;
    this.gql.offer(this.currentOffer.offer_uuid).subscribe(
      (o: ApolloQueryResult<OfferQueryData>) => {
        this.onOfferChange.next(o.data.artists_offers);
      }
    )
  }

  setArtistsItemsSpecs(item: Offer, currentAOI: number) {
    this.artistsItemsSpecs = [];
    if (item.artists_offers_items[currentAOI] && item.artists_offers_items[currentAOI].artists_items[0].artists_items_specs)
      item.artists_offers_types.forEach(aot => {
        item.artists_offers_items[currentAOI].artists_items[0].artists_items_specs.filter(ais => ais.job_type === aot.type_id || ais.job_type === null).forEach(
          s => {
            if (!this.artistsItemsSpecs.find(inS => inS.id === s.id)) {
              const icon = this.utils.getIconByUrl(s.attachment);
              this.artistsItemsSpecs.push({
                attachment: s.attachment,
                attachment_type: s.attachment_type,
                created_at: s.created_at,
                id: s.id,
                item_id: s.id,
                job_type: s.job_type,
                sort_index: s.sort_index,
                title: s.title,
                updated_at: s.updated_at,
                isImage: icon === 'photo',
                fileExtension: this.utils.getFileExtension(s.attachment),
                icon,
                source_table: s.source_table,
                mandatory: this.isSpecMandatory(s),
                isOffer: true,
                referenceId: item.id,
                text: s.text
              });
            }
          }
        );
        this.artistsItemsSpecs.sort(this.sortSpecs);
        this.artistsItemsSpecs.sort(this.sortSpecsByType.bind(this));
        // this.artistsItemsSpecs = this.artistsItemsSpecs.concat(item.artists_offers_items[this.currentAOI].artists_items[0].artists_items_specs.filter(ais => ais.job_type == aot.type_id || ais.job_type === null));
      });
    if (item.artists_offers_items[currentAOI] && item.artists_offers_items[currentAOI].artists_items[0].artists_items_downloads) {
      let arr = item.artists_offers_items[currentAOI].artists_items[0].artists_items_downloads.filter(aid => aid.attachment_type != AttachmentFileType.GUIDE_IMAGE).sort(this.sortSpecs).sort(this.sortSpecsByType.bind(this));
      arr.forEach(id => {
        const icon = this.utils.getIconByUrl(id.url);
        this.artistsItemsSpecs.push({
          attachment_type: id.attachment_type,
          attachment: id.url,
          item_id: id.item_id,
          title: '',
          sort_index: id.sort_index,
          job_type: id.job_type,
          isImage: icon == 'photo',
          fileExtension: this.utils.getFileExtension(id.url),
          icon
        } as ArtistsItemSpecUI);
      });
    }
    // this.artistsItemsSpecs.sort((s1, s2) => {
    //   return (s1.isImage ? 1 : 0) -  (s2.isImage ? 1 : 0);
    // });
    // this.artistsItemsSpecs = this.artistsItemsSpecs.concat(item.artists_offers_items[this.currentAOI].artists_items[0].artists_items_specs.filter(ais => item.artists_offers_types.find(aot => aot.type_id == ais.item_id)));
  }


  sortSpecs(a: any, b: any): number {
    return a.sort_index - b.sort_index;
    // if (a.sort_index > b.sort_index) {
    //   return -1;
    // }
    // if (b.sort_index > a.sort_index) {
    //   return 1;
    // }
    // return 0;
  }

  getSourceTableWeights() {
    return {
      'retailers_sub_categories_specifications': 1,
      'products_sub_categories_attachments': OfferService.IMPORTANT_SOURCE_TABLE_WEIGHT,
      null: OfferService.MAX_SOURCE_TABLE_WEIGHT,
      undefined: OfferService.MAX_SOURCE_TABLE_WEIGHT
    };
  }

  sortSpecsByType(a: any, b: any): number {
    const weights = this.getSourceTableWeights();
    return weights[a.source_table] - weights[b.source_table];
  }

  setOfferUI(offer: Offer, options = new OfferUIOptions(), userGroup?: Array<Group>): Offer {
    if (!offer) {
      return;
    }
    if (offer.hours_to_complete) {
      this.artistsItemsDimensions = {};
    }

    let images = [] as Array<NativeResourceSet>;
    const oTypes = this.getOfferJobTypes(offer);
    const isTexture = !!oTypes.find(t => t.id === JobsTypes.TEXTURE && t.relevant);
    const isGeometry = !!oTypes.find(t => t.id === JobsTypes.GEOMETRY && t.relevant);
    const isRender = !!oTypes.find(t => t.id === JobsTypes.RENDR && t.relevant);
    let isHighPoly = false;
    let artistMinimumRelevantRank = 0;
    if (this.auth.user) {
      artistMinimumRelevantRank = this.auth.user.rank;
    }
    if (offer.artists_offers_items) {
      let counter = 0;
      let aoiCounter = 0;
      offer.artists_offers_items.forEach(aoi => {
        let polyTypes = [];
        aoi.artists_items.forEach(ai => {
          // maxComplexity = (typeof ai.complexity === 'number' && ai.complexity > maxComplexity) ? ai.complexity : maxComplexity;
          this.artistsItemsDimensions[aoiCounter] = {
            width: ai.width,
            height: ai.height,
            length: ai.length,
            units: ai.units
          };
          if (ai.artists_items_specs) {
            ai.artists_items_specs.sort((s1: ArtistsItemSpec, s2: ArtistsItemSpec) => {
              return s1.sort_index - s2.sort_index;
            });
          }
          this.artistsItemsDimensions[aoiCounter] = this.utils.convertToCM(this.artistsItemsDimensions[aoiCounter]);
          aoiCounter++;
        });
        const currentImages = this.mapper.getImagesFromOffer(offer, counter++);
        images = images.concat(currentImages);
        if (options?.polyTypes) {
          polyTypes = this.utils.getKeyValuePolyByPolySpecs(aoi.artists_offers_items_polygon_specifications, true, this.getSubCatDefaults(offer));
          aoi.UI = {
            images: currentImages,
            polyTypes
          };
        }
        isHighPoly = isHighPoly || !!aoi.artists_offers_items_polygon_specifications?.find(s => s.poly_type === PolyType.HIGH);
      });
      images.forEach(i => {
        if (i.sort_index == null) {
          i.sort_index = images.length;
        }
      });
      if (typeof options?.modelSortPos === 'number' && options?.modelSortPos != 1) {
        images.forEach(i => {
          if (i.type === MediaTag.MODEL) {
            i.sort_index = options.modelSortPos;
          }
        });
      }
      images.sort((a: NativeResourceSet, b: NativeResourceSet) => {
        if (a.sort_index < b.sort_index) {
          return -1;
        } else if (a.sort_index > b.sort_index) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    offer.artists_offers_pricing.forEach(a => {
      a.allow_rank_bonus = offer.allow_rank_bonus;
      a.allow_time_bonus = offer.allow_time_bonus;
      a.rank_bonus = 0;
      a.time_bonus = 0;
    });

    let defaultPrice = offer.artists_offers_pricing.find(aop => aop.group_id == null && aop.active);
    if (!defaultPrice) {
      defaultPrice = offer.artists_offers_pricing.find(aop => aop.group_id == null);
    }
    let price = [defaultPrice ? defaultPrice.base_price : 0];
    if (userGroup?.length > 0 && userGroup[0].id) {
      price = price.concat(offer.artists_offers_pricing.filter(g => userGroup.some(u => u.id === g.group_id)).map(a => a.base_price));
    }

    offer.UI = {
      price: Math.max(...price),
      images,
      sufficientRank: !offer.public ? true : (this.auth.user ? artistMinimumRelevantRank >= offer.complexity : true),
      maxBonus: this.pricingHelper.getMaxBonus(offer.artists_offers_pricing[0]?.base_price, offer.allow_time_bonus, offer.allow_rank_bonus),
      userBonus: this.pricingHelper.getCurrentBonus(this.auth.user, offer.artists_offers_pricing[0]?.base_price,
        this.utils.getMinPossibleDate(), this.utils.getMaxPossibleDate(), null, offer.allow_time_bonus, offer.allow_rank_bonus, isGeometry, isTexture),
      isMulti: this.isMultiple(offer),
      offerJobTypes: oTypes,
      actionsTooltip: null,
      isGeometry,
      isTexture,
      showHoursToPerfect: false,
      isChecked: false,
      orgPrice: null,
      index: options?.index,
      isHighPoly
    }
    if (defaultPrice && offer.UI.price > defaultPrice.base_price) {
      offer.UI.orgPrice = defaultPrice.base_price;
    }
    offer.UI.actionsTooltip = this.getActionsTooltip(offer);
    if (offer.artists_offers_items[0].artists_items && offer.artists_offers_items[0].artists_items[0] && offer.artists_offers_items[0].artists_items[0].product_id) {
      offer.artists_offers_items.sort((i1, i2) => {
        return i2.artists_items[0].product_id - i1.artists_items[0].product_id;
      });
    }
    return offer;
  }


  /**
   * Fetch Mesh libraries by sub category id (if exist)
   * @param subcatid - id of subCategory
   */
  public getMeshLib(subcatid: number): Observable<ApolloQueryResult<AllLibraries>> {
    return this.gql.meshLibraries([subcatid]);
  }

  /**
   * Open mesh libraries panel
   * @param meshLibraries - all mesh libraries
   * @param subCatName - the name of subcategory
   * @param selectedMesh - the mesh that was selected in preview (if exists)
   */
  public openMeshLibrary(meshLibraries: Array<ArtistLibrary>, subCatName: string, selectedMesh: ArtistMesh = null): void {
    this.dialog.open(MeshLibraryDialogComponent, {
      width: window.innerWidth < 1270 ? '90vw' : '1270px',
      data: {
        meshLibraries: meshLibraries,
        subCatdesc: subCatName,
        selectedMesh
      } as MeshLibraryWrapper
    });
  }

  isSpecMandatory(spec: ArtistsItemSpec) {
    // "products_sub_categories_attachments"
    return spec.source_table === 'retailers_sub_categories_specifications';
  }

  getActionsTooltip(item: Offer): string {
    if (item.UI.sufficientRank) {
      return null;
    }
    const rank = this.getArtistRank(item.UI.isGeometry, item.UI.isTexture);
    return `minimum trust level for this job is ${item.complexity}, yours is ${rank}`;
  }

  getArtistRank(isGeometry: boolean, isTexture: boolean): number {
    if (this.auth.user) {
      return this.auth.user.rank;
    }
    return 0;
  }

  getOfferJobTypes(offer: Offer): Array<OfferJobTypes> {
    const offerJobTypes = [];
    if (this.jobTypes) {
      this.jobTypes.forEach(jt => {
        offerJobTypes.push({
          description: jt.description,
          id: jt.id,
          relevant: !!offer.artists_offers_types.find(aoi => aoi.type_id == jt.id)
        })
      });
    }
    return offerJobTypes;
  }

  async syncGeometryComplexity(request: OfferRequest, wrapper?: OfferRequestWrapper) {
    if (request.estimated_geometry_hours) {
      for (let i = 0; i < request.items.length; i++) {
        const p = await this.getProduct(request.items[i].id);
        if (p.estimated_geometry_hours !== request.estimated_geometry_hours) {
          p.estimated_geometry_hours = request.estimated_geometry_hours;
          await this.saveProduct(p);
        }
      }
      if (wrapper?.selectedItems) {
        const itemsToSync = wrapper.selectedItems.filter(si => si.estimated_geometry_hours !== request.estimated_geometry_hours);
        for (let i = 0; i < itemsToSync.length; i++) {
          itemsToSync[i].estimated_geometry_hours = request.estimated_geometry_hours;
          await this.saveProduct(itemsToSync[i]);
        }
      }
    }
  }

  async createOffer(request: OfferRequest, cb: Function, targetPrice: OfferPrice) {
    if (request.multiple_offers && request.same_mesh) {
      request.same_mesh = false;
      const n: Notification = {
        text: 'canceling "same mesh" option due to "multiple offers" flag',
        type: NotificationType.Info,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', n);
    }
    if (request.artists_offers_types.find(o => o.type_id === JobsTypes.GEOMETRY))
      await this.syncGeometryComplexity(request);
    const exec = async () => {
      this.rest.artistOffer('post', request).subscribe(
        (offers: Array<Offer>) => {
          const n: Notification = {
            text: 'offers successfully created',
            type: NotificationType.Success,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', n);
          if (request.items) {
            request.items.forEach(
              i => {
                this.pixelsService.sendPixel({
                  event: "sendToOffers",
                  reference_id: i.id
                });
              }
            );
          }
          if (cb)
            cb(offers);
          offers.forEach(offer => {
            this.pixelsService.sendPixel({
              event: 'offer_price_by_complexity',
              offer_id: offer.id,
              recommended_base_price: targetPrice.base_price,
              recommended_additional_variation_price: targetPrice.additional_variation_price,
              recommended_first_variation_price: targetPrice.first_variation_price
            });
          });
        },
        async err => {
          if (err && err.error && err.error.indexOf('Product ID: ') > -1) {
            let msg = `${err.error}\r\n\r\nAre you sure you want to create another offer with the same job-type?`;
            if (err.error.indexOf('does not have valid measurements') > -1) {
              msg = `${err.error}\r\n\r\nAre you sure you want to create offers without valid measurements?`;
            }
            if (confirm(msg)) {
              request.force = true;
              await exec();
            }
          } else if (err && err.error && err.error.indexOf('Offer does not contain all variations of serial number: ') > -1) {
            if (confirm(`${err.error}\r\n\r\nAre you sure you want to create an offer without all of the product\'s variations?`)) {
              request.force = true;
              await exec();
            }
          } else if (err && err.error && err.error.indexOf('Offer does not contain all variations of product name: ') > -1) {
            if (confirm(`${err.error}\r\n\r\nAre you sure you want to create an offer without all of the product\'s names?`)) {
              request.force = true;
              exec();
            }
          } else if (err && err.error && err.error.indexOf('duplicate key value violates unique constraint') > -1) {
            const n: Notification = {
              text: 'Variation name already exists. Please enter a unique name!',
              type: NotificationType.Error,
              action: 'OK'
            }
            this.broadcaster.broadcast('notifyUser', n);
          } else {
            this.utils.httpErrorResponseHandler(err, 'failure creating offers');
          }
        }
      );
    }
    if (request.base_price >= OfferService.MAX_OFFER_PRICE) {
      const code = prompt(`Maximum base price is ${OfferService.MAX_OFFER_PRICE - 1}$ while current price is ${request.base_price}$. Either lower base price or insert Yehiel's code:`);
      if (code !== OfferService.MAX_OFFER_PRICE_CODE) {
        return;
      }
    }
    exec();
  }

  // getArtistsCategoryRank(categoryId): ArtistsUsersCategoryRank {
  //   let res = null as ArtistsUsersCategoryRank;
  //   if (this.auth.user.artists_users_categories_rankings)
  //     res = this.auth.user.artists_users_categories_rankings.find(acr => acr.category_id == categoryId);
  //   if (!res) {
  //     res = {
  //       category_id: categoryId,
  //       artist_user_id: this.auth.user.id,
  //       rank: 0
  //     }
  //   }
  //   return res;
  // }

  // GetRelevantArtistCatogories(user: User, offer: Offer): Array<ArtistsUsersCategoryRank> {
  //   let result = [] as Array<ArtistsUsersCategoryRank>;
  //   if (!user) return result;
  //   let catIds = [];
  //   offer.artists_offers_items.forEach(aoi => catIds = catIds.concat(aoi.artists_items.map(ai => ai.category_id)))
  //   // const catIds = offer.artists_offers_items.artists_items.map(ai => ai.category_id);
  //   catIds.forEach(id => {
  //     const parentCat = this.categoriesService.parentCategoriesToParentDictionary[id];
  //     const c = user.artists_users_categories_rankings instanceof Array ? user.artists_users_categories_rankings.find(acr => acr.parent_category_id == parentCat.parent_id) : null;
  //     if (c)
  //       result.push(c);
  //     else {
  //       result.push({
  //         artist_user_id: user.id,
  //         parent_category_id: parentCat.parent_id,
  //         rank: 0
  //       } as ArtistsUsersCategoryRank);
  //     }
  //   });
  //   return result;
  // }

  // getMissingRank(user: User, offer: Offer): ArtistsUsersCategoryRank {
  //   let res = null;
  //   if (!offer.category_id) return res;
  //   const cRanks = this.GetRelevantArtistCatogories(user, offer);
  //   offer.artists_offers_items.forEach(aoi => {
  //     aoi.artists_items.forEach(ai => {
  //       const parentCat = this.categoriesService.parentCategoriesToParentDictionary[ai.category_id];
  //       const c = cRanks.find(cr => cr.parent_category_id == parentCat.parent_id);
  //       if (c && ai.complexity > c.rank)
  //         res = c;
  //     });
  //   })
  //   return res;
  // }

  getArtistCurrentCapacity(artistUserId: number): Observable<ApolloQueryResult<JobsQueryData>> {
    const options = {
      limit: 0,
      offset: 0,
      status: UtilsService.ARTIST_ACTIVE_JOBS_STATS,
      artist_user_id: [artistUserId],
      is_archived: false,
      is_paused: false
    } as JobsFilterOptions;
    return this.gql.jobs(options);
  }

  async getArtistActiveJobs(artistUserId: number): Promise<ListRowsResponse> {
    return new Promise(async (resolve, reject) => {
      const options = {
        limit: 1,
        offset: 0,
        status: UtilsService.ARTIST_ACTIVE_JOBS_STATS,
        artist_user_id: [artistUserId],
        is_archived: false,
        is_desc: true,
        order_by: 'created_at'
      } as JobsFilterOptions;
      this.gql.getArtistActiveJobs(options).subscribe(res => {
        resolve(res.data.allJobs);
      });
      // this.getArtistCurrentCapacity(artistUserId).subscribe(res => {
      //   resolve(res.data.allJobs.count);
      // })
    });
  }

  getAtistCurrentTotalCapacity(artistUserId: number): Observable<ApolloQueryResult<JobsQueryData>> {
    const options = {
      limit: 0,
      offset: 0,
      status: UtilsService.ARTIST_ACTIVE_TOTAL_JOBS_STATS,
      artist_user_id: [artistUserId],
      is_archived: false
    } as JobsFilterOptions;
    return this.gql.jobs(options);
  }

  isMultiple(item: Offer): boolean {
    let res = item.artists_offers_items.length > 1;
    if (item.same_mesh && item.artists_offers_types && item.artists_offers_types.find(i => i.type_id == JobsTypes.GEOMETRY)) {
      if (!this.isAdmin)
        res = false;
    }
    return res;
  }

  getArtistsOffersTypes(types: Array<number>): Array<ArtistsOffersTypes> {
    let res = [] as Array<ArtistsOffersTypes>;
    types.forEach(t => res.push({ type_id: t } as ArtistsOffersTypes));
    return res;
  }

  public saveProduct(item: Product): Promise<Product> {
    return new Promise(async (resolve, reject) => {
      let query = '';
      if (item.id)
        query = '/' + item.id;
      this.rest.product(item.id ? 'put' : 'post', item, query).subscribe(
        () => {
          const updatedProduct = this.getProduct(item.id);
          Object.assign(item, updatedProduct);
          resolve(item);
          // this.gql.product(item.id).subscribe(p => {
          //   Object.assign(item, p.data.products);
          //   resolve(item);
          // });
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async getProduct(id: number): Promise<Product> {
    const p = await this.utils.observableToPromise(this.gql.product(id));
    return cloneDeep(p.data.products);
  }

  async syncRequestCategories(wrapper: OfferRequestWrapper): Promise<OfferRequestWrapper> {
    const firstCatId = wrapper.request.category_id;
    const firstSubCatId = wrapper.request.subCategoryId;
    if (!firstCatId || !firstSubCatId) return wrapper;

    // All products should have the same category
    if (wrapper.currentItem && wrapper.request.category_id != wrapper.currentItem.category_id) {
      wrapper.currentItem.category_id = wrapper.request.category_id;
      await this.saveProduct(wrapper.currentItem);
    }
    const updateProductSubCategory = async (p: Product): Promise<Product> => {
      let sc = {
        product_id: wrapper.currentItem.id,
        sub_category_id: wrapper.request.subCategoryId,
        products_sub_categories: [{
          category_id: wrapper.request.category_id
        }]
      } as ProductsSubCategoriesAssociation;
      p.products_sub_categories_associations = [sc];
      return await this.saveProduct(p);
    }
    if (!wrapper.request.multiple_offers) {
      if (wrapper.currentItem) {
        const fSubCatId = (wrapper.currentItem.products_sub_categories_associations && wrapper.currentItem.products_sub_categories_associations[0]) ? wrapper.currentItem.products_sub_categories_associations[0].sub_category_id : null;
        // At least 1 product doesn\'t have a Sub Category!
        if (!fSubCatId || wrapper.currentItem.products_sub_categories_associations.length > 1) {
          wrapper.currentItem = await updateProductSubCategory(wrapper.currentItem);
        }

        if (wrapper.currentItem && wrapper.currentItem.products_sub_categories_associations?.length > 1 || !wrapper.currentItem.products_sub_categories_associations
          || !wrapper.currentItem.products_sub_categories_associations[0]
          || fSubCatId != wrapper.currentItem.products_sub_categories_associations[0].sub_category_id
        ) {
          // All of the product must have the same Sub Category in case of a none-multiple offers
          wrapper.currentItem = await updateProductSubCategory(wrapper.currentItem);
        }
      }
      if (wrapper.selectedItems) {
        for (let i = 0; i < wrapper.selectedItems.length; i++) {
          // for (let selectedItem of wrapper.selectedItems) {
          if (!wrapper.selectedItems[i].products_sub_categories_associations[0] || firstSubCatId != wrapper.selectedItems[i].products_sub_categories_associations[0].sub_category_id || wrapper.selectedItems[i].products_sub_categories_associations.length > 1) {
            // All of the product must have the same Sub Category in case of a none-multiple Offers!
            wrapper.selectedItems[i] = await updateProductSubCategory(wrapper.selectedItems[i]);
          }
          if (firstCatId != wrapper.selectedItems[i].category_id) {
            // All of the product must have the same Category in case of a none-multiple Offers!
            wrapper.selectedItems[i].category_id = firstCatId;
            wrapper.selectedItems[i] = await this.saveProduct(wrapper.selectedItems[i]);
          }
        }
      }
    } else {
      if (wrapper.selectedItems) {
        for (let selectedItem of wrapper.selectedItems) {
          if (!selectedItem.category_id) {
            selectedItem.category_id = firstCatId;
            await this.saveProduct(selectedItem);
          }
        }
      }
    }
    return wrapper;
  }

  // validateOfferRequest(request: OfferRequest, currentItem?: Product, selectedItems?: Product[]): ValidateResponse {
  validateOfferRequest(wrapper: OfferRequestWrapper): ValidateResponse {
    let res = {
      isValid: true
    } as ValidateResponse;


    if (wrapper.currentItem && wrapper.request.category_id != wrapper.currentItem.category_id) {
      res.isValid = false;
      res.reason = 'All products should have the same category';
      return res;
    }


    let firstCatId;
    let firstSubCatId;

    if (wrapper.selectedItems && wrapper.selectedItems[0]?.category_id) {
      firstCatId = wrapper.selectedItems[0].category_id;
      firstSubCatId = wrapper.selectedItems[0].products_sub_categories_associations[0] ? wrapper.selectedItems[0].products_sub_categories_associations[0].sub_category_id : null;
    }

    if (!wrapper.request.multiple_offers) {
      if (wrapper.currentItem) {
        const fSubCatId = (wrapper.currentItem.products_sub_categories_associations && wrapper.currentItem.products_sub_categories_associations[0]) ? wrapper.currentItem.products_sub_categories_associations[0].sub_category_id : null;
        if (!fSubCatId) {
          res.isValid = false;
          res.reason = 'At least 1 product doesn\'t have a Sub Category!';
          return res;
        }

        if (wrapper.currentItem && !wrapper.currentItem.products_sub_categories_associations[0]
          || fSubCatId != wrapper.currentItem.products_sub_categories_associations[0].sub_category_id
        ) {
          res.isValid = false;
          res.reason = 'All of the product must have the same Sub Category in case of a none-multiple offers';
          return res;
        }
      }
      if (wrapper.selectedItems) {
        for (let selectedItem of wrapper.selectedItems) {
          if (firstCatId != selectedItem.category_id) {
            res.isValid = false;
            res.reason = 'All of the product must have the same Category in case of a none-multiple Offers!';
            return res;

          }
          if (!selectedItem.products_sub_categories_associations[0] || firstSubCatId != selectedItem.products_sub_categories_associations[0].sub_category_id) {
            res.isValid = false;
            res.reason = 'All of the product must have the same Sub Category in case of a none-multiple Offers!';
            return res;

          }
        }
      }
    } else {
      if (wrapper.request.multiple_offers && !wrapper.selectedItems?.length) {
        res.isValid = false;
        res.reason = 'Select at least 1 product first!';
        return res;

      }
      if (!firstCatId) {
        res.isValid = false;
        res.reason = 'At least 1 product doesn\'t have a Category!';
        return res;

      }

      if (!firstSubCatId) {
        res.isValid = false;
        res.reason = 'At least 1 product doesn\'t have a Sub Category!';
        return res;

      }
      if (!wrapper.request.artists_offers_types.length) {
        res.isValid = false;
        res.reason = 'Select at least 1 job type first!';
        return res;

      }
      if (wrapper.selectedItems) {
        for (let selectedItem of wrapper.selectedItems) {
          if (selectedItem.UI.resourceTypes.length == 0) {
            res.isValid = false;
            res.reason = 'Define resource types on "' + selectedItem.name + '" first!';
            return res;
          }

          if (!selectedItem.category_id) {
            res.isValid = false;
            res.reason = 'Set category on "' + selectedItem.name + '" first!';
            return res;
          }
        }
      }

    }
    if (!wrapper.request.created_by) {
      res.isValid = false;
      res.reason = 'Created by is mandatory';
      return res;
    }
    if (!wrapper.request.category_id) {
      res.isValid = false;
      res.reason = 'Category ID is mandatory';
      return res;
    }
    if (!wrapper.request.hours_to_complete) {
      res.isValid = false;
      res.reason = 'Hours to complete by is mandatory';
      return res;
    }
    if (typeof wrapper.request.complexity !== 'number') {
      res.isValid = false;
      res.reason = 'Complexity is mandatory';
      return res;
    }
    if (!wrapper.request.artists_offers_types || !wrapper.request.artists_offers_types.length) {
      res.isValid = false;
      res.reason = 'Offer type is mandatory (Geometry, Texture or Render)';
      return res;
    }
    // The estimated_geometry_hours field is probebly not expected on the offer POST route but we will make sure the user has selected it and that we have assigned it to all products
    else if (wrapper.request.artists_offers_types.find(t => t.type_id === JobsTypes.GEOMETRY) && (!wrapper.request.estimated_geometry_hours || (wrapper.selectedItems?.length && !wrapper.selectedItems.find(si => si.estimated_geometry_hours)))) {
      res.isValid = false;
      res.reason = 'Geometry complexity is mandatory for geometry offers';
      return res;
    }
    if (wrapper.request.base_price === null || wrapper.request.base_price < 0) {
      res.isValid = false;
      res.reason = 'Base price is mandatory';
      return res;
    }
    if (!wrapper.request.complexity) {
      res.isValid = false;
      res.reason = 'complexity (rank) is mandatory';
      return res;
    }
    if (wrapper.request.private_offer) {
      if ((!wrapper.request.artist_user_id || !wrapper.request.artist_user_id.length) && (!wrapper.request.artists_offers_groups || !wrapper.request.artists_offers_groups.length)) {
        res.isValid = false;
        res.reason = 'Artists or groups are mandatory for private offer';
        return res;
      }
    }
    return res;
  }

  //, polyTypes: Array<KeyValuePoly>
  getSubCatDefaults(item: Offer): { [id: number]: CategoryDefaults } {
    let defaults = this.categoriesService.subCategoriesDefaultsDictionary;
    let subCategoryId = item.artists_offers_items[0].artists_items[0].artists_items_sub_categories_associations && item.artists_offers_items[0].artists_items[0].artists_items_sub_categories_associations[0] && item.artists_offers_items[0].artists_items[0].artists_items_sub_categories_associations[0].sub_category_id;
    let res = [] as { [id: number]: CategoryDefaults };
    if (defaults[subCategoryId]) {
      // polyTypes.forEach(polyType => {
      for (let polyTypeDefaults of defaults[subCategoryId]) {

        if (polyTypeDefaults.value) {
          item.artists_offers_types.forEach(aot => {
            const def = polyTypeDefaults.value.find(cd => cd.jobType == aot.type_id);
            // if (def)
            res[polyTypeDefaults.polyType] = def;
            // res.push(def);
          });
          // res = res.concat(polyTypeDefaults.value);
        }

      }
      // const polyTypeDefaults = defaults[subCategoryId].find(pt => pt.polyType == polyType.key);

      // });
    }
    return res;
  }

  getDefaultOfferRequest(): OfferRequest {
    return {
      created_by: this.auth.user ? this.auth.user.id : null,
      hours_to_complete: OfferService.HOURS_TO_COMPLETE,
      complexity: 0,
      // rank_bonus: 0,
      // time_bonus: 0,
      allow_rank_bonus: false,
      allow_time_bonus: false,
      artists_offers_types: [],
      base_price: null,
      manager_id: null,
      qualitySupervisor_id: null,
      multiple_offers: false,
      // multiple_jobs: false,
      same_mesh: true,
      private_offer: false,
      notes: null,
      category_id: null
    } as OfferRequest;
  }

  getManager(managerId: number) {
    if (managerId && this.isAdmin && !this.manager && !this.fetchingManager) {
      this.fetchingManager = true;
      this.gql.user(managerId).subscribe(
        u => {
          this.manager = u.data.artists_users;
          this.fetchingManager = false;
        }
      );
    }
  }


  getQualitySupervisor(qualitySupervisorId: number) {
    if (qualitySupervisorId && this.isAdmin && !this.qualitySupervisor && !this.fetchingQualitySupervisor) {
      this.fetchingQualitySupervisor = true;
      this.gql.user(qualitySupervisorId).subscribe(
        u => {
          this.qualitySupervisor = u.data.artists_users;
          this.fetchingQualitySupervisor = false;
        }
      );
    }
  }

  getFeedbacker(feedbackerId: number) {
    if (feedbackerId && this.isAdmin && !this.feedbacker && !this.fetchingFeedbacker) {
      this.fetchingFeedbacker = true;
      this.gql.user(feedbackerId).subscribe(
        u => {
          this.feedbacker = u.data.artists_users;
          this.fetchingFeedbacker = false;
        }
      );
    }
  }

  getFeedbacks(job: Job): Array<ArtistsResourcesFeedback> {
    let unresolvedFeedbacks = [];
    if (job && job.artists_jobs_items) {
      job.artists_jobs_items.forEach(aji => {
        if (aji.artists_jobs_resources) {
          aji.artists_jobs_resources.forEach(ajr => {
            if (ajr.artists_resources_feedback) {
              ajr.artists_resources_feedback.forEach(f => {
                if (!f.fixed)
                  unresolvedFeedbacks.push(f);
              });
            }
          });
        }
      });
    }
    return unresolvedFeedbacks;
  }

  async saveComment(comment: ArtistsOffersPrivateComments, isDelete?: boolean): Promise<ArtistsOffersPrivateComments> {
    return new Promise((resolve, reject) => {
      const query = comment.id ? `/${comment.id}` : '';
      let method =comment.id ? 'put' : 'post';
      if(isDelete){
        method = 'delete';
      }
      this.rest.offerComment(method, comment, query).subscribe({
        next: (res:ArtistsOffersPrivateComments) =>{ 
          res.artists_users = [this.auth.user];
          resolve(res);
        },
        error: err => this.utils.httpErrorResponseHandler(err, 'failure saving comment')
      });
    });
  }

  onDestroy() {
    this.artistsItemsDimensions = {};
    this.artistsItemsSpecs = [];
    // this.additionalDownloads = [];
  }

  public skipToNextOffer(options: ProductFilterOptions, productId: number): Observable<OfferResponseData> {
    this.pixelsService.sendPixel({
      event: 'click',
      button_name: 'skip',
      product_id_requested: productId
    });
    return this.getOffer(options);
  }

  public getOffer(options: ProductFilterOptions): Observable<OfferResponseData> {
    return this.gql.products(options).pipe(map(res => ({ product: cloneDeep(res.data.allProducts.rows[0]), totalProducts: res.data.allProducts.count })));
  }
}
