import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BodyService } from '../shared/body.service';
import { UtilsService } from '../shared/utils.service';

@Component({
    selector: 'app-push-teaser',
    templateUrl: 'push-teaser.html',
    styles: [`
    ul li {
        list-style-type: decimal;
    }
    .v-align-b {
        vertical-align: bottom;
    }
    `]
})
export class PushTeaser implements OnDestroy {
    public isSubscribedDenied: boolean;
    public isSubscribed: boolean;
    public isSubscribedDeniedCancel: boolean;
    private interval: any;
    constructor(
        public dialogRef: MatDialogRef<PushTeaser>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public body: BodyService,
        private utils: UtilsService
    ) {
        this.isSubscribedDenied = data.denied;
        this.isSubscribed = data.subscribed;
        this.isSubscribedDeniedCancel = false;
        if (this.isSubscribedDenied)
        this.interval = setInterval(() => {
            this.isSubscribedDenied = Notification['permission'] == 'denied';
            if (!this.isSubscribedDenied)
                this.isSubscribedDeniedCancel = true;
        }, 3000);
    }

    copyLink(str: string) {
        this.utils.copyClipboard(str);
    }

    ngOnDestroy() {
        if (typeof this.interval !== 'undefined')
            clearInterval(this.interval);
    }
}