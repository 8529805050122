import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NotificationsWebsocketService } from '../communication/notifications-websocket.service';
import { CreatorsNotification, CreatorsNotificationType, CreatorsNotificationListResponse, CreatorsNotificationUI, CreatorsNotificationUIGroup } from './notifications';
import { AuthService } from '../auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RolesHelperService } from '../auth/roles-helper.service';
import { NotificationType, Notification } from '../shared/notifications';
import { BodyService } from 'app/shared/body.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomNotification } from './notifications/custom-notification';
import { UtilsService } from 'app/shared/utils.service';
import { JobManagerChange } from 'app/job/job';

@Injectable()
export class NotificationsService implements OnDestroy {
  // static BATCH_SIZE = 100; // TODO increment infinite scrolling
  static ACTION_TYPES = [
    CreatorsNotificationType.FEEDBACK_RECEIVED,
    CreatorsNotificationType.JOB_ASSIGNED,
    CreatorsNotificationType.CUSTOM_BROADCAST,
    CreatorsNotificationType.PRIVATE_OFFER,
    CreatorsNotificationType.RELEVANT_PUBLIC_OFFER,
    CreatorsNotificationType.FEEDBACK_COMMENT,
    CreatorsNotificationType.FEEDBACK_WAITING_FOR_REVIEW,
    CreatorsNotificationType.PRIVATE_OFFER_HAS_BEEN_TAKEN,
    CreatorsNotificationType.OFFER_HAS_UPDATED_ITS_PRICING,
    CreatorsNotificationType.ARTIST_HAS_GROUP_PRIVATE_OFFER,
    CreatorsNotificationType.NEW_JOB_COMMENT,
    CreatorsNotificationType.SIMILAR_ITEM,
    CreatorsNotificationType.PAYMENT_APPROVED,
    CreatorsNotificationType.PAYMENT_CANCELED,
    CreatorsNotificationType.TRUST_LEVEL_INCREASED,
    CreatorsNotificationType.TRUST_LEVEL_DECREASED,
    CreatorsNotificationType.SOURCE_FILE_MATCH,
    CreatorsNotificationType.SOURCE_FILE_NO_MATCH,
    CreatorsNotificationType.ONE_SOURCE_FILE_MATCH,
    CreatorsNotificationType.ONE_SOURCE_FILE_NO_MATCH
  ];
  public onNotification: Subject<CreatorsNotification>;
  public onSidebarToggle: Subject<boolean>;
  public notifications: Array<CreatorsNotification>;
  public notificationsGroups: Array<CreatorsNotificationUIGroup>;
  public unred: number;
  private isSidebarOpen: boolean;
  private limit: number;
  private offset: number;
  private subs: Array<Subscription>;
  private unhandledNotifications: Array<CreatorsNotification>;
  private onStateChangeSub: Subscription;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private isInit: boolean;
  private unhandledNotificationsIds: Array<number>;

  constructor(
    private notificationsWebsocket: NotificationsWebsocketService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private rolesHelper: RolesHelperService,
    private body: BodyService,
    private dialog: MatDialog,
    private utils: UtilsService
  ) {
    this.subs = [];
    this.onNotification = new Subject<CreatorsNotification>();
    this.isSidebarOpen = false;
    this.onSidebarToggle = new Subject<boolean>();
    this.isInit = false;
    this.notifications = [];
    this.notificationsGroups = [];
    this.unhandledNotifications = [];
    this.unhandledNotificationsIds = [];
    this.unred = 0;

    this.initPagination();

    if (this.auth.isloggedIn())
      this.subscribe();
    if (this.notificationsWebsocket.isOpen) {
      this.isInit = true;
      this.fetch();
    }
    this.onLogin = this.broadcaster.on('onLogin').subscribe(
      () => {
        this.subscribe();
      }
    );
    this.onLogout = this.broadcaster.on('onLogout').subscribe(
      () => {
        this.isInit = false;
        this.unsubscribe();
        this.notifications = [];
        this.buildGroupes();
      }
    );
  }

  public initPagination() {
    this.limit = 20;
    this.offset = 0;
  }

  public toggleSidebar(state: boolean) {
    this.isSidebarOpen = state;
    // this.body.isBlur = this.isSidebarOpen;
    this.onSidebarToggle.next(this.isSidebarOpen);
  }

  public getSidebarState(): boolean {
    return this.isSidebarOpen;
  }

  public markAsRead(notificationId: number) {
    this.notificationsWebsocket.read(notificationId);
    this.onRead(notificationId);
  }

  public markAllAsRead() {
    this.notificationsWebsocket.readAll();
    // this.onReadAll();
  }

  public handledNotificationsById(id: number) {
    const notification = this.notifications.find(n => n.id == id);
    if (notification)
      this.handledNotification(notification);
    else
      this.unhandledNotificationsIds.push(id);
  }

  // private onPush(obj: any) {
  //   console.log(obj);
  //   // this.onAnnouncement(obj); // or something like that
  // }

  private onRead(id: number) {
    let n = this.notifications.find(n => n.id == id);
    if (n && !n.read) {
      n.read = true;
      if (this.unred > 0)
        this.unred--;
      this.broadcaster.broadcast('deployLinks');
      this.buildGroupes();
    }
  }

  private onReadAll() {
    this.notifications.forEach(n => n.read = true);
    this.unred = 0;
    this.broadcaster.broadcast('deployLinks');
    this.buildGroupes();
  }

  private onAnnouncements(notifications: CreatorsNotificationListResponse) {
    this.unred = notifications.unread_count;
    this.pushArray(notifications.items);
    if (this.unhandledNotificationsIds.length) {
      this.unhandledNotificationsIds.forEach(
        id => {
          const n = this.notifications.find(n => n.id == id);
          if (n)
            this.handledNotification(n);
        }
      );
      this.unhandledNotificationsIds = [];
    }
    this.broadcaster.broadcast('deployLinks');
  }

  private pushArray(items: Array<CreatorsNotification>) {
    // this.initPagination();
    let n = this.notifications;
    n = n.concat(items);
    this.notifications = n.sort(function (a, b) {
      var c = new Date(a.created_at).getTime();
      var d = new Date(b.created_at).getTime();
      return d - c;
    });
    this.buildGroupes();
  }

  private async onAnnouncement(notification: CreatorsNotification) {
    const notificationTypes = await this.auth.fetchNotificationTypes();
    if (!notificationTypes) {
      this.unhandledNotifications.push(notification);
      this.auth.fetchNotificationTypes();
    }
    else
      this.handledNotification(notification);
  }

  private async handledNotification(notification: CreatorsNotification) {
    const notificationTypes = await this.auth.fetchNotificationTypes();

    if (!notificationTypes) {
      this.utils.throw('notification types ENUM is undefined!')
    };

    const isNotificationUnread = !notification.read;
    const isNewNotification = !this.notifications.find(n => n.id === notification.id);
    const isKnownActionType = NotificationsService.ACTION_TYPES.find(t => t === notification.notification_type);

    if (isNotificationUnread && isNewNotification && isKnownActionType) {
      this.unred++;
    }

    switch (notification.notification_type) {
      case (CreatorsNotificationType.ROLES_REFRESHED): {
        this.rolesHelper.fetchRoles();
        break;
      }
      case CreatorsNotificationType.JOB_MANAGER_STATUS_CHANGE: {
        const data: JobManagerChange = {
          managerId: notification.artist_user_id,
          status: notification.reference_id,
          notes: notification.notes
        };
        this.broadcaster.broadcast('onJobManagerChange', data);
        break;
      }
      case CreatorsNotificationType.VERSION_UPDATE: {
        const data: Notification = {
          text: 'A newer version of CREATORS-3D is available',
          type: NotificationType.Info,
          action: 'REFRESH',
          callback: this.refresh,
          scope: this,
          autoDismiss: false
        };
        this.broadcaster.broadcast('notifyUser', data);
        break;
      }
      case CreatorsNotificationType.JOB_DUE_SOON:
      case CreatorsNotificationType.FEEDBACK_COMMENT:
      case CreatorsNotificationType.FEEDBACK_WAITING_FOR_REVIEW:
      case CreatorsNotificationType.PRIVATE_OFFER_HAS_BEEN_TAKEN:
      case CreatorsNotificationType.OFFER_HAS_UPDATED_ITS_PRICING:
      case CreatorsNotificationType.ARTIST_HAS_GROUP_PRIVATE_OFFER:
      case CreatorsNotificationType.NEW_JOB_COMMENT:
      case CreatorsNotificationType.SIMILAR_ITEM:
      case CreatorsNotificationType.RELEVANT_PUBLIC_OFFER:
      case CreatorsNotificationType.JOB_ASSIGNED:
      case CreatorsNotificationType.FEEDBACK_RECEIVED:
      case CreatorsNotificationType.PRIVATE_OFFER:
      case CreatorsNotificationType.CUSTOM_BROADCAST:
      case CreatorsNotificationType.PAYMENT_APPROVED:
      case CreatorsNotificationType.PAYMENT_CANCELED:
      case CreatorsNotificationType.SOURCE_FILE_MATCH:
      case CreatorsNotificationType.SOURCE_FILE_NO_MATCH:
      case CreatorsNotificationType.ONE_SOURCE_FILE_MATCH:
      case CreatorsNotificationType.ONE_SOURCE_FILE_NO_MATCH: {
        this.pushArray([notification]);
        this.onNotification.next(notification);
        break;
      }
    }
  }

  private buildGroupes() {
    this.notificationsGroups = [];
    let dictionary = {};
    let last = null as CreatorsNotificationUI;
    this.notifications.forEach(item => {
      if (item && !dictionary[item.id]) {
        dictionary[item.id] = true;
        let audit = {} as CreatorsNotificationUI;
        audit.icon = item.icon;
        audit.id = item.id;
        audit.notification_type = item.notification_type;
        audit.title = item.title;
        audit.created_at = item.created_at;
        audit.read = item.read;
        audit.reference_id = item.reference_id;
        audit.notes = item.notes;
        audit.url = item.url;
        audit.artist_user_id = item.artist_user_id;

        if (last && this.utils.sameDay(last.created_at, item.created_at)) {
          this.notificationsGroups[this.notificationsGroups.length - 1].items.push(audit);
        }
        else {
          this.notificationsGroups.push({
            created_at: item.created_at,
            items: [audit]
          });
        }
        last = item;
      }
    });
  }

  public refresh() {
    // let prefix = '&';
    // if (!window.location.search)
    //   prefix = '?';
    // window.location.href += `${prefix}v=${new Date().getTime()}`;
    window.location.reload();
  }

  public showCustomBroadcast(n: CreatorsNotificationUI) {
    this.body.isBlur = true;
    let dialogRef = this.dialog.open(CustomNotification, {
      // width: (window.innerWidth - 20) + 'px',
      data: {
        notificationId: n.id,
        notes: n.notes
      }
    });
    dialogRef.afterClosed().subscribe((id: number) => {
      this.body.isBlur = false;
      if (id) {
        this.markAsRead(id);
      }
    });
  }

  private onNotificationRefreshed() {
    for (let i = this.unhandledNotifications.length - 1; i >= 0; i--) {
      this.handledNotification(this.unhandledNotifications.pop());
    }
  }

  private subscribe() {
    this.subs.push(this.notificationsWebsocket.onAnnouncement.subscribe(this.onAnnouncement.bind(this)));
    this.subs.push(this.notificationsWebsocket.onAnnouncements.subscribe(this.onAnnouncements.bind(this)));
    this.subs.push(this.notificationsWebsocket.onRead.subscribe(this.onRead.bind(this)));
    this.subs.push(this.notificationsWebsocket.onReadAll.subscribe(this.onReadAll.bind(this)));
    this.subs.push(this.broadcaster.on('notificationRefreshed').subscribe(this.onNotificationRefreshed.bind(this)));
    // this.onAnnouncementSub = this.notificationsWebsocket.onAnnouncement.subscribe(this.onAnnouncement.bind(this));
    // this.onAnnouncementsSub = this.notificationsWebsocket.onAnnouncements.subscribe(this.onAnnouncements.bind(this));
    // this.onReadSub = this.notificationsWebsocket.onRead.subscribe(this.onRead.bind(this));
    // this.onReadAllSub = this.notificationsWebsocket.onReadAll.subscribe(this.onReadAll.bind(this));
    // this.onPushNotificationSub = this.swService.onPushNotification.subscribe(this.onPush.bind(this));
    // this.onNotificationRefreshedSub = this.broadcaster.on('notificationRefreshed').subscribe(this.onNotificationRefreshed.bind(this));


    if (!this.notificationsWebsocket.isOpen) {
      this.onStateChangeSub = this.notificationsWebsocket.onStateChange.subscribe(() => {
        if (this.notificationsWebsocket.isOpen || !this.isInit) {
          this.fetch();
        }
      });
    }
  }

  public fetchMore() {
    this.offset += 20;
    this.fetch();
  }

  private fetch() {
    this.notificationsWebsocket.fetch(this.limit, this.offset, NotificationsService.ACTION_TYPES);
  }

  private unsubscribe() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];
    // if (this.onAnnouncementSub)
    //   this.onAnnouncementSub.unsubscribe();
    // // if (this.onPushNotificationSub)
    // //   this.onPushNotificationSub.unsubscribe();
    // if (this.onNotificationRefreshedSub)
    //   this.onNotificationRefreshedSub.unsubscribe();
    // if (this.onAnnouncementsSub)
    //   this.onAnnouncementsSub.unsubscribe();
    // if (this.onReadSub)
    //   this.onReadSub.unsubscribe();
    // if (this.onReadAllSub)
    //   this.onReadAllSub.unsubscribe();

  }

  ngOnDestroy() {
    this.unsubscribe();
    this.onStateChangeSub.unsubscribe();
    this.onLogin.unsubscribe();
    this.onLogout.unsubscribe();
  }
}
