import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class NotificationActionResolve  {

    constructor() { }

    resolve(route: ActivatedRouteSnapshot) {
        return parseInt(route.params['id']);
    }
}