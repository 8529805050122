<div class="wrap">
  <!-- <button mat-icon-button color="primary" class="message-btn" (click)="toggleMessages()">
    <mat-icon>message</mat-icon>
  </button> -->
  <mat-list role="list" class="sidebar alt-bg scrollbar" [ngClass]="{'open': isSidebarOpen}"
    (scroll)="onScroll($event)">
    <mat-list-item role="listitem" class="header contrast-text">
      <mat-progress-bar *ngIf="chatService.fetching" mode="indeterminate" color="primary">
      </mat-progress-bar>
      <div class="inner-header">
        <button mat-icon-button class="message-close-btn contrast-text" (click)="toggleMessages()" aria-label="close chat">
          <mat-icon>close</mat-icon>
        </button>
        contacts
        <button mat-icon-button [matMenuTriggerFor]="menu" class="flex-op-dir" aria-label="chat options">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <!-- <div mat-menu-item [ngClass]="{'has-support': chatService.isSupport}" (click)="onMenuSelect($event)">
            <mat-slide-toggle color="primary" [(ngModel)]="groupMode">create group</mat-slide-toggle>
          </div> -->
          <!-- <div mat-menu-item *ngIf="chatService.isSupport" (click)="onMenuSelect($event)">
            <mat-slide-toggle color="primary" [(ngModel)]="supportMode">support mode</mat-slide-toggle>
          </div> -->
          <div mat-menu-item (click)="onMenuSelect($event, true)">
            <mat-slide-toggle color="primary" [(ngModel)]="chatService.unreadMode">Unread only</mat-slide-toggle>
          </div>
          <div mat-menu-item (click)="chatService.readAll()">
            <mat-icon>checklist</mat-icon>
            Mark all as read
          </div>
          <a mat-menu-item routerLink="/messages">
            <mat-icon>list</mat-icon>
            All Messages
          </a>
          <button mat-menu-item (click)="chatService.minimizeAll()" aria-label="minimize all chats">
            <mat-icon>minimize</mat-icon>
            Minimize all chats
          </button>
          <button mat-menu-item (click)="chatService.closeAll()" aria-label="close all chats">
            <mat-icon>playlist_remove</mat-icon>
            Close all chats
          </button>
        </mat-menu>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem" class="users-auto" *ngIf="isAdminLogedin()">
      <app-users-autocomplete [show-all]="true" [clear-on-select]="true" (user-change)="onUserChange($event)">
      </app-users-autocomplete>
    </mat-list-item>
    <mat-list-item role="listitem" class="users-auto pointer item" *ngIf="!isAdminLogedin() && !chatService.hasSupport"
      (click)="supportRequest()">
      <div class="support-wrap">
        <span class="status online"></span>
        <!-- <mat-icon>supervisor_account</mat-icon> -->
        <mat-icon>help</mat-icon>
        <span>
          &nbsp;Support
        </span>
      </div>
    </mat-list-item>
    <ng-container *ngIf="isSidebarOpen">
      <mat-list-item class="pointer item" role="listitem"
        *ngFor="let h of chatService.historyDictionary | keyValuePure : chatService.dummyCounter : sortOptions; let i = index"
        (click)="openChat(h.value)" [ngClass]="{'first': i == 0}">
        <div class="item-innder">
          <span class="status" [ngClass]="{'online': h.value.artists_users_chats[0].artists_users[0].available}"></span>
          <span class="count" [matTooltip]="h.value.unread_count + ' unread messages'"
            *ngIf="h.value.unread_count || !h.value.read">{{h.value.unread_count ? (h.value.unread_count
            > 9 ? '9+' : h.value.unread_count) : ''}}</span>
          <mat-icon *ngIf="!h.value.avatar_url && h.value.type_id == chatService.PERSONAL">
            account_circle
          </mat-icon>
          <mat-icon class="mat-primary" *ngIf="h.value.type_id != chatService.PERSONAL">
            {{h.value.type_id == chatService.SUPPORT ? 'help' : h.value.type_id == chatService.PAYMENTS ? 'payment' : 'assignment'}}
          </mat-icon>
          <img class="avatar" *ngIf="h.value.avatar_url && h.value.type_id == chatService.PERSONAL"
            [src]="h.value.avatar_url" />
          &nbsp;
          <span class="contacts-wrap ellipsis" *ngIf="!h.value.topic">
            <span *ngFor="let userChat of h.value.artists_users_chats | exclude:'user_id':auth.user.id; let last = last"
              class="ellipsis" [matTooltip]="userChat.artists_users[0].name">
              {{userChat.artists_users[0].name}}
              <span *ngIf="!last">,</span>
            </span>
          </span>
          <span class="contacts-wrap ellipsis" *ngIf="h.value.topic">
            <span class="ellipsis" [matTooltip]="h.value.topic">
              {{h.value.topic}}
            </span>
          </span>
        </div>
      </mat-list-item>
    </ng-container>
  </mat-list>
  <div *ngIf="!chatService.fullscreenMode" class="bottom-bar"
    [ngClass]="{'side-open': isSidebarOpen, 'has-open': chatService.hasOpen, 'has-full': chatService.fullscreenId}">
    <div class="bottom-slider" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
      fxLayoutAlign.xs="start">
      <div *ngFor="let h of chatService.historyDictionary | openUsers : 5" class="user"
        [ngClass]="{'open': h.isOpen, 'hide': h.isHidden, 'minimize': chatService.isMinimize[h.id], 'fullscreen': chatService.fullscreenId == h.id}"
        [hidden]="chatService.fullscreenId && chatService.fullscreenId != h.id">
        <app-chat-conversation [history]="h" (on-change)="onHistoryChange($event)"></app-chat-conversation>
      </div>
    </div>
  </div>
</div>