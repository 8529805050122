import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcasterService } from 'ng-broadcaster';
import { RestService } from '../communication/rest.service';
import { CustomRequestOptions } from '../communication/custom-request';
import { StorageService } from 'ng-storage-service';
import { User, Credentials, UserAvailabilityStatus, UserQueryData } from './user';
import { GraphqlService } from '../communication/graphql.service';
import { UtilsService } from '../shared/utils.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { ArtistsGroupUser, Group } from './group';
import { KeyValuePair } from 'asset-adjustments';

export enum IAuthType {
    SOCIAL,
    INITIAL,
    CONFIRMED
}

@Injectable()
export class AuthService {

    public user: User;
    public websocketEndpoint: string;
    public notificationsWebsocketEndpoint: string;
    public authType$: BehaviorSubject<IAuthType>;
    private _notificationTypes: Array<KeyValuePair>;

    constructor(
        private router: Router,
        private broadcaster: BroadcasterService,
        private rest: RestService,
        private storage: StorageService,
        private gql: GraphqlService,
        private utils: UtilsService
    ) {
        this.websocketEndpoint = this.rest.websocketEndpoint;
        this.notificationsWebsocketEndpoint = this.rest.notificationsWebsocketEndpoint;
        this.user = this.storage.get('user') as User;
        this.authType$ = new BehaviorSubject(IAuthType.INITIAL);
    }

    public getEndpoints() {
        return this.rest.endpoints;
    }

    atachRolesToUser(user: User) {
        if (!user.roles && user.artists_users_roles) {
            user.roles = [];
            for (let i = 0; i < user.artists_users_roles.length; i++) {
                if (user.artists_users_roles[i].artists_roles) {
                    for (let j = 0; j < user.artists_users_roles[i].artists_roles.length; j++) {
                        user.roles.push(user.artists_users_roles[i].artists_roles[j]);
                    }
                }
            }
        }
    }

    login(credentials: Credentials, recaptchaToken: string) {
        return new Promise((resolve, reject) => {
            this.rest.auth('post', credentials, `?g-recaptcha-response=${recaptchaToken}`).subscribe(
                (result: User) => {
                    this.atachRolesToUser(result);
                    this.storeUser(result);
                    if (result.token) {
                        this.storeToken(result.token);
                    }
                    this.onLoginSuccess();
                    resolve({});
                },
                (err: any) => {
                    this.utils.httpErrorResponseHandler(err, 'login attempt failure');
                    reject(err);
                }
            );
        });
    }

    onLoginSuccess(navToDefault = true, broadcastOnLogin = true) {
        let affiliate_id = this.storage.get('reference_id');
        if (affiliate_id)
            this.user.reference_id = affiliate_id;
        const gclid = this.storage.get('gclid');
        if (gclid) {
            this.user.gclid = gclid;
            this.storage.remove('gclid');
        }
        if (gclid || affiliate_id)
            this.rest.userProfile('put', this.user, '?uid=' + this.user.id).subscribe();
        if (broadcastOnLogin)
            this.broadcaster.broadcast('onLogin', this.user);
        if (location.pathname.indexOf('/login/') > -1 && location.pathname.replace('/login/', '')) {
            this.router.navigate([decodeURIComponent(location.pathname.replace('/login/', ''))]);
        }
        else if (navToDefault)
            this.router.navigate(['/offers']);
        let group_id = parseInt(this.storage.get('group_id'));
        if (!isNaN(group_id)) {
            let payload = {
                group_id,
                artist_user_id: this.user.id
            };
            this.rest.groupUsers('post', payload).subscribe(
                () => this.storage.remove('group_id')
            );
        }
        this.refreshSession();
    }

    enhancedConversionData() {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
            'enhanced_conversion_data': {
                'email': this.user.email
            }
        });
    }

    onLogout() {
        this.storage.remove('user');
        this.storage.remove('token');
        this.refreshSession();
        delete this.user;
        this.broadcaster.broadcast('onLogout', this.user);
    }

    logout() {
        this.rest.auth('delete', null, '?client_id=' + this.utils.getClientId()).subscribe(
            (result) => {
                this.onLogout()
            },
            (err) => {
                this.onLogout();
            }
        );
    }

    isloggedIn() {
        return this.storage.has('token') && this.storage.has('user');
    }

    public fetchUser(userId: number, options?: CustomRequestOptions): Observable<ApolloQueryResult<UserQueryData>> {
        return this.gql.user(userId, options);
    }

    /**
     * Store user in local storage
     * @param user
     */
    public storeUser(user: User): void {
        this.user = user;
        this.addInvitingArtist();
        this.storage.set('user', user);
    }

    /**
     * Setting referrer user id
     * @param userId
     */
    public setInvitingUser(userId): void {
        if (userId) {
            this.storage.set('invitingUser', userId);
        }
    }

    public getInvitingUser(): number {
        return this.storage.get('invitingUser');
    }

    /**
     * Adding inviting user to current user if inviting user exists
     */
    private addInvitingArtist(): void {
        const invitingUser = this.storage.get('invitingUser');
        if (invitingUser && !this.user.referrer_artist_id) {
            this.user.referrer_artist_id = invitingUser;
        }
    }

    /**
     * Store token in local storage
     * @param token
     */
    public storeToken(token: string): void {
        this.storage.set('token', token);
    }

    public getUser(): User {
        return this.user || this.storage.get('user');
    }

    refreshUserDate() {
        const token = this.storage.get('token');
        if (!this.user || !this.user.id || !token) return;
        let options = new CustomRequestOptions();
        options.showLoading = false;
        this.fetchUser(this.user.id, options).subscribe((userObj) => {
            let user = (userObj.data && userObj.data.artists_users) ? this.utils.deepCopyByValue(userObj.data.artists_users) : null;
            if (!user) {
                this.logout();
                return
            };
            this.atachRolesToUser(user);
            if (this.user) {
                user.token = this.user.token;
            }
            this.storeUser(user);
            this.broadcaster.broadcast('userRefreshed', this.user);
        });
    }

    async refreshUserDateAsync() {
        return new Promise((resolve, reject) => {
            let onRefresh = this.broadcaster.on('userRefreshed').subscribe(() => {
                onRefresh.unsubscribe();
                resolve(null);
            });
            this.refreshUserDate();
        });
    }

    async fetchNotificationTypes() {
        if (!this._notificationTypes) {
            this._notificationTypes = await this.utils.observableToPromise(this.rest.notificationTypes());
            this.broadcaster.broadcast('notificationRefreshed', this._notificationTypes);
        }
        return this._notificationTypes;
    }

    confirm(token: string) {
        if (token) {
            this.rest.confirm('PUT', {
                email_confirmed: true
            }, `?token=${token}`).subscribe(
                (result: User) => {
                    this.atachRolesToUser(result);
                    this.storeUser(result);
                    this.storeToken(token);
                    this.onLoginSuccess(false, true);
                    this.router.navigate(['/onboarding']);
                },
                (err: any) => {
                    this.utils.httpErrorResponseHandler(err, 'confirmation failure');
                    this.authType$.next(IAuthType.INITIAL);
                }
            )
        }
    }

    refreshSession() {
        this.broadcaster.broadcast('refreshSession');
    }

    async updateAdjustmetnsPreset(force: boolean) {
        return new Promise((resolve, reject) => {
            if (!this.user) {
                resolve(null);
                return;
            }
            if (force || !this.user.artists_users_presets)
                this.gql.artistsUsersPresets(this.user.id).subscribe(u => {
                    this.user.artists_users_presets = this.utils.deepCopyByValue(u.data.artists_users.artists_users_presets);
                    resolve(null)
                }, err => reject);
            else
                resolve(null);
        });
    }

    async detectCountry() {
        let gp = await this.utils.getLocation();
    }

    changeUserStatus(status: UserAvailabilityStatus, user = this.user) {
        user.availability_status = status;
        return this.utils.observableToPromise(this.rest.userProfile('put', user, '?uid=' + user.id));
    }

    async getUserGroups(userId: number): Promise<Array<Group>> {
        const aug = await this.getArtistUserGroups(userId);
        let res = [] as Array<Group>;
        if (aug)
            aug.forEach(agu => {
                res = res.concat(agu.artists_groups);
            });
        return res;
    }

    async getArtistUserGroups(userId: number): Promise<Array<ArtistsGroupUser>> {
        return new Promise(async (resolve, reject) => {
            const user = await this.utils.observableToPromise(this.gql.userGroups(userId)) as ApolloQueryResult<UserQueryData>;
            resolve(user.data.artists_users.artists_groups_users);
        });
    }
}
