import { CanvasPaintState, KeyValueAnyPair } from "./enums";

export interface FormattedDim {
    size: number;
    suffix: string;
}

export interface ElementDimensions {
    width?: number;
    height?: number;
}

export class ApiCallOptions {
    successMessage?: string;
    errorMessage?: string;
    callback?: Function;
    constructor(successMessage = 'item saved successfully', errorMessage = 'failure saving item') {
        this.successMessage = successMessage;
        this.errorMessage = errorMessage;
    }
}

export enum DimensionsUnits {
    INCH = 1,
    CM = 2,
}

export interface CanvasPaintEvent {
    state: CanvasPaintState;
    clickX: number;
    clickY: number;
    isDragging: boolean;
    color: string;
    lineWidth: number;
    // typePerClick: CanvasPaintState;
    text: string;
    isChunk: boolean;
    isDone?: boolean;
}

export class TimeUnits {
    public years: number;
    public months: number;
    public days: number;
    public hours: number;
    public minutes: number;
    public seconds: number;
    public ms: number;
    constructor() {
        this.years = 0;
        this.months = 0;
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        this.ms = 0;
    }
}

export class ViewerUrlParams {
    public rotate: boolean;
    public exposure: number;
    constructor() {
        this.rotate = true;
    }
}

export interface ValidateResponse {
    isValid: boolean;
    reason?: string;
}

export interface IntersectionObserverOptions {
    root?: HTMLElement;
    rootMargin?: string; // '0px',
    threshold?: number; // 0.0 - 1.0
    once: boolean;
}

export interface MinMidMax {
    min: KeyValueAnyPair;
    mid: KeyValueAnyPair;
    max: KeyValueAnyPair;
}

export interface ScriptsFetcher {
    loaded: boolean;
    promise: Array<Function>;
    type: ScriptsFetcherType;
}

export enum ScriptsFetcherType {
    QUILL = 1,
    QUILL_CSS = 2,
    TUS = 3,
}

export var CORS_TOKEN = 'NgQun95Vma7QVVc34Lqxw7ETNWwmnuBA';