import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RolesManagerService } from 'ng-role-based-access-control';
import { AuthService } from './auth.service';

@Injectable()
export class CanActivateSuGuard  {
    constructor(private router: Router, private roles: RolesManagerService,
        private auth: AuthService) { }
    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (!this.auth.getUser() || !this.roles.isUserInRole(this.auth.getUser().roles, 'Super User')) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}
