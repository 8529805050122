import { Injectable } from '@angular/core';
import { RolesManagerService } from 'ng-role-based-access-control';
import { StorageService } from 'ng-storage-service';
import { Subject, Subscription } from 'rxjs';
import { RestService } from '../communication/rest.service';
import { RolesConfig, Role } from 'ng-role-based-access-control';
import { AuthService } from './auth.service';
import { UserRole, User } from './user';
import { BroadcasterService } from 'ng-broadcaster';
import { ArtistsGroupUser, GroupType } from './group';

export interface ArtistRole extends Omit<Role, 'artists_jobs_roles_archive_reasons'> {
  artists_jobs_roles_archive_reasons: Array<ArtistsJobsRolesArchiveReasons>;
}

export interface ArtistsJobsRolesArchiveReasons {
  artists_jobs_archive_reasons?: IArtistsJobsArchiveReasons
}

export interface IArtistsJobsArchiveReasons {
  archive_reason: string;
  id: string;
}
export type RoleName = 'Super User' | 'Data Tagger' | 'Job Manager' | 'Pricing Manager' | 'Artist Without Time Limit' | 'Quality Supervisor' | 'Senior Feedback Master';


@Injectable({
  providedIn: 'root'
})
export class RolesHelperService {
  private fetchingRoles: boolean;
  public onRolesFetched: Subject<null>;
  private init: boolean;
  private subs: Array<Subscription>;
  private _isAdminLogedin: boolean;
  private _isSupportLogedin: boolean;
  private _isSULogedin: boolean;
  private _isArtistAdminLogedin: boolean;
  private _isArtistLogedin: boolean;
  private _isQualitySupervisorLogedin: boolean;
  private _isJobManagerLogedin: boolean;
  private _isJobAgentLogedin: boolean;
  private _isTechnicalReviewerLogedin: boolean;
  private _isGodLogedin: boolean;
  private _isAdditionalPrice: boolean;
  private _isUnderNDA: boolean;
  private _isRankDefinitionEditorLogedin: boolean;
  private _isSeniorFeedbackMaster: boolean;
  private _userRoles: { [id: string]: boolean };
  constructor(
    private roles: RolesManagerService,
    private storage: StorageService,
    private rest: RestService,
    private auth: AuthService,
    private broadcaster: BroadcasterService
  ) {
    this.subs = [];
    this.fetchingRoles = false;
    this.onRolesFetched = new Subject<null>();
    this._userRoles = {};
    this.subs.push(this.broadcaster.on('onLogout').subscribe(this.deleteLazy.bind(this)));
    this.subs.push(this.broadcaster.on('onLogin').subscribe(this.deleteLazy.bind(this)));
  }

  deleteLazy() {
    delete this._isAdminLogedin;
    delete this._isSupportLogedin;
    delete this._isSULogedin;
    delete this._isArtistAdminLogedin;
    delete this._isArtistLogedin;
    delete this._isGodLogedin;
    delete this._isQualitySupervisorLogedin;
    delete this._isTechnicalReviewerLogedin;
    delete this._isJobManagerLogedin;
    delete this._isJobAgentLogedin;
    delete this._isUnderNDA;
    delete this._isRankDefinitionEditorLogedin;
    delete this._isSeniorFeedbackMaster;
    this.deleteRolesCache();
  }

  deleteRolesCache() {
    this._userRoles = {};
  }

  // initialize role config from server
  fetchRoles() {
    try {
      let last = this.storage.get('roles'), isUpdatedFormat = true;
      if (last) {
        if (last.roles_permissions instanceof Array && (!last.roles_permissions[0].role_name || !last.roles_permissions[0].permission_description)) {
          isUpdatedFormat = false;
        }
        if (isUpdatedFormat) {
          this.roles.setConfig(last);
          this.init = true;
          this.deleteRolesCache();
          this.onRolesFetched.next(null);
        }
      }
    }
    catch (e) { }
    if (!this.fetchingRoles) {
      this.fetchingRoles = true;
      this.rest.rolesConfig('GET').subscribe((data: RolesConfig) => {
        this.fetchingRoles = false;
        this.roles.setConfig(data);
        this.storage.set('roles', data);
        this.init = true;
        this.deleteRolesCache();
        this.onRolesFetched.next(null);
      },
        err => {
          this.fetchingRoles = false;
          if (!this.roles.getConfig()) {
            this.init = false;
            // return this.auth.logout();
            return;
          }
          // this.init = true;
          this.onRolesFetched.next(null);
        });
    }
  }

  getAllUIRoles(): Array<Role> {
    if (this.init) {
      let res = [] as Array<Role>;
      this.roles.getConfig().roles.forEach(role => {
        res.push(
          {
            created_at: role.created_at,
            id: role.id,
            name: role.name,
            updated_at: role.updated_at
          }
        );
      });
      return res;
    }
    return null;
  }

  getUIRoles(existing?: Array<UserRole>): Array<Role> {
    if (this.init) {
      let res = [] as Array<Role>;
      existing = existing ? existing : [];
      this.roles.getConfig().roles.forEach(role => {
        if (existing.filter(r => r.role_id == role.id).length > 0)
          res.push(
            {
              created_at: role.created_at,
              id: role.id,
              name: role.name,
              updated_at: role.updated_at
            }
          );
      });
      return res;
    }
    return null;
  }

  getRoleIdbyName(name: string) {
    let roles = this.getAllUIRoles();
    for (let i = 0; i < roles?.length; i++) {
      if (roles[i].name === name) {
        return roles[i].id;
      }
    }
    return null;
  }

  save(config): void {
    this.rest.rolesConfig('PUT', config).subscribe((data: RolesConfig) => {
      this.fetchRoles();
    });
  }

  doesUserHasPermission(permissionName: string): boolean {
    if (!this.auth.getUser()) return false;
    if (typeof this._userRoles[permissionName] !== 'boolean')
      this._userRoles[permissionName] = this.roles.doesRolesHasPermission(this.auth.getUser().roles, permissionName);
    return this._userRoles[permissionName];
  }

  isInit() {
    return this.init;
  }

  isAdminLogedin(): boolean {
    if (typeof this._isAdminLogedin !== 'boolean')
      this._isAdminLogedin = this.auth.getUser() && (this.roles.isUserInRole(this.auth.getUser().roles, 'Super User') || this.roles.isUserInRole(this.auth.getUser().roles, 'Artist Admin') || this.isFeedbackMaster() || this.isQualitySupervisorLogedin());
    return this._isAdminLogedin;
  }

  isSupportLogedin(): boolean {
    if (typeof this._isSupportLogedin !== 'boolean')
      this._isSupportLogedin = this.auth.getUser() && (this.roles.isUserInRole(this.auth.getUser().roles, 'Support'));
    return this._isSupportLogedin;
  }

  isArtistAdminLogedin(): boolean {
    if (typeof this._isArtistAdminLogedin !== 'boolean')
      this._isArtistAdminLogedin = this.auth.getUser() && (this.roles.isUserInRole(this.auth.getUser().roles, 'Artist Admin'));
    return this._isArtistAdminLogedin;
  }

  isSULogedin(): boolean {
    if (typeof this._isSULogedin !== 'boolean')
      this._isSULogedin = this.auth.getUser() && this.roles.isUserInRole(this.auth.getUser().roles, 'Super User');
    return this._isSULogedin;
  }

  isArtistLogedin() {
    if (typeof this._isArtistLogedin !== 'boolean')
      this._isArtistLogedin = this.auth.getUser() && this.roles.isUserInRole(this.auth.getUser().roles, 'Artist');
    return this._isArtistLogedin;
  }

  isQualitySupervisorLogedin(): boolean {
    if (typeof this._isQualitySupervisorLogedin !== 'boolean')
      this._isQualitySupervisorLogedin = this.auth.getUser() && this.roles.isUserInRole(this.auth.getUser().roles, 'Quality Supervisor');
    return this._isQualitySupervisorLogedin;
  }

  isTechnicalReviewerLogedin(): boolean {
    if (typeof this._isTechnicalReviewerLogedin !== 'boolean')
      this._isTechnicalReviewerLogedin = this.auth.getUser() && this.roles.isUserInRole(this.auth.getUser().roles, 'Technical Reviewer');
    return this._isTechnicalReviewerLogedin;
  }

  isJobManager() {
    if (typeof this._isJobManagerLogedin !== 'boolean')
      this._isJobManagerLogedin = this.auth.getUser() && this.roles.isUserInRole(this.auth.getUser().roles, 'Job Manager');
    return this._isJobManagerLogedin;
  }

  isJobAgent() {
    if (typeof this._isJobAgentLogedin !== 'boolean')
      this._isJobAgentLogedin = this.auth.getUser() && this.roles.isUserInRole(this.auth.getUser().roles, 'Job Agent');
    return this._isJobAgentLogedin;
  }

  isAdditionalPriceManager() {
    if (typeof this._isAdditionalPrice !== 'boolean')
      this._isAdditionalPrice = this.auth.getUser() && this.roles.isUserInRole(this.auth.getUser().roles, 'Additional Price');
    return this._isAdditionalPrice;
  }

  isSUFMQS(): boolean {
    return this.isSULogedin() || this.isQualitySupervisorLogedin() || (this.isJobManager() && this.isArtistAdminLogedin());
  }

  // returns the abbreviation for the first role found, in dictionary array order, in the resulting roles object
  roleAbbreviation(roles: Array<UserRole>) {
    const dictionary = [{
      role: ["Super User"],
      abbreviation: "SU"
    },
    {
      role: ["Quality Supervisor"],
      abbreviation: "QS"
    },
    {
      role: ["Job Manager", "Artist Admin"],
      abbreviation: "FM"
    }
    ];

    if (roles && roles.length > 0) {
      for (let i = 0; i < dictionary.length; i++) {
        if (dictionary[i].role.every(d => roles.map(r => r.artists_roles[0].name).includes(d))) {
          return dictionary[i].abbreviation;
        }
      }
    }
    return null;

  }

  isFeedbackMaster() {
    return this.isJobManager() && this.isArtistAdminLogedin();
  }

  isGodLogedin() {
    if (typeof this._isGodLogedin !== 'boolean')
      this._isGodLogedin = this.auth.getUser() && (this.auth.getUser().id == 16448); // (this.auth.user.id == 20); (Old Ran) // || this.auth.user.id == 162 (Nati)
    return this._isGodLogedin;
  }

  doesUserHasRole(user: User, role: RoleName) {
    let userRoles = user?.artists_users_roles;
    if (userRoles) {
      for (let i = 0; i < userRoles.length; i++) {
        let ul = userRoles[i].artists_roles;
        if (ul && ul.some(r => r.name == role)) {
          return true;
        }
      }
    }
    return false;
  }
  
  isUserUnderNDA(aGUs: Array<ArtistsGroupUser>): boolean {
    // instead of checking the user's group we should add a permission for those users, maybe even a new role for full-timers
    let isFTorStudio = false;
    if (aGUs) {
      for (const agu of aGUs) {
        if (agu.artists_groups.find(ag => ag.type === GroupType.FULL_TIMER || ag.type === GroupType.STUDIO)) {
          isFTorStudio = true;
          break;
        }
      }
    }
    return isFTorStudio;
  }

  isUnderNDA(): boolean {
    if (typeof this._isUnderNDA !== 'boolean') {
      this._isUnderNDA = this.auth.getUser() && this.isAdminLogedin();
      const user = this.auth.getUser();
      if (!this._isUnderNDA && user) {
        this._isUnderNDA = this.isUserUnderNDA(user.artists_groups_users);
      }
    }
    return this._isUnderNDA;
  }

  isRankEditor() {
    const user = this.auth.getUser();

    if (typeof this._isRankDefinitionEditorLogedin !== 'boolean')
      this._isRankDefinitionEditorLogedin = user && this.roles.isUserInRole(user.roles, 'Rank Definition Editor');
    return this._isRankDefinitionEditorLogedin;
  }

  isSeniorFeedbackMaster() {
    if (typeof this._isSeniorFeedbackMaster !== 'boolean')
      this._isSeniorFeedbackMaster = this.auth.getUser() && this.roles.isUserInRole(this.auth.getUser().roles, 'Senior Feedback Master');
    return this._isSeniorFeedbackMaster;
  }
}
