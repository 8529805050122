import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArtistsItemSpecUI } from '../job';

@Component({
  selector: 'app-specifications-reminder-dialog',
  templateUrl: './specifications-reminder-dialog.component.html',
  styleUrls: ['./specifications-reminder-dialog.component.scss']
})
export class SpecificationsReminderDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SpecificationsReminderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public specs: Array<ArtistsItemSpecUI>
  ) { }

  close(approved: boolean) {
    this.dialogRef.close(approved);
  }
}
