import { Injectable } from '@angular/core';
import {
  KeyValuePair,
  KeyValueAnyPair,
  InputFileType,
  PaymentSystem,
  Priority,
  JobsTypes,
  GeometryType,
  QaModes
} from './enums';
import { StorageService } from 'ng-storage-service';
import { MediaTag, ResourceMode } from 'app/job/resource';
import { PolyType, PolyShapeType, KeyValuePoly } from 'app/offer/offers';
import { TagAttributeType } from 'app/tagging/tagging';
import { AttachmentFileType } from 'app/categories/category';
import { ChatHistoryType, UserAvailabilityStatus } from 'app/auth/user';
import { DimensionsUnits } from './utils';
import { ResourcePolygonReductionActions } from 'app/job/resource-polygon-reduction';
import { GroupType } from 'app/auth/group';

@Injectable()
export class EnumsService {
  public MODEL: MediaTag;
  public IMAGE: MediaTag;
  public VIDEO: MediaTag;
  public IFRAME: MediaTag;
  // private _singletone: { [id: string]: any };
  constructor(
    private storage: StorageService
  ) {
    this.MODEL = MediaTag.MODEL;
    this.VIDEO = MediaTag.VIDEO;
    this.IMAGE = MediaTag.IMAGE;
    this.IFRAME = MediaTag.IFRAME;
    // this._singletone = {};
  }

  getViewerTypes(): Array<KeyValuePair> {
    let res: Array<KeyValuePair> = new Array();
    res.push({
      key: '3JS',
      value: 'three.js'
    });
    res.push({
      key: 'b4w',
      value: 'Blend4Web'
    });
    res.push({
      key: 'browser',
      value: '2D'
    });
    return res;
  }

  getJobStatus(): Array<KeyValuePair> {
    let res: Array<KeyValuePair> = new Array();
    res.push({
      key: 'In Progress',
      value: 'In Progress'
    });
    res.push({
      key: 'Job Pending Review',
      value: 'Job Pending Review'
    });
    res.push({
      key: 'Job Has Feedback',
      value: 'Job Has Feedback'
    });
    res.push({
      key: 'Pending Secondary Review',
      value: 'Pending Secondary Review'
    });
    res.push({
      key: 'Pending Source Files',
      value: 'Pending Source Files'
    });
    res.push({
      key: 'Approved Job',
      value: 'Approved Job'
    });
    return res;
  }

  getProductStatus(): Array<KeyValuePair> {
    // cs_id,6,Canceled
    let res: Array<KeyValuePair> = new Array();
    res.push({
      key: [1],
      value: 'Request Created'
    });
    res.push({
      key: [2],
      value: 'In Progress - Offers'
    });
    res.push({
      key: [3],
      value: 'In Progress - Jobs'
    });
    res.push({
      key: [5],
      value: 'Rejected'
    });
    res.push({
      key: [4],
      value: 'Client Feedback'
    });
    res.push({
      key: [6],
      value: 'Canceled'
    });
    res.push({
      key: [8],
      value: 'Approved'
    });
    return res;
  }

  // getFormatsTypes(): Array<KeyValuePair> {
  //   let res: Array<KeyValuePair> = new Array();
  //   // res.push({
  //   //   key: '',
  //   //   value: 'any'
  //   // });

  //   res.push({
  //     key: 1,
  //     value: 'OBJ'
  //   });
  //   res.push({
  //     key: 2,
  //     value: 'FBX'
  //   });
  //   // res.push({
  //   //   key: 3,
  //   //   value: 'FBX_PBR'
  //   // });
  //   res.push({
  //     key: 4,
  //     value: 'DAE'
  //   });
  //   res.push({
  //     key: 5,
  //     value: 'BLEND'
  //   });
  //   res.push({
  //     key: 6,
  //     value: 'glTF'
  //   });
  //   res.push({
  //     key: 7,
  //     value: 'GLB'
  //   });
  // res.push({
  //   key: 8,
  //   value: 'USDZ'
  // });
  // res.push({
  //   key: 9,
  //   value: 'PNG'
  // });
  // res.push({
  //   key: 10,
  //   value: 'JPG'
  // });
  // res.push({
  //   key: 11,
  //   value: 'MP4'
  // });
  //   return res;
  // }


  getArtistAvailability(): Array<KeyValuePair> {
    return [
      { key: 'Part-Time', value: 'Part-Time' },
      { key: 'Full-Time', value: 'Full-Time' }
    ];
  }

  getCounries(): Array<KeyValuePair> {
    return JSON.parse(`[
      { "value": "Afghanistan", "key": "AF" },
      { "value": "Åland Islands", "key": "AX" },
      { "value": "Albania", "key": "AL" },
      { "value": "Algeria", "key": "DZ" },
      { "value": "American Samoa", "key": "AS" },
      { "value": "AndorrA", "key": "AD" },
      { "value": "Angola", "key": "AO" },
      { "value": "Anguilla", "key": "AI" },
      { "value": "Antarctica", "key": "AQ" },
      { "value": "Antigua and Barbuda", "key": "AG" },
      { "value": "Argentina", "key": "AR" },
      { "value": "Armenia", "key": "AM" },
      { "value": "Aruba", "key": "AW" },
      { "value": "Australia", "key": "AU" },
      { "value": "Austria", "key": "AT" },
      { "value": "Azerbaijan", "key": "AZ" },
      { "value": "Bahamas", "key": "BS" },
      { "value": "Bahrain", "key": "BH" },
      { "value": "Bangladesh", "key": "BD" },
      { "value": "Barbados", "key": "BB" },
      { "value": "Belarus", "key": "BY" },
      { "value": "Belgium", "key": "BE" },
      { "value": "Belize", "key": "BZ" },
      { "value": "Benin", "key": "BJ" },
      { "value": "Bermuda", "key": "BM" },
      { "value": "Bhutan", "key": "BT" },
      { "value": "Bolivia", "key": "BO" },
      { "value": "Bosnia and Herzegovina", "key": "BA" },
      { "value": "Botswana", "key": "BW" },
      { "value": "Bouvet Island", "key": "BV" },
      { "value": "Brazil", "key": "BR" },
      { "value": "British Indian Ocean Territory", "key": "IO" },
      { "value": "Brunei Darussalam", "key": "BN" },
      { "value": "Bulgaria", "key": "BG" },
      { "value": "Burkina Faso", "key": "BF" },
      { "value": "Burundi", "key": "BI" },
      { "value": "Cambodia", "key": "KH" },
      { "value": "Cameroon", "key": "CM" },
      { "value": "Canada", "key": "CA" },
      { "value": "Cape Verde", "key": "CV" },
      { "value": "Cayman Islands", "key": "KY" },
      { "value": "Central African Republic", "key": "CF" },
      { "value": "Chad", "key": "TD" },
      { "value": "Chile", "key": "CL" },
      { "value": "China", "key": "CN" },
      { "value": "Christmas Island", "key": "CX" },
      { "value": "Cocos (Keeling) Islands", "key": "CC" },
      { "value": "Colombia", "key": "CO" },
      { "value": "Comoros", "key": "KM" },
      { "value": "Congo", "key": "CG" },
      { "value": "Congo, The Democratic Republic of the", "key": "CD" },
      { "value": "Cook Islands", "key": "CK" },
      { "value": "Costa Rica", "key": "CR" },
      { "value": "Cote D'Ivoire", "key": "CI" },
      { "value": "Croatia", "key": "HR" },
      { "value": "Cuba", "key": "CU" },
      { "value": "Cyprus", "key": "CY" },
      { "value": "Czech Republic", "key": "CZ" },
      { "value": "Denmark", "key": "DK" },
      { "value": "Djibouti", "key": "DJ" },
      { "value": "Dominica", "key": "DM" },
      { "value": "Dominican Republic", "key": "DO" },
      { "value": "Ecuador", "key": "EC" },
      { "value": "Egypt", "key": "EG" },
      { "value": "El Salvador", "key": "SV" },
      { "value": "Equatorial Guinea", "key": "GQ" },
      { "value": "Eritrea", "key": "ER" },
      { "value": "Estonia", "key": "EE" },
      { "value": "Ethiopia", "key": "ET" },
      { "value": "Falkland Islands (Malvinas)", "key": "FK" },
      { "value": "Faroe Islands", "key": "FO" },
      { "value": "Fiji", "key": "FJ" },
      { "value": "Finland", "key": "FI" },
      { "value": "France", "key": "FR" },
      { "value": "French Guiana", "key": "GF" },
      { "value": "French Polynesia", "key": "PF" },
      { "value": "French Southern Territories", "key": "TF" },
      { "value": "Gabon", "key": "GA" },
      { "value": "Gambia", "key": "GM" },
      { "value": "Georgia", "key": "GE" },
      { "value": "Germany", "key": "DE" },
      { "value": "Ghana", "key": "GH" },
      { "value": "Gibraltar", "key": "GI" },
      { "value": "Greece", "key": "GR" },
      { "value": "Greenland", "key": "GL" },
      { "value": "Grenada", "key": "GD" },
      { "value": "Guadeloupe", "key": "GP" },
      { "value": "Guam", "key": "GU" },
      { "value": "Guatemala", "key": "GT" },
      { "value": "Guernsey", "key": "GG" },
      { "value": "Guinea", "key": "GN" },
      { "value": "Guinea-Bissau", "key": "GW" },
      { "value": "Guyana", "key": "GY" },
      { "value": "Haiti", "key": "HT" },
      { "value": "Heard Island and Mcdonald Islands", "key": "HM" },
      { "value": "Holy See (Vatican City State)", "key": "VA" },
      { "value": "Honduras", "key": "HN" },
      { "value": "Hong Kong", "key": "HK" },
      { "value": "Hungary", "key": "HU" },
      { "value": "Iceland", "key": "IS" },
      { "value": "India", "key": "IN" },
      { "value": "Indonesia", "key": "ID" },
      { "value": "Iran, Islamic Republic Of", "key": "IR" },
      { "value": "Iraq", "key": "IQ" },
      { "value": "Ireland", "key": "IE" },
      { "value": "Isle of Man", "key": "IM" },
      { "value": "Israel", "key": "IL" },
      { "value": "Italy", "key": "IT" },
      { "value": "Jamaica", "key": "JM" },
      { "value": "Japan", "key": "JP" },
      { "value": "Jersey", "key": "JE" },
      { "value": "Jordan", "key": "JO" },
      { "value": "Kazakhstan", "key": "KZ" },
      { "value": "Kenya", "key": "KE" },
      { "value": "Kiribati", "key": "KI" },
      { "value": "Korea, Democratic People'S Republic of", "key": "KP" },
      { "value": "Korea, Republic of", "key": "KR" },
      { "value": "Kuwait", "key": "KW" },
      { "value": "Kyrgyzstan", "key": "KG" },
      { "value": "Lao People'S Democratic Republic", "key": "LA" },
      { "value": "Latvia", "key": "LV" },
      { "value": "Lebanon", "key": "LB" },
      { "value": "Lesotho", "key": "LS" },
      { "value": "Liberia", "key": "LR" },
      { "value": "Libyan Arab Jamahiriya", "key": "LY" },
      { "value": "Liechtenstein", "key": "LI" },
      { "value": "Lithuania", "key": "LT" },
      { "value": "Luxembourg", "key": "LU" },
      { "value": "Macao", "key": "MO" },
      { "value": "Macedonia, The Former Yugoslav Republic of", "key": "MK" },
      { "value": "Madagascar", "key": "MG" },
      { "value": "Malawi", "key": "MW" },
      { "value": "Malaysia", "key": "MY" },
      { "value": "Maldives", "key": "MV" },
      { "value": "Mali", "key": "ML" },
      { "value": "Malta", "key": "MT" },
      { "value": "Marshall Islands", "key": "MH" },
      { "value": "Martinique", "key": "MQ" },
      { "value": "Mauritania", "key": "MR" },
      { "value": "Mauritius", "key": "MU" },
      { "value": "Mayotte", "key": "YT" },
      { "value": "Mexico", "key": "MX" },
      { "value": "Micronesia, Federated States of", "key": "FM" },
      { "value": "Moldova, Republic of", "key": "MD" },
      { "value": "Monaco", "key": "MC" },
      { "value": "Mongolia", "key": "MN" },
      { "value": "Montserrat", "key": "MS" },
      { "value": "Morocco", "key": "MA" },
      { "value": "Mozambique", "key": "MZ" },
      { "value": "Myanmar", "key": "MM" },
      { "value": "Namibia", "key": "NA" },
      { "value": "Nauru", "key": "NR" },
      { "value": "Nepal", "key": "NP" },
      { "value": "Netherlands", "key": "NL" },
      { "value": "Netherlands Antilles", "key": "AN" },
      { "value": "New Caledonia", "key": "NC" },
      { "value": "New Zealand", "key": "NZ" },
      { "value": "Nicaragua", "key": "NI" },
      { "value": "Niger", "key": "NE" },
      { "value": "Nigeria", "key": "NG" },
      { "value": "Niue", "key": "NU" },
      { "value": "Norfolk Island", "key": "NF" },
      { "value": "Northern Mariana Islands", "key": "MP" },
      { "value": "Norway", "key": "NO" },
      { "value": "Oman", "key": "OM" },
      { "value": "Pakistan", "key": "PK" },
      { "value": "Palau", "key": "PW" },
      { "value": "Palestinian Territory", "key": "PS" },
      { "value": "Panama", "key": "PA" },
      { "value": "Papua New Guinea", "key": "PG" },
      { "value": "Paraguay", "key": "PY" },
      { "value": "Peru", "key": "PE" },
      { "value": "Philippines", "key": "PH" },
      { "value": "Pitcairn", "key": "PN" },
      { "value": "Poland", "key": "PL" },
      { "value": "Portugal", "key": "PT" },
      { "value": "Puerto Rico", "key": "PR" },
      { "value": "Qatar", "key": "QA" },
      { "value": "Reunion", "key": "RE" },
      { "value": "Romania", "key": "RO" },
      { "value": "Russian Federation", "key": "RU" },
      { "value": "RWANDA", "key": "RW" },
      { "value": "Saint Helena", "key": "SH" },
      { "value": "Saint Kitts and Nevis", "key": "KN" },
      { "value": "Saint Lucia", "key": "LC" },
      { "value": "Saint Pierre and Miquelon", "key": "PM" },
      { "value": "Saint Vincent and the Grenadines", "key": "VC" },
      { "value": "Samoa", "key": "WS" },
      { "value": "San Marino", "key": "SM" },
      { "value": "Sao Tome and Principe", "key": "ST" },
      { "value": "Saudi Arabia", "key": "SA" },
      { "value": "Senegal", "key": "SN" },
      { "value": "Serbia and Montenegro", "key": "CS" },
      { "value": "Seychelles", "key": "SC" },
      { "value": "Sierra Leone", "key": "SL" },
      { "value": "Singapore", "key": "SG" },
      { "value": "Slovakia", "key": "SK" },
      { "value": "Slovenia", "key": "SI" },
      { "value": "Solomon Islands", "key": "SB" },
      { "value": "Somalia", "key": "SO" },
      { "value": "South Africa", "key": "ZA" },
      { "value": "South Georgia and the South Sandwich Islands", "key": "GS" },
      { "value": "Spain", "key": "ES" },
      { "value": "Sri Lanka", "key": "LK" },
      { "value": "Sudan", "key": "SD" },
      { "value": "Suriname", "key": "SR" },
      { "value": "Svalbard and Jan Mayen", "key": "SJ" },
      { "value": "Swaziland", "key": "SZ" },
      { "value": "Sweden", "key": "SE" },
      { "value": "Switzerland", "key": "CH" },
      { "value": "Syrian Arab Republic", "key": "SY" },
      { "value": "Taiwan, Province of China", "key": "TW" },
      { "value": "Tajikistan", "key": "TJ" },
      { "value": "Tanzania, United Republic of", "key": "TZ" },
      { "value": "Thailand", "key": "TH" },
      { "value": "Timor-Leste", "key": "TL" },
      { "value": "Togo", "key": "TG" },
      { "value": "Tokelau", "key": "TK" },
      { "value": "Tonga", "key": "TO" },
      { "value": "Trinidad and Tobago", "key": "TT" },
      { "value": "Tunisia", "key": "TN" },
      { "value": "Turkey", "key": "TR" },
      { "value": "Turkmenistan", "key": "TM" },
      { "value": "Turks and Caicos Islands", "key": "TC" },
      { "value": "Tuvalu", "key": "TV" },
      { "value": "Uganda", "key": "UG" },
      { "value": "Ukraine", "key": "UA" },
      { "value": "United Arab Emirates", "key": "AE" },
      { "value": "United Kingdom", "key": "GB" },
      { "value": "United States", "key": "US" },
      { "value": "United States Minor Outlying Islands", "key": "UM" },
      { "value": "Uruguay", "key": "UY" },
      { "value": "Uzbekistan", "key": "UZ" },
      { "value": "Vanuatu", "key": "VU" },
      { "value": "Venezuela", "key": "VE" },
      { "value": "Viet Nam", "key": "VN" },
      { "value": "Virgin Islands, British", "key": "VG" },
      { "value": "Virgin Islands, U.S.", "key": "VI" },
      { "value": "Wallis and Futuna", "key": "WF" },
      { "value": "Western Sahara", "key": "EH" },
      { "value": "Yemen", "key": "YE" },
      { "value": "Zambia", "key": "ZM" },
      { "value": "Zimbabwe", "key": "ZW" }
    ]`) as Array<KeyValuePair>;
  }

  getAvailability(): Array<KeyValuePair> {
    return [
      { value: 'Part-Time', key: 'Part-Time' },
      { value: 'Full-Time', key: 'Full-Time' }
    ];
  }

  getPaymentsMethod(): Array<KeyValuePair> {
    return [
      { value: 'UpWork', key: PaymentSystem.UP_WORK },
      { value: 'PayPal', key: PaymentSystem.PAY_PAL },
      { value: 'Payoneer', key: PaymentSystem.PAYONEER }
    ];
  }

  // getHDRs(): Array<KeyValuePair> {
  //   return [
  //     { value: 'yellow', key: 5 },
  //     { value: 'blue', key: 6 },
  //     // { value: 'blue green', key: 7 },
  //     { value: 'white', key: 8 },
  //     { value: 'wood', key: 9 },
  //     { value: 'black', key: 10 },
  //     { value: 'off-white', key: 11 },
  //     // { value: 'space-blue', key: 12 },
  //     { value: 'bronze', key: 14 },
  //     { value: 'silver', key: 15 },
  //     { value: 'gold', key: 16 }
  //   ];
  // }

  getTexturesSizes(): Array<KeyValuePair> {
    return [
      { value: '512', key: 512 },
      { value: '1K', key: 1024 },
      { value: '2K', key: 2048 },
      { value: '4K', key: 4096 }
    ];
  }

  getSidesRender(): Array<KeyValuePair> {
    return [
      { value: 'Front Side', key: 'FrontSide' },
      { value: 'Back Side', key: 'BackSide' },
      { value: 'Double Side', key: 'DoubleSide' }
    ];
  }

  getTransparentRenderOrder(): Array<KeyValuePair> {
    return [
      { value: 'Z Index', key: '1' },
      { value: 'Reverse Camera Proximity', key: '2' },
      { value: 'Camera Proximity', key: '3' }
    ];
  }

  getProductPositions(): Array<KeyValuePair> {
    let res: Array<KeyValuePair> = new Array();
    res.push({
      key: 1,
      value: 'Standing'
    });
    res.push({
      key: 2,
      value: 'Flat'
    });
    return res;
  }

  getJobActionsTypes(): Array<KeyValueAnyPair> {
    let res: Array<KeyValueAnyPair> = new Array();
    // res.push({
    //   key: 'hours_to_complete',
    //   value: 'Edited hours to complete'
    // });
    // res.push({
    //   key: 'products_resources',
    //   value: 'Edited resources'
    // });
    // res.push({
    //   key: 'products_urls',
    //   value: 'Edited URL\'s'
    // });
    // res.push({
    //   key: 'products_tags',
    //   value: 'Edited tags'
    // });
    // res.push({
    //   key: 'products_specs',
    //   value: 'Edited specifications'
    // });
    // res.push({
    //   key: 'products_categories',
    //   value: 'Edited categories'
    // });
    // res.push({
    //   key: 'products_resources_feedback',
    //   value: 'Created a feedback'
    // });
    // res.push({
    //   key: 'products_identifiers',
    //   value: 'Edited serial number'
    // });
    // res.push({
    //   key: 'notes',
    //   value: 'Edited description'
    // });
    res.push({
      key: null,
      value: {
        'hours_to_complete': 'Hours to Complete',
        'org_htc': 'Hours to Complete (HTC)',
        'opened_by': 'Opened By',
        'source_offer': 'Source Offer',
        'is_archived': 'Deleted',
        'delivered_at': 'Delivered At',
        'price': 'Price',
        'status': 'Status',
        'paid': 'Paid',
        'same_mesh': 'Same Mesh',
        'manager_id': 'Manager ID',
        'countdown_active': 'Countdown Active',
        'bypass_dimensions': 'Bypass Dimensions',
        'has_been_extended': 'Has Been Extended',
        'quality_supervisor': 'Quality Supervisor',
        'total_time_extensions': 'Total Time Extensions',
        'is_paused': 'is paused',
        'pause_reason': 'pause reason',
        'job_taken': 'Has taken this job',
        'unattended_comment': 'Unattended Comment',
        'opened_texture': 'Opened Texture',
        'reopen': 'Reopen',
        'complexity': 'Complexity',
        'bypass_geometry_accuracy': 'Bypass Geometry Accuracy',
        'bypass_color_comparison': 'Bypass Color Comparison',
        'feedback_session_open': 'Open Feedback Session',
        'current_feedback_session_id': 'Feedback Session ID',
        'sticky_type': 'Sticky Type',
        'score_affects_rank': 'Score Affects Rank',
        'job_score': 'Job Score',
        'feedbacker_id': 'Feedbacker ID'
      }
    },
      {
        key: 'artists_jobs_pricing',
        value: 'Edited Base Price'
        // value: {
        //   'base_price': 'Base Price'
        // }
      },
      {
        key: 'artists_jobs_resources',
        value: 'Edited a Resource'
        // { 'artists_jobs_resources': 'Resource' }
      });
    return res;
  }

  public getDimensionsUnits(): Array<KeyValuePair> {
    return [
      { key: DimensionsUnits.INCH, value: 'inch' },
      { key: DimensionsUnits.CM, value: 'cm' }
    ];
  }

  getMaterialTypes(): Array<KeyValueAnyPair> {
    return [
      { key: 'MeshPhysicalMaterial', value: 'Mesh Physical Material' },
      { key: 'MeshStandardMaterial', value: 'Mesh Standard Material' },
      { key: 'MeshPhongMaterial', value: 'Mesh Phong Material' },
      { key: 'MeshBasicMaterial', value: 'Mesh Basic Material' },
      { key: 'MeshLambertMaterial', value: 'Mesh Lambert Material' },
      // { key: 'MeshToonMaterial', value: 'Mesh Toon Material' },
      // { key: 'PointsMaterial', value: 'Points Material' },
      // { key: 'RawShaderMaterial', value: 'Raw Shader Material' },
      // { key: 'ShaderMaterial', value: 'Shader Material' },
      // { key: 'ShadowMaterial', value: 'Shadow Material' },
      // { key: 'SpriteMaterial', value: 'Sprite Material' },
      // { key: 'LineBasicMaterial', value: 'Line Basic Material' },
      // { key: 'LineDashedMaterial', value: 'Line Dashed Material' },
      // { key: 'Material', value: 'Material' }
    ];
  }

  getLeads(): Array<KeyValueAnyPair> {
    return [
      { key: 'Google', value: 'Google' },
      { key: 'Facebook', value: 'Facebook' },
      { key: 'Friends', value: 'Friends' },
      { key: 'UpWork', value: 'UpWork' },
      { key: 'Freelancer', value: 'Freelancer' },
      { key: 'Creativepool', value: 'Creativepool' },
      { key: 'Guru', value: 'Guru' },
      { key: 'Other', value: 'Other' }
    ];
  }

  getFileAcceptTypes(): { [id: number]: string } {
    let res = {};
    res[InputFileType.ZIP] = 'application/x-zip-compressed';
    res[InputFileType.MP4] = '.video/mp4,video/x-m4v,video/*';
    res[InputFileType.PNG] = 'image/png';
    res[InputFileType.JPEG] = 'image/jpg, image/jpeg';
    res[InputFileType.TIFF] = 'image/tif, image/tiff';
    res[InputFileType.GLB] = '.glb';
    res[InputFileType.USDZ] = '.usdz';
    Object.freeze(res);
    return res;
  }

  getSuffixByAcceptTypes(): { [id: string]: Array<string> } {
    let res = {};
    const fat = this.getFileAcceptTypes();
    res[fat[InputFileType.ZIP]] = ['zip'];
    res[fat[InputFileType.MP4]] = ['mp4'];
    res[fat[InputFileType.PNG]] = ['png'];
    res[fat[InputFileType.JPEG]] = ['jpg', 'jpeg'];
    res[fat[InputFileType.TIFF]] = ['tif', 'tiff'];
    res[fat[InputFileType.GLB]] = ['glb', 'zip'];
    res[fat[InputFileType.USDZ]] = ['usdz'];
    Object.freeze(res);
    return res;
  }

  public getPolyTypes(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    const dictionary = this.getPolyTypesDictionary();
    for (var i in dictionary) {
      res.push({
        key: parseInt(i),
        value: dictionary[i].value.name
      })
    }
    return res;
  }

  public getPolyShapeTypes() {
    const res: Array<KeyValuePair> = [];
    const dictionary = this.getPolyShapeTypesDictionary();
    for (const i in dictionary) {
      if (Reflect.has(dictionary, i)) {
        res.push({
          key: parseInt(i),
          value: dictionary[i].value.name
        });
      }
    }
    return res;
  }

  public getPolyTypesDictionary(): { [id: number]: KeyValuePoly } {
    const res = {} as { [id: number]: KeyValuePoly };
    res[PolyType.LOW] = {
      key: PolyType.LOW,
      value: {
        name: 'low',
        icon: 'https://cdn.creators3d.com/hotlink-ok/icons/v2/low-poly.svg'
      }
    } as KeyValuePoly;
    res[PolyType.MID] = {
      key: PolyType.MID,
      value: {
        name: 'mid',
        icon: 'https://cdn.creators3d.com/hotlink-ok/icons/v2/mid-poly.svg'
      }
    } as KeyValuePoly;
    res[PolyType.HIGH] = {
      key: PolyType.HIGH,
      value: {
        name: 'high',
        icon: 'https://cdn.creators3d.com/hotlink-ok/icons/v2/high-poly.svg',
        desc: 'high definition for render'
      }
    } as KeyValuePoly;
    return res;
  }

  public getPolyShapeTypesDictionary(): { [id: number]: KeyValueAnyPair } {
    let res = {} as { [id: number]: KeyValueAnyPair };
    res[PolyShapeType.TRIANGULAR] = {
      key: PolyShapeType.TRIANGULAR,
      value: {
        name: 'triangles'
      }
    } as KeyValueAnyPair;
    res[PolyShapeType.QUADRANGULAR] = {
      key: PolyShapeType.QUADRANGULAR,
      value: {
        name: 'quads'
        // name: 'quadrangular'
      }
    } as KeyValueAnyPair;
    return res;
  }

  public getTagDictionaryAttributeTypes(): { [id: number]: string } {
    let res = {} as { [id: number]: string };
    res[TagAttributeType.RADIO] = 'select options (radio)';
    res[TagAttributeType.RANK] = 'rank (stars)';
    return res;
  }

  public getTagListAttributeTypes(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    const dictionary = this.getTagDictionaryAttributeTypes();
    for (let i in dictionary) {
      res.push({
        key: parseInt(i),
        value: dictionary[i]
      });
    }
    return res;
  }

  public getAttachmentFileTypes(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: AttachmentFileType.GUIDE_IMAGE,
      value: AttachmentFileType[AttachmentFileType.GUIDE_IMAGE]
    });
    // res.push({
    //   key: AttachmentFileType.GUIDE_IFRAME,
    //   value: AttachmentFileType[AttachmentFileType.GUIDE_IFRAME]
    // });
    res.push({
      key: AttachmentFileType.GUIDE_DOWNLOAD,
      value: AttachmentFileType[AttachmentFileType.GUIDE_DOWNLOAD]
    });
    res.push({
      key: AttachmentFileType.IMAGE,
      value: AttachmentFileType[AttachmentFileType.IMAGE]
    });
    // res.push({
    //   key: AttachmentFileType.THREE_D_MODEL,
    //   value: AttachmentFileType[AttachmentFileType.THREE_D_MODEL]
    // });
    res.push({
      key: AttachmentFileType.ZIP,
      value: AttachmentFileType[AttachmentFileType.ZIP]
    });
    res.push({
      key: AttachmentFileType.RICH_TEXT,
      value: AttachmentFileType[AttachmentFileType.RICH_TEXT]
    });
    return res;
  }

  public getPriorities(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: Priority.LOWEST,
      value: Priority[Priority.LOWEST].toLowerCase()
    });
    res.push({
      key: Priority.LOW,
      value: Priority[Priority.LOW].toLowerCase()
    });
    res.push({
      key: Priority.MEDIUM,
      value: Priority[Priority.MEDIUM].toLowerCase()
    });
    res.push({
      key: Priority.HIGH,
      value: Priority[Priority.HIGH].toLowerCase()
    });
    res.push({
      key: Priority.HIGHEST,
      value: Priority[Priority.HIGHEST].toLowerCase()
    });
    return res;
  }

  public getPrioritiesDictionary(): { [id: number]: string } {
    let res = {} as { [id: number]: string };
    res[Priority.LOWEST] = Priority[Priority.LOWEST];
    res[Priority.LOW] = Priority[Priority.LOW];
    res[Priority.MEDIUM] = Priority[Priority.MEDIUM];
    res[Priority.HIGH] = Priority[Priority.HIGH];
    res[Priority.HIGHEST] = Priority[Priority.HIGHEST];
    return res;
  }

  public getJobTypes(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: JobsTypes.FIX,
      value: JobsTypes[JobsTypes.FIX]
    });
    res.push({
      key: JobsTypes.GEOMETRY,
      value: JobsTypes[JobsTypes.GEOMETRY]
    });
    res.push({
      key: JobsTypes.RENDR,
      value: JobsTypes[JobsTypes.RENDR]
    });
    res.push({
      key: JobsTypes.TEXTURE,
      value: JobsTypes[JobsTypes.TEXTURE]
    });
    return res;
  }

  public getChatHistoryTypes(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: ChatHistoryType.JOB_CHAT,
      value: 'job chat'
    });
    res.push({
      key: ChatHistoryType.PAYMENTS,
      value: 'payments'
    });
    res.push({
      key: ChatHistoryType.PERSONAL,
      value: 'personal'
    });
    res.push({
      key: ChatHistoryType.SUPPORT,
      value: 'support'
    });
    return res;
  }

  public getQaModes(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: QaModes.BOTH,
      value: 'FM and QS'
    });
    res.push({
      key: QaModes.FM_ONLY,
      value: 'FM only'
    });
    res.push({
      key: QaModes.QS_ONLY,
      value: 'QS only'
    });
    return res;
  }

  public getResourcePolygonReductionActions(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: ResourcePolygonReductionActions.change_poly_scale,
      value: 'change poly scale'
    });
    res.push({
      key: ResourcePolygonReductionActions.change_poly_type,
      value: 'change poly type'
    });
    res.push({
      key: ResourcePolygonReductionActions.add_watermark,
      value: 'add watermark'
    });
    res.push({
      key: ResourcePolygonReductionActions.fix,
      value: 'fix'
    });
    return res;
  }

  public getUserAvailabilityStatus(isQS = false): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
      res.push({
        key: UserAvailabilityStatus.OFFLINE,
        value: 'Not working'
      });
      res.push({
        key: UserAvailabilityStatus.ONLINE,
        value: `Online (Feedback Master)`
      });
      res.push({
        key: UserAvailabilityStatus.OFF_WORK,
        value: 'Internal tasks'
      });
    return res;
  }

  public getGroupTypes(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: GroupType.FULL_TIMER,
      value: 'Full Timer'
    });
    res.push({
      key: GroupType.STUDIO,
      value: 'Studio'
    });
    res.push({
      key: GroupType.TEST,
      value: 'Test'
    });
    res.push({
      key: GroupType.CAMPAIGN,
      value: 'Campaign'
    });
    res.push({
      key: GroupType.REGION,
      value: 'Region'
    });
    res.push({
      key: GroupType.TRAINING,
      value: 'Training'
    });
    res.push({
      key: GroupType.FIX_JOBS,
      value: 'Fix Jobs'
    });
    res.push({
      key: GroupType.QS_FM_TEAM,
      value: 'QS FM team'
    });
    res.push({
      key: GroupType.GENERAL,
      value: 'General'
    });
    res.push({
      key: GroupType.PRIVATE,
      value: 'Private'
    });
    res.push({
        key: GroupType.CONTENT,
        value: 'Content'
      });
    return res;
  }

  public getGeometryTypes(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: GeometryType.HardSurface,
      value: GeometryType[GeometryType.HardSurface].replace(/([A-Z])/g, ' $1')
    });
    res.push({
      key: GeometryType.Organic,
      value: GeometryType[GeometryType.Organic]
    });
    res.push({
      key: GeometryType.Mixture,
      value: GeometryType[GeometryType.Mixture]
    });
    return res;
  }

  public getAlignmentPixels(): Array<KeyValuePair> {
    const res = [] as Array<KeyValuePair>;
    res.push({
      key: 0,
      value: 'full image'
    });
    res.push({
      key: 1,
      value: '1X1'
    });
    res.push({
      key: 5,
      value: '5X5'
    });
    res.push({
      key: 10,
      value: '10X10'
    });
    res.push({
      key: 30,
      value: '30X30'
    });
    return res;
  }

  public getResourceModes(): Array<KeyValuePair> {
    return [{
      key: ResourceMode.ADJUSTMENTS,
      value: 'adjustments'
    }, {
      key: ResourceMode.ALIGNMENT,
      value: 'geometry accuracy'
    }, {
      key: ResourceMode.COLOR_SAMPLING,
      value: 'color sampling'
    }, {
      key: ResourceMode.DECIMATE_POLYGONS,
      value: 'reduce polygons'
    }];
  }

  public getIconByTag(tag: MediaTag): string {
    switch (tag) {
      case MediaTag.IMAGE: {
        return 'https://cdn.creators3d.com/hotlink-ok/icons/baseline-image.svg';
      }
      case MediaTag.MODEL: {
        return 'https://cdn.creators3d.com/hotlink-ok/icons/baseline-3d_rotation-24px.svg';
      }
      case MediaTag.VIDEO: {
        return 'https://cdn.creators3d.com/hotlink-ok/icons/video-camera.svg';
      }
    }
  }

}
