<div id="mainNavigationBar" class="header-wrapper" headerScrollState>

  <!-- Desktop ToolBar -->
  <mat-toolbar class="hide-sm">
    <div fxLayout="row" fxFill>

      <!-- Left Links -->
      <div fxFlex fxLayoutAlign="start center">
        <a mat-button routerLink="home" routerLinkActive="active" class="logo-img-bg" aria-label="logo"></a>
      </div>

      <!-- Centered Links -->
      <div fxFlex fxLayoutAlign="center center">
        <span *ngFor="let link of (links | mainNav : 'left') | bottom" [ngClass]="link.cssClass">
          <a *ngIf="link.type == ANCHOR" mat-button routerLink="{{link.route}}" routerLinkActive="active">
            <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
            {{link.label}}
          </a>
          <a *ngIf="link.type == BUTTON" mat-button (click)="linkClick(link.action)">
            <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
            {{link.label}}
          </a>
          <a *ngIf="link.type == EXTERNAL" mat-button href="{{link.route}}" target="_blank">
            {{link.label}}
          </a>
        </span>
      </div>

      <!-- Right Links -->
      <div fxFlex fxLayoutAlign="end center">
        <span *ngIf="(links | mainNav : 'left' : true).length > 0">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <span *ngFor="let link of links | mainNav : 'left' : true" [ngClass]="link.cssClass">
              <a *ngIf="link.type == ANCHOR" mat-menu-item routerLink="{{link.route}}" routerLinkActive="active">
                <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
                {{link.label}}
              </a>
              <a *ngIf="link.type == BUTTON" mat-menu-item (click)="linkClick(link.action)">
                <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
                {{link.label}}
              </a>
            </span>
          </mat-menu>
        </span>
        <span *ngFor="let link of links | mainNav : 'right'" [ngClass]="link.cssClass">
          <a *ngIf="link.type == ANCHOR && !link.icon" mat-button routerLink="{{link.route}}" routerLinkActive="active"
            (click)="anchorClick(link.route)">
            {{link.label}}
          </a>
          <a *ngIf="link.type == ANCHOR && link.icon" mat-icon-button routerLink="{{link.route}}"
            routerLinkActive="active">
            <mat-icon>{{link.icon}}</mat-icon>
            {{link.label}}
          </a>
          <a *ngIf="link.type == BUTTON && !link.icon" mat-button (click)="linkClick(link.action)">
            {{link.label}}
          </a>
          <a *ngIf="link.type == BUTTON && link.icon" mat-icon-button (click)="linkClick(link.action)">
            <mat-icon>{{link.icon}}</mat-icon>
            {{link.label}}
            <span class="count" *ngIf="link.count">{{link.count > 99 ? overThenNinetyNine : link.count}}</span>
          </a>
        </span>
      </div>

    </div>
  </mat-toolbar>

  <!-- Mobile ToolBar -->
  <mat-sidenav-container class="hide-gt-sm" [hasBackdrop]="false">

    <mat-sidenav [mode]="'over'" #mobileMenu (open)="closeStartButton.focus()" [position]="'end'" class="mobile-menu">
      <button mat-button #closeStartButton (click)="mobileMenu.close()" class="marg-r-100" aria-label="mobile menu">
        <mat-icon>close</mat-icon>
      </button>
      <div *ngFor="let link of links | bottom" (click)="mobileMenu.close()" class="nav-link text-center">
        <a class="disp-block" *ngIf="link.type == ANCHOR && !link.icon" mat-button routerLink="{{link.route}}"
          routerLinkActive="active" color="primary" [ngClass]="link.cssClass">
          {{link.label}}
        </a>
        <a class="disp-block" *ngIf="link.type == ANCHOR && link.icon" mat-icon-button routerLink="{{link.route}}"
          routerLinkActive="active" color="primary" [ngClass]="link.cssClass">
          <mat-icon>{{link.icon}}</mat-icon>
          {{link.label}}
        </a>
        <a class="disp-block" *ngIf="link.type == BUTTON && !link.icon" mat-button (click)="linkClick(link.action)"
          color="accent" [ngClass]="link.cssClass">
          {{link.label}}
        </a>
        <a class="disp-block" *ngIf="link.type == BUTTON && link.icon" mat-icon-button (click)="linkClick(link.action)"
          color="accent" [ngClass]="link.cssClass">
          <mat-icon>{{link.icon}}</mat-icon>
          {{link.label}}
          <span class="count" *ngIf="link.count">{{link.count > 99 ? overThenNinetyNine : link.count}}</span>
        </a>
        <a class="disp-block" *ngIf="link.type == EXTERNAL && !link.icon" mat-button href="{{link.route}}"
          target="_blank" color="primary" [ngClass]="link.cssClass">
          {{link.label}}
        </a>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar>
        <div fxLayout="row" fxFill>

          <!-- Left Links -->
          <div fxFlex fxLayoutAlign="start center">
            <a mat-button routerLink="home" routerLinkActive="active" class="logo-img-bg" aria-label="logo"></a>
          </div>
          <!-- Right Links -->
          <div fxFlex fxLayoutAlign="end center">
            <a mat-icon-button (click)="mobileMenu.open()">
              <mat-icon>more_vert</mat-icon>
            </a>
          </div>
        </div>
      </mat-toolbar>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>

<!-- Mobile Stripe Header -->
<div *ngIf="auth.isloggedIn()" class="bottom-stripe box-shadow hide-gt-xs" fxLayout="row" fxLayoutAlign="space-between center">
  <span *ngFor="let link of links | bottom : true" [fxFlex] class="text-center">
    <a *ngIf="link.type == ANCHOR && link.icon" mat-icon-button color="mat-primary" routerLink="{{link.route}}" [ngClass]="link.cssClass">
      <mat-icon>{{link.icon}}</mat-icon>
    </a>
    <a *ngIf="link.type == BUTTON && link.icon" mat-icon-button color="mat-primary" (click)="linkClick(link.action)"
      color="accent" [ngClass]="link.cssClass">
      <mat-icon>{{link.icon}}</mat-icon>
      <span class="count" *ngIf="link.count">{{link.count > 99 ? overThenNinetyNine : link.count}}</span>
    </a>
  </span>
</div>
