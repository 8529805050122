import { Component, OnInit } from '@angular/core';
import { SwService } from 'app/sw-helper/sw.service';
import { StorageService } from 'ng-storage-service';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'app-homescreen-teaser',
  templateUrl: './homescreen-teaser.component.html',
  styleUrls: ['./homescreen-teaser.component.scss']
})
export class HomescreenTeaserComponent implements OnInit {
  static LAST_DATE = 'homescreen-teaser-last';
  static WEEK_MS = 604800000;
  public visible: boolean;
  constructor(
    private swService: SwService,
    private storage: StorageService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    let date = this.storage.get(HomescreenTeaserComponent.LAST_DATE);
    if (date) {
      date = date * 1;
      if (!isNaN(date)) {
        if (this.utils.isAboveTS(new Date().getTime(), date, HomescreenTeaserComponent.WEEK_MS))
          this.visible = true;
      }
    }
    else
      this.visible = true;
  }

  add() {
    this.swService.addToHomescreen();
    this.hide();
  }

  hide() {
    this.visible = false;
    this.storage.set(HomescreenTeaserComponent.LAST_DATE, new Date().getTime());
  }
}
