<h2 mat-dialog-title>Never miss an Offer</h2>
<mat-dialog-content>
    <p>Subscribe to Browser Notifications and get updates about new offers as soon as they are being released.</p>
    <p>Be the first to decide if the Job is yours!</p>

    <div *ngIf="isSubscribedDenied">
        <p class="warn-accent-color">
            <mat-icon class="v-align-b">warning</mat-icon> Looks like you have denied notifications for Creators3D.com
        </p>
        <p>That's fine, you can undo that, here's how:</p>
        <ul>
            <li>
                Go to <a (click)="copyLink('chrome://settings/content/notifications?search=notifica')" href="chrome://settings/content/notifications?search=notifica"
                    target="_blank">chrome://settings/content/notifications?search=notifica</a>
            </li>
            <li>
                On "Block" section search for "https://www.creators3d.com:443"
            </li>
            <li>
                Click the options
                <mat-icon class="v-align-b">more_vert</mat-icon> icon
            </li>
            <li>
                Click on "Remove"
            </li>
            <li>
                Click <button mat-button class="link-btn" [mat-dialog-close]="true" [disabled]="isSubscribedDenied">here</button>
            </li>
        </ul>
    </div>
    <div *ngIf="isSubscribedDeniedCancel">
        <p class="success-accent-color">
            <mat-icon class="v-align-b">done</mat-icon> notifications for Creators3D.com are no longer denied
        </p>
    </div>
    <p>
        <mat-accordion *ngIf="!body.isTouch">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Subscribe on your Smartphone:
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <ul>
                        <li>
                            Go to <a href="www.creators3d.com" target="_blank">www.creators3d.com</a> from your mobile
                            device
                        </li>
                        <li>
                            Login to your account
                        </li>
                        <li>
                            Open the Menu
                            <mat-icon class="v-align-b">more_vert</mat-icon> button
                        </li>
                        <li>
                            Go to your profile by clickong on the
                            <mat-icon class="v-align-b">account_circle</mat-icon> button
                        </li>
                        <li>
                            Scroll to "Notifications" section and click on the "Subscribe" button
                        </li>
                    </ul>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true" color="primary" [disabled]="isSubscribedDenied">subscribe</button>
    <button mat-button [mat-dialog-close]="false">not interested</button>
</mat-dialog-actions>