import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ArtistsChatMessage, ChatUser, ChatHistory, ChatHistoryType, ArtistSUserChat, ChatUserId, User, ChatMessageType, UserTyping, UserAvailability, OpenChatHistoryState, ChatHistoryResponse, ChatHistoryOptions, ArtistsChatsTopic, ChatSession } from '../auth/user';
import { StorageService } from 'ng-storage-service';
import { UtilsService } from '../shared/utils.service';
import { WebsocketService } from 'app/communication/websocket.service';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { AuthService } from 'app/auth/auth.service';
import { UserAvailabilityStatus } from 'app/auth/user';
import { RolesHelperService } from 'app/auth/roles-helper.service';
import { EmojisService } from './emojis.service';
import { GraphqlService } from 'app/communication/graphql.service';

@Injectable()
export class ChatService {
  static PAGE_SIZE: number = 20;
  private isSidebarOpen: boolean;
  // private textMessageSound = '//cdn.creators3d.com/hotlink-ok/message.mp3';
  private textMessageSound = 'https://cdn.creators3d.com/hotlink-ok/sounds/chat_message_ring.mp3';
  public onSidebarToggle: Subject<boolean>;
  public onDeployLinks: Subject<null>;
  public onBeforeChatChange: { id: number, any };
  public onChatChange: { id: number, any };
  public chatsPagination: { id: number, number };
  public isPendingRequests: { id: number, boolean };
  public isMinimize: { id: number, boolean };
  public usersDictionary: { [id: number]: ChatUser };
  public fullscreenId: number;
  public totalUnread: number;
  public totalCount: number;
  public hasOpen: boolean;
  public dummyCounter: number;
  public history: Array<ChatHistory>;
  public openOnHistory: { id: number, OpenChatHistoryState };
  public historyDictionary: { id: number, ChatHistory };
  public hasSupport: boolean;
  public fullscreenMode: boolean;
  public currentMainChatHistory: ChatHistory;
  public isSU: boolean;
  public isAdmin: boolean;
  public isSupport: boolean;
  public unreadMode: boolean;
  public isArtistAdmin: boolean;
  public isJobManager: boolean;
  public isJobChatAgent: boolean;
  public topic: string;
  public messageBody: { id: number, string };
  public usersForGroup: Array<User>;
  public onChatSession: Subject<ChatSession>;
  private _chatTopics: Array<ArtistsChatsTopic>;
  // private _chatTopicsDictionary: { [id: number]: string };
  private _fetching: boolean;
  private lastTS: number;
  private isInit: boolean;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private onNewVersion: Subscription;
  private subs: Array<Subscription>;
  private jobIdToOpen: number;
  private openPayment: boolean;
  private _typeId: ChatHistoryType;
  private _unresolvedOnly: boolean;
  private _managerId: number;
  private onStateChangeSub: Subscription;
  private _gotFromCache: boolean;
  private chunkIndex: number;
  private names: { id: number, string };
  private onStateChangeTasks: Array<Function>;

  constructor(
    private storage: StorageService,
    private utils: UtilsService,
    private websocket: WebsocketService,
    private broadcaster: BroadcasterService,
    private auth: AuthService,
    private rolesHelper: RolesHelperService,
    private emojisService: EmojisService,
    private gql: GraphqlService
  ) {
    this.subs = [];
    this.onStateChangeTasks = [];
    this.usersForGroup = [];
    this.names = {} as { id: number, string };
    this.chunkIndex = 0;
    this.unreadMode = false;
    this.history = [];
    this._fetching = false;
    this.openOnHistory = {} as { id: number, OpenChatHistoryState };
    this.dummyCounter = 0;
    this.lastTS = new Date().getTime();
    this.isSidebarOpen = false;
    this.onSidebarToggle = new Subject<boolean>();
    this.onDeployLinks = new Subject<null>();
    this.onChatSession = new Subject<ChatSession>();
    this.onChatChange = {} as { id: number, any };
    this.onBeforeChatChange = {} as { id: number, any };
    this.chatsPagination = {} as { id: number, number };
    this.isPendingRequests = {} as { id: number, boolean };
    this.isMinimize = {} as { id: number, boolean };
    this.usersDictionary = {} as { id: number, ChatUser };
    // this.totalUnread = 0;
    this.hasOpen = false;
    this.historyDictionary = {} as { id: number, ChatHistory };
    this.messageBody = {} as { id: number, string };
    // this._chatTopicsDictionary = {};
    this.isInit = false;
    this._gotFromCache = false;
    this.hasSupport = false;
    this.utils.requestIdleCallback(() => {
      this.setRoles();
    });

    this.preloadSounds();
    this.subscribe();

    if (this.websocket.isOpen) {
      this.isInit = true;
      this.fetch();
    }
    this.onLogin = this.broadcaster.on<User>('onLogin').subscribe(
      user => {
        this.chunkIndex = 0;
        this.historyDictionary = {} as { id: number, ChatHistory };
        this.subscribe();
        if (this.websocket.isOpen) {
          this.isInit = true;
          this.fetch();
        }
        this.setRoles();
      }
    );
    this.onLogout = this.broadcaster.on<User>('onLogout').subscribe(
      () => {
        this.toggleSidebar(false);
        this.history = [];
        delete this.historyDictionary;
        delete this.totalUnread;
        delete this.totalCount;
        this._fetching = false;
        // delete this.users;
        this.hasOpen = false;
        this.isInit = false;
        this.unsubscribe();
        // console.log("%c onLogout render()", 'background: #00aacc; color: #fff');
        this.render();
      }
    );
    this.onNewVersion = this.broadcaster.on<User>('onLogout').subscribe(
      () => {
        this.fetchTopics();
      }
    );
    this.isAdmin = this.rolesHelper.isAdminLogedin();
  }

  get chatTopics() {
    return this._chatTopics;
  }

  // get chatTopicsDictionary() {
  //   return this._chatTopicsDictionary;
  // }

  get gotFromCache(): boolean {
    return this._gotFromCache;
  }

  get fetching(): boolean {
    return this._fetching;
  }

  get typeId(): ChatHistoryType {
    return this._typeId;
  }

  set unresolvedOnly(status: boolean) {
    this._unresolvedOnly = status;
    if (this.unresolvedOnly && this.rolesHelper.isFeedbackMaster())
      this._managerId = this.auth.user.id;
    else
      this._managerId = null;
    this.refreshAndFetch(true);
  }

  get unresolvedOnly() {
    return this._unresolvedOnly;
  }

  set typeId(value: ChatHistoryType) {
    this._typeId = value;
    this._unresolvedOnly = false;
    this._managerId = null;
    this.refreshAndFetch(true);
  }

  fetchTopics(): Promise<Array<ArtistsChatsTopic>> {
    return new Promise((resolve, reject) => {
      this.gql.chatTopics().subscribe((res) => {
        this._chatTopics = res.data.artists_chats_topics;
        // this._chatTopicsDictionary = {};
        // this._chatTopics.forEach(t => this._chatTopicsDictionary[t.chat_type_id] = t.description);
        resolve(this._chatTopics);
      });
    });

  }

  private setRoles() {
    this.isSU = this.rolesHelper.isSULogedin();
    this.isSupport = this.rolesHelper.isSupportLogedin();
    this.isArtistAdmin = this.rolesHelper.isArtistAdminLogedin();
    this.isJobManager = this.rolesHelper.isJobManager();
    this.isJobChatAgent = this.rolesHelper.isJobAgent();
  }

  isManagedByMe(chat: ChatHistory): boolean {
    return chat.manager_id == this.auth.user.id && this.auth.user.availability_status == UserAvailabilityStatus.ONLINE;
  }

  public toggleSidebar(state: boolean) {
    this.isSidebarOpen = state;
    this.onSidebarToggle.next(this.isSidebarOpen);
  }

  public refreshLinks() {
    this.onDeployLinks.next(null);
  }

  public getSidebarState(): boolean {
    return this.isSidebarOpen;
  }

  public fixNewLine(item: ArtistsChatMessage): ArtistsChatMessage {
    if (item && item.body)
      item.body = item.body.replace(/\r|\n/g, " <br />");
    return item;
  }

  public normalizeMessages(messages: Array<ArtistsChatMessage>): Array<ArtistsChatMessage> {
    if (messages instanceof Array) {
      messages.sort(function (a, b) {
        let c = new Date(a.created_at).getTime();
        let d = new Date(b.created_at).getTime();
        return c - d;
      });
      messages.forEach((item) => {
        item = this.fixNewLine(item);
      })
    }
    return messages;
  }

  public playTextMessageSound(): void {
    this.utils.playSound(this.textMessageSound);
  }

  public saveCurrentState() {
    this.storage.set('chatMinimizeState', this.isMinimize);
  }

  public getLastState(): { id: number, boolean } {
    let minimizeState = this.storage.get('chatMinimizeState');
    if (minimizeState)
      return minimizeState;
    return null;
  }

  public setUsersByHistory(h: ChatHistory) {
    if (h.artists_users_chats && h.artists_users_chats.length > 0)
      h.artists_users_chats.forEach(auc => {
        if (auc.artists_users && auc.artists_users.length > 0) {
          auc.artists_users.forEach(au => {
            this.usersDictionary[auc.user_id] = au;
          });
        }
      });
  }

  public openChatById(chatHistoryId: number, fullscreenMode: boolean, isMinimize: boolean) {
    this.isMinimize[chatHistoryId] = isMinimize;
    const current = this.history.find(h => h.id == chatHistoryId);
    if (current) {
      this.openChat(current, fullscreenMode, isMinimize);
      if (fullscreenMode)
        this.currentMainChatHistory = current;
    }
    else
      this.openOnHistory[chatHistoryId] = { fullscreenMode: fullscreenMode } as OpenChatHistoryState;
  }

  public openChat(history: ChatHistory, fullscreenMode: boolean, isMinimize?: boolean) {
    if (history.unread_count && !this.isMinimize[history.id]) {
      this.totalUnread -= history.unread_count;
      history.unread_count = 0;
    }
    if (!fullscreenMode) {
      if (typeof isMinimize === 'boolean')
        this.isMinimize[history.id] = isMinimize;
      else if (!history.isOpen || typeof this.isMinimize[history.id] !== 'boolean') // if the chat is not open right now we will minimize it
        this.isMinimize[history.id] = true;
      history.isOpen = true;
      history.isHidden = false;
      history.order = new Date().getTime();
      this.hasOpen = true;
      this.saveCurrentState();
      // this.normalizeMessages();
    }
    else {
      this.currentMainChatHistory = history;
    }
    this.render();
    if (!this.isMinimize[history.id] && history.artists_chats_messages && history.artists_chats_messages[history.artists_chats_messages.length - 1] && !history.read)
      this.websocket.setIsRead(history.id, history.artists_chats_messages[history.artists_chats_messages.length - 1].id);
  }

  render(reRender?: boolean) {
    // console.log('%c chat rendered ' + this.dummyCounter + ' times', 'background: #222; color: #bada55');
    const now = new Date().getTime();
    if (this.utils.isAboveTS(now, this.lastTS, 1000)) {
      this.dummyCounter++;
      // this.cdr.detectChanges();
      this.lastTS = now;
    }
    else if (!reRender)
      setTimeout(() => {
        this.render(true);
      }, 500);
  }

  public readAll() {
    this.websocket.readAll();
    // this.onReadAll();
    setTimeout(() => {
      this.refreshAndFetch();
    }, 1000);
  }

  onReadAll() {
    this.history.forEach(h => {
      h.unread_count = 0;
    })
    this.totalUnread = 0;
    this.render();
    this.broadcaster.broadcast('deployLinks');
  }

  public getUserHistory(uid: number, maxchatUsers?: number): Array<ArtistSUserChat> {
    let res = [] as Array<ArtistSUserChat>;
    for (let i = 0; i < this.history.length; i++) {
      if (this.history[i].artists_users_chats) {
        let artistsuserchats = this.history[i].artists_users_chats.filter(auc => auc.user_id == uid);
        if (typeof maxchatUsers === 'number') {
          if (this.history[i].artists_users_chats.length <= maxchatUsers)
            res = res.concat(artistsuserchats);
        }
        else
          res = res.concat(artistsuserchats);
      }
    }
    return res;
  }

  public openChatByUser(user: User, fullscreenMode: boolean, isMinimize: boolean, topic?: string) {
    if (user) {
      let cha = this.getUserHistory(user.id, 2), chat = null;
      for (let j = 0; j < cha.length; j++) {
        if (this.historyDictionary[cha[j].chat_id] && this.historyDictionary[cha[j].chat_id].topic == topic) {
          chat = this.historyDictionary[cha[j].chat_id];
          break;
        }
      }
      if (!chat) {
        let chatUsers = [] as Array<ChatUserId>;
        chatUsers.push({
          id: user.id
        });
        this.websocket.createChat(chatUsers, topic);
      }
      else
        this.openChat(chat, fullscreenMode, isMinimize);
    }
  }

  public isGroup(c: ChatHistory) {
    if (!c || !c.artists_users_chats || !c.artists_users_chats.length)
      return false;
    return c.artists_users_chats.length > 2;
  }

  public createGroupChat(uids: Array<number>, topic?: string) {
    let chatUsers = [] as Array<ChatUserId>;
    uids.forEach(id => chatUsers.push({ id }));
    this.websocket.createChat(chatUsers, topic);
  }

  private subscribe() {
    this.subs.push(this.websocket.onHistory.subscribe(this.onHistory.bind(this)));
    this.subs.push(this.websocket.onChatMessage.subscribe(this.onChatMessage.bind(this)));
    this.subs.push(this.websocket.onChatCreated.subscribe(this.onNewChat.bind(this)));
    this.subs.push(this.websocket.onChatMessageHistory.subscribe(this.onChatMessageHistory.bind(this)));
    this.subs.push(this.websocket.onUserAvailability.subscribe(this.onUserAvailability.bind(this)));
    this.subs.push(this.websocket.onUserTyping.subscribe(this.onUserTyping.bind(this)));
    this.subs.push(this.websocket.onReadAll.subscribe(this.onReadAll.bind(this)));
    this.subs.push(this.websocket.onChatSession.subscribe(this._onChatSession.bind(this)));
    if (this.rolesHelper.isFeedbackMaster())
      this.subs.push(this.broadcaster.on('onJobManagerChange').subscribe(this.onJobManagerChange.bind(this)));

    if (!this.websocket.isOpen) {
      this.onStateChangeSub = this.websocket.onStateChange.subscribe(() => {
        if (this.websocket.isOpen || !this.isInit) {
          this.fetch();
        }
        if (this.websocket.isOpen) {
          this.onStateChangeTasks.forEach(f => f());
          this.onStateChangeTasks = [];
        }
      });
    }
  }

  async fetch(force = false) {
    if (!force && (this._fetching || (typeof this.totalCount === 'number' && this.history.length >= this.totalCount))) return;
    this._fetching = true;
    await this.fetchTopics();
    this.websocket.getHistory(this.getFetchOptions());
  }

  private getFetchOptions(): ChatHistoryOptions {
    const historyOffset = this.getHistoryOffset();
    return {
      limit: historyOffset,
      offset: historyOffset * this.chunkIndex++,
      topic: this.topic,
      unresolved_only: this.unresolvedOnly,
      type_id: this._typeId,
      artists_users: this.usersForGroup.length ? this.usersForGroup.map(u => u.id) : null,
      manager_id: this._managerId
    }
  }

  refreshAndFetch(closeAll = false) {
    this.chunkIndex = 0;
    this.history = [];
    if (closeAll) {
      this.currentMainChatHistory = null;
    }
    for (let i in this.historyDictionary) {
      if (!this.historyDictionary[i].isOpen || closeAll) {
        delete this.historyDictionary[i];
      }
    }
    this.dummyCounter++;
    this.fetch(true);
  }

  openChatByJobId(jobId: number) {
    this.jobIdToOpen = jobId;
    const options = {
      job_id: this.jobIdToOpen
    } as ChatHistoryOptions;
    if (this.websocket.isOpen)
      this.websocket.getHistory(options);
    else {
      this.onStateChangeTasks.push(this.websocket.getHistory.bind(this.websocket, options));
    }
  }

  public getHistoryOffset(): number {
    const listItemHeight = 62;
    return Math.round(window.innerHeight / listItemHeight * 1.5);
  }

  private unsubscribe() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];
    if (this.onStateChangeSub)
      this.onStateChangeSub.unsubscribe();
  }

  private preloadSounds() {
    new Audio(this.textMessageSound);
  }

  onHistory(historyResponse: ChatHistoryResponse) {
    this._fetching = false;
    this.totalUnread = historyResponse.total_unread;
    this.totalCount = historyResponse.count;
    // let totalUnread = 0;
    // this.history = historyResponse.rows;
    if (historyResponse.rows.length > 0) {
      historyResponse.rows.forEach((h) => {
        this.history.push(h);
        if (this.openOnHistory[h.id]) {
          let fullscreenMode = this.openOnHistory[h.id].fullscreenMode;
          delete this.openOnHistory[h.id];
          this.openChat(h, fullscreenMode, this.isMinimize[h.id]);
          this.playTextMessageSound();
        }
        if (this.jobIdToOpen && h.job_id == this.jobIdToOpen) {
          delete this.jobIdToOpen;
          if (this.historyDictionary[h.id]) {
            setTimeout(() => {
              this.historyDictionary[h.id].isOpen = true;
              this.historyDictionary[h.id].isHidden = false;
            });
          }
          this.openChat(h, false, false);
          this.playTextMessageSound();
        }
        else if (this.openPayment && h.type_id == ChatHistoryType.PAYMENTS) {
          if (this.historyDictionary[h.id]) {
            setTimeout(() => {
              this.historyDictionary[h.id].isOpen = true;
              this.historyDictionary[h.id].isHidden = false;
            });
          }
          this.openChat(h, false, false);
          this.playTextMessageSound();
        }
        this.setChatToDictionary(h);
        // if (typeof h.unread_count === 'number')
        //   totalUnread += h.unread_count;
        this.setUsersByHistory(h);
        if (h.topic && h.topic.indexOf('Support') == 0)
          this.hasSupport = true;
      });
    }
    this.render();
    // this.totalUnread = totalUnread;
    this.refreshLinks();
    if (!this._gotFromCache) {
      this._gotFromCache = true;
      let state = this.getLastState();
      if (state) {
        for (let id in state) {
          if (this.historyDictionary[id])
            this.openChat(this.historyDictionary[id], false, true);
        }
        this.isMinimize = state;
        this.saveCurrentState();
      }
      this.emojisService.prefetch();
    }
  }

  setChatToDictionary(h: ChatHistory) {
    if (!h.avatar_url) {
      if (h.artists_users_chats) {
        h.artists_users_chats.forEach((artistsuserchat) => {
          if (artistsuserchat.artists_users && artistsuserchat.artists_users.length) {
            let otherUser = artistsuserchat.artists_users.find(u => u.id != this.auth.user.id);
            if (otherUser)
              h.avatar_url = otherUser.avatar_url;
            if (h.avatar_url) return false;
          }
        });
      }
    }
    if (this.historyDictionary[h.id]) {
      h.isOpen = this.historyDictionary[h.id].isOpen;
      h.isHidden = this.historyDictionary[h.id].isHidden;
      h.order = this.historyDictionary[h.id].order;
    }
    this.historyDictionary[h.id] = h;
    this.onChatChange[h.id] = new Subject<boolean>();
    this.onBeforeChatChange[h.id] = new Subject<boolean>();
  }

  onChatMessage(message: ArtistsChatMessage) {
    let chat = this.historyDictionary[message.chat_id] as ChatHistory;
    if (chat && chat.artists_chats_messages) {
      if (chat.artists_chats_messages.length > 0 && chat.artists_chats_messages[chat.artists_chats_messages.length - 1].id == message.id) {
        return; // fix duplicate issue
      }
      message = this.fixNewLine(message);
      let doPush = true;
      if (message.message_type == ChatMessageType.FILE) {
        let index = chat.artists_chats_messages.findIndex(m => !m.id && m.message_type == ChatMessageType.FILE);
        if (typeof index === 'number' && chat.artists_chats_messages[index]) {
          chat.artists_chats_messages[index] = Object.assign(chat.artists_chats_messages[index], message);
          doPush = false;
        }
        else
          doPush = true;
        // chat.artists_chats_messages.splice(index, 1);
      }
      let increment = () => {
        chat.unread_count++;
        this.totalUnread++;
        this.broadcaster.broadcast('deployLinks')
      };
      if (this.fullscreenMode) {
        if (this.currentMainChatHistory && this.currentMainChatHistory.id != chat.id) {
          increment();
        }
      }
      else if (chat.isOpen) {
        if (this.isMinimize[chat.id]) {
          increment();
        }
      }
      else {
        increment();
      }
      if (doPush)
        chat.artists_chats_messages.push(message);
      this.onChatChange[message.chat_id].next(null);
    }
    else {
      // this.websocket.getHistoryByChatId(message.chat_id, this.chatsPagination[message.chat_id]);
      this.openOnHistory[message.chat_id] = true;
      // this.websocket.getHistory(message.chat_id, this.chatsPagination[message.chat_id]);
      this.websocket.getHistory({ chat_id: message.chat_id } as ChatHistoryOptions);
      return;
      // this.isPendingRequests[message.chat_id] = false;
      // this.chatsPagination[message.id] = 50;
      // this.websocket.getHistoryByChatId(message.id);
    }
    let open = !this.fullscreenMode;
    if (this.isJobManager && !this.isJobChatAgent && chat.type_id == ChatHistoryType.JOB_CHAT) return this.render();// this._jobsManaging[chat.job_id])

    if (open) {
      let isMinimize = (!chat.topic || chat.topic.indexOf('Support') == -1) ? false : undefined;
      if (!isMinimize && chat.type_id == ChatHistoryType.JOB_CHAT && this.isAdmin) {
        isMinimize = undefined;
        if (this.isArtistAdmin && this.auth.user.availability_status !== UserAvailabilityStatus.OFFLINE)
          isMinimize = false;
      }
      this.openChat(chat, this.fullscreenMode, isMinimize);
    }
    if (message.user_id != this.auth.user.id)
      this.playTextMessageSound();
    // console.log("%c onChatMessage render()", 'background: #00aacc; color: #fff');
    this.render();
  }

  minimizeAll() {
    for (let id in this.historyDictionary) {
      const c = this.historyDictionary[id] as ChatHistory;
      this.isMinimize[c.id] = true;
    }
    delete this.fullscreenId;
    this.saveCurrentState();
  }

  closeAll() {
    const to = 350;
    for (let id in this.historyDictionary) {
      const c = this.historyDictionary[id] as ChatHistory;
      this.isMinimize[c.id] = true;
      delete this.isMinimize[c.id];
      delete this.fullscreenId;
      setTimeout(() => {
        c.isOpen = false;
      }, to);
      c.isHidden = true;
    }
    delete this.fullscreenId;
    this.saveCurrentState();
    setTimeout(() => this.render.bind(this), to);
    this.render();
  }

  onNewChat(chat: ChatHistory) {
    this.setChatToDictionary(chat);
    // console.log("%c onNewChat render()", 'background: #00aacc; color: #fff');
    this.render();
    this.openChat(chat, this.fullscreenMode, false);
  }

  onChatMessageHistory(messages: Array<ArtistsChatMessage>) {
    messages = this.normalizeMessages(messages);
    if (messages && messages.length > 0) {
      if (this.historyDictionary[messages[0].chat_id]) {
        this.onBeforeChatChange[messages[0].chat_id].next(null);
        if (!this.historyDictionary[messages[0].chat_id].children)
          this.historyDictionary[messages[0].chat_id].children = {};
        if (!this.historyDictionary[messages[0].chat_id].artists_chats_messages)
          this.historyDictionary[messages[0].chat_id].artists_chats_messages = [];
        this.historyDictionary[messages[0].chat_id].artists_chats_messages = messages;
        this.isPendingRequests[messages[0].chat_id] = false;
        this.onChatChange[messages[0].chat_id].next(null);
        // console.log("%c onChatMessageHistory render()", 'background: #00aacc; color: #fff');
        this.render();
      }
      else {
        this.openOnHistory[messages[0].chat_id] = true;
        // this.websocket.getHistory(messages[0].chat_id, this.chatsPagination[messages[0].chat_id]);
        this.websocket.getHistory({ chat_id: messages[0].chat_id } as ChatHistoryOptions);
      }
    }
  }

  onUserTyping(userTyping: UserTyping) {
    let cha = this.getUserHistory(userTyping.user_id), chat = null;
    for (let j = 0; j < cha.length; j++) {
      if (this.historyDictionary[cha[j].chat_id]) {
        chat = this.historyDictionary[cha[j].chat_id] as ChatHistory;
        if (chat.artists_users_chats &&
          chat.artists_users_chats[0].artists_users instanceof Array) {
          for (let i = 0; i < chat.artists_users_chats[0].artists_users.length; i++) {
            chat.artists_users_chats[0].artists_users[i].typing = userTyping.typing;
          }
        }
      }
    }
    // console.log("%c onUserTyping render()", 'background: #00aacc; color: #fff');
    this.render();
  }

  onUserAvailability(userAvailability: UserAvailability) {
    let cha = this.getUserHistory(userAvailability.user_id), chat = null, render = false;
    for (let j = 0; j < cha.length; j++) {
      if (this.historyDictionary[cha[j].chat_id]) {
        chat = this.historyDictionary[cha[j].chat_id] as ChatHistory;
        if (chat.artists_users_chats &&
          chat.artists_users_chats[0].artists_users instanceof Array) {
          for (let i = 0; i < chat.artists_users_chats[0].artists_users.length; i++) {
            chat.artists_users_chats[0].artists_users[i].available = userAvailability.available;
          }
        }
        render = true;
      }
    }
    if (render) {
      // console.log("%c onUserAvailability render()", 'background: #00aacc; color: #fff');
      this.render();
    }
  }

  getNames(c: ChatHistory): string {
    if (this.names[c.id])
      return this.names[c.id];
    this.names[c.id] = '';
    for (let i = 0; i < c.artists_users_chats.length; i++) {
      if (this.auth.user.id == c.artists_users_chats[i].artists_users[0].id) {
        continue;
      }
      this.names[c.id] += c.artists_users_chats[i].artists_users[0].name + ', ';
    }
    this.names[c.id] = this.names[c.id].substring(0, this.names[c.id].length - 2);
    return this.names[c.id];
  }

  toggleUnread(chat: ChatHistory) {
    chat.read = !chat.read;
    this.websocket.setAsUnread(chat.id, chat.read);
    if (chat.read)
      this.totalUnread--;
    else
      this.totalUnread++;
  }

  supportRequest() {
    this.websocket.supportRequest();
  }

  paymentRequest() {
    const opctions = {
      limit: 1,
      offset: 0,
      type_id: ChatHistoryType.PAYMENTS
    } as ChatHistoryOptions;
    this.openPayment = true;
    this.websocket.getHistory(opctions);
  }

  _onChatSession(session: ChatSession) {
    this.historyDictionary[session.chat_id].session_id = session.id;
    this.onChatSession.next(session);
  }

  async openSession(chatId: number, topicId: number) {
    await this.websocket.openSession(chatId, topicId);
  }

  async closeSession(chatId: number) {
    await this.websocket.closeSession(chatId);
  }

  async editSession(chatId: number, topicId: number) {
    await this.websocket.editSession(chatId, topicId);
  }

  onJobManagerChange() {
    this.refreshAndFetch();
  }
}
