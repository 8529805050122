import { Role } from 'ng-role-based-access-control';
import { ListRowsResponse } from '../communication/custom-request';
import { Retailer } from '../shared/retailers';
import { FileWrap } from 'app/ui-components/file';
import { PaymentSystem } from 'app/shared/enums';
import { AdjustmentsPreset } from 'app/ui-components/asset-adjustments';
import { ArtistsGroupUser, Group } from './group';
import { ArtistRole } from './roles-helper.service';

export interface User {
    id?: number;
    created_at?: Date;
    updated_at?: Date;
    name: string;
    email: string;
    roles?: Array<Role>;
    token?: string;
    // children?: UserChildren,
    roleId?: number;
    avatar_url: string;
    first_name: string;
    last_name: string;
    skype: string;
    country: string;
    city: string;
    timezone: string;
    availability: string;
    job_capacity: number;
    total_capacity: number;
    typing: boolean;
    reference_id: string;
    reference_text: string;
    paypal_id?: string;
    payoneer_id?: string;
    payoneer_approved?: boolean;
    // rank: number;
    new_user: boolean;
    payment_type: PaymentSystem;
    geometry_feedback_ranking: number;
    texture_feedback_ranking: number;
    override_rank: boolean;
    override_capacity: boolean;
    allow_payments: boolean;
    gclid: string;
    availability_status: UserAvailabilityStatus;
    artists_users_roles?: Array<UserRole>;
    // artists_users_categories_self_rankings?: Array<ArtistUserCategorySelfRanking>;
    artists_users_push_details?: Array<ArtistsUserPushDetails>;
    // artists_allowed_categories?: Array<ArtistsAllowedCategory>;
    artists_users_categories_rankings?: Array<ArtistsUsersCategoryRank>;
    artists_users_mails_subscriptions?: Array<ArtistsUsersMailsSubscription>;
    artists_users_software?: Array<ArtistsUsersSoftware>;
    artists_statistics?: Array<ArtistsStatistics>;
    artists_users_presets?: Array<AdjustmentsPreset>;
    artists_groups_users?: Array<ArtistsGroupUser>;
    uiRoles?: Array<Role>;
    referrer_artist_id?: number;
    fm_id:number;
    artists_groups?: Array<Group>;
    retailers_feedback_masters: Array<RetailerUser>;
    artists_users_expertise: Array<UserExpertis>;
    rank: number;
}


export interface UserExpertis {
        id: number;
        job_type_id: number;
        artist_user_id: number;
      }
export interface ChatUser extends User {
    // isOpen?: boolean;
    // order?: number;
    available: boolean;
}

export interface ChatUserId {
    id: number;
}

export interface ChatHistory {
    isOpen?: boolean;
    isHidden?: boolean;
    order?: number;
    created_at: Date;
    updated_at: Date;
    id: number;
    topic: string;
    unread_count: number;
    // children: ChatHistoryChildren;
    job_id: number;
    artists_users_chats: Array<ArtistSUserChat>;
    artists_chats_messages: Array<ArtistsChatMessage>;
    avatar_url?: string;
    type_id: ChatHistoryType;
    read: boolean;
    manager_id: number;
    session_id?: number;
}

export interface ChatHistoryOptions {
    chat_id?: number;
    limit?: number;
    offset?: number;
    topic?: string;
    job_id?: number;
    unread_only?: boolean;
    type_id?: ChatHistoryType;
    unresolved_only?: boolean;
    artists_users: Array<number>;
    manager_id?: number;
}

export interface ChatHistoryResponse {
    rows: Array<ChatHistory>;
    count: number;
    total_unread: number;
}

export interface ChatSession {
    created_at: string;
    updated_at: string;
    session_topic_id: number;
    resolved: boolean;
    id: number;
    chat_id: number;
}

export interface OpenChatHistoryState {
    fullscreenMode: boolean;
}

export interface ArtistsChatMessagesGroup {
    created_at: Date;
    items: Array<ArtistsChatMessage>;
}

// export interface ChatHistoryChildren {
//     artistsuserchats: Array<ArtistSUserChat>;
//     artistschatmessages: Array<ArtistsChatMessage>;
// }

export enum ChatMessageType {
    TEXT,
    VOICE,
    VIDEO,
    FILE,
}

export enum ChatHistoryType {
    // ZIEO,
    PERSONAL = 1,
    SUPPORT = 2,
    JOB_CHAT = 3,
    PAYMENTS = 4,
    GROUP = 5
}

export interface ArtistSUserChat {
    chat_id: number;
    // children: ArtistSUserChatChildren;
    artists_users: Array<ChatUser>;
    created_at: Date;
    updated_at: Date;
    id: number;
    user_id: number;
    typing: boolean;
}

export interface ArtistsChatsTopic {
    id: number;
    description: string;
    chat_type_id: ChatHistoryType;
    admin_only: boolean;
}

export interface UserAvailability {
    user_id: number;
    available: boolean;
}

export interface UserTyping {
    chat_id: number;
    user_id: number;
    typing: boolean;
}

// export interface ArtistSUserChatChildren {
//     artistsusers: Array<ChatUser>;
//     // created_at: Date;
//     // updated_at: Date;
//     // email: string;
//     // id:
// }

export interface ArtistsChatsMessagesUser {
    chat_id: number;
    created_at: Date;
    id: number;
    message_id: number;
    notified: boolean;
    read: boolean;
    updated_at: Date;
    user_id: number;
}

export interface ArtistsChatMessageSend {
    is_auto_message: boolean;
}

export interface ArtistsChatMessage {
    chat_id: number;
    created_at: Date;
    updated_at: Date;
    id: number;
    user_id: number;
    body: string;
    chat_session_id?: number;
    message_type: ChatMessageType;
    artists_chats_messages_users?: Array<ArtistsChatsMessagesUser>;
    // isDummy?: boolean;
    // TODO add sent: boolean; witch if true means that the chat has been sent to te receiver
}

export interface userAvailability {
    user_id: number;
    available: boolean;
}

// export interface UserChildren {
//     artistsuserroles?: Array<UserRole>;
//     artistsusercategoryselfrankings?: Array<ArtistUserCategorySelfRanking>;
//     artistsuserpushdetails?: Array<ArtistsUserPushDetails>;
//     artistsallowedcategories?: Array<ArtistsAllowedCategory>;
//     uiRoles?: Array<Role>;
// }

export interface ArtistsAllowedCategory {
    artist_user_id?: number;
    category_id: number;
    created_at?: Date;
    id?: number;
    updated_at?: Date;
}

export interface ArtistsUsersCategoryRank {
    id: number;
    created_at: Date;
    updated_at: Date;
    artist_user_id: number;
    parent_category_id: number;
    // category_id: number;
    rank: number;
    UI: ArtistsUsersCategoryRankUI;
}

export interface ArtistsUsersCategoryRankUI {
    categoryDescription: string;
    parentDescription: string;
}

export interface ArtistsUserPushDetails {
    artist_user_id: number;
    created_at?: Date;
    updated_at?: Date;
    id?: number;
    endpoint: string;
    public_key: string;
    auth: string;
    client_id: string;
}

export interface UserRole {
    id?: number;
    role_id?: number;
    artist_user_id?: number;
    created_at?: Date;
    updated_at?: Date;
    artists_roles: Array<ArtistRole>;
    // children?: UserRoleChildren;
}

export enum UserAvailabilityStatus {
    ONLINE = 1,
    OFFLINE = 2,
    OFF_WORK = 3,
}

// export interface UserRoleChildren {
//     artistsroles: Array<Role>;
//     // id: number;
//     // name: string;
//     // created_at: Data;
//     // updated_at: Data;
// }

export interface Credentials {
    name: string,
    password: string
}

export interface UsersFilterOptions {
    limit:number;
    offset: number;
    first_name: string;
    last_name: string;
    email: string;
    show_all: boolean;
    name: string;
    parent_category_id: number;
    rank: number;
    id: Array<number>;
    permission: Array<string>;
    role_id: Array<number>;
    retailer_id?: number;
    is_group_leader?: boolean;
}

export interface UsersQueryData {
    allArtistsUsers: ListRowsResponse;
}

export interface UserQueryData {
    artists_users: User;
}

export interface CMSUser {
    id?: number,
    created_at?: Date,
    updated_at?: Date,
    email: string,
    roles?: Array<Role>,
    token?: string,
    // children?: UserChildren,
    avatar_url: string;
    firstname: string;
    lastname: string;
    phone_number: string;
    subscribed: boolean;
    subscribed_notifications: boolean;
    subscribed_daily_analytics: boolean;
    subscribed_weekly_analytics: boolean;
    retailers_users: Array<RetailerUser>;
    users_roles?: Array<UserRole>;
    uiRoles?: Array<Role>;
}

export interface RetailerUser {
    created_at?: Date;
    id?: number;
    retailer_id: number;
    updated_at?: Date;
    user_id: number;
    retailers: Array<Retailer>;
}

export interface ArtistsUsersMailsSubscription {
    id: number;
    mail_category_id: MailsSubscriptionType;
    subscribed: boolean;
    created_at: Date;
    updated_at: Date;
}

export enum MailsSubscriptionType {
    SYSTEM_NOTIFICATIONS = 1,
    OFFERS_NOTIFICATIONS = 2,
    JOB_NOTIFICATIONS = 3,
    PAYMENTS = 4,
    ESSENTIAL = 5,
}

export interface ArtistsUsersSoftware {
    is_valid: boolean;
    software_id: number;
    software_name: string;
    is_qualified: boolean;
    software_image_url: string;
}

export interface ArtistsUsersSoftwareUpload extends ArtistsUsersSoftware {
    isUploading?: boolean;
    progress?: number;
    success?: boolean;
    currentFiles?: Array<FileWrap>;
}

export interface ArtistsStatistics {
    open_jobs: number;
    revenue: number;
    average_feedback: number;
    average_complexity: number;
    job_delivery: number;
}
