import { ProductData, ProductUi } from "app/product/product";
import { FilterOptions } from "app/shared/enums";

export interface TaggingProject {

    id?: number;
    created_at?: Date;
    updated_at?: Date;
    opened_by?: number;
    title: string;
    description: string;
    active: boolean;
    product_limit: number;
    product_limit_date: string;
    random_order: boolean;
    priority: number;
    sub_categories?: Array<string>;
    subCategoriesName?: Array<string>;
    tagging_projects_attributes?: Array<TaggingProjectsAttribute>;// questions
    tagging_projects_products?: Array<TaggingProjectsProduct>;
    tagging_projects_sub_categories?: Array<TaggingProjectSubCat>;
    isComplete: boolean;
}

export interface TaggingProjectSubCat {
    sub_category_id: number;
}

export interface TaggingProductItem {
    name: string;
    id: number;
    project_id: number;
    isAnswered: boolean;
    products_data: Array<TaggingProductData>;
    tagging_projects_attributes?: Array<TaggingProjectsAttribute>;
    UI: ProductUi;
}

export interface TaggingProductData extends ProductData  {    
    isAnswered: boolean;
    tagging_projects_data_answers: Array<Array<TaggingProjectsDataValue>>;
}

export interface TaggingProjectsProduct {
    id: number;
    project_id: number;
    product_id: number;
    review_option: TagReviewState;
    UI: TaggingProjectsProductUI;
}

export interface TaggingProjectsProductUI {
    done: boolean;
}

export enum TagReviewState {
    REVIEWED = 1,
    NOT_REVIEWED = 2,
}

export interface TaggingProjectsAttribute {
    id: number;
    project_id: number;
    attribute_key: string;
    attribute_type: TagAttributeType;
    attribute_description: string;
    tagging_projects_attributes_enums: Array<TaggingProjectsAttributesEnum>;// answers 
    maxAnsDictionary: number;
    created_at: string;
}

export enum TagAttributeType {
    RADIO = 1,
    RANK = 2,
    // Number = 1,
    // String = 2,
    // Boolean = 3,
    // Date = 4,
    // NumbersArray = 5,
    // StringsArray = 6,
    // BooleansArray = 7,
    // DatesArray = 8,
}

export interface TaggingProjectsAttributesEnum {
    id: number;
    created_at: Date;
    updated_at: Date;
    project_id: number;
    attribute_id: number;// the id of the TaggingProjectsAttribute
    attribute_enum_id: number;// the value of the answer
    attribute_enum_key: string;// the text of the answer
}

export interface TaggingProjectsDataValue {
    id: number;
    project_id: number;
    tagger_id: number;
    data_id: number;
    attribute_id: number;
    attribute_value: string;// the value of the answer (TaggingProjectsAttributesEnum.attribute_enum_key)
}

export interface TaggingProjectsQueryData {
    tagging_projects: TaggingProject;
}

export interface AllTaggingProjectsQueryData {
    allTaggingProjects: AllTaggingProjectsQueryDataInner;
}

export interface AllTaggingProjectsQueryDataInner {
    rows: Array<TaggingProject>;
    count: number;
}

export interface TaggingProjectsFilterOptions extends FilterOptions {
}