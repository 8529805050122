import { ChatMessageType } from "app/auth/user";
import { BaseArtistsJobsResourcesColorComparison } from "app/job/resource";
import { Coordinates3D } from "app/shared/enums";

export interface StatisticsPixel {
    artists_events: PixelArtistsEvents;
}

export interface PixelArtistsEvents {
    user_id?: number;
    // session_id?: string;
    type?: string;
    event: string;
    reference_id?: number;
    variation_id?: string;
    referer?: string;
    // comments?: PixelArtistsEventsComments;
    // comments?: string;alignment_id
    client_id?: string;
    event_value?: number;
    action_value?: string;
    chat_action?: string;
    hotjar?: boolean;
    pageview_id?: string;
    url?: string;
    client_time?: number;
    // ua: UserAgent;
    button_name?: string;
    // click_id?: number;
    user_agent?: string;
    page_type?: string;
    affiliate_id?: string;
    expandMode?: boolean;
    size?: number;
    error?: string;
    width?: number;
    height?: number;
    scrollY?: number;
    sort_order?: string;
    filter_name?: string;
    filter_value?: any;
    item_id?: number;
    offer_id?: number;
    image_id?: number;
    requirement_id?: string;
    survey_id?: string;
    survey_name?: string;
    survey_action?: string;
    answer?: string;
    remarks?: string;
    popup_id?: string;
    popup_name?: string;
    popup_action?: string;
    card_type?: string;
    popup_page_no?: number;
    artist_id?: number;
    sign_up_step?: string;
    button_position?: string;
    sign_up_values?: Array<string>;
    button_value?: any;
    job_id?: number
    alignment_id?: number
    color_accuracy?: number
    opaque_color_accuracy?: number
    delta_ab?: number;
    delta_l?: number;
    delta_e?: number;
    screen_id?: string;
    client_feedback_id?: string;
    affect_artist_rank?: boolean;
    feedback_id?: number;
    text?: string;
    quick_feedback?: string;
    total_time?: number;
    current_time?: number;
    percentage?: number;
    opaque_percentage?: number;
    chat_id?: number;
    chat_type?: string;
    previous_value?: number;
    lng?: string;
    card_position?: number;
    question?: string;
    job_warning_type?: string;
    offer_base_price?: number;
    offer_max_price?: number;
    relevant_offer?: boolean;
    message?: string;
    chat_message_type?: ChatMessageType;
    is_auto_message?: boolean;
    chat_session_id?: number;
    index?: number;
    coordinates?: Coordinates3D;
    inviting_artist_id?: number;
    screen_width?: number;
    screen_height?: number;
    darkmode?: boolean;
    product_id?: number;
    product_id_requested?: number
    product_id_compared?: number
    value?: string
    fix_stage?: FixStage;
    fix_type?: string;
    fix_id?: string;
    color_test_session?: string;
    color_test_session_instance?: string;
    after_fix?: boolean;
    color_test_value?: BaseArtistsJobsResourcesColorComparison;
    is_general?: boolean;
    recommended_base_price?: number;
    recommended_additional_variation_price?: number;
    recommended_first_variation_price?: number;
    user_state?: string;
    screen_state?: string;
    idle_minutes?: number;
    score?: number;
    comment?: string;
    ref?: string;
}

// export interface UserAgent {
//     os_name: string; // "Windows",
//     os_version: 10,
//     browser_name: "Chrome",
//     browser_version: 89,
//     country_code: "US",
//     device_name: "Computer",
//     device_version: 0
// }

export interface PixelArtistsEventsComments {
    timestamp: number;
}

// Legacy events
export interface StatisticsPixelLegacy {
    artists_events: PixelArtistsEventsLegacy;
}

export interface PixelArtistsEventsLegacy {
    user_id?: number;
    session_id?: string;
    event_type: string;
    reference_id?: number;
    variation_id?: string;
    referer?: string;
    // comments?: PixelArtistsEventsComments;
    comments?: string;
    client_id?: string;
    event_value?: number;
    hotjar?: boolean;
}

export interface PixelArtistsEventsCommentsLegacy {
    timestamp: number;
}

export enum FixStage {
  TRY_TO_FIX = 'try_to_fix',
  FIXED = 'fixed',
  NOT_FIXED = 'not_fixed'
}
