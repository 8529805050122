<div class="offer-create-fields-container no-padd-children">
    <form [formGroup]="assign" novalidate>
        <div>
            <div fxLayout="column" fxFlex="30" fxLayoutAlign="start start" class="flex-wrap">
                <div fxFlex *ngIf="currentItems?.length">
                    <b>{{currentItems?.length}}</b> Assets Selected
                </div>
                <div fxFlex>
                    <!-- *ngIf="!assign.controls['multiple_jobs'].value" -->
                    <mat-form-field>
                        <mat-label>Job type</mat-label>
                        <mat-select [multiple]="true" name="artistsOffersTypes"
                            formControlName="artists_offers_types" (selectionChange)="onOfferTypesChange()">
                            <mat-option *ngFor="let type of jobTypes" [value]="type.id">
                                {{ type.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex>
                    <mat-form-field
                        [ngClass]="{'mat-input-invalid mat-form-field-invalid ng-invalid': assign.controls.hours_to_complete.value < 1}">
                        <mat-label>hours to complete</mat-label>
                        <input matInput type="number" min="1" name="hours_to_complete"
                            formControlName="hours_to_complete">
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-form-field>
                        <mat-label>base price</mat-label>
                        <input matInput type="number" min="0" formControlName="base_price"
                            name="base_price">
                    </mat-form-field>
                </div>
                <ng-container *ngIf="offerCreationHelper.isTextureOnly">
                    <div fxFlex>
                        <mat-form-field>
                            <mat-label>first variation price</mat-label>
                            <input matInput type="number" min="0"
                                (input)="onVarPriceChange()" formControlName="first_variation_price"
                                name="first_variation_price">
                        </mat-form-field>
                    </div>
                    <div fxFlex>
                        <mat-form-field>
                            <mat-label>additional variation price</mat-label>
                            <input matInput type="number" min="0"
                                (input)="onVarPriceChange()" formControlName="additional_variation_price"
                                name="additional_variation_price">
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- <div fxFlex>
                <app-users-autocomplete [placeholder]="'Feedback Master'" [clear-on-select]="true" [show-all]="true"
                    (user-change)="onManagerChange($event)" [permission]="['Manage Jobs', 'Super User']">
                </app-users-autocomplete>
            </div> -->
                <div fxFlex *ngIf="manager">
                    <button mat-icon-button (click)="clearManager()">
                        <mat-icon>delete</mat-icon>
                    </button>
                    {{manager.name}}
                </div>

                <div fxFlex *ngIf="qualitySv && isSU">
                    <app-users-autocomplete [placeholder]="'Quality Supervisor'" [clear-on-select]="true"
                        [show-all]="true" (user-change)="onqualitySupervisorChange($event)" [roles]="[qualitySv]">
                    </app-users-autocomplete>
                </div>
                <div fxFlex *ngIf="qualitySupervisor">
                    <button mat-icon-button (click)="clearqualitySupervisor()">
                        <mat-icon>delete</mat-icon>
                    </button>
                    {{qualitySupervisor.name}}
                </div>

                <div fxFlex>
                    <app-users-autocomplete [placeholder]="'Feedbacker'" [clear-on-select]="true" [show-all]="true"
                        (user-change)="onFeedbackerChange($event)" [permission]="['Manage Jobs','Super User']">
                    </app-users-autocomplete>
                </div>
                <div fxFlex *ngIf="feedbacker">
                    <button mat-icon-button (click)="clearFeedbacker()">
                        <mat-icon>delete</mat-icon>
                    </button>
                    {{feedbacker.name}}
                </div>
            </div>
            <div fxLayout="column" fxFlex="30" fxLayoutAlign="start start" class="flex-wrap">
                <div fxFlex>
                    <mat-slide-toggle name="allow_rank_bonus" formControlName="allow_rank_bonus" color="primary">allow
                        trust level bonus</mat-slide-toggle>
                </div>
                <div fxFlex>
                    <mat-slide-toggle name="allow_time_bonus" formControlName="allow_time_bonus" color="primary">allow
                        time bonus</mat-slide-toggle>
                </div>
                <div fxFlex>
                    <mat-slide-toggle name="hidden" formControlName="hidden" color="primary">hidden
                    </mat-slide-toggle>
                </div>
                <div fxFlex>
                    <mat-slide-toggle [checked]="isMultipleOffers" (change)="onChangeMultipleOffers($event)"
                        name="multiple_offers" formControlName="multiple_offers" color="primary"
                        matTooltip="create an offer for each item">
                        multiple offers
                    </mat-slide-toggle>
                </div>
                <div fxFlex>
                    <mat-slide-toggle name="private_offer" formControlName="private_offer" color="primary">
                        private offer
                    </mat-slide-toggle>
                </div>
                <div fxFlex>
                    <mat-slide-toggle name="is_poc" formControlName="is_poc" color="primary">
                        is POC
                    </mat-slide-toggle>
                </div>

                <div fxFlex>
                    <mat-slide-toggle name="is_test" formControlName="is_test" color="primary">
                        is test
                    </mat-slide-toggle>
                </div>

                <div fxFlex>
                    <mat-slide-toggle name="fix" formControlName="fix" color="primary">
                        is fix
                    </mat-slide-toggle>
                </div>
                <div fxFlex>
                    <mat-slide-toggle matTooltip="Mini job - adjusting existing assets" name="mini_job"
                        formControlName="mini_job" color="primary">
                        mini job
                    </mat-slide-toggle>
                </div>
                <div fxFlex *ngIf="isSU">
                    <mat-slide-toggle name="auto_incremented_rank" formControlName="auto_incremented_rank"
                        color="primary">
                        auto incremented trust level
                    </mat-slide-toggle>
                </div>
                <div fxFlex *ngIf="isSameMeshDisabled()">
                    <mat-slide-toggle name="same_mesh" formControlName="same_mesh" color="primary" (change)="refreshSubCatPrice()"
                        matTooltip="hide number of items for geometry offer">
                        same mesh
                    </mat-slide-toggle>
                </div>
                <div fxFlex
                    *ngIf="isAutomaticRendering() && sourceJob && rolesHelper.doesUserHasPermission('Automatic texturing and rendering')">
                    <mat-slide-toggle name="automatic_rendering" formControlName="automatic_rendering" color="primary">
                        automatic texturing and rendering </mat-slide-toggle>
                </div>
                <div fxFlex>
                    geometry complexity ({{assign.controls.estimated_geometry_hours.value}} hours)
                    <button type="button" mat-button (click)="openGeometryComplexity()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div fxFlex>
                    <app-ranking matTooltip="set complexity trust level" [rank]="0" [max]="5" [edit-mode]="true"
                        (on-change)="onRankChange($event)">
                    </app-ranking>
                </div>
                <div fxFlex class="marg-top" *ngIf="this.assign.controls.fix.value">
                    <mat-form-field class="fix-reason">
                        <mat-label>Fix reason</mat-label>
                        <mat-select class="uppercase" (selectionChange)="onFixReasonsValueChange($event)">
                            <mat-option [matTooltip]="t.reason" *ngFor="let t of artistsFixReasons" [value]="t.id"
                                class="uppercase">
                                {{t.source}} - {{t.reason}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="column" fxFlex="50" fxLayoutAlign="start start" class="flex-wrap group-column">
                <div fxFlex *ngIf="assign.value.private_offer">
                    <app-users-autocomplete (user-change)="onArtistChange($event)" [show-all]="true">
                    </app-users-autocomplete>
                </div>
                <div fxLayout="column">
                    <div fxFlex="100">
                        <button mat-icon-button (click)="addPersistentGroup()" class="add-persistent">
                            <span class="text">add persistent group</span>
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <div fxFlex="100" *ngFor="let aog of artistsOffersGroup; let i = index">
                        <app-user-groups-select placeholder="persistent group"
                            (on-group-change)="onPersistentGroupChange($event, i)">
                        </app-user-groups-select>
                        <button mat-icon-button class="pos-abs" (click)="deletePersistentGroup(i)" color="warn">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <mat-form-field>
                            <mat-label>price</mat-label>
                            <input matInput [disabled]="!assign.controls.artists_offers_groups.value"
                                (change)="onGroupPrice($event, aog)" type="number" step="0.01">
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex *ngIf="assign.value.private_offer">
                    <app-user-groups-select placeholder="quick users group" (on-group-change)="onGroupChange($event)">
                    </app-user-groups-select>
                </div>
                <div *ngIf="assign.value.private_offer">
                    Artists:
                </div>
                <div fxFlex *ngIf="assign.value.private_offer">

                    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center start" class="flex-wrap">
                        <span *ngIf="assign.value.private_offer" class="artists">
                            <div *ngFor="let artist of artists | keyValuePure : counter; let i = index"
                                class="pos-rel artist-item">
                                <div class="logo" *ngIf="artist.value.avatar_url"
                                    [style.backgroundImage]="'url(' + artist.value.avatar_url + ')'"></div>
                                <span> {{artist.value.name}}</span>
                                <button mat-icon-button class="remove-poly pos-abs" (click)="removeArtist(artist)"
                                    color="warn">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </span>
                    </div>
                </div>

            </div>

            <div fxLayout="column" fxFlex="40" fxLayoutAlign="start start" class="flex-wrap">



                <div *ngIf="sourceJob && allFormatsDictionary">
                    <div fxFlex>
                        resources:
                        <div *ngFor="let aji of sourceJob.artists_jobs_items">
                            <div *ngFor="let r of aji.artists_jobs_resources">
                                <span fxFlex class="gray11" *ngIf="r.approved == 1">
                                    {{allFormatsDictionary[r.resource_type].resource_name}},
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex *ngIf="product && product.category_id" class="gray">
                    *** <div class="underline">
                        Average price for category:
                    </div>
                    <div *ngFor="let p of averagePrice">
                        <div *ngIf="jobsTypesService.uiDictionary[p.type_id]">
                            {{jobsTypesService.uiDictionary[p.type_id].description}}: {{p.average}}$
                        </div>
                        <div *ngIf="!jobsTypesService.uiDictionary[p.type_id]">
                            Full Asset: <b>{{p.average}}$</b>
                        </div>
                    </div>
                    <div *ngIf="subCatPrices">
                        <div class="underline">
                            Average price for sub-category:
                        </div>
                        <div *ngFor="let p of subCatPrices">
                            <div *ngIf="jobsTypesService.uiDictionary[p.type_id]">
                                {{jobsTypesService.uiDictionary[p.type_id].description}}: {{p.average | number :
                                '1.0-2'}}$
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex *ngIf="product?.priority">
                    <mat-form-field>
                        <mat-label>Priority</mat-label>
                        <mat-select formControlName="priority">
                            <mat-option *ngFor="let p of offerService.priorities" [value]="p.key">
                                {{ p.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

        </div>
        <div>
            <app-text-editor [click-img]="true" [show-html]="true" [show-asset]="true" [set-value]="notesValue" (textChange)="onTextChange($event)" placeholder="notes . . ."></app-text-editor>
        </div>
    </form>

    <div>
        <button mat-button (click)="addPoly()">
          <span class="add-variation">
            Add Variations <mat-icon>add</mat-icon>
          </span>
        </button>

        <div class="variations-container">
          <app-poly-specs *ngFor="let poly of polySpecs;let i = index" [(ngModel)]="polySpecs[i]" [hoverEffect]="true"
            (delete)="removePoly(i)" (duplicate)="duplicatePolySpec($event)"></app-poly-specs>
        </div>
    </div>

    <div *ngIf="sourceJob" class="offer-creation-variations">
        <fieldset>
            <legend>Create offer from job:</legend>
            <app-offer-variations [jobItems]="sourceJob.artists_jobs_items"
                (selectedItems)="onItemVariationChange($event)"></app-offer-variations>
            <div class="or-wrap pos-rel">
                <span class="pos-abs horizontal-align">Or</span>
                <mat-divider></mat-divider>
            </div>
            <app-chip-input placeholder="Add product items" (input-change)="onProductsChange($event)">
            </app-chip-input>
            <div>
                <mat-chip-listbox>
                    <mat-chip color="accent" selected="true"
                        *ngFor="let p of productsToAdd;let i = index">
                        <span class="ellipsis">{{p}}</span>
                        <mat-icon matChipRemove (click)="removeProduct(i)">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-listbox>
            </div>
        </fieldset>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around center" class="additional-wrap">
        <div fxFlex>
            <app-file-upload [icon]="'attach_file'" class="disp-block w-100" [text]="'upload additional files'"
                [drop-mode]="true" [miltiple]="true" (filesChange)="onAdditionalFilesChange($event)"></app-file-upload>
            <mat-progress-bar *ngIf="uploading" mode="indeterminate"></mat-progress-bar>
        </div>
        <div fxFlex="50" class="notes-wrap">
            <mat-list role="list" *ngIf="fileList.length" class="scrollbar">
                <mat-list-item role="listitem">{{fileList.length}} files:</mat-list-item>
                <mat-list-item role="listitem" *ngFor="let file of fileList;let i = index">
                    <i class="material-icons">{{file.icon}}</i>
                    {{file.name}}
                    <button mat-icon-button class="remove-file pos-abs" (click)="removeFile(i)" color="warn">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
    </div>

    <div>
        <button mat-raised-button color="primary" (click)="createOffer()">create new offers</button>
    </div>
</div>
