import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnChanges
} from '@angular/core';
import { KeyValuePair, ATTACHMENT_ICON } from 'app/shared/enums';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-simple-carousel-slider',
  templateUrl: './simple-carousel-slider.component.html',
  styleUrls: ['./simple-carousel-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleCarouselSliderComponent implements OnInit, OnChanges {
  @Input() images: Array<KeyValuePair>;
  @Input() width: number;
  @Input() height: number;
  @Input() isResetIndex: boolean;
  @Input('nav-overlap') navOverlap: boolean;
  @Input() disabled: boolean;
  @Output('index-change') indexChange: EventEmitter<number>;
  @ViewChild('scroller') scroller: ElementRef;
  public ai = ATTACHMENT_ICON;
  @Input() public currentIndex = 0;
  public total: number;
  public isRightNavDisabled: boolean;
  public translateX: number;
  public imm: boolean;

  constructor(
    private utils: UtilsService,
    private cdr: ChangeDetectorRef) {
    this.currentIndex = 0;
    this.translateX = 0;
    this.indexChange = new EventEmitter<number>();
    this.isRightNavDisabled = false;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    if (this.images) {
      this.total = this.images.length;
    } else {
      this.total = 0;
    }
    this.currentIndex = Math.max(Math.min(this.currentIndex, this.total - 1), 0);
    this.setRightNavDisabled();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isResetIndex) {
      this.currentIndex = 0;
    }
    if (changes && changes.images && changes.images.currentValue && changes.images.previousValue &&
      changes.images.currentValue.length !== changes.images.previousValue.length) {
      if (this.getSliderWidth() > this.getParentWidth()) {
        if (this.isRightNavDisabled) {
          setTimeout(() => {
            this.translateX = this.getMinTranslate();
          }, 500);
        }
      }
    }
    this.init();
  }

  navLeft() {
    this.translateX += this.getParentWidth();
    if (this.translateX > 0) {
      this.translateX = 0;
    }
    this.setRightNavDisabled();
  }

  navRight() {
    const fdParent = this.getParentWidth();
    const fd = this.getSliderWidth();
    this.translateX -= fdParent;
    if (-this.translateX > (fd - fdParent)) {
      this.translateX = -(fd - fdParent);
    }
    this.setRightNavDisabled();
  }

  setIndex(index: number): void {
    this.indexChange.emit(index);
    if (this.currentIndex === index) {
      return;
    }
    this.currentIndex = index;
    this.setRightNavDisabled();
  }

  setRightNavDisabled(): boolean {
    if (!this.scroller) {
      this.isRightNavDisabled = true;
      return;
    }

    if (-this.translateX >= (this.getSliderWidth() - this.getParentWidth())) {
      this.isRightNavDisabled = true;
    } else {
      this.isRightNavDisabled = false;
    }
  }

  getMinTranslate() {
    return -Math.max(this.getSliderWidth() - this.getParentWidth(), 0);
  }

  private getParentWidth(): number {
    return this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement.parentElement).width)?.size;
  }

  private getSliderWidth(): number {
    return this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement).width)?.size;
  }
}
