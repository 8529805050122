import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { GraphqlService } from '../../communication/graphql.service';

@Injectable()
export class ComplexityGuideResolve  {

    constructor(private gql: GraphqlService) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.gql.complexityGuide(route.params['id']);
    }
}
