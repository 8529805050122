<mat-form-field>
  <input matInput [placeholder]="placeholder" aria-label="autocomplete" [matAutocomplete]="anyAuto" [formControl]="itemCtrl"
    #filterInput>
  <mat-icon class="arrow" *ngIf="(filteredItems | async).length">keyboard_arrow_down</mat-icon>
  <mat-autocomplete #anyAuto="matAutocomplete">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item[mainField]" (onSelectionChange)="onItemChange(item)">
      <img *ngIf="imgField" style="vertical-align:middle" aria-hidden [src]="item[imgField]" height="25" />
      <span *ngIf="mainField">{{ item[mainField] }}</span>
      <small *ngIf="subField">({{item[subField]}})</small>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>