import { Injectable } from '@angular/core';
import { ShareObject } from './share';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  available: boolean;
  constructor() {
    this.available = !!navigator['share'];
    // this.available = true;
  }

  post(msg: ShareObject = new ShareObject(), onSuccess?: Function, onFailure?: Function) {
    if (!this.available) return;
    if (!msg)
      msg = new ShareObject();
    navigator['share'](msg)
      .then(() => () => {
        if (onSuccess)
          onSuccess();
      })
      .catch((error: any) => {
        if (onFailure)
          onFailure(error);
        else
          console.warn('Error sharing', error);
      });
  }
}
