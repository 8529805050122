import { KeyValuePair } from "../shared/enums";
import { AssetAdjustmentSector, AssetAdjustmentType, HdriOptions, SceneParamsOptions, ShadowPlaneOptions, ThreeLight } from "asset-adjustments";
import { CombineImagesMode, ResourceWarningType } from 'app/job/resource';

export interface AssetAdjustments {
    value: any;
    action: string;
    name: string;
    urlParam: string;
    min?: number;
    max?: number;
    step?: number;
    type: AssetAdjustmentType;
    options?: any;
    selectOptions?: Array<KeyValuePair>;
    optionsImages?: { [id: number]: string };
    default?: any;
    sector: AssetAdjustmentSector;
    hasColor?: boolean;
    hasToggle?: boolean;
    toggleState?: boolean;
    colorChanged?: boolean;
    color?: string;
    disabled?: boolean;
    multiple?: boolean;
    hsl?: ThreeVector3;
    hasHSL?: boolean;
    HSLChanged?: boolean;
    cssClass?: string;
}

// export enum AssetAdjustmentType {
//     SLIDER = 1,
//     SELECT = 2,
//     TOGGLE = 3,
//     CUSTOM = 4,
//     JOYSTICK = 5,
//     VECTOR3 = 6
// }

// export enum AssetAdjustmentSector {
//     SCENE = 1,
//     LIGHTING = 2,
//     MATERIALS = 3,
//     SHADOW = 4,
//     ANNOTATIONS = 5,
// }

export enum AssetAdjustmentPosition {
    BEFORE = 1,
    CENTER = 2,
    AFTER = 3,
    TOP = 4,
    BOTTOM = 5,
}

export interface ThreeImage {
    url: string;
    uuid: string;
    name: string;
}

export interface ThreeVector2 {
    x: number;
    y: number;
}

export interface ThreeVector3 {
    x: number;
    y: number;
    z: number;
}

export interface Vector3PercentageValue extends ThreeVector3 {
    xv: number;
    yv: number;
    zv: number;
}

export interface ThreeMetadata {
    generator: string;
    type: string;
    version: number;
}

export interface ThreeColor {
    r: number;
    g: number;
    b: number;
}

export interface BoundingBox {
    size: number;
    x: number;
    y: number;
    z: number;
}

export interface MeshData {
    position: ThreeVector3;
    scale: ThreeVector3;
    center?: ThreeVector3;
}

export interface ThreeMaterial {
    uuid: string;
    type: string;//"MeshStandardMaterial"
    transparent: boolean;
    textures: Array<ThreeTexture>;
    roughness: number;
    roughnessMap: string;
    normalMap: string;
    normalScale: Array<number>;//z {x: 1, y: 1}
    name: string;
    metalness: number;
    metalnessMap: string;
    depthFunc: number;
    depthTest: boolean;
    depthWrite: boolean;
    images: Array<ThreeImage>;
    map: string;
    metadata: ThreeMetadata;
    alphaMap: string;
    alphaTest: number;
    aoMap: string;
    aoMapIntensity: number;
    blendDst: number;
    blendDstAlpha: any;
    blendEquation: number;
    blendEquationAlpha: any;
    blendSrc: number;
    blendSrcAlpha: any;
    blending: number;
    bumpMap: string;
    bumpScale: number;
    clipIntersection: boolean;
    clipShadows: boolean;
    clippingPlanes: any;
    color: ThreeColor;
    colorWrite: boolean;
    defines: any;//{STANDARD: ""}
    displacementBias: number;
    displacementMap: string;
    displacementScale: number;
    dithering: boolean;
    emissive: ThreeColor;
    emissiveIntensity: number;
    emissiveMap: string;
    envMap: string;
    envMapIntensity: number;
    flatShading: boolean;
    fog: boolean;
    lightMap: string;
    lightMapIntensity: number;
    lights: boolean;
    morphNormals: boolean;
    morphTargets: boolean;
    needsUpdate: boolean;
    opacity: number;
    overdraw: number;
    polygonOffset: boolean;
    polygonOffsetFactor: number;
    polygonOffsetUnits: number;
    precision: any;
    premultipliedAlpha: boolean;
    program: any;//tg {getUniforms: ƒ, getAttributes: ƒ, destroy: ƒ, name: "MeshStandardMaterial", id: 1, …}
    refractionRatio: number;
    shadowSide: any;
    side: number;
    skinning: boolean;
    userData: any;
    vertexColors: number;
    visible: boolean;
    wireframe: boolean;
    wireframeLinecap: string;//"round"
    wireframeLinejoin: string;//"round"
    wireframeLinewidth: number;
    specularMap: string;
    shininess: number;
    reflectivity: number;//MeshPhysicalMaterial
    velvetIntensity: number;
    clearCoat: number;
    clearCoatRoughness: number;
    UI: {
        materialManipulation: ThreeMaterialManipulation
    }// Creators3D
}

export interface ThreeMaterialManipulation {
    reflectivity: number;
    color: string;//ThreeColor
    type: string;
    alphaTest: number;
    transparent: boolean;
    velvetIntensity: number;
    clearCoat: number;
    clearCoatRoughness: number;
}

export interface ThreeTexture {
    uuid: string;
    alphaMap: ThreeTexture;
    aoMap: ThreeTexture;
    aoMapIntensity: number;
    bumpMap: ThreeTexture;
    bumpScale: number;
    displacementMap: ThreeTexture;
    displacementScale: number;
    displacementBias: number;
    emissiveMap: ThreeTexture;
    emissive: ThreeColor;
    emissiveIntensity: number;
    envMap: ThreeTexture;
    envMapIntensity: number;
    lightMap: ThreeTexture;
    lightMapIntensity: number;
    map: ThreeTexture;
    color: ThreeColor;
    metalness: number;
    metalnessMap: ThreeTexture;
    morphNormals: boolean;
    morphTargets: boolean;
    defines: any;//{STANDARD: ""}
    normalMap: ThreeTexture;
    normalScale: ThreeVector2;
    refractionRatio: number;
    roughness: number;
    roughnessMap: ThreeTexture;
    imageSrc: string;
}

export interface ThreeTextureDictionary {
    name: string;
    type: string;
    object: ThreeTexture;
}

// export interface TexturedMaterial {
//     uuid: string;
//     name: string;
//     map: ThreeTexture;
//     type: string;
//     intensity: number;
//     src: string;
//     material: ThreeMaterial;
//     flipY: boolean;
//     relatedType: string;
//     srcChange: boolean;
//     // alphaMap: ThreeTexture;
//     // aoMap: ThreeTexture;
//     // aoMapIntensity: number;
//     // bumpMap: ThreeTexture;
//     // displacementMap: ThreeTexture;
//     // emissiveMap: ThreeTexture;
//     // envMap: ThreeTexture;
//     // envMapIntensity: number;
//     // lightMap :ThreeTexture;
//     // lightMapIntensity: number;
//     // map: ThreeTexture;
//     // metalnessMap: ThreeTexture;
//     // normalMap: ThreeTexture;
//     // roughnessMap: ThreeTexture;
// }

// export interface MapAdjustmentOption {
//     defaultValue?: number;
//     maxIntensity?: number;
//     restore?: boolean;
//     rgb?: boolean;
//     imageManipulation?: boolean;
//     hasColor?: boolean;
//     defaultManipulationValue?: number;
//     maxManipulationValue?: number;
//     videoEnabled?: boolean;
//     minFilter?: boolean;
//     alias?: string;
// }

export interface LightSphereArrow {
    type: string;
    index?: number;
}

export interface AdjustmentsPreset {
    id?: number;
    artist_user_id: number;
    preset_json?: AdjustmentsPresetJson;
    preset_name: string;
    created_at?: Date;
    updated_at?: Date;
    type_id: ArtistsUsersPresetType;
    sub_category_id?: number;
    retailer_sub_category_id?: number;
    retailer_id?: number;
    sort_index?: number;
    hdriUrl?: string;
}

export interface AdjustmentsPresetJson {
    lights: { [id: string]: Array<ThreeLight> };
    // texturesAnimations: { [id: string]: ITextureAnimatorOptions };
    shadowPlane: ShadowPlaneOptions;
    urlParats: any;
    hdri: HdriOptions;
    params: SceneParamsOptions;

}

export enum ArtistsUsersPresetType {
    PRIVATE = 1,
    PUBLIC = 2,
}

export interface AdjustmentsPresetOptions {
    type_id: ArtistsUsersPresetType;
    sub_category_id: number;
    retailer_sub_category_id: number;
    retailer_id: number;
    job_id: number;
    isAmazon: boolean;
}

export interface AdjustmentsData {
    subCategoryId: number;
    retailerSubCategoryId: number;
    retailerId: number;
    combineImagesMode: CombineImagesMode;
    resourceWarningType?: ResourceWarningType;
    jobId: number;
    isAmazon: boolean;
}

export enum SAVE_PRESET_FOR {
    RETAILER_SUB_CATEGORY = 1,
    PRODUCT_SUB_CATEGORY = 2,
    PERSONAL = 3,
    RETAILER = 4,
}
