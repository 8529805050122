import { Injectable } from '@angular/core';
import * as EventBus from '@vertx/eventbus-bridge-client.js';
import { StorageService } from 'ng-storage-service';
import { AuthService } from '../auth/auth.service';
import { Subscription, Observable, Subject } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { User } from '../auth/user';
import { CreatorsNotification, CreatorsNotificationListResponse, CreatorsNotificationType } from '../ui-components/notifications';
import { TextToSpeechService } from '../shared/text-to-speech.service';
import { SwService } from '../sw-helper/sw.service';
import { environment } from 'environments/environment';
import { RolesManagerService } from 'ng-role-based-access-control';
import { RolesHelperService } from '../auth/roles-helper.service';

@Injectable()
export class NotificationsWebsocketService {
  private eventBus: any;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private connetionLossCount: number;
  private connetionLossNotify: boolean;
  private onRolesFetched: Subscription;
  public onStateChange: Subject<boolean>;
  public onAnnouncement: Subject<CreatorsNotification>;
  public onAnnouncements: Subject<CreatorsNotificationListResponse>;
  public onRead: Subject<number>;
  public onReadAll: Subject<null>;
  public isOpen: boolean;
  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private textToSpeech: TextToSpeechService,
    private swService: SwService,
    private roles: RolesManagerService,
    private rolesHelper: RolesHelperService) {
    this.isOpen = false;
    this.onAnnouncement = new Subject<CreatorsNotification>();
    this.onAnnouncements = new Subject<CreatorsNotificationListResponse>();
    this.onRead = new Subject<number>();
    this.onReadAll = new Subject<null>();
    this.connetionLossCount = 0;
    this.connetionLossNotify = false;
    if (this.auth.isloggedIn())
      this.connect();
    this.onLogin = this.broadcaster.on<User>('onLogin').subscribe(
      user => this.connect()
    );
    this.onLogout = this.broadcaster.on<User>('onLogout').subscribe(
      user => this.disconnect()
    );
    this.onStateChange = new Subject<boolean>();
    this.onRolesFetched = this.rolesHelper.onRolesFetched.subscribe(
      data => {
        this.auth.refreshUserDate();
      }
    );
  }

  public fetch(limit: number, offset: number, types = [CreatorsNotificationType.FEEDBACK_RECEIVED, CreatorsNotificationType.JOB_ASSIGNED, CreatorsNotificationType.CUSTOM_BROADCAST]) {
    if (!this.auth.isloggedIn()) return;
    let obj = {
      limit: limit,
      offset: offset,
      types: types
    }
    this.eventBus.send('com.creators3d.notifications.server.fetch', obj, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onAnnouncements.next(response.body);
    });
  }

  public read(notificationId: number) {
    let obj = {
      id: notificationId
    }
    this.eventBus.send('com.creators3d.notifications.server.read', obj, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onRead.next(response.body instanceof Array ? response.body[0] : response.body);
    });
  }

  public readAll() {
    this.eventBus.send('com.creators3d.notifications.server.mark.all.read', {}, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onReadAll.next(response.body instanceof Array ? response.body[0] : response.body);
    });
  }

  private connect() {
    let options = {
      vertxbus_reconnect_attempts_max: Infinity,
      vertxbus_reconnect_delay_min: 1000,
      vertxbus_reconnect_delay_max: 5000,
      vertxbus_reconnect_exponent: 2,
      vertxbus_randomization_factor: 0.5
    };
    const token = this.storage.get('token');
    if (token) {
      this.eventBus = new EventBus(this.auth.notificationsWebsocketEndpoint + '/eventbus?token=' + token, options);
    this.eventBus.onopen = this.onopen.bind(this);
    this.eventBus.onclose = this.onclose.bind(this);
    this.eventBus.enableReconnect(true);
    }
  }

  private onopen() {
    this.setState(true);
    this.register();

  }

  private onclose() {
    this.setState(false);
  }

  private setState(state: boolean) {
    this.isOpen = state;
    console.log('Notifications WebSocket is ' + (this.isOpen ? 'open' : 'close'));
    if (this.isOpen) {
      this.rolesHelper.fetchRoles();
      this.connetionLossCount = 0;
      if (environment.production)
          this.swService.checkForUpdate(); // after machine sleep or offline check for updates
      // if (this.connetionLossNotify) {
        
      // }
      this.connetionLossNotify = false;
    }
    else {
      if (++this.connetionLossCount > 10 && !this.connetionLossNotify) {
        this.connetionLossNotify = true;
      }
    }
    this.onStateChange.next(this.isOpen);
  }

  private register() {
    this.eventBus.registerHandler("com.creators3d.notifications.client." + this.auth.user.id, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onAnnouncement.next(response.body);
    });
  }

  private disconnect() {
    if (this.eventBus && this.eventBus.state != this.eventBus.CLOSING && this.eventBus.state != this.eventBus.CLOSED)
      this.eventBus.close();
  }
}
