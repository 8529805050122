<div *ngIf="emojisService.list">
  <mat-tab-group class="no-min-w" (selectedIndexChange)="onIndexChange($event)">
    <mat-tab *ngFor="let topic of emojisService.list; let i = index" [label]="topic.symbol">
      <ng-container *ngIf="currentIndex == i">
        <button fxFlex mat-icon-button *ngFor="let emoji of topic.emojis" (click)="send(emoji.browser)">
          {{emoji.browser}}
        </button>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
  <!-- <button fxFlex mat-icon-button *ngFor="let i of list" (click)="send(i)">
    {{i}}
  </button> -->
</div>