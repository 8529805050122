import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ChatService } from '../ui-components/chat.service';
import { UtilsService } from './utils.service';

@Injectable()
export class ChatLinkResolve  {

    constructor(
        private chatService: ChatService,
        private utils: UtilsService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        this.chatService.openChatById(parseInt(route.params['id']), true, false);
        this.utils.forceRedirectTo('messages');
        return null;
    }
}