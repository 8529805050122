import { Injectable } from '@angular/core';
import { ResourceTypesService } from './resource-types.service';
import { ProductUi, Product, ResourceType } from './product';
import { MapperService } from 'app/shared/mapper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ItemPricing } from 'app/offer/offers';
import { KeyValuePair } from 'app/shared/enums';
import { EnumsService } from 'app/shared/enums.service';
import {EndpointsService} from 'app/communication/endpoints.service';
import {EndPoints} from '../communication/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ProductUtilsService {
  public _allFormatsDictionary: { [id: number]: ResourceType };
  public onFormatsDictionary: Array<Function>;
  private statusList: Array<KeyValuePair>;
  constructor(
    private resourceTypesService: ResourceTypesService,
    private mapper: MapperService,
    private sanitizer: DomSanitizer,
    private enums: EnumsService,
    private endpoints: EndpointsService
  ) {
    this.onFormatsDictionary = [];
    this.statusList = this.enums.getProductStatus();
  }

  get allFormatsDictionary() {
    return this._allFormatsDictionary;
  }

  set allFormatsDictionary(value: { [id: number]: ResourceType }) {
    this._allFormatsDictionary = value;
    this.onFormatsDictionary.forEach(f => f());
    this.onFormatsDictionary = [];
  }

  getProdutUI(item: Product): ProductUi {
    let UI = {} as ProductUi;
    UI.images = this.mapper.getProductImages(item);
    UI.smallImages = UI.images.map(i => i.small || i.big);
    UI.jobType = [];
    UI.resourceTypes = [];
    if (item.products_resources_types) {
      UI.resourceTypes = this.resourceTypesService.mapProductResourceTypeToUi(item.products_resources_types);
      // let formatsTypes = this.enums.getFormatsTypes();
      // let formatsTypes = this.resourceTypesService.getCachedTypes();
      // for (let i = 0; i < item.products_resources_types.length; i++) {
      //   UI.resourceTypes.push(formatsTypes.find(f => f.resource_type_id == item.products_resources_types[i].resource_type_id).resource_type_id);
      // }
    }
    UI.productsResourcesTypes = [];
    if (item.products_resources && item.products_resources.length) {
      UI.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.products_resources[0].resource_default + '&theme-color=' + decodeURIComponent('#000000'));
      if (!this.allFormatsDictionary)
        this.onFormatsDictionary.push(() => {
          this.getProdutUI(item);
        });
      else {
        item.products_resources.forEach(pr => {
          if (this.allFormatsDictionary[pr.viewer_resource_type] && !UI.productsResourcesTypes.find(exist => exist?.id == this.allFormatsDictionary[pr.viewer_resource_type].id))
            UI.productsResourcesTypes.push(this.allFormatsDictionary[pr.viewer_resource_type]);
        });
      }
    }
    UI.artists_pricing = {} as ItemPricing;
    UI.CMSLink = this.endpoints.getEndpointDomain(EndPoints.CMS) + '/product/' + item.id;
    UI.relatedJobs = [];
    if (item.artists_items) {
      item.artists_items.forEach(ai => {
        if (ai?.artists_jobs_items) {
          ai.artists_jobs_items.forEach(aji => {
            if (aji.artists_jobs) {
              aji.artists_jobs.forEach(aj => {
                if (aj.artists_users) {
                  aj.artists_users.forEach(au => {
                    UI.relatedJobs.push({
                      key: au.name,
                      value: `/jobs/job/${aj.id}`
                    });
                  });
                }
              });
            }
          });
        }
      });
    }
    return UI;
  }

  public getProductsFromProductQuery(rows: Array<Product>): Array<Product> {
    let res = [];
    if (rows.length) {
      rows.forEach((item: Product) => {
        let p = {} as Product;
        Object.assign(p, item);
        res.push(p);
      });
    }
    return res;
  }

  getStatusById(id: number): KeyValuePair {
    return this.statusList.find(i => i.key == id);
  }
}
