import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ThemePalette } from '@angular/material/core';
import { KeyValuePair } from '../../shared/enums';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownButtonComponent {
  @Input() variant: 'big' | 'thick' | 'huge';
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() color: ThemePalette = 'warn';
  @Input() options: KeyValuePair[];
  @Output() selectionChange = new EventEmitter<KeyValuePair>();
  @ViewChild('dropdown') dropdown: MatSelect;

  constructor() { }

  public onButtonClick(): void {
    if (this.options.length) {
      this.dropdown.toggle()
    } else {
      this.selectionChange.emit({key: undefined, value: undefined});
    }
  }
}
