import { Injectable } from '@angular/core';
import { ProductResourceType, ResourceType } from './product';
import { GraphqlService } from '../communication/graphql.service';
import { KeyValueAnyPair } from '../shared/enums';
import { BroadcasterService } from 'ng-broadcaster';
import { AuthService } from 'app/auth/auth.service';
import { ArtistsItemsResourcesType } from 'app/job/job';
import { Observable } from 'rxjs';
import { UtilsService } from 'app/shared/utils.service';
import { map } from 'rxjs/operators';
import { FileWrap } from 'app/ui-components/file';
@Injectable({
  providedIn: 'root'
})
export class ResourceTypesService {
  private cachedTypes: Array<ResourceType>;
  private cachedDefaultTypes: Array<ProductResourceType>;
  private isFetching: boolean;
  private fetch: Observable<any>;
  private resolves: Array<Function>;
  constructor(
    private gql: GraphqlService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private auth: AuthService
  ) {
    this.resolves = [];
    this.isFetching = false;
    if (auth.user) {
      // this.gql.resourceTypes().subscribe(
      //   t => {
      //     this.cachedTypes = t.data.resources_types;
      //     this.broadcaster.broadcast('onResourceTypes');
      //   }
      // );
      this.getResourceTypes();
      this.gql.defaultResourceTypes().subscribe(
        t => {
          this.cachedDefaultTypes = t.data.products_defaults_resources_types;
          this.broadcaster.broadcast('onDefaultResourceTypes');
        }
      );
    }
  }

  async getResourceTypesAsync(): Promise<Array<ResourceType>> {
    return new Promise((resolve, reject) => {
      if (this.isFetching) {
        this.resolves.push(resolve);
      }
      else if (this.cachedTypes) {
        resolve(JSON.parse(JSON.stringify(this.cachedTypes)));
      }
      else {
        this.resolves.push(resolve);
        this.getResourceTypes();
      }
    });
  }

  getResourceTypes() {
    // return this.rest.productCategory('get') as Observable<Array<Category>>;
    if (this.isFetching) return this.fetch;
    this.isFetching = true;
    // if (this.auth.isloggedIn()) {
    // const options = {
    //   avg_price: true
    // } as ProductCategoryOptions;
    this.fetch = this.gql.resourceTypes().pipe(map(res => {
      this.cachedTypes = this.utils.deepCopyByValue(res.data.resources_types);
      this.isFetching = false;
      this.resolves.forEach(r => r(this.getCachedTypes()));
      this.resolves = [];
      return this.getCachedTypes();
    }));
    // }
    // else {
    //   this.fetch = this.rest.productCategory('get').map(
    //     (obj: Array<Category>) => {
    //       this.cachedCategories = obj;
    //       this.setParents();
    //       this.isFetching = false;
    //       return this.getCachedCategories();
    //     });
    // }
    return this.fetch;
  }

  getCachedTypes(): Array<ResourceType> {
    if (this.cachedTypes) return JSON.parse(JSON.stringify(this.cachedTypes));
    return this.cachedTypes;
  }

  getCachedDefaultTypes(): Array<ProductResourceType> {
    if (this.cachedDefaultTypes) return JSON.parse(JSON.stringify(this.cachedDefaultTypes));
    return this.cachedDefaultTypes;
  }

  getTypes() {
    return this.gql.resourceTypes();
  }

  getDefaultsTypes() {
    return this.gql.defaultResourceTypes();
  }

  public mapUiToResourceType(value: Array<number>, allFormats: Array<ResourceType>, existing: Array<ArtistsItemsResourcesType>, idAndField: KeyValueAnyPair): any {//Array<ArtistsItemsResourcesType> {
    let res = [] as Array<ProductResourceType>;
    if (value && value.length) {
      if (!existing) existing = [];
      value.forEach(id => {
        const exist = existing.find(i => i.resources_types && i.resources_types[0].id == id);
        if (exist)
          res.push(exist);
        else {
          let item = {
            resources_types: [allFormats.find(af => af.id == id)]
          } as ProductResourceType;
          item.resource_type_id = item.resources_types[0].id;
          item[idAndField.key] = idAndField.value;
          res.push(item);
        }
      });
    }
    return res;
  }

  public mapResourceTypeToUi(value: Array<ResourceType>): Array<number> {
    if (value)
      return value.map(t => t.id);
  }

  public mapProductResourceTypeToUi(value: Array<ProductResourceType>): Array<number> {
    let res = [];
    value.forEach(prt => {
      if (prt.resources_types && prt.resources_types.length)
        res.push(prt.resources_types[0].id);
    });
    return res;
  }

  public isFileTypeDeprecated(file: FileWrap) {
    let types = this.getCachedTypes();
    let deprecated = types.filter(f => f.is_deprecated);
    return deprecated.some(d => {
      var extension = d.resource_name.toLowerCase();
      var strRegExPattern = '\\.(' + extension + ')$';
      var r = new RegExp(strRegExPattern);
      return file.suffix.toLowerCase() === extension || file.name.toLowerCase().match(r);
    })
  }
}
