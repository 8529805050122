import { ArtistsItem, ArtistsItemsResourcesType, ArtistsJobsType } from '../job/job';
import { ListRowsResponse } from '../communication/custom-request';
import { User } from '../auth/user';
import { PagingFilter } from 'app/shared/paging';
import {ArtistsJobsResourcesSourceFile, MediaTag} from 'app/job/resource';
import { FormatsType, JobsTypes } from 'app/shared/enums';
import { Product } from 'app/product/product';
import { ResourceJsonParams } from 'asset-adjustments';
import { Group } from '../auth/group';
import { Coordinates3D } from 'app/shared/enums';
import { JobTypes } from '../job-type/jot-type';

export interface Offer {
    created_at?: Date;
    id?: number;
    offer_uuid?: string;
    // price: number;
    public: boolean;
    updated_at?: Date;
    taken: boolean;
    hours_to_complete: number;
    complexity: number;
    category_id: number;
    // offer_type: number;
    same_mesh: boolean;
    notes: string;
    allow_rank_bonus: boolean;
    allow_time_bonus: boolean;
    is_archived: boolean;
    fix: boolean;
    fix_reason: number;
    mini_job: boolean;
    automatic_rendering: boolean;
    auto_incremented_rank: boolean;
    opened_by: number;
    manager_id: number;
    feedbacker_id: number;
    quality_supervisor: number;
    // is_poc: boolean;
    hidden: boolean;
    first_variation_price: number;
    additional_variation_price: number;
    artists_offers_items: Array<ArtistsOffersItem>;
    artists_offers_users: Array<ArtistsOffersUser>;
    artists_offers_pricing: Array<ItemPricing>;
    artists_offers_types: Array<ArtistsOffersTypes>;
    artists_offers_groups: Array<ArtistsOffersGroup>;
    artists_offers_private_comments: Array<ArtistsOffersPrivateComments>;
    items?: Array<Product>; // products to add
    UI: OfferUI;
    is_test: boolean;
    is_dummy?:  boolean;
}
export interface EditOffer {

    offer_uuid?: string;
    total_price: number;
    public: boolean;
    hidden: boolean;
    hours_to_complete: number;
    private_artist: Array<User>;
    persisten_group: Array<Group>;
    min_trust_level: number;
    required_format: Array<FormatsType>;
    assigneeType: number;
    polygon_specification: Array<PolygonSpecifications>;

}

export interface MassEditOffer {

    offer_uuid?: string;
    total_price: number;
    public: boolean;
    hidden: boolean;
    hours_to_complete: number;
    artists: Array<User>;
    groups: Array<Group>;
    complexity: number;
    required_formats: Array<FormatsType>;
    assigneeType: number;
  //  polygon_specification: Array<PolygonSpecifications>;
  polygon_specifications: Array<ArtistsOffersItemsPolygonSpecifications>;

}

export interface ArtistsOffersUser {
    id: number;
    artists_users: Array<User>;
}
export interface ArtistsFixReasons {
    id: number;
    source: string;
    reason: string;
}
export interface ArtistsOffersItem {
    id: number;
    created_at: Date;
    updated_at: Date;
    offer_id: number;
    item_id: number;
    cms_url: string;
    artists_items: Array<ArtistsItem>;
    artists_offers_resources: Array<OfferResource>;
    artists_offers_items_resources_types?: Array<ArtistsOffersItemsResourcesType>;
    artists_offers_items_polygon_specifications: Array<ArtistsOffersItemsPolygonSpecifications>;
    artists_offers_renders_specifications: Array<ArtistsOffersRendersSpecification>;
    UI: ArtistsOffersItemUI;
}

export interface ArtistsOffersItemUI {
    images: Array<NativeResourceSet>;
    polyTypes?: Array<KeyValuePoly>;
}

export interface OfferUI {
    images: Array<NativeResourceSet>;
    // complexity: number;
    // missingCategoryRank: ArtistsUsersCategoryRank;
    sufficientRank: boolean;
    maxBonus: number;
    userBonus: number;
    isMulti: boolean;
    offerJobTypes: Array<OfferJobTypes>;
    actionsTooltip: string;
    isGeometry: boolean;
    isTexture: boolean;
    isHighPoly: boolean;
    showHoursToPerfect: boolean;
    isChecked: boolean;
    surveyId?: string;
    popupId?: string;
    price: number;
    orgPrice: number;
    index: number;
    coordinates?: Coordinates3D;
}

export interface KeyValuePoly {
    key: PolyType;
    value: PolyDesc;
}

export interface PolyDesc {
    name: string;
    shapeType: PolyShapeType;
    shapeName: string;
    icon: string;
    min?: number;
    max?: number;
    maxSize?: number;
    desc?: string;
    variationName?: string;
    serialNumber?: string;
    jobType?: JobsTypes
}

export class OfferUIOptions {
    polyTypes: boolean;
    modelSortPos: number;
    index: number;
    constructor() {
        this.modelSortPos = 1;
    }
}

export interface CachedOffer extends Offer {
    isCached?: boolean;
}

// export interface OfferChildren {
//     artistsitemdatas: Array<ArtistsItemData>;
//     artistsitemspecs: Array<any>;
//     artistsitemdownloads: Array<ArtistsItemDownload>;
// }

export interface ArtistsItemData {
    created_at?: Date;
    id?: number;
    item_id?: number;
    updated_at?: Date;
    url: string;
    small_image_url?: string;
}

export interface NativeResourceSet {
    big: string;
    small: string;
    sort_index?: number;
    type: MediaTag;
    viewer_type?: string;
    id?: number;
    contains_whole_product?: boolean;
    is_product_only?: boolean;
    comments?: string;
    item_id?: number;
    meta?: any;
    description?: string;
}

// export enum NativeResourceType {
//     IMAGE = 1,
//     VIDEO = 2,
//     MODEL = 3,
// }

export interface ImageDialog {
    url: string;
    title?: string;
    isIframe?: boolean;
    link?: string;
}

export interface ElementDimensions {
    width: number;
    height: number;
}

export interface OfferFilterOptions extends PagingFilter {
    // limit: number;
    // offset: number;
    public: boolean;
    // is_desc: boolean;
    // order_by: string;
    category_id: Array<number>;
    complexity: Array<number>;
    artist_user_id: Array<number>;
    type_id: Array<number>;
    relevant: boolean;
    session_id?: string;
}

export interface ListOffersRowsResponse extends ListRowsResponse {
    count_public: number;
    count_relevant: number;
    count_private: number;
}
export interface OffersQueryData {
    allOffers: ListOffersRowsResponse;
}

export interface OfferQueryData {
    artists_offers: Offer;
}
export interface ArtistsFixReasonsData {
    artists_offers_fix_reasons: Array<ArtistsFixReasons>;
}

export interface OfferRequestWrapper {
    request: OfferRequestUI;
    currentItem?: Product;
    selectedItems?: Product[];
}

export interface OfferRequestUI extends OfferRequest {
    subCategoryId?: number;
}
export interface OfferRequest {
    created_by: number;
    complexity: number;
    private_offer: boolean;
    multiple_offers: boolean;
    // multiple_jobs: boolean;
    artist_user_id?: Array<number>;
    notes: string;
    // due_date: Date;
    hours_to_complete: number;
    same_mesh: boolean;
    artists_offers_types: Array<ArtistsOffersTypes>;
    source_job?: number;
    items?: Array<OfferRequestItem>;
    allow_rank_bonus: boolean;
    allow_time_bonus: boolean;
    manager_id: number;
    qualitySupervisor_id?: number;
    artists_offers_items?: Array<ArtistsOffersItem>;
    base_price: number;
    category_id: number;
    fix?: boolean;
    force?: boolean;
    // is_poc: boolean;
    hidden: boolean;
    first_variation_price: number;
    additional_variation_price: number;
    artists_offers_groups: Array<ArtistsOffersGroup>;
    artists_offers_pricing: Array<ArtistsOffersPrice>;
    downloads: Array<string>;
    // resources: Array<number>;
    // product_id?: number;
    is_sticky?: boolean;
    feedbacker_id?: number;
    is_creation_tool?: boolean;
    mini_job?: boolean;
    estimated_geometry_hours?: number;
}

export interface OfferRequestItem {
    id: number;
    downloads: Array<string>;
    resources?: Array<number> | Array<{ id: number }>;
    // notes: string;
    // category_id: number;
    artists_jobs_resources?: Array<number> | Array<{ id: number }>;
}

export interface ItemPricing {
    base_price: number;
    rank_bonus: number;
    time_bonus: number;
    allow_rank_bonus?: boolean;
    allow_time_bonus?: boolean;
    group_id?: number;
    offer_id?: number;
    active?: boolean;
    price_change?: number;
}
// export interface ItemPricingState {
//     rank: number;
//     time: number;
// }
export interface OfferResource extends GeneralResource {
    job_item_id?: number;
    file?: string;
    scale?: number;
    specular_intensity?: number;
}

export interface GeneralResource {
    id?: number;
    viewer_type?: string;
    resource_type: FormatsType;
    created_at?: Date;
    updated_at?: Date;
    viewer_url?: string;
    final_url?: string;
    resource_ar_url?: string;
    source_files_location?: string;
    feedback_id?: number;
    poly_type?: PolyType;
    extracted_poly_shape_type?: PolyShapeType;
    json_params?: ResourceJsonParams;
    preview_image_url?: string;
    gif_url?: string;
    validation_errors?: string;
    has_validation_errors?: boolean;
    artists_jobs_resources_source_files?: Array<ArtistsJobsResourcesSourceFile>;
}

export enum OfferDeclineReason {
    SKIP = 0,
    PRICE = 1,
    COMPLEXITY = 2,
    OTHER = 3,
    SHORT_DEADLINE = 4,
    SPECS_UNCLEAR = 5,
}

// export interface InsufficientCategoryRank {
//     description: string;
//     complexity: number;
//     rank: number;
// }

// export interface ArtistsOffersType {
//     type_id: number;
// }

export interface ArtistsOffersTypes extends ArtistsJobsType {
}

export interface RendersSpecification {
    // render_type: RenderType;
    background_color: string;
    created_at: Date;
    duration: number;
    frames_per_second: number;
    id: number;
    product_position: number;
    product_reflection: boolean;
    product_shadow: boolean;
    resolution_height: number;
    resolution_width: number;
    updated_at: Date;
}

export interface ArtistsOffersRendersSpecification extends RendersSpecification {
    offer_id: number;
    artists_offers_renders_specifications_angles: Array<ArtistsOffersRendersSpecificationsAngle>;
}

export interface PolygonSpecifications {
    created_at?: Date;
    id?: number;
    min_poly_count: number;
    max_poly_count: number;
    poly_type: PolyType,
    poly_shape_type: PolyShapeType;
    updated_at?: Date;
    variation_name?: string;
    serial_number?: string;
    job_type?: JobsTypes;
}

export interface ArtistsOffersItemsPolygonSpecifications extends PolygonSpecifications {
    offer_item_id?: number;
}


export interface ArtistsOffersRendersSpecificationsAngle extends RendersAngles {

}

export enum RenderType {
    IMAGES = 1,
    VIDEO = 2,
    IMAGES_AND_VIDEO = -1,
}

export interface RendersAngles {
    angle_x: number;
    angle_y: number;
    screenshot: string;
}

export interface ArtistsOffersItemsResourcesType extends ArtistsItemsResourcesType {
    offer_item_id: number;
}

export enum PolyType {
    LOW = 1,
    MID = 2,
    HIGH = 3,
}

export const POLY_TYPE: { [key in PolyType]: string } = {
    [PolyType.LOW]: 'Low poly',
    [PolyType.MID]: 'Mid poly',
    [PolyType.HIGH]: 'High poly'
};

export enum PolyShapeType {
    TRIANGULAR = 1,
    QUADRANGULAR = 2,
}

export interface OfferJobTypes extends JobTypes {
    relevant: boolean;
    // type_id: number;
}

export interface ArtistsOffersGroup {
    id?: number;
    group_id: number;
    artists_groups?: Array<Group>;
}

export interface ArtistsOffersPrice {
    base_price: number;
    time_bonus: number;
    rank_bonus: number;
    group_id?: number;
}

export interface OffersMassEdit {
    values: Offer;
    where: OfferFilterOptions;
}

export interface OfferResponseData {
    product: Product;
    totalProducts: number;
}

export interface OfferPrice {
    base_price: number;
    additional_variation_price: number;
    first_variation_price: number;
    hours_to_complete: number;
}


export interface ArtistsOffersPrivateComments {
    id: number;
    offer_id: number;
    artist_user_id: number;
    comment: string;
    private: boolean;
    created_at: Date;
    updated_at: Date;
    artists_users: Array<User>;
}