<div class="wrap simple-carousel-slider-wrapper" [ngClass]="{'nav-overlap': navOverlap}">
  <button mat-icon-button (click)="navLeft()" [disabled]="translateX == 0" class="nav left simple-carousel-slider-nav-button-left" color="primary"
  [ngClass]="{'simple-carousel-slider-nav-button-invisible': translateX == 0 }"
  >
    <!-- &#60; -->
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div class="center-me image-wrap simple-carousel-slider-image-wrap" [style.height]="height + 'px'">
    <div class="scroller" [ngClass]="{'imm': imm}" [style.width]="((width + 6) * total) + 'px'" #scroller
      [style.transform]="'translateX(' + translateX + 'px)'">
      <button class="image contrast-bg pos-rel simple-carousel-slider-img-wrapper" *ngFor="let img of images;let i = index" (click)="setIndex(i)"
        [style.width]="width + 'px'" [style.height]="height + 'px'" [ngClass]="{'current': i == currentIndex}" [disabled]="disabled">
        <ng-template [ngIf]="img.key" [ngIfElse]="elseContainer">
          <img [src]="img.value" alt="image" loading="lazy" class="simple-carousel-slider-img slider-image-with-description" [ngClass]="{'invert-box-content': img.value == ai}">
          <p class="slider-image-description">
            {{img.key}}
          </p>
        </ng-template>
        <ng-template #elseContainer>
          <img [src]="img.value" alt="image" loading="lazy" class="simple-carousel-slider-img content-va" [ngClass]="{'invert-box-content': img.value == ai}">
        </ng-template>
      </button>
    </div>
  </div>
  <button mat-icon-button (click)="navRight()" [disabled]="isRightNavDisabled" class="nav right simple-carousel-slider-nav-button-right" color="primary"
    [ngClass]="{'simple-carousel-slider-nav-button-invisible': isRightNavDisabled }"
  >
    <!-- &#62; -->
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
