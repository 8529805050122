<mat-progress-bar class="main-progress-bar" *ngIf="progressBarActive" mode="indeterminate"></mat-progress-bar>

<app-main-nav *ngIf="hasNav" class="hide-incognito" [ngClass]="{'blur': body.isBlur}"></app-main-nav>
<div class="content-wrap pos-rel" [@routerTransition]="getState(o)" [ngClass]="{'blur': body.isBlur, 'content-wrap-small': hasFooter, 'content-wrap-full': !hasFooter, 'content-wrap': hasNav}">
  <router-outlet #o="outlet"></router-outlet>
</div>

<div class="footer-container" *ngIf="hasNav">
  <app-footer *ngIf="hasFooter" class="hide-incognito"></app-footer>
  <app-scroll-top></app-scroll-top>
  <app-cookies-compliance></app-cookies-compliance>
  <app-chat></app-chat>
  <app-support *ngIf="auth.isloggedIn()"></app-support>
  <app-notifications></app-notifications>
  <app-homescreen-teaser class="hide-incognito" *ngIf="isBeforeinstallEnabled"></app-homescreen-teaser>
</div>

