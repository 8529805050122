import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ProductFilterOptions,
  ProductQueryData,
  ProductAuditQueryData,
  SingleProductQueryData,
  ProductsDefaultsResourcesTypesQueryData,
  ProductsResourcesTypesQueryData,
  SourchFilesTypesQueryData,
  ProductCategoryData,
  CategoryPriceData,
  ProductsPricingkData,
  TaggingProductsQueryData,
  ISubCategoryComplexityImageData,
  ISubCategoryComplexitiesData,
  FeedbackQueryData
} from '../product/product';
import { JobsQueryData, JobsFilterOptions, JobQueryData, JobAuditQueryData, jobsPriceQueryData } from '../job/job';
import { UsersFilterOptions, UsersQueryData, UserQueryData } from '../auth/user';
import { RetailersQueryData, RetailersFilterOptions, RetailerSubCategoryQueryData } from '../shared/retailers';
import { BodyService } from '../shared/body.service';
import { ArtistsFixReasonsData, OfferFilterOptions, OfferQueryData, OffersQueryData } from '../offer/offers';
import { SoftwareQueryData } from 'app/softwares/softwares';
import { JobTypesQueryData } from 'app/job-type/jot-type';
import { ArtistsJobsQuickFeedbackData, FeedbackTypes, PauseReasons } from 'app/job/feedback';
import { ArtistResourcesQueryData, ArtistUploadsFilterOptions } from 'app/viewer-resources/artist-uploads';
import { CustomRequestOptions } from './custom-request';
import { TaggingProjectsQueryData, TaggingProjectsFilterOptions, AllTaggingProjectsQueryData } from 'app/tagging/tagging';
import { RankQueryData } from 'app/ui-components/rank';
import { ArtistsGroupsUsersFilter, GroupUsersQuery, GroupsQuery, ArtistsGroupsFilter } from 'app/auth/group';
import { AllArtistsUsersSurveysQueryData, SurveyResponse } from 'app/survey/survey';
import { AllLibraries } from 'app/mesh-library/mesh-library';
import { ComplexityGuideQuery, IComplexityGuideOptions, ISubCategoriesComplexityGuidesOptions } from 'app/product/offer-creation-tool/offer-creation-tool.interface';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {
  public productsObservable: Observable<ProductQueryData>;
  constructor(
    private apollo: Apollo,
    private body: BodyService
  ) { }

  public getValues(options: any): string {
    let isEmpty = (param) => {
      if (param === '') return true;
      if (param instanceof Array && !param.length) return true;
      return false;
    }
    let params = '';
    for (let i in options) {
      if (!isEmpty(options[i])) {
        let isString = typeof options[i] === 'string';
        let val = options[i];
        if (val === '[]') continue;
        if (isString)
          params += i + ':' + '"' + val + '"' + ',';
        else if (val instanceof Array) {
          if (val.length) {
            if (!(val[0] instanceof Array)) {
              let toAdd = i + ':[';
              if (typeof val[0] === 'string') {
                val.forEach((inner) => {
                  // if (typeof val === 'string')
                  //   toAdd += '"' + val + '",'
                  if (typeof inner === 'string')
                    toAdd += '"' + inner + '",'
                  else
                    toAdd += '' + val + ','
                });
                toAdd = toAdd.substring(0, toAdd.length - 1);
              }
              else
                toAdd += val;
              params += toAdd + '],';
            }
            else
              params += i + ':[' + val.toString() + '],';
          }
        }
        else
          params += i + ':' + val + ',';
      }
    }
    if (options && Object.keys(options).length)
      params = params.substring(0, params.length - 1);
    return params;
  }

  // private normalizeRetailers(options: RetailersFilterOptions): RetailersFilterOptions {
  //   if (options.id instanceof Array) {
  //     options.id.map(i => i * 1)
  //   }
  //   return options;
  // }

  private normalizeProduct(options: ProductFilterOptions): ProductFilterOptions {
    if (typeof options.is_desc === 'string' && options.is_desc)
      options.is_desc = options.is_desc == 'true';
    if (typeof options.is_archived === 'string' && options.is_archived)
      options.is_archived = options.is_archived == 'true';
    if (options.status_id instanceof Array) {
      let optionsAny = options as any;
      if (typeof optionsAny.status_id[0] === 'string' && options.status_id[0]['indexOf'](',') > -1)
        options.status_id = options.status_id[0]['split'](',');
      options.status_id = options.status_id.map(i => i * 1);
    }
    if (options.name instanceof Array && options.name.length) {
      // options.name = options.name.map(i => '"' + decodeURIComponent(i) + '"');
      options.name = options.name.map(i => decodeURIComponent(i));
    }
    if (options.retailer_id instanceof Array)
      options.retailer_id.map(i => i * 1);
    if (options.missing_project_id instanceof Array)
      options.missing_project_id.map(i => i * 1);
    return options;
  }

  private normalizeRetailers(options: RetailersFilterOptions): RetailersFilterOptions {
    if (options.id instanceof Array) {
      options.id.map(i => i * 1)
    }
    return options;
  }

  // private normalizeJobs(options: JobsFilterOptions): JobsFilterOptions {

  //   return options;
  // }

  private onRequestEnd() {
    this.body.decreaseNumOfRequestsInProgress();
  }

  private onRequestStart() {
    this.body.increaseNumOfRequestsInProgress();
  }

  /**
   * Fetch complexity manager guides
   * @param options
   */
  public complexityGuides(options: IComplexityGuideOptions): Observable<ApolloQueryResult<ComplexityGuideQuery>> {
    this.onRequestStart();
    const params = this.getValues(options);
    const query = gql`
      {
        allComplexityGuides(${params}) {
          rows {
                name
                id
                products_complexity_sets {
                  price
                  id
                  complexity_level
                  complexity_guide_id
                  products_complexity_sets_images {
                    id
                    complexity_set_id
                    image
                    label
                  }
                }
                products_complexity_guides_sub_categories {
                  sub_category_id
                  complexity_guide_id
                }
          }
          count
        }
      }
    `;
    let res = this.apollo.query<ComplexityGuideQuery>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ComplexityGuideQuery>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  /**
   * Fetch a single complexity manager guide
   * @param id
   */
  public complexityGuide(id: number): Observable<ApolloQueryResult<ComplexityGuideQuery>> {
    this.onRequestStart();
    const query = gql`
      {
        complexityGuide(id: ${id}) {
          name
          id
          products_complexity_sets {
            price
            id
            complexity_level
            complexity_guide_id
            products_complexity_sets_images {
              id
              complexity_set_id
              image
              label
            }
          }
          products_complexity_guides_sub_categories {
            sub_category_id
            complexity_guide_id
          }
        }
      }
    `;
    let res = this.apollo.query<ComplexityGuideQuery>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ComplexityGuideQuery>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  /**
   * Fetch complexity manager guides by sub categories
   * @param options
   */
  public subCategoriesComplexityGuides(options: ISubCategoriesComplexityGuidesOptions): Observable<ApolloQueryResult<ComplexityGuideQuery>> {
    this.onRequestStart();
    const params = this.getValues(options);
    const query = gql`
      {
        subCategoriesComplexityGuides(${params}) {
          sub_category_id
          products_complexity_guides {
            id
            products_complexity_sets {
              price
              id
              complexity_level
              complexity_guide_id
              products_complexity_sets_images {
                id
                complexity_set_id
                image
                label
              }
            }
            products_complexity_guides_sub_categories {
              sub_category_id
              complexity_guide_id
            }
          }
        }
      }
    `;
    let res = this.apollo.query<ComplexityGuideQuery>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ComplexityGuideQuery>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public products(options: ProductFilterOptions): Observable<ApolloQueryResult<ProductQueryData>> {
    this.onRequestStart();
    options = this.normalizeProduct(options);
    let params = this.getValues(options);
    let query = gql`
    {
      allProducts(${params}) {
        rows {
          id
          created_at
          name
          c_name
          serial_number
          cs_id
          status_id
          category_id
          due_date
          retailer_id
          width
          height
          length
          units
          notes
          priority
          similar_approved_max_score
          similar_requested_max_score
          reject_reason
          process_type
          products_categories{
            products_sub_categories {
              id
            }
          }
          products_renders_requests {
            id
            status_id
            creator_status_id
          }
          retailers {
            id
            name
            logo_url
          }
          products_sub_categories_associations {
            id
            sub_category_id
            product_id
            products_sub_categories {
              id
              description
            }
          }
          products_data {
            url
            small_image_url
            id
            sort_index
          }
          products_urls {
            id
            url
          }
          products_specs {
            id
            spec_key
            spec_value
          }
          products_resources {
            id
            viewer_resource_type
            resource_default
            resource_enabled
            viewer_type
            products_resources_feedback {
              id
              fixed
              automated_copy_to_artists_succeeded
            }
            artists_jobs_resources {
              id
              approved
              artists_jobs_items {
                  id
                  item_id
                  job_id
                  artists_jobs {
                      id
                      status
                  }
                }
            }
          }
          artists_items {
            id
            artists_jobs_items {
              artists_jobs {
                id
                artists_users {
                  id
                  name
                }
              }
            }
          }
          products_resources_types {
            id
            resource_type_id
            product_id
            resources_types {
              id
              resource_name
            }
          }
          products_polygon_specifications {
            id
            min_poly_count
            max_poly_count
            poly_type
            poly_shape_type
            variation_name
            job_type
            serial_number
          }
          products_sub_categories_suggestions {
            user_id
            sub_category_id
            confidence
          }
          products_identifiers {
            id
            serial_number
          }
        }
        count
      }
    }
    `;
    let res = this.apollo.query<ProductQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public product(id: number): Observable<ApolloQueryResult<SingleProductQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      products(id: ${id}) {
        id
        created_at
        retailer_id
        name
        c_name
        serial_number
        status_id
        enabled
        mobile_enabled
        mobile_traffic
        desktop_traffic
        category_id
        price
        dimensions
        width
        height
        length
        units
        currency
        ir
        reject_reason
        estimated_geometry_hours
        products_sub_categories_associations {
          id
          sub_category_id
        }
        retailers {
          id
          name
        }
        artists_items {
          id
          product_id
        }
        products_resources {
          resource_big
          resource_small
          resource_default
          resource_enabled
          resource_info
          resource_mobile_order
          resource_order
          created_at
          resource_text
          id
          viewer_resource_type
          products_resources_feedback {
            id
            created_at
            updated_at
            feedback_type
            feedback_sub_type
            opened_by
            title
            screenshot
            notes
            fixed
            feedback_sub_types {
              id
              description
            }
             feedback_types {
              id
              description
            }
            products_resources_feedback_comments {
              id
              created_at
              updated_at
              user_id
              comment
              users {
                firstname
                lastname
                id
              }
            }
          }
        }
        products_data {
          product_id
          url
          small_image_url
          id
          sort_index
        }
        products_urls {
          product_id
          url
          id
        }
        products_tags {
          product_id
          tag
          confidence
          id
        }
        products_specs {
          product_id
          spec_key
          spec_value
          id
        }
        products_audit {
          user_id
          reference_table
          reference_id
          action_type
          field_name
          old_value
          new_value
          created_at
          id
          users {
            firstname
            lastname
            avatar_url
          }
        }
        products_resources_types {
          id
          resource_type_id
          product_id
          resources_types {
            id
            resource_name
          }
        }
        products_polygon_specifications {
          id
          min_poly_count
          max_poly_count
          poly_type
          poly_shape_type
        }
      }
    }
    `;
    let res = this.apollo.query<SingleProductQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<SingleProductQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public feedback(id: number): Observable<ApolloQueryResult<FeedbackQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      products_resources_feedback(id: ${id}) {
        id
        created_at
        screenshot
        notes
        feedback_type
        feedback_sub_type
        opened_by
        products_resources {
          artists_jobs_resources {
            artists_jobs_items {
              artists_jobs {
                artists_offers {
                  is_artist_contractor
                }
              }
            }
          }
        }
        products_resources_feedback_comments {
          comment
          created_at
          users {
            firstname
            lastname
            id
          }
        }
        users {
          firstname
          lastname
          id
        }
      }
    }
    `;
    let res = this.apollo.query<FeedbackQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<FeedbackQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public productAudit(productId: number): Observable<ApolloQueryResult<ProductAuditQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      products_audit(product_id: ${productId}) {
        product_id
        user_id
        reference_table
        reference_id
        action_type
        field_name
        old_value
        created_at
        new_value
        id
        users {
          firstname
          lastname
          avatar_url
        }
      }
    }
    `;
    let res = this.apollo.query<ProductAuditQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductAuditQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public jobsIds(options: JobsFilterOptions): Observable<ApolloQueryResult<JobsQueryData>> {
    this.onRequestStart();

    let params = this.getValues(options);
    let query = gql`
    {
      allJobs(${params}) {
        rows {
          id
          is_paused
          pause_reason
          paused_at
        }
        count
      }
    }
    `;
    let res = this.apollo.query<JobsQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<JobsQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public jobs(options: JobsFilterOptions): Observable<ApolloQueryResult<JobsQueryData>> {
    this.onRequestStart();

    let params = this.getValues(options);
    let query = gql`
    {
      allJobs(${params}) {
        rows {
          id
          created_at
          updated_at
          price
          status
          hours_to_complete
          counter_updated_at
          paid
          same_mesh
          allow_rank_bonus
          allow_time_bonus
          is_archived
          fix
          mini_job
          artist_user_id
          is_paused
          pause_reason
          pause_reason_id
          sorted_by
          manager_id
          feedbacker_id
          paused_at
          artists_jobs_types {
            type_id
          }
          artists_jobs_pricing {
            base_price
            rank_bonus
            time_bonus
            price_change
          }
          artists_jobs_items {
            id
            artists_jobs_resources {
              id
            }
            artists_items {
              id
              name
              category_id
              complexity
              priority
              artists_items_data {
                url
                small_image_url
                sort_index
              }
              products {
                id
                is_poc
                retailers {
                  id
                  name
                }
              }
            }
            artists_jobs_items_polygon_specifications {
              id
              poly_type
            }
          }
          artists_users {
            id
            name
            avatar_url
            rank
          }
        }
        count
      }
    }
    `;
    let res = this.apollo.query<JobsQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<JobsQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public getArtistActiveJobs(options: JobsFilterOptions): Observable<ApolloQueryResult<JobsQueryData>> {
    this.onRequestStart();

    let params = this.getValues(options);
    let query = gql`
    {
      allJobs(${params}) {
        rows {
          id
          status
          artists_jobs_items {
            id
            artists_items {
              id
              name
              artists_items_data {
                id
                small_image_url
              }
            }
          }
          artists_users {
            id
            name
            avatar_url
          }
        }
        count
      }
    }
    `;
    let res = this.apollo.query<JobsQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<JobsQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public jobsForFeebacks(options: JobsFilterOptions): Observable<ApolloQueryResult<JobsQueryData>> {
    this.onRequestStart();

    let params = this.getValues(options);
    let query = gql`
    {
      allJobs(${params}) {
        rows {
          id
          created_at
          status
          artists_jobs_items {
            id
            artists_items {
              name
              id
            }
            artists_jobs_resources {
              is_archived
              artists_resources_feedback {
                id
                fixed
                hidden
              }
              artists_jobs_resources_categories_ranking {
                id
                rank
              }
            }
          }
          artists_jobs_types {
            type_id
          }
        }
        count
      }
    }
    `;
    let res = this.apollo.query<JobsQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<JobsQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public chatTopics(): Observable<ApolloQueryResult<any>> {
    this.onRequestStart();
    let query = gql`
    {
      artists_chats_topics {
        id
        description
        chat_type_id
        admin_only
      }
    }`;
    let res = this.apollo.query<any>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<any>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public job(jobId: number): Observable<ApolloQueryResult<JobQueryData>> {
    this.onRequestStart();

    let query = gql`
    {
      artists_jobs(id:${jobId}) {
        id
        created_at
        updated_at
        opened_by
        price
        artist_user_id
        status
        hours_to_complete
        counter_updated_at
        paid
        notes
        same_mesh
        allow_rank_bonus
        allow_time_bonus
        is_archived
        manager_id
        fix
        mini_job
        countdown_active
        reopen
        source_job
        source_offer_uuid
        opened_texture
        bypass_dimensions
        bypass_geometry_accuracy
        bypass_color_comparison
        is_paused
        pause_reason
        pause_reason_id
        quality_supervisor
        fix_reason
        unattended_comment
        feedbacker_id
        sticky_type
        is_creation_tool
        force_score_doesnt_affect_rank
        is_dummy
        paused_at
        current_feedback_session_id
        feedback_session_open
        source_file_match
        job_qa_mode
        fm_active
        artists_jobs_tags {
          id
          tag
          is_predefined
        }
        artists_jobs_types {
          type_id
        }
        artists_jobs_pricing {
          base_price
          rank_bonus
          time_bonus
          price_change
        }
        artists_users {
          id
          name
          avatar_url
          new_user
          rank
          artists_users_roles {
            id
            role_id
            artist_user_id
        }
          artists_groups_users {
            artists_groups {
              id
              group_name
            }
          }
        }
        artists_jobs_items {
          id
          item_id
          cms_url
          artists_jobs_items_resources_types {
            id
            resource_type_id
            job_item_id
            resources_types {
              resource_name
              id
            }
          }
          artists_jobs_resources {
            id
            artist_approved
            created_at
            viewer_url
            approved
            viewer_type
            resource_type
            cam_change
            resource_ar_url
            mesh_compress
            is_archived
            poly_count
            vert_count
            extracted_poly_shape_type
            poly_type
            sent_to_retailers
            final_approval
            job_item_id
            preview_image_url
            has_validation_errors
            blender_glb_file_size
            uv_out_of_bounds
            uv_overlaps
            poly_variation_name
            serial_number
            diffuse_min_value
            diffuse_max_value
            resource_raw_url
            artists_jobs_resources_uv_layouts {
              id
              ajr_id
              uv_layout_url
            }
            artists_resources_feedback {
              title
              feedback_type
              feedback_sub_type
              notes
              screenshot
              video_url
              transcription
              created_at
              opened_by
              id
              fixed
              hidden
              whitelisted
              feedback_session_id
              feedback_types {
                id
                description
              }
              feedback_sub_types {
                id
                description
              }
              artists_resources_feedback_comments {
                comment
                id
                artist_user_id
                created_at
                updated_at
                feedback_id
                artists_users {
                  id
                  name
                  avatar_url
                }
              }
              artists_users {
                id
                name
                avatar_url
              }
            }
            artists_jobs_resources_source_files {
              id
              software_id
            }
            artists_jobs_resources_categories_ranking {
              id
              rank
            }
            artists_jobs_resources_alignments {
              id
              url
              created_at
              artist_user_id
              config_json
              data_id
              artists_users {
                name
                artists_users_roles{
                    role_id
                    artists_roles {
                      id
                      name
                    }
                }
              }
            }
            artists_jobs_resources_geometry_accuracy {
              id
              score
              url
              resource_id
              request
            }
            artists_jobs_resources_color_comparisons {
              id
              reference_sample_image_url
              resource_sample_image_url
              comparison_delta_l
              comparison_delta_ab
            }
          }
          artists_items {
            id
            name
            price
            product_id
            dimensions
            material
            notes
            width
            height
            length
            units
            category_id
            sub_category_id
            complexity
            serial_number
            priority
            description
            artists_items_identifiers {
              serial_number
              variation_id
            }
            artists_items_data {
              id
              url
              small_image_url
              sort_index
            }
            artists_items_downloads {
              url
            }
            artists_items_specs {
              id
              attachment
              title
              sort_index
              attachment_type
              job_type
              text
              source_table
            }
            products {
              id
              retailer_sub_category_id
              is_poc
              due_date
              process_type
              estimated_geometry_hours
              products_sub_categories_associations {
                id
                sub_category_id
              }
              retailers {
                id
                name
                maximum_glb_size
                source_file_match_approval
                auto_validate_source_files
                retailers_studios_mapping{
                  id
                  studio_id
                  retailer_id
                }
                retailers_mesh_conventions {
                  mesh_name
                  mesh_description
                }
              }
            }
            artists_items_sub_categories_associations {
              id
              sub_category_id
            }
          }
          artists_jobs_renders {
            id
            updated_at
            software_id
            render_type
            frames
            counter
            status
          }
          artists_jobs_items_polygon_specifications {
            id
            min_poly_count
            max_poly_count
            poly_type
            poly_shape_type
            job_type
            serial_number
            variation_name
          }
        }
        artists_jobs_renders_specifications {
          background_color
          duration
          frames_per_second
          product_position
          product_reflection
          product_shadow
          resolution_width
          resolution_height
          artists_jobs_renders_specifications_angles {
            angle_x
            angle_y
            screenshot
          }
        }
        artists_jobs_private_comments {
          id
          job_id
          comment
          private
          created_at
          updated_at
          is_copied
          artists_users {
            id
            name
            avatar_url
          }
        }
        artists_jobs_subscribers {
          id
          job_id
          email
        }
      }
    }
    `;
    let res = this.apollo.query<JobQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<JobQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public userGroups(id: number, options = new CustomRequestOptions()): Observable<ApolloQueryResult<UserQueryData>> {
    id *= 1;
    if (options.showLoading)
      this.onRequestStart();
    let query = gql`
    {
      artists_users(id:${id}) {
        id
        artists_groups_users {
          artists_groups {
            id
            type
          }
        }
      }
    }`;
    let res = this.apollo.query<UserQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<UserQueryData>>;
    // res.subscribe(this.onRequestEnd.bind(this));
    res.subscribe(() => {
      if (options.showLoading)
        this.onRequestEnd();
    });
    return res;
  }

  public user(id: number, options = new CustomRequestOptions()): Observable<ApolloQueryResult<UserQueryData>> {
    id *= 1;
    if (options.showLoading)
      this.onRequestStart();
    let query = gql`
    {
      artists_users(id:${id}) {
        id
        name
        email
        avatar_url
        first_name
        last_name
        skype
        country
        city
        timezone
        job_capacity
        total_capacity
        reference_text
        paypal_id
        payoneer_id
        payoneer_approved
        payment_type
        geometry_feedback_ranking
        texture_feedback_ranking
        rank
        override_rank
        override_capacity
        allow_payments
        gclid
        availability_status
        rank
        artists_users_categories_rankings {
          id
          artist_user_id
          parent_category_id
          rank
        }
        artists_users_push_details {
          id
          endpoint
          public_key
          auth
        }
        artists_users_roles {
          id
          role_id
          artist_user_id
          artists_roles {
            id
            name
          }
        }
        artists_users_mails_subscriptions {
          id
          mail_category_id
          subscribed
        }
        artists_users_software {
          software_id
          is_qualified
          id
        }
        artists_statistics {
          open_jobs
          revenue
          average_feedback
          average_complexity
          job_delivery
          average_geo_feedback
          average_tex_feedback
          average_render_feedback
          approved_geo_count
          approved_tex_count
          approved_render_count
          archived_geo_count
          archived_tex_count
          archived_render_count
        }
        artists_groups_users {
          id
          group_id
          group_leader
          artist_user_id
          artists_groups {
            id
            type
              group_name
              artists_users {
                id
                name
              }
          }
        }
        artists_groups {
          id
          group_name
          group_leader
        }
        retailers_feedback_masters {
          retailer_id
          id
          retailers {
            id
            name
          }
        }
        artists_users_expertise {
          id
          job_type_id
          artist_user_id
        }
      }
    }`;
    let res = this.apollo.query<UserQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<UserQueryData>>;
    // res.subscribe(this.onRequestEnd.bind(this));
    res.subscribe(() => {
      if (options.showLoading)
        this.onRequestEnd();
    });
    return res;
  }

  public users(options: UsersFilterOptions): Observable<ApolloQueryResult<UsersQueryData>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allArtistsUsers(${params}) {
        rows {
          id
          first_name
          last_name
          name
          avatar_url
          email
          created_at
          artists_groups {
            id
            group_name
            group_leader
            }
          artists_users_roles {
            artists_roles {
              id
            }
          }
        }
        count
      }
    }
    `;
    let res = this.apollo.query<UsersQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<UsersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public retailerForQuery(options: RetailersFilterOptions): Observable<ApolloQueryResult<RetailersQueryData>> {
    this.onRequestStart();
    options = this.normalizeRetailers(options);
    let params = this.getValues(options);
    let query = gql`
    {
      allRetailers(${params}) {
        rows {
          id
          name
          logo_url
          retailers_contracts {
            id
            retailer_index
          }
          retailers_batches {
            avg_product_price
            contract_id
            contract_index
            created_at
            end_at
            gross_margin
            id
            retailer_id
            total_models
            updated_at
            contract_index
            name
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<RetailersQueryData>({ query: query }) as Observable<ApolloQueryResult<RetailersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public defaultResourceTypes(): Observable<ApolloQueryResult<ProductsDefaultsResourcesTypesQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      products_defaults_resources_types {
        resource_type_id
        resources_types {
          id
          resource_name
        }
      }
    }
    `;
    let res = this.apollo.query<ProductsDefaultsResourcesTypesQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductsDefaultsResourcesTypesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public resourceTypes(): Observable<ApolloQueryResult<ProductsResourcesTypesQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      resources_types {
        id
        resource_name
        is_deprecated
        type
      }
    }
    `;
    let res = this.apollo.query<ProductsResourcesTypesQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductsResourcesTypesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public sourchFilesTypes(): Observable<ApolloQueryResult<SourchFilesTypesQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      source_files_types {
        id
        description
      }
    }
    `;
    let res = this.apollo.query<SourchFilesTypesQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<SourchFilesTypesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public offers(options: OfferFilterOptions): Observable<ApolloQueryResult<OffersQueryData>> {
    this.onRequestStart();

    let params = this.getValues(options);
    let query = gql`
    {
      allOffers(${params}) {
        rows {
          id
          offer_uuid
          created_at
          taken
          public
          hours_to_complete
          complexity
          category_id
          same_mesh
          allow_rank_bonus
          allow_time_bonus
          mini_job
          fix
          is_test
          artists_offers_groups {
            id
            group_id
            artists_groups {
              id
              group_name
              artists_groups_users {
                id
                artists_users {
                  id
                  name
                }
              }
            }
          }
          artists_offers_types {
            type_id
          }
          artists_offers_items {
            artists_offers_items_resources_types {
              id
              resource_type_id
              offer_item_id
              resources_types {
                id
                resource_name
              }
            }
            artists_offers_items_polygon_specifications {
              id
              poly_type
            }
            artists_items {
              id
              name
              category_id
              sub_category_id
              price
              width
              height
              length
              units
              material
              due_date
              notes
              products {
                is_poc
                retailers {
                  id
                  name
                }
              }
              artists_items_data {
                id
                url
                small_image_url
                sort_index
              }
            }
          }
          artists_offers_pricing {
            base_price
            rank_bonus
            time_bonus
            group_id
            offer_id
            active
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<OffersQueryData>({ query: query }) as Observable<ApolloQueryResult<OffersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public offersCount(options: OfferFilterOptions): Observable<ApolloQueryResult<OffersQueryData>> {
    this.onRequestStart();

    let params = this.getValues(options);
    let query = gql`
    {
      allOffers(${params}) {
        count
      }
    }`;
    let res = this.apollo.query<OffersQueryData>({ query: query }) as Observable<ApolloQueryResult<OffersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public legacyOffer(id: number): Observable<ApolloQueryResult<OfferQueryData>> {
    id *= 1;
    this.onRequestStart();
    let query = gql`
    {
      artists_offers(id:${id}) {
        id
        offer_uuid
      }
    }`;
    let res = this.apollo.query<OfferQueryData>({ query: query }) as Observable<ApolloQueryResult<OfferQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public offersFixReasons(): Observable<ApolloQueryResult<ArtistsFixReasonsData>> {

    this.onRequestStart();
    let query = gql`
    {  artists_offers_fix_reasons{
      id
      source
      reason
    }
    }`;
    let res = this.apollo.query<ArtistsFixReasonsData>({ query: query }) as Observable<ApolloQueryResult<ArtistsFixReasonsData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public offer(uuid: string): Observable<ApolloQueryResult<OfferQueryData>> {
    // id *= 1;
    this.onRequestStart();
    let query = gql`
    {
      artists_offers(offer_uuid:"${uuid}") {
        id
        offer_uuid
        created_at
        taken
        hours_to_complete
        public
        complexity
        category_id
        notes
        same_mesh
        allow_rank_bonus
        allow_time_bonus
        watched_amount
        fix
        mini_job
        auto_incremented_rank
        opened_by
        is_archived
        manager_id
        is_test
        hidden
        quality_supervisor
        automatic_rendering
        fix_reason
        feedbacker_id
        is_creation_tool
        is_dummy
        artists_offers_private_comments {
          id
          offer_id
          comment
          private
          created_at
          updated_at
          artists_users {
            id
            name
            avatar_url
          }
        }
        artists_offers_groups {
          id
          group_id
          artists_groups {
            id
            group_name
            artists_groups_users {
              id
              artists_users {
                id
                name
              }
            }
          }
        }
        artists_offers_types {
          type_id
        }
        artists_offers_items {
          id
          cms_url
          artists_offers_items_resources_types {
            id
            resource_type_id
            offer_item_id
            resources_types {
              id
              resource_name
            }
          }
          artists_offers_resources {
            viewer_url
            resource_type
            artists_jobs_resources_source_files {
              id
              software_id
            }
          }
          artists_items {
            category_id
            sub_category_id
            product_id
            price
            width
            height
            length
            units
            material
            due_date
            notes
            name
            notes
            id
            priority
            serial_number
            description
            artists_items_identifiers {
              serial_number
              variation_id
            }
            artists_items_data {
              id
              url
              small_image_url
              sort_index
            }
            artists_items_specs {
              id
              attachment
              title
              sort_index
              attachment_type
              job_type
              source_table
              text
            }
            artists_items_downloads {
              id
              url
            }
            artists_items_sub_categories_associations {
              id
              sub_category_id
            }
            products {
              is_poc
              estimated_geometry_hours
              retailers {
                id
                name
              }
            }
          }
          artists_offers_items_polygon_specifications {
            id
            min_poly_count
            max_poly_count
            poly_type
            poly_shape_type
            job_type
            serial_number
            variation_name
          }
        }
        artists_offers_users {
          id
          artists_users {
            id
            name
          }
        }
        artists_offers_pricing {
          base_price
          rank_bonus
          time_bonus
          group_id
          offer_id
          active
        }
        artists_offers_renders_specifications {
          background_color
          duration
          frames_per_second
          product_position
          product_reflection
          product_shadow
          resolution_width
          resolution_height
          artists_offers_renders_specifications_angles {
            angle_x
            angle_y
            screenshot
          }
        }
      }
    }`;
    let res = this.apollo.query<OfferQueryData>({ query: query }) as Observable<ApolloQueryResult<OfferQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public jobAudit(id: number): Observable<ApolloQueryResult<JobAuditQueryData>> {
    id *= 1;
    this.onRequestStart();
    let query = gql`
    {
      artists_jobs_audit(job_id:${id}) {
        job_id
        user_id
        reference_table
        reference_id
        action_type
        field_name
        old_value
        new_value
        comments
        created_at
        artists_users {
          id
          name
          avatar_url
        }
      }
    }`;
    let res = this.apollo.query<JobAuditQueryData>({ query: query }) as Observable<ApolloQueryResult<JobAuditQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public softwares(): Observable<ApolloQueryResult<SoftwareQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      software_enum {
        id
        enum_id
        software_name
        software_image_url
      }
    }`;
    let res = this.apollo.query<SoftwareQueryData>({ query: query }) as Observable<ApolloQueryResult<SoftwareQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  // public productsCategories(options: ProductCategoryOptions): Observable<ApolloQueryResult<ProductCategoryData>> {
  public productsCategories(): Observable<ApolloQueryResult<ProductCategoryData>> {
    this.onRequestStart();
    // let params = this.getValues(options);
    // productsCategories(${params}) {
    let query = gql`
    {
      productsCategories {
        id
        description
        parent_description
        parent_id
        products_sub_categories {
          id
          description
          category_id
          geometry_type
        }
      }
    }`;
    let res = this.apollo.query<ProductCategoryData>({ query: query }) as Observable<ApolloQueryResult<ProductCategoryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public productsFullCategories(): Observable<ApolloQueryResult<ProductCategoryData>> {
    this.onRequestStart();
    let query = gql`
    {
      productsCategories {
        id
        description
        parent_description
        parent_id
        products_sub_categories {
          id
          description
          category_id
          geometry_price
          texture_price
          additional_geometry_price
          additional_texture_price
          geometry_type
          products_sub_categories_polygon_specifications {
            id
            sub_category_id
            poly_type
            min_poly_count
            max_poly_count
            poly_shape_type
          }
          products_sub_categories_attachments {
            id
            sub_category_id
            title
            attachment
            sort_index
            attachment_type
            job_type
            text
          }
          products_sub_categories_dimensions {
            id
            sub_category_id
            width
            height
            length
          }
          artists_users_presets {
            id
            artist_user_id
            preset_name
            preset_json
            type_id
            sub_category_id
            retailer_sub_category_id
            retailer_id
            sort_index
          }
        }
      }
    }`;
    let res = this.apollo.query<ProductCategoryData>({ query: query }) as Observable<ApolloQueryResult<ProductCategoryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public jobsTypes(): Observable<ApolloQueryResult<JobTypesQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      jobs_types {
        id
        description
      }
    }`;
    let res = this.apollo.query<JobTypesQueryData>({ query: query }) as Observable<ApolloQueryResult<JobTypesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  /**
   * Request sub category complexities
   */
  public subCategoryComplexities(): Observable<ApolloQueryResult<ISubCategoryComplexitiesData>> {
    this.onRequestStart();
    const query = gql`
    {
      products_sub_categories_complexities {
        id
        created_at
        updated_at
        sub_category_id
        complexity_image_id
        complexity_level
      }
    }
    `;
    return this.apollo.query<any>({ query: query }).pipe(tap(() => this.onRequestEnd.bind(this))) as Observable<ApolloQueryResult<ISubCategoryComplexitiesData>>;
  }

  /**
   * Return the array of images for subcategory complexity manager
   * @returns
   */
  public subCategoryComplexityImages(): Observable<ApolloQueryResult<ISubCategoryComplexityImageData>> {
    this.onRequestStart();
    let query = gql`
    {
      products_complexities_images {
        id
        created_at
        updated_at
        image_url
      }
    }
    `;
    return this.apollo.query<any>({ query: query }).pipe(tap(() => this.onRequestEnd.bind(this))) as Observable<ApolloQueryResult<ISubCategoryComplexityImageData>>;
  }

  public categoriesPricing(category_id: number): Observable<ApolloQueryResult<CategoryPriceData>> {
    this.onRequestStart();
    let query = gql`
    {
      categories_pricing(category_id: ${category_id}) {
        type_id
        average
      }
    }`;
    let res = this.apollo.query<CategoryPriceData>({ query: query }) as Observable<ApolloQueryResult<CategoryPriceData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public quickFeedback(): Observable<ApolloQueryResult<ArtistsJobsQuickFeedbackData>> {
    this.onRequestStart();
    let query = gql`
    {
      artists_jobs_quick_feedbacks {
        id
        feedback_description
      }
    }`;
    let res = this.apollo.query<ArtistsJobsQuickFeedbackData>({ query: query }) as Observable<ApolloQueryResult<ArtistsJobsQuickFeedbackData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public productsPricing(id: number): Observable<ApolloQueryResult<ProductsPricingkData>> {
    this.onRequestStart();
    let query = gql`
    {
      products_pricing(id: ${id}) {
        type_id,
        average
      }
    }`;
    let res = this.apollo.query<ProductsPricingkData>({ query: query }) as Observable<ApolloQueryResult<ProductsPricingkData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public artistResource(options: ArtistUploadsFilterOptions): Observable<ApolloQueryResult<ArtistResourcesQueryData>> {
    this.onRequestStart();
    // is_desc is not supported yet
    delete options.is_desc;
    // order_by is not supported yet
    delete options.order_by;
    let params = this.getValues(options);
    let query = gql`
    {
      artistsResourcesUploads(${params}) {
        rows {
          id
          created_at
          artist_user_id
          viewer_url
          preview_image_url
        }
        count
      }
    }`;
    let res = this.apollo.query<ArtistResourcesQueryData>({ query: query }) as Observable<ApolloQueryResult<ArtistResourcesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public unpaidJobsPrice(artistUserId: number): Observable<ApolloQueryResult<jobsPriceQueryData>> {
    this.onRequestStart()
    let query = gql`{jobsPrice (artist_user_id:${artistUserId}) {price}}`;
    let res = this.apollo.query<jobsPriceQueryData>({ query: query }) as Observable<ApolloQueryResult<jobsPriceQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public taggingProjects(id: number): Observable<ApolloQueryResult<TaggingProjectsQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      tagging_projects(id:${id}) {
        id
        created_at
        updated_at
        opened_by
        title
        description
        product_limit
        product_limit_date
        active
        random_order
        tagging_projects_sub_categories {
          sub_category_id
        }
        tagging_projects_products {
          id
          project_id
          product_id
          review_option
        }
        tagging_projects_attributes {
          id
          created_at
          updated_at
          attribute_key
          attribute_type
          attribute_description
          tagging_projects_attributes_enums {
            id
            created_at
            updated_at
            attribute_id
            attribute_enum_id
            attribute_enum_key
          }
        }
        tagging_projects_products {
          id
          created_at
          updated_at
          project_id
          product_id
          review_option
        }
        tagging_projects_data_values {
          id
          project_id
          tagger_id
          data_id
          attribute_id
          attribute_value
        }
      }
    }`;
    let res = this.apollo.query<TaggingProjectsQueryData>({ query: query }) as Observable<ApolloQueryResult<TaggingProjectsQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public allTaggingProjects(options: TaggingProjectsFilterOptions): Observable<ApolloQueryResult<AllTaggingProjectsQueryData>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allTaggingProjects(${params}) {
        rows {
          id
          opened_by
          title
          description
          product_limit
          active
          priority
          random_order
          tagging_projects_sub_categories {
            sub_category_id
          }
          tagging_projects_attributes {
            id
            created_at
            updated_at
            attribute_key
            attribute_type
            attribute_description
            tagging_projects_attributes_enums {
              id
              created_at
              updated_at
              attribute_id
              attribute_enum_id
              attribute_enum_key
            }
          }
          tagging_projects_products {
            id
            project_id
            product_id
            review_option
          }
          tagging_projects_data_values {
            id
            project_id
            data_id
            tagger_id
            attribute_id
            attribute_value
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<AllTaggingProjectsQueryData>({ query: query }) as Observable<ApolloQueryResult<AllTaggingProjectsQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public productsForTags(options: ProductFilterOptions): Observable<ApolloQueryResult<TaggingProductsQueryData>> {
    this.onRequestStart();
    delete options.is_archived;
    delete options.is_desc;
    let params = this.getValues(options);

    let query = gql`
    {
      allTaggingProducts(${params}) {
        rows {
          id
          name
          category_id
          products_sub_categories_associations {
            id
            sub_category_id
          }
          tagging_projects_products {
            id
            product_id
            project_id
            review_option
          }
          products_data {
            url
            small_image_url
            id
            sort_index
            tagging_projects_data_values {
              id
              project_id
              attribute_id
              attribute_value
              tagger_id
              data_id
            }
          }
          products_resources {
            id
            viewer_resource_type
            resource_big
            resource_default
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<TaggingProductsQueryData>({ query: query }) as Observable<ApolloQueryResult<TaggingProductsQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public rankingDefinitions(): Observable<ApolloQueryResult<RankQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      artists_ranking_definitions {
        id
        created_at
        updated_at
        from_score
        to_score
        job_capacity
        total_capacity
      }
    }`;
    let res = this.apollo.query<RankQueryData>({ query: query }) as Observable<ApolloQueryResult<RankQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public artistsGroups(options: ArtistsGroupsFilter): Observable<ApolloQueryResult<GroupsQuery>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allArtistsGroups(${params}) {
        rows {
          id
          group_name
          group_leader
          increase_percentage
          type

          artists_groups_feedback_masters {
            fm_id
            id
            artist_group_id
            artists_users {
              name
              email
            }
          }
          artists_groups_users {
            artist_user_id
            artists_users {
               id
               name
               avatar_url
               last_name
               first_name
               }
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<GroupsQuery>({ query: query }) as Observable<ApolloQueryResult<GroupsQuery>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public artistsGroupsSlim(options: ArtistsGroupsFilter): Observable<ApolloQueryResult<GroupsQuery>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allArtistsGroups(${params}) {
        rows {
          id
          group_name
          group_leader
          increase_percentage
          type
          artists_groups_users {
            artist_user_id
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<GroupsQuery>({ query: query }) as Observable<ApolloQueryResult<GroupsQuery>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public artistsGroupUsers(options: ArtistsGroupsUsersFilter): Observable<ApolloQueryResult<GroupUsersQuery>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allArtistsGroupsUsers(${params}) {
        rows {
          id
          artist_user_id
          artists_users {
            id
            name
            avatar_url
            first_name
            last_name
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<GroupUsersQuery>({ query: query }) as Observable<ApolloQueryResult<GroupUsersQuery>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public artistsUsersPresets(id: number, options = new CustomRequestOptions()): Observable<ApolloQueryResult<UserQueryData>> {
    id *= 1;
    if (options.showLoading)
      this.onRequestStart();
    let query = gql`
    {
      artists_users(id:${id}) {
        id
        artists_users_presets {
          id
          artist_user_id
          preset_name
          preset_json
          type_id
          sub_category_id
          retailer_sub_category_id
          retailer_id
          sort_index
        }
      }
    }`;
    let res = this.apollo.query<UserQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<UserQueryData>>;
    res.subscribe(() => {
      if (options.showLoading)
        this.onRequestEnd();
    });
    return res;
  }

  public retailersCategories(): Observable<ApolloQueryResult<RetailerSubCategoryQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      retailersCategories {
        retailers_sub_categories {
          id
          artists_users_presets {
            id
            artist_user_id
            preset_name
            preset_json
            type_id
            sub_category_id
            retailer_sub_category_id
            retailer_id
            sort_index
          }
        }
      }
    }`;
    let res = this.apollo.query<RetailerSubCategoryQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<RetailerSubCategoryQueryData>>;
    res.subscribe(() => {
      this.onRequestEnd();
    });
    return res;
  }

  public allArtistsUsersSurveys(options: SurveyResponse): Observable<ApolloQueryResult<AllArtistsUsersSurveysQueryData>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allArtistsUsersSurveys(${params}) {
        rows {
          artist_user_id
          job_id
          survey_type_id
          response_id
        }
        count
      }
    }`;
    let res = this.apollo.query<AllArtistsUsersSurveysQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<AllArtistsUsersSurveysQueryData>>;
    res.subscribe(() => {
      this.onRequestEnd();
    });
    return res;
  }

  public retailerBatches(options: RetailersFilterOptions): Observable<ApolloQueryResult<RetailersQueryData>> {
    this.onRequestStart();
    options = this.normalizeRetailers(options);
    let params = this.getValues(options);
    let query = gql`
    {
      allRetailers(${params}) {
        rows {
          id
          name
          logo_url
          retailers_contracts {
            id
            retailer_index
          }
          retailers_batches {
                     avg_product_price
                     contract_index
                     contract_id
                     created_at
                     end_at
                     gross_margin
                     id
                     retailer_id
                     total_models
                     updated_at
                     name
             }
        }
        count
      }
    }`;
    let res = this.apollo.query<RetailersQueryData>({ query: query }) as Observable<ApolloQueryResult<RetailersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public meshLibraries(sub_cat_id?: Array<number>): Observable<ApolloQueryResult<AllLibraries>> {
    this.onRequestStart();
    let option = `(is_archived: false)`;
    if (sub_cat_id) {
      option = `(is_archived: false,sub_category_id:[${sub_cat_id}])`;
    }
    let query = gql`
        {
          allLibraries ${option}
          {
                id
                name
                is_archived
                products_sub_categories{
                  id
                  category_id
                }
                artists_meshes{
                  id
                  library_id
                  name
                  is_archived
                  geometry_url
                  thumbnail_url
                }
            }
        }`;
    let res = this.apollo.query<AllLibraries>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<AllLibraries>>;
    res.subscribe(() => {
      this.onRequestEnd();
    });
    return res;
  }

  public getArchiveReasons(userId) {
    this.onRequestStart();
    let query = gql`{
      artists_users(id: ${userId}) {
        artists_users_roles {
          artists_roles {
            id
            name
            artists_jobs_roles_archive_reasons {
              artists_jobs_archive_reasons {
                id
                archive_reason
              }
            }
          }
        }
      }
    }`;
    let res = this.apollo.query({query: query});

    res.subscribe(() => {
      this.onRequestEnd();
    });

    return res;
  }

  public resourcesAlignments(jobId) {
    this.onRequestStart();
    let query = gql`{
      resourcesAlignments(limit:10, offset:0 ,job_id: ${jobId}, is_desc:true ){
        rows{
          id
          created_at
          job_item_id
          final_approval
          type_id
          artists_jobs_resources_alignments{
            id
            config_json
            url
            data_id
            created_at
            resource_id
            artist_user_id
            artists_users {
              name
              artists_users_roles{
                  role_id
                  artists_roles {
                    id
                    name
                  }
              }
            }
          }
        }
        count
      }
    }`;
    let res = this.apollo.query({query: query}) as Observable<ApolloQueryResult<any>>;

    res.subscribe(() => {
      this.onRequestEnd();
    });

    return res;
  }

  public jobRetailerPresets(jobId: number): Observable<ApolloQueryResult<JobQueryData>> {
    this.onRequestStart();

    let query = gql`
    {
      artists_jobs(id:${jobId}) {
        id
        artists_jobs_items {
          id
          artists_items {
            id
            products {
              id
              retailers {
                id
                artists_users_presets {
                  id
                  artist_user_id
                  preset_name
                  preset_json
                  type_id
                  sub_category_id
                  retailer_sub_category_id
                  retailer_id
                  sort_index
                }
              }
            }
          }
        }
      }
    }
    `;
    let res = this.apollo.query<JobQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<JobQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public feedbackTypes(): Observable<ApolloQueryResult<FeedbackTypes>>{
    this.onRequestStart();
    let query = gql`
    {
      feedbackTypes(order_by: "display_order", is_desc: false) {
       id
       description
       feedback_sub_types {
         id
         description
         feedback_type_id
        }
      }
    }
    `;
    let res = this.apollo.query<FeedbackTypes>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<FeedbackTypes>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public pauseReasons(): Observable<ApolloQueryResult<PauseReasons>>{
    this.onRequestStart();
    let query = gql`
    {
      pauseReasons(order_by: "display_order", is_desc: false) {
       id
       description
      }
    }
    `;
    let res = this.apollo.query<PauseReasons>(
      {
        query: query
      }
      ) as Observable<ApolloQueryResult<PauseReasons>>;
      res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }
}
