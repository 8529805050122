import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GraphqlService } from '../../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { JobsQueryData, JobsFilterOptions } from 'app/job/job';
import { JobsService } from 'app/job/jobs.service';

@Injectable()
export class ArtistJobsResolve  {

    constructor(private gql: GraphqlService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (!route.params['id']) return null;
        let options = {
            artist_user_id: [route.params['id'] * 1],
            order_by: 'created_at',
            is_desc: true,
            status: ['Approved'],
            paid: true,
            limit: JobsService.DEFAULT_FILTER.limit
        } as JobsFilterOptions;
        // TODO add limit and infinite scroll to artist portfolio
        return this.gql.jobs(options) as Observable<ApolloQueryResult<JobsQueryData>>;
    }
}
