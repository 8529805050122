<mat-form-field>
  <input matInput placeholder="client" aria-label="client" [matAutocomplete]="retailerAuto" [formControl]="retailerCtrl" #filterInput>
  <mat-autocomplete [panelWidth]="panelWidth" #retailerAuto="matAutocomplete">
    <mat-option *ngFor="let retailer of filteredRetailers | async"
      [value]="retailer.name" (onSelectionChange)="onRetailerChange(retailer)">
      <img style="vertical-align:middle" aria-hidden src="{{retailer.logo_url}}" height="25" />
      <span>{{ retailer.name }}</span>
      <small>({{retailer.id}})</small>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>