import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BodyService } from './shared/body.service';
import { routerTransition } from './ui-components/router.animations';
import { BroadcasterService } from 'ng-broadcaster';
import { AuthService } from './auth/auth.service';
import { Meta } from '@angular/platform-browser';
import { StorageService } from 'ng-storage-service';
import { RestService } from './communication/rest.service';
import { RouterOutlet } from '@angular/router';
import { UtilsService } from './shared/utils.service';
import { PixelsService } from './shared/pixels.service';
import {environment} from '../environments/environment';
import {SentryService} from './shared/sentry.service';
// import * as Sentry from '@sentry/angular';
import { IdleDetectionService } from './shared/idle-detection.service';
import { IIdleDetector } from './shared/enums';

export enum SignOnType {
  SIGN_IN = 'Sign-In',
  SIGN_UP = 'Sign-Up'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  static TRANSLATED = 'translated';
  public progressBarActive: boolean = false;
  public isBeforeinstallEnabled: boolean;
  public hasFooter: boolean;
  public hasNav: boolean;

  constructor(
    public body: BodyService,
    private cdr: ChangeDetectorRef,
    private broadcaster: BroadcasterService,
    public auth: AuthService,
    private meta: Meta,
    private storage: StorageService,
    private rest: RestService,
    private utils: UtilsService,
    private pixels: PixelsService,
    private idleService: IdleDetectionService
  ) {
    this.broadcaster.on('beforeinstallprompt').subscribe(
      () => this.isBeforeinstallEnabled = true
    );
    this.body.requestsInProgress.subscribe((value) => {
      this.progressBarActive = value;
      this.detectChanges();
    });
    this.body.hasMainNav$.subscribe((hasNav) => this.hasMainNav(hasNav));
    this.broadcaster.on('detectChanges').subscribe(() => {
      this.detectChanges();
    });
    this.body.hasFooter$.subscribe((hasFooter: boolean) => this.hasFooter = hasFooter);
    if (!this.body.isProd)
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    const incognito = this.utils.getUrlParam(window.location.search, 'incognito');
    if (incognito) {
      document.body.classList.add('incognito');
    }
    const groupId = parseInt(this.utils.getUrlParam(window.location.search, 'gid'));
    if (!isNaN(groupId)) {
      if (this.auth.isloggedIn()) {
        let payload = {
          group_id: groupId,
          artist_user_id: this.auth.getUser().id
        };
        this.rest.groupUsers('post', payload).subscribe(
          () => { },
          () => {
            this.storage.set('group_id', groupId);
          }
        );
      }
      else {
        this.storage.set('group_id', groupId);
      }
    }
  }

  ngOnInit() {
    if (!this.storage.get(AppComponent.TRANSLATED)) {
      const html = document.querySelector('html');
      const observer = new MutationObserver(() => {
        const lng = html.getAttribute('lang');
        if (lng !== 'en' && lng !== 'en-US') {
          this.pixels.sendPixel({
            event: 'translation',
            lng: html.getAttribute('lang')
          });
          observer.disconnect();
          this.storage.set(AppComponent.TRANSLATED, true);
        }
      });
      observer.observe(html, { attributes: true, attributeFilter: ['class'], subtree: false });
    }
    const gclid = this.utils.getUrlParam(location.href, 'gclid');
    if (gclid && gclid !== location.href)
      this.storage.set('gclid', gclid);

    // init the Sentry browser SDK (for error monitoring)
    if(environment.sentry.isActivated) {
      SentryService.init();
    }

    const favIcon: HTMLLinkElement = document.querySelector('#dynamic-favicon');
    favIcon.href = environment.favIcon;
    if (this.idleService.shouldWeListen()) {
      this.broadcaster.on(IdleDetectionService.BROADCAST_MSG).subscribe(
        (e: IIdleDetector) => {
          this.pixels.sendPixel({
            event: 'idle',
            user_state: e.userState,
            screen_state: e.screenState,
            idle_minutes: IdleDetectionService.INTERVAL / 60000
          });
        }
      );
      this.idleService.listenAfterClick();
    }
  }

  private hasMainNav(hasNav: boolean): void {
    this.hasNav = hasNav;
    if (!hasNav) {
      this.body.setLastExpandMode();
    }
  }

  ngOnDestroy() {
    this.body.requestsInProgress.unsubscribe();
    this.body.hasMainNav$.unsubscribe();
  }

  detectChanges() {
    try {
      this.cdr.detectChanges();
    } catch (e) { }
  }

  getState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

}
