import { Injectable } from '@angular/core';
import { FeedbackType } from '../shared/enums';
import { GraphqlService } from 'app/communication/graphql.service';
import { ArtistsJobsQuickFeedback, feedback_sub_types, feedback_types } from './feedback';
import { openDB, IDBPDatabase } from '@tempfix/idb'
import { FeedbackDB } from './feedback'
@Injectable()
export class FeedbackService {
  static TRANSCRIPTION = '_transcription';
  public types: Array<feedback_types>;
  public filteredSubTypes: Array<feedback_sub_types>;
  public quickFeedbacks: Array<ArtistsJobsQuickFeedback>;
  public lastQuickFeedback: string;
  public feedbackOldTypeThreshold: number;
  private db: IDBPDatabase<FeedbackDB>;
  public feedbackTypes: Array<feedback_types>;
  private inProgress: boolean;
  public numOnFeedbacks: { id: number, artistJobItemNumFeedback };
  constructor(
    private gql: GraphqlService
  ) {
    this.init();
    this.refreshQuickFeedbacks();
    this.feedbackOldTypeThreshold = 4;
  }
  

  public isFeedbackTypeValid(feedback: any): boolean {
    if (!feedback || !feedback.feedback_type) return false;
    return true;
  }

  public isFeedbackSubTypeValid(feedback: any): boolean {
    if ((!isNaN(feedback?.feedback_type) &&
      feedback.feedback_type === FeedbackType.others) ||
      feedback.feedback_sub_type) return true;
    return false;
  }

  public isScreenshotValid(feedback: any): boolean {
    if (this.isVisualFeedback(feedback.feedback_type)) {
      if (!feedback.screenshot && !feedback.video_url) return false;
    }
    return true;
  }

  public isVisualFeedback(type) {
    return (type === FeedbackType.shape_doesnt_match
      || type === FeedbackType.colors_materials
      || type === FeedbackType.lighting_shadow);
  }

  async init() {
    this.types = await this.getFeedbackTypes();
  }

  refreshQuickFeedbacks() {
    this.gql.quickFeedback().subscribe(
      res => this.quickFeedbacks = res.data.artists_jobs_quick_feedbacks
    )
  }

  async initIdb() {
    if (!this.db) {
      this.db = await openDB<FeedbackDB>('feedback-db', 1, {
        upgrade(db) {
          const store = db.createObjectStore('feedback-table');
          store.createIndex('key', ['itemid', 'feedbackid'], { unique: false });
        },
      });
    }
  }


  async getDBValue(itemid, feedbackid) {
    if (!itemid) debugger;
    await this.initIdb();
    let row = await this.db.get("feedback-table", [itemid, feedbackid ?? -1]);
    return row?.comment;
  }

  async deleteDBValue(itemid, feedbackid) {
    await this.initIdb();
    await this.db.delete("feedback-table", [itemid, feedbackid ?? -1]);
  }

  async setDBValue(itemid, feedbackid, value) {
    await this.initIdb();
    await this.db.put('feedback-table', {
      comment: value,
    }, [itemid, feedbackid ?? -1]);
  }

  async getFeedbackTypes(): Promise<Array<feedback_types>> {
    return new Promise((resolve, reject) => {
      if (!this.types && !this.inProgress) {
        this.inProgress = true;
        this.gql.feedbackTypes().subscribe({
          next: (res) => {
            this.inProgress = false;
            resolve(res.data.feedbackTypes)
          },
          error: (err) => reject(err)
        })
      } else {
        resolve(this.types);
      }
    });
  }

  initSubType(typeId: number) {
    this.filteredSubTypes = null;
    let types = this.types.filter(t => t.id == typeId);
    if (types?.length && types[0].feedback_sub_types?.length) {
      this.filteredSubTypes = types[0].feedback_sub_types;
    }
  }

}
