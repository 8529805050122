import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserQueryData } from '../user';
import { Observable } from 'rxjs';
import { GraphqlService } from '../../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable()
export class UserProfileResolve  {

    constructor(private gql: GraphqlService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (!route.params['id']) return null;
        return this.gql.user(parseInt(route.params['id'])) as Observable<ApolloQueryResult<UserQueryData>>;
    }
}
