import { Injectable } from '@angular/core';
import { RetailerSubCategory } from 'app/shared/retailers';
import { GraphqlService } from 'app/communication/graphql.service';
import { UtilsService } from 'app/shared/utils.service';

@Injectable({
  providedIn: 'root'
})
export class RetailerCategoriesService {
  private cachedSubCategories: Array<RetailerSubCategory>;
  constructor(
    private gql: GraphqlService,
    private utils: UtilsService
  ) { }

  async getSubCategories(force = false): Promise<Array<RetailerSubCategory>> {
    return new Promise((resolve, reject) => {
      if (this.cachedSubCategories && !force) {
        resolve(this.utils.deepCopyByValue(this.cachedSubCategories));
      }
      else {
        this.gql.retailersCategories().subscribe(res => {
          this.cachedSubCategories = this.utils.deepCopyByValue(res.data.retailersCategories.retailers_sub_categories);
          resolve(this.utils.deepCopyByValue(this.cachedSubCategories));
        }, reject);
      }
    });
  }
}
