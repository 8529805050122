import { Component, OnInit, OnDestroy } from '@angular/core';
import { User, ChatHistory, ArtistSUserChat } from '../../auth/user';
import { AuthService } from '../../auth/auth.service';
import { OpenUsersPipe } from '../open-users.pipe';
import { ChatService } from '../chat.service';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { SortOptions } from '../../shared/key-value-pure.pipe';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [OpenUsersPipe]
})
export class ChatComponent implements OnInit, OnDestroy {
  public isSidebarOpen: boolean;
  public sortOptions: SortOptions;
  private onPopstateSub: Subscription;
  private lastTS: number;
  public groupMode: boolean;
  constructor(
    // private websocket: WebsocketService,
    public auth: AuthService,
    public chatService: ChatService,
    private broadcaster: BroadcasterService,
    private rolesHelper: RolesHelperService,
    private utils: UtilsService
  ) {
    this.chatService.onSidebarToggle.subscribe((state: boolean) => {
      this.isSidebarOpen = state;
    });
    this.isSidebarOpen = this.chatService.getSidebarState();
    this.sortOptions = {
      sortBy: 'updated_at',
      useGetTime: true,
      // sortFunction: this.chatService.sortFunction.bind(this.chatService)
    };
    this.lastTS = new Date().getTime();
  }

  ngOnInit() {
    this.subscribe();
  }

  private subscribe() {
    this.onPopstateSub = this.broadcaster.on('popstate').subscribe((e: any) => {
      if (this.isSidebarOpen) {
        // e.preventDefault();
        history.pushState(null, null, window.location.pathname);
        history.pushState(null, null, window.location.pathname);
        this.chatService.toggleSidebar(false);
      }
    });
  }

  private unsubscribe() {
    this.onPopstateSub.unsubscribe();
  }

  toggleMessages() {
    this.chatService.toggleSidebar(!this.chatService.getSidebarState());
  }

  openChat(history: ChatHistory) {
    // this.chatService.openChat(history, false);
    this.chatService.openChat(history, this.chatService.fullscreenMode, false);
  }

  onHistoryChange(newVal: ChatHistory) {
    this.chatService.historyDictionary[newVal.id] = newVal;
    this.chatService.render();
  }

  getUserHistory(uid: number, maxchatUsers?: number): Array<ArtistSUserChat> {
    return this.chatService.getUserHistory(uid, maxchatUsers);
  }

  onUserChange(user: User) {
    // this.chatService.openChatByUser(user, false, false, this.supportMode ? 'Support' : null);
    this.chatService.openChatByUser(user, false, false);
  }

  supportRequest() {
    this.chatService.supportRequest();
  }

  isAdminLogedin(): boolean {
    return this.rolesHelper.isAdminLogedin();
  }

  onScroll(evt: any) {
    let maxPercentage = 0.8;
    let total = evt.target.scrollHeight;
    let current = evt.target.scrollTop;
    let items = evt.target.querySelectorAll('.item');
    if (items.length) {
      current = items[items.length - 1].getBoundingClientRect().top;
    }
    let position = current / total;
    if (position < maxPercentage) {
      const now = new Date().getTime();
      if (this.utils.isAboveTS(now, this.lastTS, 2000)) {
        this.chatService.fetch();
        this.lastTS = now;
      }
    }
  }

  onMenuSelect(e: any, refresh?: boolean) {
    if (e && e.stopImmediatePropagation)
      e.stopImmediatePropagation();
    if (refresh) {
      setTimeout(this.chatService.refreshAndFetch.bind(this.chatService));
      // this.chatService.refreshAndFetch();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
