export enum Environment {
  PRODUCTION = 1,
  QA = 2,
  DEV = 3,
}

export enum EndPoints {
  CMS = 'cms',
  GRAPH = 'graph',
  NOTIF_WEBSOCKETS = 'notificationsWebsocket',
  UPLOAD = 'upload',
  THREE_JS_VIEWER = 'threejsviewer',
  CREATORS = 'creators',
  RETAILERS = 'retailers',
  WEBSOCKET = 'websocket',
  COLLECTOR = 'collector',
  CDN = 'cdn',
  // PUBLIC_CDN = 'publicCdn'
}
