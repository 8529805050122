import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exclude'
})
export class ExcludePipe implements PipeTransform {

  transform(arr: Array<any>, field: string, exclude: any): any {
    let res = [];
    for (let key in arr) {
      if (arr[key][field] != exclude)
        res.push(arr[key]);
      else {
        
      }
    }
    return res;
  }

}
