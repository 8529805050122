import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { OfferCreationService } from '../offer-creation-tool.service';
import { IComplexity, MAX_NUMBER_OF_COMPLEXITIES, IProductComplexity, IComplexityImage, IComplexityGuide, IComplexitySelection } from '../offer-creation-tool.interface';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { OfferCreationEnlargeImage } from '../offer-creation-enlarge-image/offer-creation-enlarge-image.component';
import { GraphqlService } from 'app/communication/graphql.service';
import { UtilsService } from 'app/shared/utils.service';
import { PixelsService } from '../../../shared/pixels.service';
import { GEOMETRY_HOURLY_PRICE } from 'app/shared/enums';

@Component(
  {
    selector: 'app-complexity-level',
    templateUrl: 'complexity-level.component.html',
    styleUrls: ['complexity-level.component.scss']
  }
)
export class ComplexityLevelComponent implements OnInit, OnDestroy {
  private subcategoryIdSubscription: Subscription;
  public complexityTable: IComplexity[];
  public selectedItem: IComplexityImage;
  public selectedRow: number;
  public complexityGuide: IComplexityGuide;
  @Input('ignore-offer-creation') ioc: boolean;
  @Input('product-id') productId: number;
  @Input('sub-category-id') subCategoryId: number;
  @Output() selected: EventEmitter<IComplexitySelection> = new EventEmitter<IComplexitySelection>();
  @Output('on-price') onPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output('on-complexity') onComplexity: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private offerCreationService: OfferCreationService,
    private dialog: MatDialog,
    private gql: GraphqlService,
    private utils: UtilsService,
    private pixelsService: PixelsService
  ) {
    this.subcategoryIdSubscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subcategoryIdSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.ioc)
      this.createComplexitiesTable(this.subCategoryId)
    else
      this.subcategoryIdSubscription.add(this.offerCreationService.subCategoryId$.pipe(filter((res) => res !== -1)).subscribe((subcategoryId: number) => this.createComplexitiesTable(subcategoryId)));
    this.pixelsService.sendPixel({
      event: 'pageView',
      product_id: this.productId || this.offerCreationService.selectedProduct.id
    });
  }

  /**
   * Create the table of complexities with the given number of rows
   */
  private async createComplexitiesTable(subcategoryId: number): Promise<void> {
    if (subcategoryId) {
        const alq = this.gql.subCategoriesComplexityGuides({ sub_category_id: [subcategoryId] });
        const subCategoriesComplexityGuides = (await this.utils.observableToPromise(alq)).data.subCategoriesComplexityGuides;
        if (subCategoriesComplexityGuides[0])
            this.complexityGuide = this.utils.deepCopyByValue((subCategoriesComplexityGuides[0].products_complexity_guides[0]));
        else
            this.complexityGuide = null;
        this.complexityTable = [];
        if (this.complexityGuide) {
            const complexities = this.complexityGuide.products_complexity_sets;
            this.createEmptyComplexitiesTable();
            if (complexities.length > 0) {
            this.setComplexities(complexities);
            }
        }
    }
    else {
      this.complexityGuide = null;
      this.complexityTable = [];
    }
  }

  /**
   * Create empty complexities table
   */
  private createEmptyComplexitiesTable(): void {
    this.complexityTable = [];
    for (let i = 0; i < MAX_NUMBER_OF_COMPLEXITIES; i++) {
      this.complexityTable[i] = {};
    }
  }

  /**
   * Set existing complexities to the complexities table
   * @param complexities
   */
  private setComplexities(complexities: IProductComplexity[]): void {
    complexities.forEach((complexity: IProductComplexity) => {
      this.complexityTable[complexity.complexity_level - 1] = {
        id: complexity.id,
        imgArray: complexity.products_complexity_sets_images
      }
      this.complexityTable[complexity.complexity_level - 1].imgArray.forEach(i => i.price = complexity.price);
    });
  }

  /**
   * Open enlarge image dialog
   * @param row
   * @param column
   */
  public openImage(row: number, column: number): void {
    const img = this.complexityTable[row].imgArray[column];
    const item = {
      url: img.image,
      label: img.label
    }
    this.dialog.open(
      OfferCreationEnlargeImage,
      {
        data: {
          item
        }
      });
  }

  public chooseItem(item: IComplexityImage, complexityIndex: number) {
    // Delay chooseItem till after chooseRow
    setTimeout(() => {
      this.selectedItem = item;
      this.setComplexity(complexityIndex);
      this.selected.emit({ image: item, guide: this.complexityGuide });
    });
  }

  public chooseRow(i: number, c: IComplexity) {
    const complexityPrice = c.imgArray[0].price;
    this.onPrice.next(complexityPrice);
    if (!this.ioc)
      this.offerCreationService.complexityPrice = complexityPrice;
    this.selectedRow = i;
    this.setComplexity(i + 1);
  }

  public setComplexity(complexityIndex: number) {
    this.onComplexity.next(complexityIndex);
    if (!this.ioc) {
      this.offerCreationService.complexity = complexityIndex;
      this.offerCreationService.setDateAndPrice();
      this.offerCreationService.enableComplexityNextButton$.next(true);
    }
  }

  getCalcPrice(complexity: IComplexity) {
    if (complexity?.imgArray && complexity.imgArray[0]?.price)
      return GEOMETRY_HOURLY_PRICE * complexity.imgArray[0].price;
    return 0;
  }
}
