import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaOptions } from './seo';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private properties: Array<HTMLMetaElement>;
  constructor(
    private meta: Meta,
    private titleService: Title
  ) {
    this.properties = [];
  }

  removeAllProperties() {
    try {
      this.properties.forEach(p => p.parentElement.removeChild(p));
    }
    catch (e) { }
    this.properties = [];
  }

  setMetaDate(options: MetaOptions) {
    this.removeAllProperties();
    if (!options.og_url)
      options.og_url = options.canonical;
    this.meta.updateTag({ name: 'description', content: options.description });
    this.meta.updateTag({ name: 'keywords', content: options.keywords });
    this.meta.updateTag({ name: 'canonical', content: options.canonical });
    this.meta.updateTag({ property: 'og:image', content: options.og_image });
    this.meta.updateTag({ property: 'og:title', content: options.og_title });
    this.meta.updateTag({ property: 'og:type', content: options.og_type });
    this.meta.updateTag({ property: 'og:url', content: options.og_url });
    if (window && window.document) {
      let ogImage = document.querySelector('meta[property="og:image"]');
      let ogTitle = document.querySelector('meta[property="og:title"]');
      let ogType = document.querySelector('meta[property="og:type"]');
      let ogUrl = document.querySelector('meta[property="og:url"]');
      if (ogImage)
        ogImage.setAttribute('content', options.og_image);
      if (ogTitle)
        ogTitle.setAttribute('content', options.og_title);
      if (ogType)
        ogType.setAttribute('content', options.og_type);
      if (ogUrl)
        ogUrl.setAttribute('content', options.og_url);
    }
    this.titleService.setTitle(options.title)
    options.properties.forEach(p => {
      this.properties.push(this.meta.addTag({
        property: p.key,
        content: p.value
      }));
    })
  }
}
