<div *ngIf="value" class="poly-specs" [class.hover]="hoverEffect">

  <div class="poly-spec-row">
    <mat-form-field class="w-100">
      <mat-label>Insert variation name</mat-label>
      <input matInput [(ngModel)]="value.variation_name" name="variation_name"
        (change)="onValueChange()">
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label>Serial number</mat-label>
      <input matInput [(ngModel)]="value.serial_number" name="serial_number"
        (change)="onValueChange()">
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label>Job type</mat-label>
      <mat-select [(ngModel)]="value.job_type" required>
        <mat-option [value]="JobType.GEOMETRY">
          Geometry
        </mat-option>
        <mat-option [value]="JobType.TEXTURE">
          Texture
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="poly-spec-row">
    <mat-form-field class="w-100">
      <mat-label>Poly type</mat-label>
      <mat-select [(ngModel)]="value.poly_type" class="uppercase" (selectionChange)="onValueChange()" required>
        <mat-option *ngFor="let t of polyTypes" [value]="t.key" class="uppercase">
          {{t.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label>Poly Shape Type</mat-label>
      <mat-select [(ngModel)]="value.poly_shape_type" class="uppercase" (selectionChange)="onValueChange()" required>
        <mat-option *ngFor="let t of polyShapeTypes" [value]="t.key" class="uppercase">
          {{t.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="value.poly_shape_type === '' && value.poly_shape_type.pristine">
        Required Poly Shape Type
      </mat-error>
    </mat-form-field>

   <div class="min-max-poly disp-block">

        <mat-form-field class="w-45 float-dir">
          <mat-label>Min polygons</mat-label>
          <input matInput type="number" [(ngModel)]="value.min_poly_count" required
            name="min_poly_count" (change)="onValueChange()" [disabled]="disabledPolyCount">
        </mat-form-field>

        <mat-form-field class="w-45 float-op-dir">
          <mat-label>Max polygons</mat-label>
          <input matInput type="number" [(ngModel)]="value.max_poly_count" required
            name="max_poly_count" (change)="onValueChange()" [disabled]="disabledPolyCount">
        </mat-form-field>
     </div>
   
  </div>

  <div class="disp-flex flex-row flex-end buttons-container">
    <div *ngIf="withDuplicate" class="disp-flex flex-row gap-4 capitalize flex-align-center pointer"
      (click)="onDuplicate(value)">
      <div class="duplicate icon"></div>
      <div>duplicate</div>
    </div>
    <div *ngIf="withDelete" class="disp-flex flex-row gap-4 capitalize flex-align-center pointer"
      (click)="onDelete(value)">
      <div class="delete icon"></div>
      <div>delete</div>
    </div>
  </div>
</div>
