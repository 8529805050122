export interface KeyValuePair {
    key: any;
    value: string;
}

export interface KeyValueAnyPair {
    key: any;
    value: any;
}

export enum InputFileType {
    ZIP = 1,
    MP4 = 2,
    PNG = 3,
    JPEG = 4,
    GLB = 5,
    USDZ = 6,
    TIFF = 7,
}

export enum FormatsType {
    OBJ = 1,
    FBX = 2,
    DAE = 4,
    BLEND = 5,
    glTF = 6,
    GLB = 7,
    USDZ = 8,
    PNG = 9,
    JPG = 10,
    MP4 = 11,
    TIFF = 12,
}

export enum CanvasPaintState {
    NOOP = 0,
    DRAW = 1,
    LINE = 2,
    WRITE = 3,
    VERTICAL_LINE = 4,
    HORIZONTAL_LINE = 5,
}

export interface Coordinates3D {
    x: number;
    y: number;
    z: number;
}

export enum PaymentSystem {
    UP_WORK = 1,
    PAY_PAL = 2,
    PAYONEER = 3,
}

export enum JobsTypes {
    GEOMETRY = 1,
    TEXTURE = 2,
    FIX = 3,
    RENDR = 4,
}

export enum AdjustmentsMode {
    NORMAL = 1,
    FULLSCREEN = 2,
    UPLOAD = 3,
}

export enum Priority {
    LOWEST = 1,
    LOW = 2,
    MEDIUM = 3,
    HIGH = 4,
    HIGHEST = 5,
}

export interface FilterOptions {
    limit: number;
    offset: number;
}

export enum QaModes {
    BOTH = 1,
    FM_ONLY = 2,
    QS_ONLY = 3,
}

export enum GeometryType {
    HardSurface = 1,
    Organic = 2,
    Mixture = 3
}

export enum ColorPlatte {
  PRIMARY_COLOR = '#57059c',
  DEFAULT_COLOR = '#d8d8d8',
  THEME_COLOR = '#7768a2'
}

export enum ArtistType {
  LOW_VOLUME = 1,
  HIGH_VOLUME = 2,
  STUDIO = 3
}

export enum OfferJobMode {
    offer = 1,
    job = 2
}
// export interface SimpleCarouselSliderItem {
//     src: string;
//     title?: string;
//     canDownload: boolean;
// }

export const ATTACHMENT_ICON = 'https://cdn.creators3d.com/site/attachment-24px.svg';
// export const ICON_3D = 'https://img-cdn.azureedge.net/hotlink-ok/viewer/3d_64.png'
// export const ICON_3D_WITH_SOURCE = 'https://img-cdn.azureedge.net/hotlink-ok/viewer/3d_with_source.png'
export const ICON_3D = 'https://himg-cdn.com/hotlink-ok/viewer/3d_64.png'
export const ICON_3D_WITH_SOURCE = 'https://himg-cdn.com/hotlink-ok/viewer/3d_with_source.png'
export const LIGHTNESS_THRESHOLD = 8.6678;
export const AB_THRESHOLD = 4.434;
export const GEOMETRY_HOURLY_PRICE = 1.7;
export const CALC_HOURS_TO_COMNPLETE = true;

export enum FeedbackType {
    shape_doesnt_match = 1,
    colors_materials = 2,
    lighting_shadow = 4,
    camera_positioning = 5,
    others = 3,
}


export enum BrowserPermission {
    PROMPT = 1,
    DENIED = 2,
    GRANTED = 3,
}

export interface IIdleDetector {
    userState: string;
    screenState: string;
}