import { Injectable } from '@angular/core';
import { User } from '../auth/user';
import { UtilsService } from './utils.service';
// import { CategoriesService } from 'app/categories/categories.service';

@Injectable({
  providedIn: 'root'
})
export class PricingHelperService {
  // static MAX_RANK = 5;
  static TIME_BONUS_PERCENTAGE = 0.1;
  static PUNCTUALITY_PERCENTAGE = 0.8;
  static RANK_BONUS_PERCENTAGE = 0.1;
  static MIN_BONUS_RANK = 4;
  constructor(
    private utils: UtilsService
    // ,
    // private categoriesService: CategoriesService
  ) { }

  // returning the bonus of the user
  // getRankBonusByUser(bonus: number, user: User, categoryId: number, fromRank): number {
  //   if (!bonus) return 0;
  //   if (!user || !user.artists_users_categories_rankings) return 0;
  //   const cat = user.artists_users_categories_rankings.find(c => c.parent_category_id == categoryId);
  //   if (!cat) return 0;
  //   const rank = Math.floor(cat.rank);
  //   if (rank <= fromRank)
  //     // return bonus;
  //     return 0;
  //   return (rank - fromRank) * bonus;
  // }

  // getRankBonusByUser(basePrice: number, user: User, categoryId: number, fromRank = PricingHelperService.MIN_BONUS_RANK, allow: boolean): number {
  getRankBonusByUser(basePrice: number, user: User, fromRank = PricingHelperService.MIN_BONUS_RANK, allow: boolean, isGeometry: boolean, isTexture: boolean): number {
    if (!basePrice || !allow) return 0;
    if (!user) return 0;
    // if (!user || !user.artists_users_categories_rankings) return 0;
    // const parentCat = this.categoriesService.parentCategoriesToParentDictionary[categoryId]
    // if (!parentCat) {
    //   // console.warn('can\'t find parent category for category ID: ' + categoryId);
    //   return 0;
    // }
    // const cat = user.artists_users_categories_rankings.find(c => c.parent_category_id == parentCat.parent_id);
    // if (!cat) return 0;
    // if (cat.rank < fromRank || typeof cat.rank !== 'number')
    //   return 0;
    if (fromRank === null)
      fromRank = PricingHelperService.MIN_BONUS_RANK;
    let rank = user.rank;
    if (rank < fromRank || typeof rank !== 'number')
      return 0;
    return basePrice * PricingHelperService.RANK_BONUS_PERCENTAGE;
  }

  // returning the number of ranks that this user has above the mandatory rank
  // getMultiplierBonusByUser(user: User, categoryId: number, fromRank: number): number {
  //   if (!user || !user.artists_users_categories_rankings) return 0;
  //   const cat = user.artists_users_categories_rankings.find(c => c.parent_category_id == categoryId);
  //   if (!cat) return 0;
  //   const rank = Math.floor(cat.rank);
  //   if (rank <= fromRank)
  //     return 0;
  //   return rank - fromRank;
  // }

  // get time bonus for punctuality if earned
  // getTimeCurrentBonus(timeBonus: number, dueDate: Date): number {
  //   if (!timeBonus) return 0;
  //   const ddate = this.utils.getSafeDate(dueDate);
  //   if (!ddate) return 0;
  //   const end = ddate.getTime();
  //   if (isNaN(end)) return 0;
  //   const timespan = new Date().getTime() - end;
  //   return timespan < 0 ? timeBonus : 0;
  // }

  getTimeBonusExpirationDate(startDate: Date, dueDate: Date): Date {
    const now = new Date();
    const ddate = this.utils.getSafeDate(dueDate);
    if (!ddate) return now;
    const end = ddate.getTime();
    if (isNaN(end)) return now;
    const sdate = this.utils.getSafeDate(startDate);
    if (!sdate) return now;
    const start = sdate.getTime();
    if (isNaN(start)) return now;
    const maxTimespan = end - start;
    return new Date(start + (maxTimespan * PricingHelperService.PUNCTUALITY_PERCENTAGE));
  }

  getTimeCurrentBonus(basePrice: number, startDate: Date, dueDate: Date, allow: boolean): number {
    if (!allow) return 0;
    const ddate = this.utils.getSafeDate(dueDate);
    if (!ddate) return 0;
    const end = ddate.getTime();
    if (isNaN(end)) return 0;

    const sdate = this.utils.getSafeDate(startDate);
    if (!sdate) return 0;
    const start = sdate.getTime();
    if (isNaN(start)) return 0;

    // the maximum time (in milliseconds) until the Job iswill timeout
    const maxTimespan = end - start;

    // the curren time (in milliseconds) from the creation of the Job until not
    const currentTimespan = new Date().getTime() - start;

    if (currentTimespan / maxTimespan < PricingHelperService.PUNCTUALITY_PERCENTAGE)
      return basePrice * PricingHelperService.TIME_BONUS_PERCENTAGE;
    return 0;
  }

  // get the maximum amount of bonus an Artist can get for this item
  // getMaxBonus(itemPricing: ItemPricing, fromRank: number): number {
  //   let total = itemPricing.time_bonus || 0;
  //   let multiplier = Math.abs(fromRank - PricingHelperService.MAX_RANK);
  //   total += multiplier * itemPricing.rank_bonus;
  //   return total;
  // }
  getMaxBonus(base_price: number, allowTimeBonus: boolean, allowRankBonus: boolean): number {
    return (allowTimeBonus ? base_price * PricingHelperService.TIME_BONUS_PERCENTAGE : 0) +
      (allowRankBonus ? base_price * PricingHelperService.RANK_BONUS_PERCENTAGE : 0);
  }

  // returns the current calculated price of an item for the current time
  // getCurrentBonus(user: User, categoryId: number, itemPricing: ItemPricing, fromRank: number, dueDate: Date): number {
  //   return (this.getMultiplierBonusByUser(user, categoryId, fromRank) * itemPricing.rank_bonus) +
  //     this.getTimeCurrentBonus(itemPricing.time_bonus, dueDate);
  // }
  // getCurrentBonus(user: User, categoryId: number, basePrice: number, startDate: Date, dueDate: Date, fromRank = PricingHelperService.MIN_BONUS_RANK, allowTimeBonus: boolean, allowRankBonus: boolean): number {
  getCurrentBonus(user: User, basePrice: number, startDate: Date, dueDate: Date, fromRank = PricingHelperService.MIN_BONUS_RANK, allowTimeBonus: boolean, allowRankBonus: boolean, isGeometry: boolean, isTexture: boolean): number {
    // return this.getTimeCurrentBonus(basePrice, startDate, dueDate, allowTimeBonus) + this.getRankBonusByUser(basePrice, user, categoryId, fromRank, allowRankBonus);
    return this.getTimeCurrentBonus(basePrice, startDate, dueDate, allowTimeBonus) + this.getRankBonusByUser(basePrice, user, fromRank, allowRankBonus, isGeometry, isTexture);
  }

  // returns the current calculated price of an item for the current time
  // getCurrentTotal(user: User, categoryId: number, itemPricing: ItemPricing, fromRank: number, dueDate: Date): number {
  //   return itemPricing.base_price +
  //     (this.getMultiplierBonusByUser(user, categoryId, fromRank) * itemPricing.rank_bonus) +
  //     this.getTimeCurrentBonus(itemPricing.time_bonus, dueDate);
  // }
  getCurrentTotal(basePrice: number, user: User, startDate: Date, dueDate: Date, fromRank = PricingHelperService.MIN_BONUS_RANK, allowTimeBonus: boolean, allowRankBonus: boolean, isGeometry: boolean, isTexture: boolean): number {
    // return basePrice + this.getCurrentBonus(user, categoryId, basePrice, startDate, dueDate, fromRank, allowTimeBonus, allowRankBonus);
    return basePrice + this.getCurrentBonus(user, basePrice, startDate, dueDate, fromRank, allowTimeBonus, allowRankBonus, isGeometry, isTexture);
  }

  getEndDate(hoursToComplete: number, startDate: Date): Date {
    const ddate = this.utils.getSafeDate(startDate);
    return new Date(ddate.getTime() + this.utils.getMSFromHours(hoursToComplete));
  }

  getTimeBonusDesc(): string {
    return `If a Job delivered before it's due-date with at least ${100 - (PricingHelperService.PUNCTUALITY_PERCENTAGE * 100)}% of the time to spare`
  }

  getRankBonusDesc(): string {
    return `If an Artist has rank of at least ${PricingHelperService.MIN_BONUS_RANK} stars`
  }

  getTimeBonusPercentage() {
    return PricingHelperService.TIME_BONUS_PERCENTAGE * 100;
  }

  getRankBonusPercentage() {
    return PricingHelperService.RANK_BONUS_PERCENTAGE * 100;
  }
}
