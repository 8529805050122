<div (click)="specClick()" #wrap>
  <app-text-editor *ngIf="spec.title" [click-img]="true" [disable]="true" [init-value]="spec.title">
  </app-text-editor>
  <app-text-editor *ngIf="spec.text" [click-img]="true" [disable]="true" [init-value]="spec.text">
  </app-text-editor>
  <div *ngIf="spec.isImage" class="img-wrap">
    <img class="max-w-100" [src]="spec.attachment" alt="specification image">
  </div>
  <ng-container *ngIf="!spec.isImage && spec.attachment">
    <img class="pointer" [src]="ATTACHMENT_ICON" alt="specification file">
  </ng-container>
</div>
