import { Injectable } from '@angular/core';
import { BrowserPermission } from './enums';
import { BroadcasterService } from 'ng-broadcaster';
import { RolesHelperService } from 'app/auth/roles-helper.service';

declare var IdleDetector;

@Injectable({
  providedIn: 'root'
})
export class IdleDetectionService {
  static INTERVAL = 900_000; // 15 minutes;
  static BROADCAST_MSG = 'idleChange';
  private controller: AbortController;
  private signal: AbortSignal;
  private isActive: boolean;
  private handleIdleBind: any;

  constructor(
    private broadcaster: BroadcasterService,
    private rolesHelper: RolesHelperService
  ) { }

  isSupported(): boolean {
    return typeof IdleDetector !== 'undefined';
  }

  async requestPermission(): Promise<BrowserPermission> {
    let p = 'denied';
    if (this.isSupported()) {
      try {
        p = await IdleDetector.requestPermission()
      } catch(e) {}
    }
    switch (p) {
      case 'prompt': {
        return BrowserPermission.PROMPT;
      }
      case 'denied': {
        return BrowserPermission.DENIED;
      }
      case 'granted': {
        return BrowserPermission.GRANTED;
      }
    }
  }

  shouldWeListen() {
    return this.rolesHelper.isFeedbackMaster() || this.rolesHelper.isQualitySupervisorLogedin();
  }

  private afterClick() {
    document.body.removeEventListener('click', this.handleIdleBind, false);
    this.listen();
  }

  async listenAfterClick() {
    this.handleIdleBind = this.afterClick.bind(this);
    document.body.addEventListener('click', this.handleIdleBind, false);
    
  }

  async listen() {
    if (!this.isSupported()) return;
    if (this.isActive) return;
    this.controller = new AbortController();
    this.signal = this.controller.signal;
    const p = await this.requestPermission();
    if (p !== BrowserPermission.GRANTED) {
      console.warn("Idle detection permission denied.");
      return;
    }
  
    try {
      const idleDetector = new IdleDetector();
      idleDetector.addEventListener("change", () => this.broadcaster.broadcast(IdleDetectionService.BROADCAST_MSG, idleDetector));
  
      await idleDetector.start({
        threshold: IdleDetectionService.INTERVAL,
        signal: this.signal
      });
      this.isActive = true;
    } catch (err) {
      // Deal with initialization errors like permission denied,
      // running outside of top-level frame, etc.
      console.error(err.name, err.message);
    }
  }
}
