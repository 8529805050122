import { Resource } from "./resource";

export interface ResourcePolygonReduction {
    resource_id: number;
    artist_user_id: number;
    action: string;
    target_poly_scale: number; // or PolyShapeType
    save_mode: boolean;
    // constructor() {
    //     this.action = ResourcePolygonReductionActions[ResourcePolygonReductionActions.change_poly_scale];
    // }
}

export enum ResourcePolygonReductionActions {
    change_poly_type = 1,
    fix = 2,
    add_watermark = 3,
    change_poly_scale = 4,
}

export interface ResourcePolygon extends Resource {
    glbMB?: number;
}