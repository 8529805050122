import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadWizardOptions } from '../upload-wizard/upload-wizard';

@Component({
  selector: 'app-upload-wizard-wrap',
  templateUrl: './upload-wizard-wrap.component.html',
  styleUrls: ['./upload-wizard-wrap.component.scss']
})
export class UploadWizardWrapComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UploadWizardWrapComponent>,
    @Inject(MAT_DIALOG_DATA) public options: UploadWizardOptions
  ) { }

  ngOnInit() {
  }

}
