export enum SampleStatus {
  success,
  failed,
  none
}

export type SampleStatusText = { [key in SampleStatus]: string };

export const SAMPLE_STATUS_TEXT: SampleStatusText = {
  [SampleStatus.success]: 'Success!',
  [SampleStatus.failed]: 'Sample Failed',
  [SampleStatus.none]: 'Sample results...'
}
