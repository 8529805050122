import { ElementRef, Injectable } from '@angular/core';
import { ArtistsResourcesFeedback, Resource, MediaTag, ArtistsJobsResourcesGeometryAccuracyUI } from './resource';
import { Job, ArtistJobItem, ArtistsJobsType, ArtistsItemSpecUI, JobQueryData } from './job';
import { UtilsService } from 'app/shared/utils.service';
import { GraphqlService } from 'app/communication/graphql.service';
import { Notification, NotificationType } from 'app/shared/notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { NativeResourceSet } from 'app/offer/offers';
import { FeedbackService } from './feedback.service';
import { JobService } from './job.service';
import { RestService } from 'app/communication/rest.service';
import { PixelsService } from 'app/shared/pixels.service';
import { RolesHelperService } from 'app/auth/roles-helper.service';
import { AuthService } from 'app/auth/auth.service';
import { UsersFilterOptions } from 'app/auth/user';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FeedbackType, KeyValuePair } from 'app/shared/enums';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { BodyService } from 'app/shared/body.service';
import { CollisionService } from 'app/ui-components/collision.service';

@Injectable({
  providedIn: 'root'
})
export class JobsFeedbacksService {
  static MESSAGE_FOR_SUPPORT = 'This job feedback is handled by another feedback master, you should only provide support for it.';
  public jobs: Array<Job>;
  public currentJob: Job;
  public currentJobFeebacks: Array<ArtistsResourcesFeedback>;
  public currentFeedback: ArtistsResourcesFeedback;
  public currentNewFeedback: ArtistsResourcesFeedback;
  public currentFeedbackForm: FormGroup;
  public feedbackCreationMode: boolean;
  public images: Array<NativeResourceSet>;
  public models: Array<Resource>;
  public currentModelIndex: number;
  public modelsJobItemDictionary: { [id: number]: ArtistJobItem };
  public modelsArtistsJobsTypes: { [id: number]: Array<ArtistsJobsType> };
  public isAdmin: boolean;
  public isSU: boolean;
  public isJobManager: boolean;
  public isNewbie: boolean;
  public sFeedbackBy: string;
  public currentArtistsjobitem: ArtistJobItem;
  // public currentArtistsjobitemId: number;
  public artistsItemsSpecs: Array<ArtistsItemSpecUI>;
  public clientFeedbackId: string;
  private afterNewFeedback: boolean;
  private lastHiddenValue: boolean;
  private transcription: Array<KeyValuePair>;
  private _routeActive: boolean;
  private _jobsVisited: Array<number>;
  private _tests: Array<ArtistsJobsResourcesGeometryAccuracyUI>;
  public artistJobItemNumFeedback: { id: number, number }

  constructor(
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private gql: GraphqlService,
    private broadcaster: BroadcasterService,
    private feedbackService: FeedbackService,
    private jobService: JobService,
    private rest: RestService,
    public pixels: PixelsService,
    private roles: RolesHelperService,
    public auth: AuthService,
    private router: Router,
    private body: BodyService,
    private collisionService: CollisionService
  ) {
    this.modelsJobItemDictionary = {};
    this.modelsArtistsJobsTypes = {};
    this.currentModelIndex = 0;
    this.jobs = [];
    this.currentJobFeebacks = [];
    this.isAdmin = this.roles.isAdminLogedin();
    this.isSU = this.roles.isSULogedin();
    this.isJobManager = this.roles.isJobManager();
    this.images = [];
    this.models = [];
    this._jobsVisited = [];
    this._tests = [];
  }

  get jobsVisited() {
    return this._jobsVisited;
  }

  get routeActive() {
    return this._routeActive;
  }

  set routeActive(value: boolean) {
    this._routeActive = value;
  }

  get urlParams() {
    return location.pathname.split('/');
  }

  get tests() {
    return this._tests;
  }

  canFeedbackJob(): boolean {
    let job = this.jobService.job || this.currentJob;
    let uid = this.auth.user.id;
    return this.isSU || !job.feedbacker_id || job.feedbacker_id === uid || this.roles.isQualitySupervisorLogedin();
    // return this.isSU || this.roles.isQualitySupervisorLogedin() || job.manager_id === uid;
  }

  async showErrorOnFeedbackRestrict() {
    let job = this.jobService.job || this.currentJob;
    let name = ''
    if (job?.feedbacker_id) {
      let u = await this.utils.getUser(job?.feedbacker_id);
      name = u.name;
    } else {
      name = 'you are not'
    }

    let data: Notification = {
      text: `You are unable to feedback this job because ${name} artist is thé feedbacker of this job`,
      type: NotificationType.Error,
      action: 'Ok'
    }
    this.broadcaster.broadcast('notifyUser', data)
  }

  removeCurrentJob() {
    delete this.currentJob;
    delete this.isNewbie;
  }

  updateVideoUrl(url: string, transcription?: string) {
    this.currentFeedbackForm.controls.video_url.setValue(url);
    this.currentFeedbackForm.controls.transcription.setValue(transcription);
    if (this.currentFeedback) {
      this.currentFeedback.video_url = url;
      this.currentFeedback.transcription = transcription;
    }
    else {
      this.currentNewFeedback.video_url = url;
      this.currentNewFeedback.transcription = transcription;
    }
  }

  removeCurrentFeedback() {
    delete this.currentFeedback;
    delete this.sFeedbackBy;
    this.feedbackCreationMode = false;
  }

  setFeedbackForm(feedback, feedbackTypes) {
    this.currentFeedbackForm = this.formBuilder.group({
      feedback_type: [feedback ? feedback.feedback_type : feedbackTypes[0].id, Validators.required],
      feedback_sub_type: [feedback ? feedback.feedback_sub_type : null, feedback?.feedback_type !== FeedbackType.others ? Validators.required : null],
      artist_user_id: feedback ? feedback.artist_user_id : null,
      created_at: feedback ? feedback.created_at : new Date(),
      updated_at: feedback ? feedback.updated_at : new Date(),
      notes: feedback ? feedback.notes : null,
      opened_by: feedback ? feedback.opened_by : null,
      artists_resources_feedback_comments: feedback ? feedback.artists_resources_feedback_comments : null,
      artists_users: feedback ? feedback.artists_users : null,
      id: feedback ? feedback.id : null,
      resource_id: feedback ? feedback.resource_id : null,
      screenshot: [feedback ? feedback.screenshot : null, null],
      title: feedback ? feedback.title : null,
      fixed: feedback ? feedback.fixed : null,
      whitelisted: feedback ? feedback.whitelisted : false,
      video_url: feedback ? feedback.video_url : null,
      transcription: feedback ? feedback.transcription : null,
    });
    this.currentFeedbackForm.get('feedback_sub_type').setValidators(this.feedbackTypeValidator(this.currentFeedbackForm));
  }

  feedbackTypeValidator(form: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const feedbackType = form.get('feedback_type')?.value;
      const needRequire = this.feedbackService.types.some(t => t.id === feedbackType && t.feedback_sub_types.length);
      const noValue = needRequire ? !(control.value) : false
      return noValue ? { required: control.value } : null;
    };
  }

  setCurrentJob(job: Job, getFull = true, ignoreNav = false, callback?) {
    if (!job) return;
    let changedJob = false;
    if (this.currentJob && this.currentJob.id != job.id)
      changedJob = true;
    this.currentJob = this.utils.deepCopyByValue(job, true);
    this._jobsVisited.push(this.currentJob.id);

    if (this.currentJob.manager_id === this.auth.user.id)
      this.jobService.setFmActive([this.currentJob.id], true);

    const clear = () => {
      delete this.currentFeedback;
      delete this.sFeedbackBy;
      this.feedbackCreationMode = false;
    };
    if (getFull) {
      this.getFullJob(this.currentJob.id, callback);
      if (changedJob)
        clear();
    }
    else if (!this.getFidFromUrl()) {
      clear();
    }
    // if (!this.currentJob.uiInit)
    this.jobService.mapUI(this.currentJob, false);
    if (this.currentArtistsjobitem)
      this.currentArtistsjobitem = this.currentJob.artists_jobs_items.find(i => i.id == this.currentArtistsjobitem.id);
    else if (this.currentJob?.artists_jobs_items?.length)
      this.currentArtistsjobitem = this.currentJob.artists_jobs_items[0];
    this.setFeedbaks();
    this.pixels.sendPixel({
      event: "setJobFeedback",
      reference_id: job.id
    });
    this.isNewbie = (this.currentJob.artists_users && this.currentJob.artists_users[0] && this.currentJob.artists_users[0].new_user) ? true : false;
    if (!ignoreNav && this.currentJob.artist_user_id)
      this.navigate(this.currentJob.id, this.currentJob.artist_user_id, this.currentFeedback ? this.currentFeedback.id : null);
  }

  getJidFromUrl() {
    const jid = this.urlParams[3];
    if (jid) return parseInt(jid);
    return null;
  }

  getAidFromUrl() {
    const aid = this.urlParams[4];
    if (aid) return parseInt(aid);
    return null;
  }

  getFidFromUrl() {
    const fid = this.urlParams[5];
    if (fid) return parseInt(fid);
    return null;
  }

  getCidFromUrl() {
    const cid = this.urlParams[6];
    if (cid) return parseInt(cid);
    return null;
  }

  async navigate(jobId: number, artistId: number, feedbackId?: number) {
    let nav = false, fidFromUrl = this.getFidFromUrl(), jidFromUrl = this.getJidFromUrl(), aidFromUrl = this.getAidFromUrl();
    if (jidFromUrl != jobId) {
      this.jobService.deleteCurrent();
      nav = true;
    }
    else if (aidFromUrl != artistId)
      nav = true;
    else if (fidFromUrl != feedbackId)
      nav = true;
    // else if (cidFromUrl != commentId)
    //   nav = true;
    if (!nav) return;
    let url = `/jobs/feedbacks/${jobId}/${artistId}`;
    if (feedbackId) {
      url += `/${feedbackId}`
      // if (commentId)
      //   url += `/${commentId}`;
    }
    this.router.navigateByUrl(url);
    await this.afterNavigate();
  }

  async afterNavigate(jobId = this.currentJob.id) {
    if (!this.jobs.find(j => j.id == jobId)) {
      const p = await this.utils.observableToPromise(this.gql.job(jobId)) as ApolloQueryResult<JobQueryData>;
      if (!this.jobs.find(j => j.id == jobId)) {
        this.jobs.unshift(this.utils.deepCopyByValue(p.data.artists_jobs));
        this.setCurrentJob(this.jobs[0], false);
      }
    }
    let needFix = false;
    if (this.currentFeedback && this.currentFeedback.id != this.getFidFromUrl()) {
      needFix = true;
    }
    else if (this.currentJob && this.currentJob.id != this.getJidFromUrl()) {
      needFix = true;
    }
    if (needFix)
      this.navigate(this.getJidFromUrl(), this.currentJob.artist_user_id, this.getFidFromUrl());
  }

  setFeedbaks() {
    if (!this.currentJob) {
      this.currentJobFeebacks = [];
      return;
    }
    this.currentJobFeebacks = [];
    this.images = [];
    this.models = [];
    this._tests = [];
    // this.currentModelIndex = 0;
    if (this.currentArtistsjobitem && this.isAdmin) {
      this.currentArtistsjobitem.artists_items.forEach(ai => {
        if (ai.artists_items_data) {
          ai.artists_items_data.forEach(aid => {
            this.images.push({
              big: aid.url,
              small: aid.small_image_url,
              type: MediaTag.IMAGE
            });
          });
        }
      });
      if (this.currentArtistsjobitem.artists_jobs_resources) {
        this.currentArtistsjobitem.artists_jobs_resources.forEach(ajr => {
          ajr.artists_resources_feedback.forEach(arf => {
            this.currentJobFeebacks.push(arf);
          });
          this.models.push(ajr);
          this.modelsJobItemDictionary[ajr.id] = this.currentArtistsjobitem;
          this.modelsArtistsJobsTypes[ajr.id] = this.currentJob.artists_jobs_types;
        });
      }
    }
    else {
      let counter = 0;
      this.currentJob.artists_jobs_items.filter((aji) => this.currentArtistsjobitem?aji.id === this.currentArtistsjobitem?.id:true).forEach(aji => {
        if (this.currentJob.same_mesh && counter) {
          // do nothing, do not dulicate for same mesh
        }
        else {
          aji.artists_items.forEach(ai => {
            if (ai.artists_items_data) {
              ai.artists_items_data.forEach(aid => {
                this.images.push({
                  big: aid.url,
                  small: aid.small_image_url,
                  type: MediaTag.IMAGE
                });
              });
            }
          });
        }
        if (aji.artists_jobs_resources) {
          aji.artists_jobs_resources.forEach(ajr => {
            ajr.artists_resources_feedback.forEach(arf => {
              this.currentJobFeebacks.push(arf);
            });
            this.models.push(ajr);
            this.modelsJobItemDictionary[ajr.id] = aji;
            this.modelsArtistsJobsTypes[ajr.id] = this.currentJob.artists_jobs_types;
          });
        }
        counter++;
      });
    }
    this.models.sort((a: Resource, b: Resource) => {
      var c = new Date(a.created_at).getTime();
      var d = new Date(b.created_at).getTime();
      return d - c;
    });
    this.currentJobFeebacks.sort((a: ArtistsResourcesFeedback, b: ArtistsResourcesFeedback) => {
      var c = new Date(a.created_at).getTime();
      var d = new Date(b.created_at).getTime();
      return d - c;
    });
    this.currentJobFeebacks.sort((a: ArtistsResourcesFeedback, b: ArtistsResourcesFeedback) => {
      return a.fixed ? 1 : -1;
    });
    if (!this.images.length || !this.models.length)
      this.feedbackCreationMode = false;
    if (this.afterNewFeedback) {
      this.afterNewFeedback = false;
      this.setCurrentFeedback(this.currentJobFeebacks[0]);
    }
    this.setAccuracyTests();
  }

  setAccuracyTests() {
    if (this.currentArtistsjobitem) {
      this.jobService.currentArtistsjobitem = this.currentArtistsjobitem;
      this.jobService.currentModelIndex = this.currentModelIndex;
      this._tests = this.collisionService.getExistTests();
    }
  }

  getFullJob(jobId: number, callback?) {
    this.gql.job(jobId).subscribe({
      next: res => {
        this.setCurrentJob(res.data.artists_jobs, false, false, callback);
        if (callback) {
          callback();
        }
      },
      error: err => this.utils.httpErrorResponseHandler(err, 'failure getting job')
    });
  }

  setCurrentComment(cid: number) {
    const elem = document.querySelector(`app-feedback-view #comment-${cid}`);
    if (elem)
      this.body.scrollToElement(new ElementRef(elem));
  }

  setCurrentFeedback(f: ArtistsResourcesFeedback) {
    // let changeFeedback = false;
    // if (this.currentFeedback && this.currentFeedback.id != f.id)
    //   changeFeedback = true;
    this.currentFeedback = f;
    delete this.sFeedbackBy;
    this.feedbackCreationMode = false;
    this.lastHiddenValue = this.currentFeedback.hidden;
    if (this.currentJob && this.currentFeedback && this.currentJob.artist_user_id == this.auth.user.id) {
      this.rest.jobCountdown('post', {
        job_id: this.currentJob.id,
        feedback_id: this.currentFeedback.id
      }).subscribe();
    }
    if (this.isSU) {
      this.gql.jobAudit(this.currentJob.id).subscribe(
        log => {
          const arr = log.data.artists_jobs_audit.filter(a => a.field_name == 'status' && a.old_value == "Pending Secondary Review");
          if (arr.length) {
            let options = {
              id: [arr[arr.length - 1].user_id],
              limit: 1,
              offset: 0,
              show_all: true
            } as UsersFilterOptions;
            if (options.id[0])
              this.gql.users(options).subscribe(
                obj => this.sFeedbackBy = obj.data.allArtistsUsers?.rows[0]?.name
              );
          }
        }
      )
    }
    if (this.currentJob.artist_user_id)
      this.navigate(this.currentJob.id, this.currentJob.artist_user_id, this.currentFeedback.id);
  }

  addFeedback() {
    this.currentNewFeedback = {} as ArtistsResourcesFeedback;
    this.feedbackCreationMode = true;
    delete this.currentFeedback;
    this.prepareForFeedback();
  }

  prepareForFeedback() {
    this.clientFeedbackId = this.utils.generateUUID();
    this.pixels.sendPixel({
      event: 'new_feedback',
      job_id: this.currentJob?.id,
      client_feedback_id: this.clientFeedbackId
    });
  }

  isNewFeedbackValid() {
    return this.feedbackService.isFeedbackTypeValid(this.currentNewFeedback) &&
      this.feedbackService.isFeedbackSubTypeValid(this.currentNewFeedback)
      && this.feedbackService.isScreenshotValid(this.currentNewFeedback);
  }

  // getNotesWithTranscription(notes: string, transcription: Array<KeyValuePair>) {
  //   let div = this.utils.textToHTML(notes);
  //   let hidden = div.querySelector(`.${JobsFeedbacksService.TRANSCRIPTION}`);
  //   if (hidden)
  //     hidden.parentElement.removeChild(hidden);
  //   hidden = document.createElement('script');
  //   hidden.setAttribute('type', 'application/json');
  //   hidden.classList.add('disp-none', JobsFeedbacksService.TRANSCRIPTION);
  //   hidden.textContent = JSON.stringify(transcription);
  //   div.appendChild(hidden);
  //   return div.innerHTML;
  // }


  async saveNewFeedback() {
    if (!this.isNewFeedbackValid()) return;
    this.jobService.saveNewFeedback(this.currentNewFeedback, this.models[this.currentModelIndex], this.currentJob, this.onFeedbackSaved.bind(this));
  }

  onFeedbackSaved(f: ArtistsResourcesFeedback) {
    this.getFullJob(this.currentJob.id);
    this.afterNewFeedback = true;
    let data: Notification = {
      text: 'feedback successfully created',
      type: NotificationType.Success,
      action: 'OK'
    };
    this.broadcaster.broadcast('notifyUser', data);
    this.feedbackCreatedPixel(f);
    this.getNumOfFeedback(this.currentJob);
  }

  getNumOfFeedback(job: Job, ajiId?: number) {
    if (ajiId)
      this.feedbackService.numOnFeedbacks[job.id][ajiId] = 0;
    job.artists_jobs_items.forEach(aji => {
      if (aji.artists_jobs_resources) {
        if (!this.feedbackService.numOnFeedbacks[job.id])
          this.feedbackService.numOnFeedbacks[job.id] = {};
        this.feedbackService.numOnFeedbacks[job.id][aji.id] = 0;
        aji.artists_jobs_resources.forEach(ajr => this.feedbackService.numOnFeedbacks[job.id][aji.id] += ajr.artists_resources_feedback.filter(f => !f.fixed && !f.hidden).length);
      }
    }
    );

    let sum = Object.keys(this.feedbackService.numOnFeedbacks[job.id]).reduce((acc, key) => {
      if (key != "sum") {
        return acc + this.feedbackService.numOnFeedbacks[job.id][key];
      }
      return acc;
    }, 0);

    this.feedbackService.numOnFeedbacks[job.id]["sum"] = sum;
    return this.feedbackService.numOnFeedbacks[job.id][ajiId];
  }

  feedbackCreatedPixel(f: ArtistsResourcesFeedback) {
    this.pixels.sendPixel({
      event: "feedback_created",
      job_id: this.currentJob?.id,
      client_feedback_id: this.clientFeedbackId,
      feedback_id: f.id,
      affect_artist_rank: !!!f.whitelisted,
      text: this.utils.getTextContent(f.notes),
      quick_feedback: this.feedbackService.lastQuickFeedback
    });
  }

  deleteReject(feedback: ArtistsResourcesFeedback): void {
    if (!confirm('are you sure you want to delete this reject?')) return;
    if (feedback.id) {
      let query = '/' + feedback.id;
      this.rest.jobFeedback('delete', null, query).subscribe(
        () => {
          this.getFullJob(this.currentJob.id);
          this.pixels.sendPixel({
            event: 'deleteFeedback',
            reference_id: this.auth.user.id
          });
          // this.refresh();
        },
        err => {
          let data: Notification = {
            text: err || 'failure deleting feedback',
            type: NotificationType.Error,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', data);
        }
      )
    }
  }

  saveEditedFeedback(ignorePut = false) {
    let currentFeedback = this.currentFeedback;
    if (!this.feedbackService.isFeedbackSubTypeValid(this.currentFeedback)) {
      alert('Please select a sub type');
      return;
    }
    if (!this.feedbackService.isScreenshotValid(this.currentFeedback)) {
      alert('Please create a screenshot');
      return;
    }
    if (this.lastHiddenValue != currentFeedback.hidden) {
      this.pixels.sendPixel({
        event: !currentFeedback.hidden ? 'feedbackRevealed' : 'feedbackHidden',
        reference_id: currentFeedback.id
      });
    }
    if (!ignorePut) {
      this.rest.jobFeedback('put', currentFeedback, `/${currentFeedback.id}`).subscribe(
        (data: ArtistsResourcesFeedback) => {
          let n: Notification = {
            text: 'feedback successfully updated',
            type: NotificationType.Success,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', n);

          this.pixels.sendPixel({
            event: "editFeedback",
            reference_id: currentFeedback.id
          });
          this.feedbackCreatedPixel(data);
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure editing feedback')
      );
    }
    this.jobService.sendFirstFMJob();
  }

  unhideAll() {
    if (!confirm('Are you sure you want to reveal all of these job feedbacks to the artist?')) return;
    if (!this.currentJobFeebacks) return;
    let arr = [];
    this.currentJobFeebacks.forEach(f => {
      if (f.hidden) {
        this.setCurrentFeedback(f);
        f.hidden = false;
        arr.push(f);
        this.saveEditedFeedback(true);
      }
    });
    if (!arr.length) {
      alert('No hidden feedbacks was found in this job!');
      return;
    }
    this.rest.jobFeedback('put', arr).subscribe(
      () => {
        let data: Notification = {
          text: 'feedbacks successfully revealed',
          type: NotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure revealing feedbacks')
    );
  }
  // onEditMode() {
  //   this.lastHiddenValue = this.currentFeedback.hidden;
  // }

  setCurrentItemById(id: number) {
    if (!this.currentArtistsjobitem || this.currentArtistsjobitem.id != id) {
      this.currentArtistsjobitem = this.currentJob.artists_jobs_items.find(i => i.id == id);
      this.jobService.setArtistsJobItemUI(this.currentArtistsjobitem, this.currentJob);
      this.artistsItemsSpecs = this.jobService.getArtistsItemsSpecs(this.currentJob, this.currentArtistsjobitem).filter(s => s.mandatory);
      this.setFeedbaks();
      this.currentModelIndex = 0;
    }
  }

  saveCurrentJob() {
    return new Promise((resolve, reject) => {
      this.rest.jobs('put', this.currentJob, '/' + this.currentJob.id).subscribe(job => {
        let data: Notification = {
          text: 'job saved',
          type: NotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        resolve(null);
      }, err => {
        this.utils.httpErrorResponseHandler(err, 'failure saving job');
        reject();
      });
    });
  }

  onDestroy() {
    this._jobsVisited = [];
    this._tests = [];
  }
}
