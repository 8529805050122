import { Injectable } from '@angular/core';
import * as EventBus from '@vertx/eventbus-bridge-client.js';
import { StorageService } from 'ng-storage-service';
import { AuthService } from '../auth/auth.service';
import { Subscription, Subject } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { User, ChatUserId, ChatHistory, ChatMessageType, ArtistsChatMessage, UserAvailability, UserTyping, ChatHistoryResponse, ChatHistoryOptions, ChatSession } from '../auth/user';
import { Notification, NotificationType } from '../shared/notifications';
// import { TextToSpeechService } from 'app/shared/text-to-speech.service';
import { SwService } from '../sw-helper/sw.service';
import { environment } from 'environments/environment';
import { PixelsService } from 'app/shared/pixels.service';
import { UtilsService } from 'app/shared/utils.service';

@Injectable()
export class WebsocketService {
  private eventBus: any;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private connetionLossCount: number;
  private connetionLossNotify: boolean;
  public onStateChange: Subject<boolean>;
  public onUsers: Subject<Array<User>>;
  public onChatMessage: Subject<ChatHistory>;
  public onUserAvailability: Subject<UserAvailability>;
  public onUserTyping: Subject<UserTyping>;
  public onHistory: Subject<ChatHistoryResponse>;
  // public onChatHistory: Subject<Array<ChatHistory>>;
  public onChatMessageHistory: Subject<Array<ArtistsChatMessage>>;
  public onChatCreated: Subject<ChatHistory>;
  public onChatSession: Subject<ChatSession>;
  public onReadAll: Subject<null>;

  public onSessionOpen$: Subject<any>;
  public onSessionClose$: Subject<any>
  public isOpen: boolean;
  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    // private textToSpeech: TextToSpeechService,
    private swService: SwService,
    private pixels: PixelsService,
    private utils: UtilsService
  ) {
    this.isOpen = false;
    this.onUsers = new Subject<Array<User>>();
    this.onChatMessage = new Subject<ChatHistory>();
    this.onHistory = new Subject<ChatHistoryResponse>();
    // this.onChatHistory = new Subject<Array<ChatHistory>>();
    this.onChatMessageHistory = new Subject<Array<ArtistsChatMessage>>();
    this.onChatCreated = new Subject<ChatHistory>();
    this.onChatSession = new Subject<ChatSession>();
    this.onReadAll = new Subject<null>();
    this.onUserAvailability = new Subject<UserAvailability>();
    this.onUserTyping = new Subject<UserTyping>();
    this.onSessionClose$ = new Subject<any>();
    this.onSessionOpen$ = new Subject<any>();
    this.connetionLossCount = 0;
    this.connetionLossNotify = false;
    if (this.auth.isloggedIn())
      this.connect();
    this.onLogin = this.broadcaster.on<User>('onLogin').subscribe(
      user => this.connect()
    );
    this.onLogout = this.broadcaster.on<User>('onLogout').subscribe(
      user => this.disconnect()
    );
    this.onUsers = new Subject<Array<User>>();
    this.onStateChange = new Subject<boolean>();
  }

  getUsers(limit?: number, offset?: number): void {
    this.eventBus.send('com.hexa.artists.chat.server.users', {
      limit: limit,
      offset: offset
    }, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onUsers.next(response.body);
    });
  }

  createChat(usersIds: Array<ChatUserId>, topic?: string) {
    var chatObj = {
      chat_users: usersIds,
      topic
    };
    this.eventBus.send('com.hexa.artists.chat.server.new.chat', chatObj, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onChatCreated.next(response.body);
    });
  }

  sendMessage(chatId: number, message: string, msgType: ChatMessageType) {
    var chatObj = {
      chat_id: chatId,
      body: message,
      message_type: msgType
    };
    this.eventBus.send('com.hexa.artists.chat.server.message', chatObj, {}, (err, response) => {
      // if (err || response.body.code == 500)
      //   console.warn(response.body.error);
      // else
      //   this.onChatMessage.next(response.body);
    });
  }

  // getChat(message: string, chatId: number) {
  //   var chatObj = {
  //     'chat_id': chatId,
  //     'body': message
  //   };
  //   this.eventBus.send('com.hexa.artists.chat.server.message', chatObj, {}, function (err, response) {
  //     if (err || response.body.code == 500)
  //       console.warn(response.body.error);
  //     else
  //       this.onChatMessage.next(response.body);
  //   });
  // }

  // getHistory(chatId: number, message: string, limit?: number, offset?: number) {
  // getHistory(chat_id?: number, limit?: number, offset?: number, topic?: string, job_id?: number) {
  getHistory(options?: ChatHistoryOptions) {
    // if (window.performance) {
    //   var t0 = performance.now();
    // }
    options = options || {} as ChatHistoryOptions;
    if (typeof options.topic !== 'string')
      delete options.topic;
    // var chatObj = {
    //   limit,
    //   offset,
    //   chat_id,
    //   job_id
    // } as any;
    // if (typeof topic === 'string')
    //   chatObj.topic = topic;
    this.eventBus.send('com.hexa.artists.chat.server.history', options, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else {
        this.onHistory.next(response.body);
      }

      // if (window.performance) {
      //   var t1 = performance.now();
      //   console.log("Call to com.hexa.artists.chat.server.history took " + (t1 - t0) + " milliseconds.");
      // }
    });
  }

  setIsTyping(chatId: number, isTyping: boolean) {
    var chatObj = {
      typing: isTyping,
      chat_id: chatId
    };
    this.eventBus.send('com.hexa.artists.chat.server.typing', chatObj, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      // else
      //   this.onHistory.next(response.body);
    });
  }

  setIsRead(chatId: number, messageId: number) {
    var chatObj = {
      message_id: messageId,
      chat_id: chatId
    };
    this.eventBus.send('com.hexa.artists.chat.server.read', chatObj, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      // else
      //   this.onHistory.next(response.body);
    });
  }

  setAsUnread(chat_id: number, read: boolean) {
    var chatObj = {
      chat_id,
      read
    };
    this.eventBus.send('com.hexa.artists.chat.server.mark.unread', chatObj, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      // else
      //   this.onHistory.next(response.body);
    });
  }

  readAll() {
    this.eventBus.send('com.hexa.artists.chat.server.mark.all.read', {}, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onReadAll.next(response.body);
    });
  }

  // getHistoryByChatId(chatId: number, limit?: number, offset?: number) {
  getHistoryByChatId(chatId: number, limit: number, operator?: string, timestamp?: Date) {
    var chatObj = {
      limit: limit,
      // offset: offset,
      operator: operator,
      timestamp: timestamp ? new Date(timestamp).getTime() : timestamp,
      chat_id: chatId
    };
    this.eventBus.send('com.hexa.artists.chat.server.history.message', chatObj, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onChatMessageHistory.next(response.body);
    });
  }

  supportRequest() {
    this.eventBus.send('com.hexa.artists.chat.server.support', {}, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onChatCreated.next(response.body);
    });
  }

  private connect() {
    if (!this.auth.isloggedIn()) return;
    let options = {
      vertxbus_reconnect_attempts_max: Infinity, // Max reconnect attempts
      vertxbus_reconnect_delay_min: 1000, // Initial delay (in ms) before first reconnect attempt
      vertxbus_reconnect_delay_max: 5000, // Max delay (in ms) between reconnect attempts
      vertxbus_reconnect_exponent: 2, // Exponential backoff factor
      vertxbus_randomization_factor: 0.5 // Randomization factor between 0 and 1
    };
    this.eventBus = new EventBus(this.auth.websocketEndpoint + '/eventbus?token=' + this.storage.get('token'), options);
    this.eventBus.onopen = this.onopen.bind(this);
    this.eventBus.onclose = this.onclose.bind(this);
    this.eventBus.onreconnect = () => {
      if (this.auth.isloggedIn()) {
        if (this.eventBus && this.eventBus.sockJSConn && this.eventBus.sockJSConn.url && this.utils.getUrlParam(this.eventBus.sockJSConn.url, 'token') !== this.storage.get('token')) {
          this.disconnect();
          this.connect();
        }
        else
          this.getHistory();
      }
    }
    this.eventBus.enableReconnect(true);
  }

  private onopen() {
    this.setState(true);
    this.register();
  }

  private onclose() {
    this.setState(false);
  }

  private setState(state: boolean) {
    this.isOpen = state;
    console.log('WebSocket is ' + (this.isOpen ? 'open' : 'close'));
    if (this.isOpen) {
      this.connetionLossCount = 0;
      let text = 'connection revived';
      if (this.connetionLossNotify) {
        let data: Notification = {
          text: text,
          type: NotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        // this.textToSpeech.say(text);
        if (environment.production)
          this.swService.checkForUpdate(); // after machine sleep or offline check for updates
      }
      this.connetionLossNotify = false;
      //  this.pixels.refreshSession();
      // this.pixels.sendPixel({
      //   event: 'newSession',
      //   width: window.innerWidth,
      //   height: window.innerHeight
      // });
    }
    else {
      if (++this.connetionLossCount > 10 && !this.connetionLossNotify) {
        this.connetionLossNotify = true;
        // let text = 'connection lost';
        // let data: Notification = {
        //   text: text,
        //   type: NotificationType.Error,
        //   action: 'OK'
        // };
        // this.broadcaster.broadcast('notifyUser', data);
        // this.textToSpeech.say(text);
      }

    }
    this.onStateChange.next(this.isOpen);
  }

  private register() {
    // this.eventBus.registerHandler("com.hexa.artists.chat.client.announcements", (err, response) => {
    //   console.log("com.hexa.artists.chat.client.announcements");
    //   if (err || response.body.code == 500)
    //     console.warn(response.body.error);
    //   else
    //     this.onAnnouncement.next(response.body);
    // });
    this.eventBus.registerHandler("com.hexa.artists.chat.client." + this.auth.user.id, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onChatMessage.next(response.body);
    });

    this.eventBus.registerHandler("com.hexa.artists.chat.client." + this.auth.user.id + ".availability", (err, response) => {
      // console.log("%c com.hexa.artists.chat.client." + this.auth.user.id + ".availability " + JSON.stringify(response.body), 'background: #00aacc; color: #fff');
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onUserAvailability.next(response.body);
    });

    this.eventBus.registerHandler("com.hexa.artists.chat.client." + this.auth.user.id + ".typing", (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onUserTyping.next(response.body);
    });

    this.eventBus.registerHandler("com.hexa.artists.chat.client." + this.auth.user.id + ".session", (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onChatSession.next(response.body);
    });
  }

  private disconnect() {
    if (this.eventBus && this.eventBus.state != this.eventBus.CLOSING && this.eventBus.state != this.eventBus.CLOSED)
      this.eventBus.close();
  }

  /**
   * Open the session with the given chat_id
   * @param chat_id
   * @param session_topic_id
   */
  public openSession(chat_id: number, session_topic_id: number): Promise<ChatSession> {
    return new Promise((resolve, reject) => {
      const chatObj = {
        chat_id,
        session_topic_id
      }
      this.eventBus.send('com.hexa.artists.chat.server.session.open', chatObj, {}, (err, response) => {
        if (err || response?.body?.code == 500) {
          console.warn(response?.body?.error);
          reject()
        }
        else {
          this.onChatSession.next(response.body);
          resolve(response.body);
        }
      });
    });
  }

  /**
   * Close the session with the given chat_id
   * @param chat_id
   */
  public closeSession(chat_id: number): Promise<ChatSession> {
    return new Promise((resolve, reject) => {
      const chatObj = {
        chat_id
      };
      this.eventBus.send('com.hexa.artists.chat.server.session.close', chatObj, {}, (err, response) => {
        if (err || response?.body?.code == 500) {
          console.warn(response?.body?.error);
          reject();
        }
        else {
          const body = response.body || {
            chat_id, id: null
          };
          resolve(body);
          this.onChatSession.next(body);
        }
      });
    });
  }

  public editSession(chat_id: number, session_topic_id: number): Promise<ChatSession> {
    return new Promise((resolve, reject) => {
      const chatObj = {
        chat_id,
        session_topic_id
      }
      this.eventBus.send('com.hexa.artists.chat.server.session.update', chatObj, {}, (err, response) => {
        if (err || response?.body?.code == 500) {
          console.warn(response?.body?.error);
          reject()
        }
        else {
          const body = response.body || {
            chat_id, id: null
          };
          resolve(body);
          this.onChatSession.next(body);
        }
      });
    });
  }
}
