import { Injectable } from '@angular/core';
import { Category, CategoriesDictionaries, ProductSubCategory, CategoryDefaultsPolyTypes } from './category';
import { Observable, Notification } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from 'app/shared/utils.service';
import { GraphqlService } from 'app/communication/graphql.service';
import { CategoryPrice } from 'app/product/product';
import { JobsTypes } from 'app/shared/enums';
import { PolyType } from 'app/offer/offers';

@Injectable()
export class CategoriesService {
  static DEFAULT_MAX_SIZE = 3.5;
  private cachedCategories: Array<Category>;
  private cachedParentCategories: Array<Category>;
  private _categoriesDictionary: { [id: number]: Category };
  private _subCategoriesDictionary: { [id: number]: ProductSubCategory };
  private _subCategoriesDefaultsDictionary: { [id: number]: Array<CategoryDefaultsPolyTypes> };
  private _parentCategoriesToParentDictionary: { [id: number]: Category };
  private isFetching: boolean;
  private fetch: Observable<any>;
  private isFetchingFull: boolean;
  private fetchFull: Observable<any>;
  private _didFetchFull: boolean;
  private _categoriesPricing: { [id: number]: Array<CategoryPrice> };
  constructor(
    private utils: UtilsService,
    private gql: GraphqlService
  ) {
    this.isFetching = false;
    this.isFetchingFull = false;
    this._categoriesPricing = {};
    // const options = {
    //   avg_price: true
    // } as ProductCategoryOptions;
    // this.gql.productsCategories(options).subscribe(
    //   c => {
    //     this.cachedCategories = this.utils.deepCopyByValue(c.data.productsCategories);
    //     this.setParents();
    //   }
    // )
    this.initDefaults();
    this.getCategories();
  }

  get didFetchFull() {
    return this._didFetchFull;
  }

  private initDefaults() {
    this._subCategoriesDefaultsDictionary = {};
    this._subCategoriesDefaultsDictionary[2] = [// Shoes
      {
        polyType: PolyType.MID,
        value: [
          {
            jobType: JobsTypes.GEOMETRY,
            value: {
              min: 500,
              max: 90000,
              maxSize: null
            }
          }
          // ,
          // {
          //   jobType: JobsTypes.TEXTURE,
          //   value: {
          //     min: 500,
          //     max: 90000,
          //     maxSize: 5
          //   }
          // }
        ]
      }
    ];
    this._subCategoriesDefaultsDictionary[25] = [// Watches
      {
        polyType: PolyType.MID,
        value: [
          {
            jobType: JobsTypes.GEOMETRY,
            value: {
              min: 500,
              max: 100000,
              maxSize: null
            }
          },
          {
            jobType: JobsTypes.TEXTURE,
            value: {
              min: null,
              max: null,
              maxSize: CategoriesService.DEFAULT_MAX_SIZE
            }
          }
        ]
      }
    ];
    this._subCategoriesDefaultsDictionary[33] = [// Sunglasses
      {
        polyType: PolyType.MID,
        value: [
          {
            jobType: JobsTypes.GEOMETRY,
            value: {
              min: 500,
              max: 15000,
              maxSize: null
            }
          },
          {
            jobType: JobsTypes.TEXTURE,
            value: {
              min: null,
              max: null,
              maxSize: CategoriesService.DEFAULT_MAX_SIZE
            }
          }
        ]
      }
    ];
    this._subCategoriesDefaultsDictionary[34] = this._subCategoriesDefaultsDictionary[33];// Eyewear
    this._subCategoriesDefaultsDictionary[27] = [// Backpack (Bags)
      {
        polyType: PolyType.MID,
        value: [
          {
            jobType: JobsTypes.GEOMETRY,
            value: {
              min: 500,
              max: 60000,
              maxSize: null
            }
          },
          {
            jobType: JobsTypes.TEXTURE,
            value: {
              min: null,
              max: null,
              maxSize: CategoriesService.DEFAULT_MAX_SIZE
            }
          }
        ]
      }
    ];
    this._subCategoriesDefaultsDictionary[26] = this._subCategoriesDefaultsDictionary[27];// Wallets
    this._subCategoriesDefaultsDictionary[28] = this._subCategoriesDefaultsDictionary[27];// Sholder bag (Bags)
    this._subCategoriesDefaultsDictionary[29] = this._subCategoriesDefaultsDictionary[27];// Hand bag (Bags)
    this._subCategoriesDefaultsDictionary[30] = this._subCategoriesDefaultsDictionary[27];// Briefcase (Bags)
    this._subCategoriesDefaultsDictionary[31] = this._subCategoriesDefaultsDictionary[27];// Bumbag (Bags)
    this._subCategoriesDefaultsDictionary[32] = this._subCategoriesDefaultsDictionary[27];// Suitcase (Bags)
    this._subCategoriesDefaultsDictionary[1] = [// Top
      {
        polyType: PolyType.MID,
        value: [
          {
            jobType: JobsTypes.GEOMETRY,
            value: {
              min: 500,
              max: 60000,
              maxSize: null
            }
          },
          {
            jobType: JobsTypes.TEXTURE,
            value: {
              min: null,
              max: null,
              maxSize: CategoriesService.DEFAULT_MAX_SIZE
            }
          }
        ]
      }
    ];
    this._subCategoriesDefaultsDictionary[3] = this._subCategoriesDefaultsDictionary[1];// T-Shirts
    this._subCategoriesDefaultsDictionary[4] = this._subCategoriesDefaultsDictionary[1];// Long Sleeve
    this._subCategoriesDefaultsDictionary[5] = this._subCategoriesDefaultsDictionary[1];// Polo
    this._subCategoriesDefaultsDictionary[6] = this._subCategoriesDefaultsDictionary[1];// Sweatshirts
    this._subCategoriesDefaultsDictionary[7] = this._subCategoriesDefaultsDictionary[1];// Sweatpants
    this._subCategoriesDefaultsDictionary[8] = this._subCategoriesDefaultsDictionary[1];// Jackets
    this._subCategoriesDefaultsDictionary[9] = this._subCategoriesDefaultsDictionary[1];// Jeans
    this._subCategoriesDefaultsDictionary[10] = this._subCategoriesDefaultsDictionary[1];// Pants
    this._subCategoriesDefaultsDictionary[11] = this._subCategoriesDefaultsDictionary[1];// Short
    this._subCategoriesDefaultsDictionary[12] = this._subCategoriesDefaultsDictionary[1];// Swimwear
    this._subCategoriesDefaultsDictionary[13] = this._subCategoriesDefaultsDictionary[1];// Jumpers
    this._subCategoriesDefaultsDictionary[14] = this._subCategoriesDefaultsDictionary[1];// Outwear
    this._subCategoriesDefaultsDictionary[15] = this._subCategoriesDefaultsDictionary[1];// Socks
    this._subCategoriesDefaultsDictionary[16] = this._subCategoriesDefaultsDictionary[1];// Trousers
    this._subCategoriesDefaultsDictionary[17] = this._subCategoriesDefaultsDictionary[1];// Hoodies
    this._subCategoriesDefaultsDictionary[18] = this._subCategoriesDefaultsDictionary[1];// Vests
    this._subCategoriesDefaultsDictionary[19] = this._subCategoriesDefaultsDictionary[1];// Underwear
    this._subCategoriesDefaultsDictionary[20] = this._subCategoriesDefaultsDictionary[1];// Dresses
    this._subCategoriesDefaultsDictionary[21] = this._subCategoriesDefaultsDictionary[1];// Hats
    this._subCategoriesDefaultsDictionary[22] = this._subCategoriesDefaultsDictionary[1];// Belts
    this._subCategoriesDefaultsDictionary[23] = this._subCategoriesDefaultsDictionary[1];// Scarves
    this._subCategoriesDefaultsDictionary[40] = [// Rings
      {
        polyType: PolyType.MID,
        value: [
          {
            jobType: JobsTypes.GEOMETRY,
            value: {
              min: 500,
              max: 150000,
              maxSize: null
            }
          },
          {
            jobType: JobsTypes.TEXTURE,
            value: {
              min: null,
              max: null,
              maxSize: CategoriesService.DEFAULT_MAX_SIZE
            }
          }
        ]
      }
    ];
    this._subCategoriesDefaultsDictionary[41] = this._subCategoriesDefaultsDictionary[40];// Earrings
    this._subCategoriesDefaultsDictionary[150] = this._subCategoriesDefaultsDictionary[40];// Necklace
    this._subCategoriesDefaultsDictionary[151] = this._subCategoriesDefaultsDictionary[40];// Bracelet
  }

  private setDictionaries(): void {
    this._categoriesDictionary = {};
    this._subCategoriesDictionary = {};
    this.getCachedCategories().forEach((category) => {
      this._categoriesDictionary[category.id] = category;
      if (category.products_sub_categories) {
        category.products_sub_categories.forEach((sub) => {
          this._subCategoriesDictionary[sub.id] = sub;
        });
      }
    });
  }

  get categoriesDictionary(): { [id: number]: Category } {
    if (!this.cachedCategories) throw 'categories arene\'t ready yet, please use CategoriesResolve for your route.';
    if (!this._categoriesDictionary) {
      this.setDictionaries();
    }
    return this._categoriesDictionary;
  }

  get subCategoriesDictionary(): { [id: number]: ProductSubCategory } {
    if (!this.cachedCategories) throw 'categories arene\'t ready yet, please use CategoriesResolve for your route.';
    if (!this._subCategoriesDictionary) {
      this.setDictionaries();
    }
    return this._subCategoriesDictionary;
  }

  get parentCategoriesToParentDictionary(): { [id: number]: Category } {
    if (!this.cachedCategories) throw 'categories arene\'t ready yet, please use CategoriesResolve for your route.';
    if (!this._parentCategoriesToParentDictionary) {
      if (!this.cachedParentCategories)
        this.setParents();
      this._parentCategoriesToParentDictionary = {};
      this.cachedCategories.forEach(c => {
        this._parentCategoriesToParentDictionary[c.id] = this.getParentByCategoryId(c.id);
      });
    }
    return this._parentCategoriesToParentDictionary;
  }

  get subCategoriesDefaultsDictionary() {
    return this._subCategoriesDefaultsDictionary;
  }

  getDictionaries(categories: Array<Category>): CategoriesDictionaries {
    let dictionary = {
      categoriesDictionary: {},
      subCategories: [],
      subCategoriesDictionary: {}
    } as CategoriesDictionaries;
    categories.forEach((category) => {
      dictionary.categoriesDictionary[category.id] = category;
      if (category.products_sub_categories) {
        category.products_sub_categories.forEach((sub) => {
          dictionary.subCategoriesDictionary[sub.id] = sub;
          dictionary.subCategories.push(sub);
        });
      }
    });
    return dictionary;
  }

  getCachedCategories() {
    return this.utils.deepCopyByValue(this.cachedCategories) as Array<Category>;
  }

  getCachedParentsCategories() {
    return this.utils.deepCopyByValue(this.cachedParentCategories) as Array<Category>;
  }

  private getParentByCategoryId(cid: number): Category {
    let res = null as Category;
    if (!this.cachedParentCategories) return res;
    this.cachedParentCategories.forEach(p => {
      if (p.children.find(c => c == cid)) {
        res = p;
        return false;
      }
    })
    return res;
  }

  setCachedCategories(categories: Array<Category>) {
    this.cachedCategories = this.utils.deepCopyByValue(categories);
    this.setParents();
  }

  getCategoryPricing(cid: number): Observable<any> {
    if (this._categoriesPricing[cid])
      return Notification.createNext(this._categoriesPricing[cid]).toObservable();
    return this.gql.categoriesPricing(cid).pipe(map(res => {
      this._categoriesPricing[cid] = res.data.categories_pricing;
      return this._categoriesPricing[cid];
    }));
  }

  getFullCategories(): Observable<Array<Category>> {
    if (this.isFetchingFull) return this.fetchFull;
    this.isFetchingFull = true;
    this.fetchFull = this.gql.productsFullCategories().pipe(map(res => {
      try {
        this.cachedCategories = this.utils.deepCopyByValue(res.data.productsCategories);
        this.setParents();
        this.setDictionaries();
        this.isFetchingFull = false;
        this._didFetchFull = true;
        return this.getCachedCategories();
      } catch(e) {
        throw(e);
      }
    }));
    return this.fetchFull;
  }

  async getFullCategoriesAsync(force = false) {
    return new Promise((resolve, reject) => {
      if (!force && this.cachedCategories && this._didFetchFull) {
        resolve(this.getCachedCategories());
      }
      else if (this.isFetchingFull) {
        this.fetchFull.subscribe(() => {
          resolve(this.getCachedCategories());
        })
      }
      else {
        this.getFullCategories().subscribe(() => {
          resolve(this.getCachedCategories());
        });
      }
    });
  }

  getCategories(): Observable<Array<Category>> {
    if (this.isFetching) return this.fetch;
    this.isFetching = true;
    this.fetch = this.gql.productsCategories().pipe(map(res => {
      try {
        this.cachedCategories = this.utils.deepCopyByValue(res.data.productsCategories);
        this.setParents();
        this.isFetching = false;
        return this.getCachedCategories();
      } catch(e) {
        console.log(`unable to fetch product categories`);
        throw(e);
      }
    }));
    return this.fetch;
  }

  getParentsByCategoriesId(ids: Array<number>, asArray = false): any {//{ [id: string]: Category; } {
    let res = {} as { [id: string]: Category; };
    if (!ids || !ids.length || !this.cachedCategories) return res;
    ids.forEach(i => {
      if (this.cachedCategories.find(j => j.id == i))
        res[this.cachedCategories.find(j => j.id == i).parent_description] = this.cachedCategories.find(c => c.id == i);
      else {
        console.error(`Missing category ID ${i} in`, this.cachedCategories);
      }
      // res[i] = this.cachedCategories.find(c => c.id == i);
    });
    Object.keys(res).forEach(p => res[p].children = this.cachedParentCategories.find(i => i.parent_description == res[p].parent_description).children);
    if (asArray)
      return Object.values(res);
    return res;
  }

  private setParents() {
    this.cachedParentCategories = [];
    this.cachedCategories.forEach(
      c => {
        if (!this.cachedParentCategories.find(p => p.parent_description == c.parent_description)) {
          let p = Object.assign({}, c) as Category;
          p.children = [];
          this.cachedCategories.forEach(ci => {
            if (ci.parent_description == p.parent_description)
              p.children.push(ci.id);
          });
          this.cachedParentCategories.push(p);
        }
      }
    );
  }
}
