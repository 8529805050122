import { NgModule } from '@angular/core';
import { JobsTypesResolve } from './jobs-types.resolve';
import { TopAssetsResolve } from '../home/home/leading-assets.resolve.';
import { CategoriesResolve } from '../categories/categories.resolve';

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    JobsTypesResolve,
    TopAssetsResolve,
    CategoriesResolve
  ]
})
export class JobTypeModule { }
