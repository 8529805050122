import { KeyValuePair } from "./enums";

export class MetaOptions {
    public description: string;
    public keywords: string;
    public canonical: string;
    public title: string;
    public og_image: string;
    public og_title: string;
    public og_type: string;
    public og_url: string;
    public properties: Array<KeyValuePair>
    constructor() {
        this.description = 'Creators3D is a 3D content creation platform that supplies Job Offers for 3D Artists.';
        this.keywords = 'Creators3D, Freelancer, 3D Artist, 3D Model, 3D Designer';
        this.canonical = window.location.href;//'https://www.creators3d.com'
        this.title = 'Creators 3D';
        this.og_image = 'https://cdn.creators3d.com/site/poster_v1.jpg';
        this.og_title = 'Creators 3D - Jobs for Professional Artists';
        this.og_type = 'website';
        // this.og_url = this.canonical;
        this.properties = [];
    }
}