import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AutocompleteComponent implements OnInit, OnChanges {
  @Input() items: Array<any>;
  @Input('clear-on-select') clearOnSelect: boolean;
  @Input('identifier') id: string;
  @Input('img-field') imgField: string;
  @Input() placeholder: string;
  @Input('main-field') mainField: string;
  @Input('sub-field') subField: string;
  @Input('current-val') currentVal: any;
  @Output('item-change') itemChange: EventEmitter<any>;
  @ViewChild('filterInput') filterInput: ElementRef;
  public itemCtrl: FormControl;
  public filteredItems: Observable<Array<any>>;
  private last: number;
  constructor(
    private utils: UtilsService
  ) {
    this.itemCtrl = new FormControl();
    this.itemChange = new EventEmitter<any>();
    this.last = new Date().getTime();
  }

  ngOnInit() {
    this.setFilteredItems();
    if (this.currentVal) {
      let current = this.items.find(i => i[this.id] == this.currentVal);
      if (current)
        this.setVAlue(current[this.mainField]);
    }
  }

  setVAlue(val: string) {
    this.itemCtrl.setValue(val);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName == 'currentVal') {
        let current = this.items.find(i => i[this.id] == this.currentVal);
        if (current)
          this.setVAlue(current[this.mainField]);
        else
          this.setVAlue('');
      }
      if (propName == 'items') {
        this.setVAlue('');
        this.setFilteredItems();
      }
    }
  }

  clear(): Array<any> {
    this.itemChange.emit(null);
    return this.items.slice();
  }

  filterItems(name: string) {
    return this.items.filter(i =>
      i[this.mainField].toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  onItemChange(item: any) {
    const now = new Date().getTime();
    if (this.utils.isAboveTS(now, this.last, 1000)) {
      this.itemChange.emit(item);
      this.last = now;
    }
    if (this.clearOnSelect) {
      this.itemCtrl.setValue('');
      this.setFilteredItems();
      setTimeout(() => {
        this.filterInput.nativeElement.blur();
      }, 0);
    }
  }

  setFilteredItems() {
    this.filteredItems = this.itemCtrl.valueChanges
      .pipe(
        startWith(''),
        map(i => i ? this.filterItems(i) : this.clear())
      );
  }
}
