import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UtilsService } from './utils.service';
import { TextToSpeechOptions } from './text-to-speech';

@Injectable()
export class TextToSpeechService {
  public isSupported: boolean;
  private voice: any;
  private voiceURI: any;
  constructor(
    private utils: UtilsService
  ) {
    this.isSupported = 'SpeechSynthesisUtterance' in window;
    // if (this.isSupported) {
    //   window.speechSynthesis.onvoiceschanged = () => {
    //     console.log('onvoiceschanged');
    //     let voices = window.speechSynthesis.getVoices();
    //     try {
    //       voices.forEach((voice, i) => {
    //         console.log(voice.name);
    //         if (voice.name.toLowerCase().indexOf('united states')) {
    //           this.voice = voice;
    //           this.voiceURI = voice.voiceURI;
    //           return false;
    //         }
    //       });
    //     } catch (e) { }
    //   };
    // }
  }

  public say(text: string, options: TextToSpeechOptions = {}): Observable<Object> {
    let sub = new Subject();
    if (options.isHTML)
      text = this.utils.getTextContent(text);
    if (!this.isSupported)
      sub.next(null);
    let msg = new SpeechSynthesisUtterance();
    // let voices = window.speechSynthesis.getVoices();
    // try {
    //   voices.forEach((voice, i) => {
    //     if (voice.name.toLowerCase().indexOf('us english')) {
    //       msg.voice = voice;
    //       msg['voiceURI'] = voice.voiceURI;
    //       return false;
    //     }
    //   });
    // } catch(e) {}

    // msg.voice = voices[10]; // Note: some voices don't support altering params
    // msg['voiceURI'] = 'native';
    if (this.voice) {
      msg.voice = this.voice;
      if (this.voiceURI) {
        msg['voiceURI'] = this.voiceURI;
      }
    }
    msg.volume = 1; // 0 to 1
    msg.rate = 1; // 0.1 to 10
    msg.pitch = 2; //0 to 2
    msg.text = text;
    msg.lang = 'en-US';

    msg.onend = (e) => {
      // console.log('Finished in ' + event['elapsedTime'] + ' MS.');
      sub.next(options.id);
    };

    speechSynthesis.speak(msg);
    return sub;
  }

  cancel() {
    if (this.isSupported)
      speechSynthesis.cancel();
  }
}
