import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateAuthGuard } from './auth/can-activate-auth-guard.service';
import { CanActivateSuGuard } from './auth/can-activate-su-guard.service';
import { NotificationActionResolve } from './communication/notification-action/notification-action.resolve';
import { ConfirmResolve } from './auth/confirm/confirm.resolve';
import { PasswordRecoveryResolve } from './auth/password-recovery/password-recovery.resolve';
import { SocialResolve } from './auth/social/social.resolve';
import { CategoriesResolve } from './categories/categories.resolve';
import { UserProfileResolve } from './auth/user-profile-wrap/user-profile.resolve';
import { ArtistJobsResolve } from './auth/artist-profile/user-profile.resolve';
import { UsersManagerIdResolve } from './auth/users-manager/users-manager-id.resolve';
import { CanActivateGuardPermissions } from './auth/can-activate-permissions-guard.service';
import { UserGroupsResolve } from './auth/user-groups/user-groups.resolve';
import { ChatLinkResolve } from './shared/chat-link.resolve';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
      path: 'roles-management',
      canActivate: [CanActivateSuGuard],
      loadComponent: () =>
        import('./auth/roles-management/roles-management.component').then(m => m.RolesManagementComponent)
    },
    {
      path: 'login',
      loadComponent: () =>
        import('./auth/login/login.component').then(m => m.LoginComponent)
    },
    {
      path: 'login/:redirect_uri',
      loadComponent: () =>
        import('./auth/login/login.component').then(m => m.LoginComponent)
    },
    {
        path: 'referral',
        loadComponent: () =>
          import('./auth/referral/referral-landing.component').then(m => m.ReferralLandingComponent)
    },
    {
        path: 'auth/sso',
        resolve: {
            profile: SocialResolve
        },
        loadComponent: () =>
          import('./auth/social/social.component').then(m => m.SocialComponent)
    },
    {
        path: 'users-manager',
        resolve: {
            categories: CategoriesResolve
        },
        canActivate: [CanActivateAuthGuard],
        loadComponent: () =>
          import('./auth/users-manager/users-manager.component').then(m => m.UsersManagerComponent)
    },
    {
        path: 'users-manager/:id',
        resolve: {
            userId: UsersManagerIdResolve,
            categories: CategoriesResolve
        },
        canActivate: [CanActivateAuthGuard],
        loadComponent: () =>
          import('./auth/users-manager/users-manager.component').then(m => m.UsersManagerComponent)
    },
    {
        path: 'user-profile/:id',
        resolve: {
            profile: UserProfileResolve,
            categories: CategoriesResolve
        },
        canActivate: [CanActivateAuthGuard],
        loadComponent: () =>
          import('./auth/user-profile-wrap/user-profile-wrap.component').then(m => m.UserProfileWrapComponent)
    },
    {
        path: 'artist/:id/:name',
        resolve: {
            profile: UserProfileResolve,
            categories: CategoriesResolve,
            jobs: ArtistJobsResolve
        },
        loadComponent: () =>
          import('./auth/artist-prifile-wrap/artist-profile-wrap.component').then(m => m.ArtistProfileWrapComponent)
    },
    {
        path: 'artist/user/password/:token',
        resolve: {
            token: PasswordRecoveryResolve
        },
        loadComponent: () =>
          import('./auth/password-recovery/password-recovery.component').then(m => m.PasswordRecoveryComponent)
    },
    {
        path: 'jobs',
        loadChildren: () => import('./job/job.module').then(m => m.JobModule)
    },
    {
        path: 'private-offers',
        loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule),
    },
    {
        path: 'offers',
        loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule)
    },
    {
        path: 'offer/:id',
        loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule),
    },
    {
        path: 'products',
        canActivate: [CanActivateAuthGuard],
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
    },
    {
        path: 'analytics',
        canActivate: [CanActivateAuthGuard],
        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule)
    },
    {
        path: 'categories-manager',
        canActivate: [CanActivateAuthGuard, CanActivateSuGuard],
        loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule)
    },
    {
        path: 'payments',
        canActivate: [CanActivateAuthGuard],
        loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
    },
    {
        path: 'broadcast',
        canActivate: [CanActivateAuthGuard, CanActivateSuGuard],
        loadComponent: () =>
          import('./communication/broadcast-notifications/broadcast-notifications.component').then(m => m.BroadcastNotificationsComponent)
    },
    {
        path: 'groups',
        resolve: {
            groups: UserGroupsResolve
        },
        canActivate: [CanActivateAuthGuard, CanActivateGuardPermissions],
        data: {permissions: ['View Groups', 'Edit Groups']},
        loadComponent: () =>
          import('./auth/user-groups/user-groups.component').then(m => m.UserGroupsComponent)
    },
    {
        path: 'chat/:id',
        resolve: {
            chat: ChatLinkResolve
        },
        loadComponent: () =>
          import('./ui-components/open-chat/open-chat.component').then(m => m.OpenChatComponent)
        // is the component necessary?
    },
    {
        path: 'notification/:id',
        resolve: {
            notificationId: NotificationActionResolve
        },
        loadComponent: () =>
          import('./communication/notification-action/notification-action.component').then(m => m.NotificationActionComponent)
    },
    {
        path: 'self-profile',
        loadComponent: () =>
          import('./auth/self-profile/self-profile.component').then(m => m.SelfProfileComponent)
    },
    {
        path: 'faq',
        redirectTo: 'online-viewer'
    },
    {
        path: 'frequently-asked-questions',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
    },
    {
        path: 'online-viewer',
        loadComponent: () =>
          import('./faq/online-viewer/online-viewer.component').then(m => m.OnlineViewerComponent)
    },
    {
        path: 'how-it-works',
        loadComponent: () =>
          import('./faq/how-it-works/how-it-works.component').then(m => m.HowItWorksComponent)
    },
    {
        path: 'privacy',
        loadComponent: () =>
          import('./auth/privacy-view/privacy-view.component').then(m => m.PrivacyViewComponent)
    },
    {
        path: 'terms',
        loadComponent: () =>
          import('./auth/terms-view/terms-view.component').then(m => m.TermsViewComponent)
    },
    {
        path: 'artist/user/confirm/:token',
        resolve: {
            token: ConfirmResolve
        },
        loadComponent: () =>
          import('./auth/confirm/confirm.component').then(m => m.ConfirmComponent)
    },
    {
        path: 'onboarding',
        loadComponent: () =>
          import('./auth/onboarding/onboarding.component').then(m => m.OnboardingComponent)
    },
    {
        path: 'messages',
        loadComponent: () =>
          import('./ui-components/messages/messages.component').then(m => m.MessagesComponent)
    },
    {
        path: 'feedbacks/:id/:uid',
        canActivate: [CanActivateAuthGuard],
        loadComponent: () =>
          import('./default-route/default-route.component').then(m => m.DefaultRouteComponent)
    },
    {
        path: 'job-withdraw/:id/:state',
        canActivate: [CanActivateAuthGuard],
        loadComponent: () =>
          import('./default-route/default-route.component').then(m => m.DefaultRouteComponent)
    },
    {
        path: 'survey/:id',
        canActivate: [CanActivateAuthGuard],
        loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule)
    },
    {
        path: 'uploads',
        canActivate: [CanActivateAuthGuard],
        loadChildren: () => import('./viewer-resources/viewer-resources.module').then(m => m.ViewerResourcesModule)
    },
    {
        path: 'tagging',
        canActivate: [CanActivateAuthGuard],
        loadChildren: () => import('./tagging/tagging.module').then(m => m.TaggingModule)
    },
    {
        path: 'rank',
        canActivate: [CanActivateSuGuard],
        loadChildren: () => import('./rank/rank.module').then(m => m.RankModule)
    },
    {
        path: 'mesh-libraries',
        canActivate: [CanActivateSuGuard],
        loadChildren: () => import('./mesh-library/mesh-library.module').then(m => m.MeshLibraryModule)
    },
    {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
    },
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: '**',
      loadComponent: () =>
        import('./default-route/default-route.component').then(m => m.DefaultRouteComponent)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
