<mat-form-field class="offer-variations">
  <mat-label>Variations</mat-label>
  <mat-select [formControl]="variations" multiple class="uppercase" (selectionChange)="onValueChange()">
    <mat-select-trigger [title]="getVariationValues()">
      <span
        [ngClass]="isMultipleSelection() ? 'offer-variations-selection-multiple': 'offer-variations-selection-single'">
        {{getSelectionsValue()}}
      </span>
      <span *ngIf="variations.value?.length > 1" class="offer-variations-additional-selection">
        (+{{variations.value.length - 1}} {{variations.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let variation of variationList" [value]="variation" [title]="variation.name">
      <div class="offer-variation">
        <img class="offer-variation-img" [src]="variation.imgUrl" />
        <div class="offer-variation-name">
          {{variation.name}}
        </div>
        <div class="offer-variation-status pos-abs">
          <span matTooltip="Approved" *ngIf="variation.approved"
            class="icon-v va-text-top primary-color has-approved pos-abs"
            [ngClass]="{'first': variation.final_approval}"><span class="path2"></span></span>
          <span matTooltip="Final Approved" *ngIf="variation.final_approval"
            class="icon-v va-text-top primary-color has-approved pos-abs second"><span class="path2"></span></span>
          <span matTooltip="Sent To CMS" *ngIf="variation.sent_to_retailers" class="pos-abs check3"></span>
        </div>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>