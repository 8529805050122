/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { Product, ProductFilterOptions, SIMILAR_REQUEST_MODE } from '../product';
import { SimilarFactoryService } from '../similar-factory.service';
import { OfferRequestUI, OfferRequestWrapper, OfferResponseData } from '../../offer/offers';
import { OfferService } from '../../offer/offer.service';
import { ValidateResponse } from '../../shared/utils';
import { AuthService } from '../../auth/auth.service';
import { CategoriesService } from 'app/categories/categories.service';
import { UtilsService } from 'app/shared/utils.service';
import { CALC_HOURS_TO_COMNPLETE, JobsTypes } from 'app/shared/enums';
import { NotificationType } from 'app/shared/notifications';
import { BundleCompareState, OfferCreationState } from './offer-creation-tool.interface';
import { GraphqlService } from '../../communication/graphql.service';
import { Retailer } from '../../shared/retailers';
import { cloneDeep } from '@apollo/client/utilities';
import { OfferCreationHelperService } from 'app/offer/offer-creation-helper.service';

@Injectable({
  providedIn: 'root'
})
export class OfferCreationService {

  public resetOfferCreation$: Subject<void> = new Subject();
  // page CATEGORIES
  public enableCategoryNextButton$: BehaviorSubject<boolean>;
  public subCategoryId$: BehaviorSubject<number>;
  // page Complexities
  public enableComplexityNextButton$: BehaviorSubject<boolean>;
  // page CREATE_BUNDLE
  public updateSimilarItems$: Subject<void>;
  // page VERIFY_BUNDLE
  public verifyBundleNextComparisonClick$: Subject<void>;
  // page ADD_INVENTORY_ITEMS
  public updateInventoryItems$: Subject<void>;
  // page INVENTORY_COMPARE
  public inventoryBundleNextComparisonClick$: Subject<void>;

  public jobOffer: OfferRequestUI;
  public offerCreationState: OfferCreationState;
  public selectedProduct: Product;
  public comparisonInventory: BundleCompareState;
  public complexity: number;
  public complexityPrice: number;

  public selectedRetailerId: number;
  public selectedBatchId: number;

  private _currentSimilarItems: { [id: number]: Array<Product> } = {};
  private _selectedBundleProductsIds: { [id: number]: boolean } = {};
  private _selectedInventoryProductsIds: { [id: number]: boolean } = {};
  private _selectedBundleProducts: Array<Product> = [];
  private _selectedInventoryProducts: Array<Product> = [];
  private _hasSentToCMS: boolean;
  private _offerRequestOffset: number = 0;

  constructor(
    public similarFactoryService: SimilarFactoryService,
    public auth: AuthService,
    private offerService: OfferService,
    private categoriesService: CategoriesService,
    public utils: UtilsService,
    private gql: GraphqlService,
    private offerCreationHelper: OfferCreationHelperService
  ) {
    this.initSubs();
    // In order to get sub categories texture prices
    this.categoriesService.getFullCategoriesAsync();
  }

  get selectedBundleProductsIds() {
    return this._selectedBundleProductsIds;
  }

  set selectedBundleProductsIds(value: { [id: number]: boolean }) {
    this._selectedBundleProductsIds = value;
    this.syncBundle();
  }

  get selectedBundleProducts() {
    return this._selectedBundleProducts;
  }

  public get offerRequestOffset(): number {
    return this._offerRequestOffset;
  }

  set selectedBundleProducts(value: Array<Product>) {
    for (const i of Object.keys(this._selectedBundleProductsIds)) {
        this._selectedBundleProductsIds[i] = false;
    }
    this._selectedBundleProducts = value;
    this._selectedBundleProducts.map(p => this._selectedBundleProductsIds[p.id] = true);
  }

  get selectedInventoryProductsIds() {
    return this._selectedInventoryProductsIds;
  }

  set selectedInventoryProductsIds(value: { [id: number]: boolean }) {
    this._selectedInventoryProductsIds = value;
    this.syncInventory();
  }

  get selectedInventoryProducts() {
    return this._selectedInventoryProducts;
  }

  set selectedInventoryProducts(value: Array<Product>) {
    for (const i of Object.keys(this._selectedInventoryProducts)) {
        this._selectedInventoryProducts[i] = false;
    }
    this._selectedInventoryProducts = value;
    this._selectedInventoryProducts.map(p => this._selectedInventoryProductsIds[p.id] = true);
  }

  get hasSentToCMS() {
    return this._hasSentToCMS;
  }

  public syncBundle() {
    this._selectedBundleProducts = [];
    for (const i of Object.keys(this._selectedBundleProductsIds)) {
      if (this._selectedBundleProductsIds[i]) {
        if (this.similarFactoryService.currentItem?.id === parseInt(i, 10)) {
            this._selectedBundleProducts.push(this.similarFactoryService.currentItem);
        } else {
            this._selectedBundleProducts.push(this._currentSimilarItems[SIMILAR_REQUEST_MODE.requested].find(p => p.id === parseInt(i, 10)));
        }
      }
    }
  }

  public syncBundleIds() {
    this._selectedBundleProductsIds = {};
    this._selectedBundleProducts.forEach(sbp => {
      this._selectedBundleProductsIds[sbp.id] = true;
    });
  }

  initSubs() {
    this.resetOfferCreation$ = new Subject();
    this.enableCategoryNextButton$ = new BehaviorSubject(false);
    this.subCategoryId$ = new BehaviorSubject(-1);
    this.enableComplexityNextButton$ = new BehaviorSubject(false);
    this.updateSimilarItems$ = new Subject();
    this.verifyBundleNextComparisonClick$ = new Subject();
    this.updateInventoryItems$ = new Subject();
    this.inventoryBundleNextComparisonClick$ = new Subject();
  }

  public syncInventory() {
    this._selectedInventoryProducts = [];
    for (let i of Object.keys(this._selectedInventoryProductsIds)) {
      if (this._selectedInventoryProductsIds[i])
        this._selectedInventoryProducts.push(this._currentSimilarItems[SIMILAR_REQUEST_MODE.approved].find(p => p.id == parseInt(i)));
    }
  }

  /**
   * Setting the stepper state
   */
  public updateStepperNextState(stepper: any) {
    switch (this.offerCreationState) {
      case OfferCreationState.CATEGORY:
        this.offerCreationState = OfferCreationState.COMPLEXITY;
        stepper.next();
        break;
      case OfferCreationState.COMPLEXITY:
        this.offerCreationState = OfferCreationState.CREATE_BUNDLE;
        stepper.next();
        break;
      case OfferCreationState.CREATE_BUNDLE:
        if (this.getSimilarSelectedItems().length > 1) {
          this.offerCreationState = OfferCreationState.VERIFY_BUNDLE;
          stepper.next();
        } else {
          this.offerCreationState = OfferCreationState.ADD_INVENTORY_ITEMS;
          stepper.next();
        }
        break;
      case OfferCreationState.VERIFY_BUNDLE:
        this.offerCreationState = OfferCreationState.ADD_INVENTORY_ITEMS;
        if (this.getSimilarSelectedItems().length > 1) {
          stepper.next();
        }
        break;
      case OfferCreationState.ADD_INVENTORY_ITEMS:
        if (this.selectedInventoryProducts.length) {
          this.offerCreationState = OfferCreationState.INVENTORY_COMPARE;
          stepper.next();
        }
        break;
    }
  }

  public updateStepperPrevState(stepper: any): void {
    switch (this.offerCreationState) {
      case OfferCreationState.COMPLEXITY:
        this.offerCreationState = OfferCreationState.CATEGORY;
        break;
      case OfferCreationState.CREATE_BUNDLE:
        this.offerCreationState = OfferCreationState.COMPLEXITY;
        break;
      case OfferCreationState.VERIFY_BUNDLE:
        this.offerCreationState = OfferCreationState.CREATE_BUNDLE;
        break;
      case OfferCreationState.ADD_INVENTORY_ITEMS:
        if (this.getSimilarSelectedItems().length < 2) {
          this.offerCreationState = OfferCreationState.CREATE_BUNDLE;
        } else {
          this.offerCreationState = OfferCreationState.VERIFY_BUNDLE;
        }
        break;
      case OfferCreationState.INVENTORY_COMPARE:
        this.offerCreationState = OfferCreationState.ADD_INVENTORY_ITEMS;
        break;
    }
    stepper.previous();
  }

  /**
   * Return true if any similar item was selected (at stage OfferCreationState.CREATE_BUNDLE)
   * @returns
   */
  public isSimilarItemsSelected(): boolean {
    // return this.similarFactoryService.currentItems?.length > 1;
    return this.selectedBundleProducts.length > 1;
  }

  /**
   * Fetching similar items
   * @param selectedProduct
   * @param mode
   */
  public async fetchSimilarItems(mode: SIMILAR_REQUEST_MODE, selectedProduct?: Product) {
    if (selectedProduct && !selectedProduct.UI)
      this.similarFactoryService.mapUI([selectedProduct]);
    await this.similarFactoryService.setCurrentMode(mode, true);
    await this.similarFactoryService.fetchSimilarItems(selectedProduct || this.selectedProduct, mode === SIMILAR_REQUEST_MODE.requested);
    this._currentSimilarItems[mode] = this.similarFactoryService.currentSimilarItems;
  }

  /**
   * Return similar items
   * @returns
   */
  public getSimilarItems(mode: SIMILAR_REQUEST_MODE): Product[] {
    return this._currentSimilarItems[mode];
  }

  public setSimilarItems(items: Product[] ): void {
    this._currentSimilarItems[this.similarFactoryService.getCurrentMode()]  = items;
  }

  /**
   * Return currently selected similar items
   * @returns
   */
  public getSimilarSelectedItems(): Product[] {
    return this.similarFactoryService.currentItems || [];
    // return this.selectedBundleProducts || [];
  }

  /**
   * Add similar item to selected similar items (add to bundle)
   * @param item
   */
  public async addSimilarItemToSelected(item: Product): Promise<void> {
    if (this._selectedBundleProductsIds[item.id]) {
      this.utils.notifyUser({
        text: 'item already added to bundle',
        type: NotificationType.Error
      });
      return;
    }
    await this.similarFactoryService.mergeNeighbor(item);
    this.selectedBundleProducts.push(item);
    this._selectedBundleProductsIds[item.id] = true;
    this._currentSimilarItems[SIMILAR_REQUEST_MODE.requested] = this.similarFactoryService.currentSimilarItems;
    this.updateSimilarItems$.next();
  }

  /**
   * Remove similar item from similar items list (remove neighbor)
   * @param itemId
   */
  public async removeSimilarItem(itemId: number): Promise<void> {
    await this.similarFactoryService.removeNeighbor(itemId);
    this._currentSimilarItems[SIMILAR_REQUEST_MODE.requested] = this.similarFactoryService.currentSimilarItems;
    this.updateSimilarItems$.next();
  }

  /**
   * Remove similar item from selected similar items list (remove from bundle)
   * @param item
   */
  public async removeSimilarItemFromSelected(item: Product): Promise<void> {
    this.similarFactoryService.removeFromBundle(item);
    this.selectedBundleProductsIds[item.id] = false;
    this.syncBundle();
    await this.similarFactoryService.jumpTo();
    this._currentSimilarItems[SIMILAR_REQUEST_MODE.requested] = this.similarFactoryService.currentSimilarItems;
  }

  initSimilarItems(selectedProduct: Product) {
    this.similarFactoryService.setInitialItem(selectedProduct);
  }

  public async refreshSelectedProducts(fetchSimilarItems: boolean) {
    // this.similarFactoryService.refreshSelected();
    this.syncInventory();
    if (fetchSimilarItems)
      await this.fetchSimilarItems(SIMILAR_REQUEST_MODE.requested, this.selectedProduct);
    this.updateInventoryItems$.next();
  }

  public getRetailers(): Observable<Retailer[]> {
    return this.gql.retailerForQuery({name: [], id: [], offset: 0}).pipe(map(res => cloneDeep(res.data.allRetailers.rows)));
  }

  /**
   * Return true if at least one item selected in inventory items page
   * @returns
   */
  public isInventoryItemsSelected(): boolean {
    // return this.similarFactoryService.selectedProducts?.length > 1;
    return this.selectedInventoryProducts.length > 1;
  }

  public async sendToCMS(inventoryProduct: Product, bundleProduct: Product): Promise<void> {
    // await this.similarFactoryService.sendToCMS(product);
    this._hasSentToCMS = true;
    let resources = inventoryProduct.products_resources.filter(pr => pr.resource_enabled).map(pr => pr.id);
    let obj = {
      id: bundleProduct.id,
      resources
    };
    let updatedBundleProduct = await this.similarFactoryService.createFromSimilar(obj);
    updatedBundleProduct = await this.offerService.getProduct(updatedBundleProduct.id);
    updatedBundleProduct.status = bundleProduct.status;
    this.similarFactoryService.mapUI([updatedBundleProduct]);
    Object.assign(bundleProduct, updatedBundleProduct);
  }


  /**
   * Create empty job offer request
   */
  public createOfferRequest(): void {
    this.jobOffer = {
      created_by: this.auth.getUser().id,
      private_offer: false,
      multiple_offers: false,
      same_mesh: false,
      items: [],
      allow_rank_bonus: false,
      allow_time_bonus: false,
      hidden: true,
      artists_offers_pricing: [],
      artists_offers_types: [{ type_id: 1 }],
      is_creation_tool: true,
      complexity: 1
    } as OfferRequestUI;
  }

  public async saveProduct() {
    this.selectedProduct.products_data.map(pd => pd.sort_index = null);
    this.selectedProduct.products_data[0].sort_index = 0;
    // We cannot save a product with mode than a single sub category
    // We will remove all of the irrelevant sub categories
    if (this.selectedProduct.products_sub_categories_associations.length > 1) {
      for (let i = this.selectedProduct.products_sub_categories_associations.length - 1; i >= 0; i--) {
        if (this.subCategoryId$.value !== this.selectedProduct.products_sub_categories_associations[i].sub_category_id) {
          this.selectedProduct.products_sub_categories_associations.splice(i, 1);
        }
      }
    }
    this.selectedProduct = await this.offerService.saveProduct(this.selectedProduct);
    if (this.selectedProduct.products_sub_categories_associations[0] && this.subCategoryId$.value !== this.selectedProduct.products_sub_categories_associations[0].sub_category_id) {
      this.subCategoryId$.next(this.selectedProduct.products_sub_categories_associations[0].sub_category_id);
    }

    return this.selectedProduct;
  }

  /**
   * Validate if job offer correct
   * @param similarItem
   * @returns
   */
  public async validateJobOffer(similarItem: Product): Promise<ValidateResponse> {
    // const inventoryItems = this.getSelectedInventoryItems().slice(0, 1);
    this.updateJobOffer();
    this.jobOffer.subCategoryId = this.subCategoryId$.value;
    if (this.jobOffer.subCategoryId && !this.jobOffer.category_id) {
      this.jobOffer.category_id = this.categoriesService.subCategoriesDictionary[this.jobOffer.subCategoryId].category_id;
    }
    let wrapper = {
      request: this.jobOffer,
      currentItem: similarItem,
      // selectedItems: this.getSelectedInventoryItems()
      selectedItems: this.selectedInventoryProducts
    } as OfferRequestWrapper;
    wrapper = await this.offerService.syncRequestCategories(wrapper);
    await this.offerService.syncGeometryComplexity(this.jobOffer, wrapper);
    return this.offerService.validateOfferRequest(wrapper);
  }

  /**
   * Create offer
   * @param onCreateOfferCallback - on create offer callback function
   */
  public async createOffer(onCreateOfferCallback: Function) {
    // this.jobOffer.category_id = this.categoryId$.value;
    this.selectedProduct.estimated_geometry_hours = this.complexity;
    await this.saveProduct();
    const subCategoryId = this.selectedProduct.products_sub_categories_associations[0].sub_category_id;
    const op = await this.offerCreationHelper.getDefaultPrices(subCategoryId, this.jobOffer.multiple_offers ? this.jobOffer.items.length : 1, this.selectedProduct.estimated_geometry_hours, this.jobOffer.multiple_offers, this.jobOffer.same_mesh);
    if (CALC_HOURS_TO_COMNPLETE)
      this.jobOffer.hours_to_complete = op.hours_to_complete;
    this.offerService.createOffer(this.jobOffer, onCreateOfferCallback, op);
  }

  /**
   * Return the next offer
   * @returns
   */
  public getNextOffer(): Observable<Product> {
    const options = this.getOfferRequestOptions();

    this._offerRequestOffset++;
    options.offset = this._offerRequestOffset;
    return this.offerService.skipToNextOffer(options, this.selectedProduct.id).pipe(map((res: OfferResponseData) => res.product));
  }

  public getOffer(): Observable<OfferResponseData> {
    const options = this.getOfferRequestOptions();
    return this.offerService.getOffer(options)
  }

  private getOfferRequestOptions(): ProductFilterOptions {
    const options: ProductFilterOptions = {
      notes: null,
      sort_index: [0],
      is_archived: false,
      cs_id: [1],
      limit: 1,
      order_by: "priority",
      is_desc: true
    }

    if (this.selectedRetailerId) {
      options.retailer_id = [this.selectedRetailerId];
    }

    if (this.selectedBatchId) {
      options.batch_id = this.selectedBatchId;
    }

    return options;
  }

  public setSelectedRetailerAndBatchIds(retailerId: number, batchId: number): void {
    this.selectedRetailerId = retailerId;
    this.selectedBatchId = batchId;
    this._offerRequestOffset = 0;
  }

  public restartView() {
    this.utils.forceRedirectTo('/products/create-offer');
  }

  public async updateJobOffer() {
    switch (this.comparisonInventory) {
      case BundleCompareState.NOT_RELEVANT:
        this.setDateAndPrice();
        break;
      case BundleCompareState.SIMILAR_SHAPE:
        this.jobOffer.mini_job = true;
        this.jobOffer.artists_offers_types = [{ type_id: 1 }]
        this.setDateAndPrice();
        break;
      case BundleCompareState.SAME_SHAPE:
        this.jobOffer.artists_offers_types = [{ type_id: 2 }]
        this.jobOffer.hidden = true;
        this.setDateAndPrice();
        break;
      case BundleCompareState.SAME_SHAPE_AND_COLOR:
        this.setDateAndPrice();
        break;
    }
  }

  /**
   * Setting the price of offer and the hours to complete
   */
  public setDateAndPrice() {
    this.jobOffer.hours_to_complete = this.getHoursToComplete();
    this.jobOffer.base_price = this.getOfferPrice();
    this.jobOffer.artists_offers_pricing = [{
      base_price: this.jobOffer.base_price,
      rank_bonus: 0,
      time_bonus: 0,
      group_id: null
    }];
  }

  private getHoursToComplete(): number {
    return 12 + 4 * (this.complexity - 1);
  }

  private getOfferPrice(): number {
    if (this.jobOffer.artists_offers_types[0].type_id === JobsTypes.TEXTURE) {
      const subCategoryId = this.selectedProduct.products_sub_categories_associations[0].sub_category_id;
      let price = this.categoriesService.subCategoriesDictionary[subCategoryId].texture_price || 10, additional = this.categoriesService.subCategoriesDictionary[subCategoryId].additional_texture_price || 5;
      return price + ((this.jobOffer.items.length - 1) * additional);
    }
    return this.complexityPrice;
  }

  afterOfferCreated() {
    this.restartView();
  }

  resetSelections() {
    this._currentSimilarItems = {};
    this._selectedBundleProductsIds = {};
    this._selectedInventoryProductsIds = {};
    this._selectedBundleProducts = [];
    this._selectedInventoryProducts = [];
    delete this._hasSentToCMS;
    delete this.comparisonInventory;
    delete this.complexity;
    delete this.complexityPrice;
  }

  onDestroy() {
    this.similarFactoryService.onDestroy();
    this.resetOfferCreation$.unsubscribe();
    this.enableCategoryNextButton$.unsubscribe();
    this.subCategoryId$.unsubscribe();
    this.enableComplexityNextButton$.unsubscribe();
    this.updateSimilarItems$.unsubscribe();
    this.verifyBundleNextComparisonClick$.unsubscribe();
    this.updateInventoryItems$.unsubscribe();
    this.inventoryBundleNextComparisonClick$.unsubscribe();
    this.resetSelections();
    delete this.jobOffer;
    delete this.offerCreationState;
    delete this.selectedProduct;
    this.initSubs();
    this.createOfferRequest();
  }
}
