import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import {EndpointsService} from './endpoints.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, finalize } from 'rxjs/operators';
import { StorageService } from 'ng-storage-service';
import { BroadcasterService } from 'ng-broadcaster';
import { Notification, NotificationType } from '../shared/notifications';
import { CustomRequestOptions } from './custom-request';
import { BodyService } from '../shared/body.service';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { CORS_TOKEN } from 'app/shared/utils';
import { EndPoints } from './endpoints';
import { IAlgoComplexityCalc } from 'app/product/offer-creation-tool/IAlgoComplexityCalc.interface';

@Injectable()
export class RestService {
    endpoints: EndpointsService;
    public websocketEndpoint: string;
    public notificationsWebsocketEndpoint: string;
    constructor(
        public _httpClient: HttpClient,
        public _cache: CacheService,
        private storage: StorageService,
        private broadcaster: BroadcasterService,
        private body: BodyService
    ) {
        this.endpoints = new EndpointsService();
        this.websocketEndpoint = this.endpoints.getEndpointDomain('websocket');
        this.notificationsWebsocketEndpoint = this.endpoints.getEndpointDomain(EndPoints.NOTIF_WEBSOCKETS);
    }

    private logOut() {
        this.storage.remove('user');
        this.storage.remove('token');
        this.broadcaster.broadcast('onLogout');
    };

    retailerGarment(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain('retailers') +
            '/garment', method, payload, query, options);
    }

    rolesConfig(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/role/permission', method, payload, query, options);
    }

    auth(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/session', method, payload, query, options);
    }

    resendEmail(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/email/confirm/resend', method, payload, query, options);
    }

    confirm(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/confirm', method, payload, query, options);
    }

    /**
     * Return the code of the user's country
     * @param method - request method
     * @param payload - request payload
     * @param query - request query string
     * @param options - request options
     * @returns - response observable
     */
    public getCountryCode(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/country', method, payload, query, options);
    }

    userProfile(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        if (!options)
            options = new CustomRequestOptions();
        options.showLoading = false;
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user', method, payload, query, options);
    }

    modelRequests(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/item', method, payload, query, options);
    }

    modelRequestsBatch(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/item/batch', method, payload, query, options);
    }

    jobs(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job', method, payload, query, options);
    }

    jobItem(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/item', method, payload, query, options);
    }

    tags(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/tag', method, payload, query, options);
    }

    jobResources(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource', method, payload, query, options);
    }

    jobFeedback(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/feedback', method, payload, query, options);
    }

    jobResourceAlignment(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/alignment', method, payload, query, options);
    }

    jobSubscriber(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/subscriber', method, payload, query, options);
    }

    imageBase64Convert(method: string = 'get', payload: Object = {}, query: string = '', options = new CustomRequestOptions()) {
        options.requestOptions = options.requestOptions || {};
        options.requestOptions.responseType = 'text' as 'json';
        // {responseType: text'}
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/image', method, payload, query, options);
    }

    jobFeedbackComments(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/feedback/comment', method, payload, query, options);
    }

    jobCountdown(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/countdown', method, payload, query, options);
    }

    artistUpload(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/upload', method, payload, query, options);
    }

    // preProcessedItem(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    //     return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
    //         '/product', method, payload, query, options);
    // }
    product(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product', method, payload, query, options);
    }

    afterCdn(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions): Observable<any> {
        let payloadAny = payload as any;
        if (payloadAny && !payloadAny.bucket) {
            payloadAny.bucket = 'cdn.creators3d.com';
        }
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) +
            '/cdn', method, payload, query, options);
    }

    retailers(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/retailer', method, payload, query, options);
    }

    similarItemsByTag(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/tag', method, payload, query, options);
    }

    // similarItemsByCompare(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    //     return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
    //         '/garment/compare', method, payload, query, options);
    // }

    metabase(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/metabase', method, payload, query, options);
    }

    resourceDetails(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/details', method, payload, query, options);
    }

    get(url: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(url, 'get', null, '', options);
    }

    artistResource(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/resource/upload', method, payload, query, options);
    }

    downloadResource(query: string = '') {
        let url = this.endpoints.getEndpointDomain(EndPoints.CREATORS) + '/artist/job/resource/download/' + query;
        url += url.indexOf('?') > -1 ? '&' : '?';
        url += 'token=' + this.storage.get('token');
        window.open(url);
        // return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
        //     '/artist/job/resource/download', method, payload, query, options);
    }

    downloadSource(sourceId: number) {
        if (!sourceId) return;
        let query = '?sid=' + sourceId;
        const token =  this.storage.get('token');
        if (token) {
            let url = this.endpoints.getEndpointDomain(EndPoints.CREATORS) + '/artist/job/resource/source/files' + query + '&token=' + token;
        window.open(url);
        }
    }

    createFromSimilar(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            // '/item/similar', method, payload, query, options);
            '/product/similar', method, payload, query, options);
    }

    productCategory(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/category', method, payload, query, options);
    }

    artistSelfRanking(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/self/ranking', method, payload, query, options);
    }

    // artistJobItemRanking(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    //     return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
    //         '/artist/job/item/rank', method, payload, query, options);
    // }

    artistJobResourceRanking(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/rank', method, payload, query, options);
    }

    gltf(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/gltf', method, payload, query, options);
    }

    invoice(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/invoice', method, payload, query, options);
    }

    // jobImagesDownload(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    //     return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
    //         '/artist/item/image/download', method, payload, query, options);
    // }

    // offerImagesDownload(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    //     return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
    //         '/artist/offer/image/download', method, payload, query, options);
    // }

    notificationTypes(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/notification/type', method, payload, query, options);
    }

    broadcastNotification(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/notification/broadcast', method, payload, query, options);
    }

    pushDetails(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/push/details', method, payload, query, options);
    }

    groups(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/group', method, payload, query, options);
    }

    feedbackmasters(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/group/fm', method, payload, query, options);
    }

    artistRetailerFm(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/retailer/fm', method, payload, query, options);
    }

    groupUsers(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/group/user', method, payload, query, options);
    }

    groupOffers(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/offer/group', method, payload, query, options);
    }

    expertise(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/expertise', method, payload, query, options);
    }

    userExists(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/exists', method, payload, query, options);
    }

    resourceFinalApproved(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/retailers', method, payload, query, options);
    }

    requestReason(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/reason', method, payload, query, options);
    }

    productFeedback(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/resource/feedback', method, payload, query, options);
    }

    artistContractor(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/resource/feedback/artist-contractor', method, payload, query, options);
    }

    productFeedbackComment(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/resource/feedback/comment', method, payload, query, options);
    }

    artistOffer(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/offer', method, payload, query, options);
    }

    artistOfferUser(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/offer/user', method, payload, query, options);
    }

    artistOfferGroup(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/offer/group', method, payload, query, options);
    }

    artistOfferItem(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/offer/item', method, payload, query, options);
    }

    uploadUSDZ(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/usdz', method, payload, query, options);
    }

    compressResource(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/compress', method, payload, query, options);
    }

    jobExtend(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/extend', method, payload, query, options);
    }

    survey(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/survey', method, payload, query, options);
    }

    productSubcategoryPoly(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/subcategory/poly/spec', method, payload, query, options);
    }

    offerCreationToolCalculateComplexity(payload: IAlgoComplexityCalc): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            '/product/complexity', 'post', payload);
    }

    /**
     * Updating the subcategory complexity image url
     * @param payload
     */
    productSubcategoryComplexity(payload: any): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            '/product/complexity/set', 'post', payload);
    }

    /**
     * Delete the exiting sub-category complexity
     * @param complexityId
     */
    productSubcategoryDeleteComplexity(complexityId: number): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            `/product/complexity/set/${complexityId}`, 'delete');
    }

    /**
     * Add new complexity image
     * @param payload
     * @returns
     */
    productSubcategoryComplexityImage(payload: any): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            '/product/complexity/set/image', 'post', payload);
    }

    /**
     * Delete the image from complexity manager table
     * @param imgId
     * @returns
     */
    productSubcategoryDeleteComplexityImage(imgId: number): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            `/product/complexity/set/image/${imgId}`, 'delete');
    }

  productSubcategoryUpdateComplexityImage(payload: any, imgId: number): Observable<any> {
    return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
      `/product/complexity/set/image/${imgId}`, 'put', payload);
  }

    /**
     * Create Product complexity guide
     * @param payload
     * @returns
     */
    productComplexityGuide(payload: {name: string}): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            `/product/complexity/guide`, 'post', payload);
    }

    productComplexityGuideUpdate(payload, guideId: number): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            `/product/complexity/guide/${guideId}`, 'put', payload);
    }

    productComplexityDelete(guideId: number): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            `/product/complexity/guide/${guideId}`, 'delete');
    }

    productComplexitySet(payload): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            `/product/complexity/set`, 'post', payload);
    }

    productComplexitySetUpdate(payload, setId: number): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            `/product/complexity/set/${setId}`, 'put', payload);
    }

    productComplexityGuideSubcategoryUpdate(payload, subcategoryId): Observable<any> {
        return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
            `/product/complexity/guide/subcategory/${subcategoryId}`, 'put', payload);
    }

    productSubcategoryAttachment(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/subcategory/attachment', method, payload, query, options);
    }

    productSubcategoryDimensions(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/subcategory/dimension', method, payload, query, options);
    }

    jobResourceMerge(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/merge', method, payload, query, options);
    }

    uploadSourceFile(payload: FormData, query: string = '', options?: CustomRequestOptions): Observable<HttpEvent<Object>> {
        // return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
        //     '/artist/job/resource/source/files', method, payload, query, options);
        return this.getPostRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/source/files' + query, payload, options);
        // const key = this.endpoints.getEndpointDomain('creators') + '/artist/job/resource/source/files';
        // if (options.showLoading) {
        //     this.body.increaseNumOfRequestsInProgress();
        // }
        // const sub = this._http.post(key, payload, {
        //     reportProgress: true,
        //     observe: 'events'
        // });
        // sub.subscribe((res) => {
        //     if (res.type == HttpEventType.Response) {
        //         this.body.decreaseNumOfRequestsInProgress();
        //     }
        // },
        // (res: HttpErrorResponse) => {
        //     this.body.decreaseNumOfRequestsInProgress();
        // })
        // return sub;
    }

    jobResourcesMultipart(payload: FormData, query: string = '', options?: CustomRequestOptions) {
        // return this.getPostRequest(this.endpoints.getEndpointDomain('creators') +
        //     '/artist/job/resource/multipart' + query, payload, options);
        return this.getPostRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) +
            '/resource' + query, payload, options);
    }

    // resourcesSourceMultipart(payload: FormData, query: string = '', options?: CustomRequestOptions) {
    //     return this.getPostRequest(this.endpoints.getEndpointDomain('upload') +
    //         '/source' + query, payload, options);
    // }

    afterSource(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) +
            '/source', method, payload, query, options);
    }

    afterResource(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) +
            '/resource', method, payload, query, options);
    }

    afterRender(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) +
            '/render', method, payload, query, options);
    }

    pixel(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.COLLECTOR) +
            '/reports', method, payload, query, options);
    }

    topOffersJobs(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/top/offers/top/jobs', method, payload, query, options);
    }

    payoneerLink(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/payoneer', method, payload, query, options);
    }

    downloadFromCDN(path: string) {
        return this._httpClient.get(this.endpoints.getEndpointDomain(EndPoints.CDN) + path);
        // return this._httpClient.request('get', this.endpoints.getEndpointDomain('cdn') + path);
    }

    taggingProject(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/tagging/project', method, payload, query, options);
    }

    taggingProjectAttributes(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/tagging/project/attribute', method, payload, query, options);
    }

    taggingProjectAttributesEnum(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/tagging/project/attribute/enum', method, payload, query, options);
    }

    taggingProjectAttributesProduct(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/tagging/project/product', method, payload, query, options);
    }

    taggingProjectDataValue(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/tagging/project/data/value', method, payload, query, options);
    }

    mergeOffers(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/offer/merge', method, payload, query, options);
    }

    offerItemPolySpec(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/offer/item/poly/spec', method, payload, query, options);
    }

    jobItemPolySpec(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/item/poly/spec', method, payload, query, options);
    }

    resourceScale(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/scale', method, payload, query, options);
    }

    rankDefinition(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/ranking/definition', method, payload, query, options);
    }

    meshLibJSON(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CDN) +
            '/site/mesh-lib.json?v=5', method, payload, query, options);
        // return this.executeRequest('https://cdncreators3dcom.blob.core.windows.net/public/site/mesh-lib.json?v=5',
        //     method, payload, query, options);
    }

    fetchQNA(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            let response = await fetch(`${this.endpoints.getEndpointDomain(EndPoints.CDN)}/site/qna.txt?v=1`);
            resolve(await response.text());
        });
    }

    similarItems(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/similar/items', method, payload, query, options);
        // return this.executeRequest('https://cdncreators3dcom.blob.core.windows.net/public/site/mesh-lib.json?v=5',
        //     method, payload, query, options);
    }

    adjustmentsPresets(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/user/preset', method, payload, query, options);
    }

    jobComment(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/private/comment', method, payload, query, options);
    }

    offerComment(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/offer/private/comment', method, payload, query, options);
    }

    reconstruction(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/image/reconstruction', method, payload, query, options);
    }


    artistsLibraries(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/libraries', method, payload, query, options);
    }

    artistsMeshes(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/meshes', method, payload, query, options);
    }

    polygonReduction(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/transform', method, payload, query, options);
    }

    alignmentRender(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/artist/job/resource/alignment/render', method, payload, query, options);
    }

    warmUpSimilarItems(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CREATORS) +
            '/product/batch/similar', method, payload, query, options);
    }

    geometryAccuracy(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
      return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
          '/artist/job/resource/geometry/accuracy', method, payload, query, options);
    }

    colorComparison(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
      return this.executeRequest(this.endpoints.getEndpointDomain('creators') +
          '/artist/job/resource/color-comparison', method, payload, query, options);
    }

    getCreatorsRoute(): string {
        return this.endpoints.getEndpointDomain(EndPoints.CREATORS);
    }

    getToken(): string {
        return this.storage.get('token');
    }

    meshTextureLibJSON(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
        if (!options)
            options = new CustomRequestOptions();
        options.dontAddToken = true;
        return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CDN) +
            '/site/meshes_textures.json', method, payload, query, options);
    }

    private getPostRequest(url: string, payload: FormData, options: CustomRequestOptions = new CustomRequestOptions()): Observable<HttpEvent<Object>> {
        if (this.storage.has('token') && url.indexOf('?token=') == -1 && url.indexOf('&token=') == -1) {
            if (url.indexOf('?') > -1)
                url += '&token=' + this.storage.get('token');
            else
                url += '?token=' + this.storage.get('token');
        }

        // let safeParse = (str: any) => {
        //     try {
        //         return JSON.parse(str);
        //     }
        //     catch (e) {
        //         return str;
        //     }
        // }

        // return Observable.create((observer) => {
        //     let xhr = new XMLHttpRequest();
        //     xhr.onreadystatechange = () => {
        //         if (xhr.readyState == 4) {
        //             if (xhr.status == 200) {
        //                 observer.next({
        //                     type: HttpEventType.Response,
        //                     body: safeParse(xhr.response)
        //                 });
        //                 observer.completed();
        //             } else {
        //                 observer.error({
        //                     type: HttpEventType.Response,
        //                     body: safeParse(xhr.response)
        //                 });
        //             }
        //         }

        //         return () => {
        //             xhr.abort();
        //         };
        //     }
        //     xhr.upload.onprogress = (evt) => {
        //         console.log(evt);
        //         observer.next({
        //             type: HttpEventType.UploadProgress,
        //             loaded: evt.loaded,
        //             total: evt.total
        //         });
        //     }

        //     xhr.open("POST", url, true);
        //     xhr.send(payload);
        // });


        return this._httpClient.post(url, payload, {
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders()
            // headers: new HttpHeaders({
            //     'Content-Type': undefined
            // })
        });
    }

    private executeRequest(url: string, method: string = 'get', payload: Object = {}, query: string = '', options: CustomRequestOptions = new CustomRequestOptions()): Observable<Object> {
        let key = url + query;
        if (this.endpoints.isLocalhost() && key.indexOf(this.endpoints.getEndpointDomain(EndPoints.CREATORS)) === 0) {
            if (key.indexOf('?') > -1)
                key += `&cors_override_key=${CORS_TOKEN}`;
            else
                key += `?cors_override_key=${CORS_TOKEN}`;
        }
        if (!options.dontAddToken) {
            if (this.storage.has('token') && key.indexOf('?token=') == -1 && key.indexOf('&token=') == -1) {
                if (key.indexOf('?') > -1)
                    key += '&token=' + this.storage.get('token');
                else
                    key += '?token=' + this.storage.get('token');
            }
        }
        method = method.toLowerCase();
        if (!options.ignoreCache && this._cache.has(key)) {
            return of(this._cache.get(key));
        }
        if (options.showLoading) {
            this.body.increaseNumOfRequestsInProgress();
        }

        // let req = new HttpRequest(method, key, {
        //     body: payload,
        //     reportProgress: options.requestOptions ? options.requestOptions.reportProgress : false,
        //     observe: options.requestOptions ? options.requestOptions.observe : undefined
        // });

        options.requestOptions = options.requestOptions || {};
        options.requestOptions.body = payload;

        return this._httpClient.request(method, key, options.requestOptions)
            // return this._httpClient[method](key, payload, options.requestOptions)
            // return this._http[method](key, payload, options.requestOptions)
            // return this._http.request(req)
            // .map(res => {
            //     console.log(res.status);
            //     if (res.status < 200 || res.status >= 300) {
            //         throw new Error('This request has failed ' + res.status);
            //     }
            //     else {
            //         try {
            //             return res.json();
            //         }
            //         catch (e) {
            //             return res._body;
            //         }
            //     }
            // })
            .pipe(tap(json => {
                // if (options.showLoading) {
                //     this.body.decreaseNumOfRequestsInProgress();
                // }
                if (!options.ignoreCache)
                    this._cache.set(key, json);
            }))
            .pipe(catchError((error: any) => {
                // if (options.showLoading) {
                //     // if (--this.numOfRequestsInProgress == 0)
                //     //     this.requestsInProgress.next(false);
                //     this.body.decreaseNumOfRequestsInProgress();
                // }
                let data: Notification = {
                    text: error ? error._body : 'unknown error',
                    type: NotificationType.Error,
                    action: 'O.K.'
                }
                this.broadcaster.broadcast('notifyUser', data);
                if (error.status == 401) {
                    // this.body.resetNumOfRequestsInProgress();
                    this.logOut();
                }
                if (error.status == 403) {
                    // this.body.resetNumOfRequestsInProgress();
                    // this.router.navigate(['/jobs']);
                }
                return throwError(error);
            }))
            .pipe(finalize(() => {
                if (options.showLoading) {
                    this.body.decreaseNumOfRequestsInProgress();
                }
            }));
        // .share();
    }

}
