<div class="collision-pairing-rows">

  <div class="collision-pairing-rows__row disp-flex space-between flex-align-center"
       [ngClass]="{'disabled': disableRows}"
       *ngFor="let row of rows; let i = index"
       (mouseover)='onMouseover(i)' (mouseout)='onMouseout(i)'>

    <ng-container [ngSwitch]="row">
      <ng-container *ngSwitchCase="RowStatus.empty"><span class="empty">Empty pair</span></ng-container>
      <ng-container *ngSwitchCase="RowStatus.matched">Pair matched</ng-container>
      <ng-container *ngSwitchCase="RowStatus.matchedTransition">Pair matched</ng-container>
      <ng-container *ngSwitchDefault><span class="match-new-pair">Match new pair</span></ng-container>
    </ng-container>

    <div [ngSwitch]="row" class="disp-flex flex-align-center">
      <ng-container *ngSwitchCase="RowStatus.matched">
        <div class="check-delete-container">
          <mat-icon class="check-icon green">check</mat-icon>
          <button class="delete-icon pointer" mat-icon-button (click)="removePoint(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="dot" [ngClass]="{'fill': row === RowStatus.matchingSecond || row === RowStatus.matchedTransition}"></div>
        <div class="dot" [ngClass]="{'fill': row === RowStatus.matchedTransition}"></div>
      </ng-container>
    </div>
  </div>

</div>
