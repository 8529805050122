<div class="referral-dialog-wrapper">
    <div class="referral-dialog-title-wrapper">
        <div class="referral-dialog-title-icon"></div>
        <div class="referral-dialog-title">
            <div class="referral-dialog-title-primary">Refer a Friend</div>
            <div class="referral-dialog-title-secondary">Earn $30 for your friend's first approved 3D job!</div>
        </div>
    </div>
    <div class="referral-dialog-link-wrapper">
        <div class="referral-dialog-link">{{url}}</div>
        <div class="referral-dialog-link-copy-button" (click)="saveToClipboard()">Copy Link</div>
    </div>
    <div class="referral-dialog-note">
        Share this link with your friends to join Creators and take their first job offer!
    </div>
    <mat-icon class="referral-dialog-close-icon" (click)="onCloseClick()">close</mat-icon>
</div>