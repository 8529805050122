<div class="wrap" [ngClass]="{'open': isOpen}">
  <button mat-fab color="accent" (click)="toggle()" class="accent-bg" *ngIf="!isOpen">
    <mat-icon class="contrast-text" aria-label="support">contact_support</mat-icon>
  </button>
  <div class="panel box-content">
    <button mat-icon-button color="accent" (click)="toggle()" *ngIf="isOpen" class="accent-bg close-btn">
      <mat-icon class="contrast-text" aria-label="support">close</mat-icon>
    </button>
    <div *ngIf="!showJobs" class="list">
      <h4>Select the subject of the discussion:</h4>
      <button mat-raised-button color="primary" *ngFor="let btn of butons" (click)="select(btn)"
        [ngClass]="btn.value.css" [matTooltip]="btn.key">
        {{btn.key}}
      </button>
    </div>
    <div *ngIf="showJobs" class="list jobs scrollbar">
      <h4>Select the related job:</h4>
      <a routerLink="/job/{{job.id}}" *ngFor="let job of jobs" (click)="selectJob(job)" mat-button class="ghost ellipsis">
        <img [src]="job.artists_jobs_items[0].artists_items[0].artists_items_data[0]?.small_image_url" alt="job">
        {{job.artists_jobs_items[0]?.artists_items[0]?.name}}
      </a>
    </div>
  </div>
</div>
<!-- <button mat-fab color="accent" (click)="supportRequest()" class="accent-bg">
  <mat-icon class="contrast-text" aria-label="support">contact_support</mat-icon>
</button> -->