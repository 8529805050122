import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hexa-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() disabled: boolean;
  @Input() color: 'primary' | 'secondary' = 'primary';

  constructor() { }
}
