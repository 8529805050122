import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {EndPoints, Environment} from './endpoints';

@Injectable({
  providedIn: 'root'
})
export class EndpointsService {
    private endpoints: Map<string, string>;

    constructor() {
        this.endpoints = new Map();
        this.setEndpoints();
    }

    public getEndpointDomain(name: string) {
        return this.endpoints.get(name);
    }

    isLocalhost(): boolean {
        return location.hostname === 'localhost';
    }

    getCurrentEnvironment(): Environment {
      return window.location.host == 'www.creators3d.com' ? Environment.PRODUCTION : window.location.host == 'www.c3dstaging.com' ? Environment.QA : Environment.DEV;
    }

    private setEndpoints() {
        const cDomain = environment.endPoints.c3dDomain;
        const hDomain = environment.endPoints.cmsDomain;

        this.endpoints.set(EndPoints.THREE_JS_VIEWER, environment.endPoints.threejsviewer);
        this.endpoints.set(EndPoints.RETAILERS, `https://r.${hDomain}`);
        this.endpoints.set(EndPoints.CMS, `https://cms.${hDomain}`);
        this.endpoints.set(EndPoints.CREATORS, `https://a.${cDomain}`);
        this.endpoints.set(EndPoints.WEBSOCKET, `https://ac.${cDomain}`);
        this.endpoints.set(EndPoints.NOTIF_WEBSOCKETS, `https://notifications.${cDomain}`);
        this.endpoints.set(EndPoints.GRAPH, `https://graph.${cDomain}`);
        this.endpoints.set(EndPoints.COLLECTOR, `https://collector.${cDomain}`);
        this.endpoints.set(EndPoints.UPLOAD, `https://upload.${cDomain}`);
        this.endpoints.set(EndPoints.CDN, 'https://cdn.creators3d.com');
        // this.endpoints.set(EndPoints.PUBLIC_CDN, 'https://public.creators3d.com');

      if (environment.rootCssClass) {
        document.body.classList.add(environment.rootCssClass);
      }
    }
}
