import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { JobsTypesService } from './jobs-types.service';
import { JobTypes } from './jot-type';

@Injectable()
export class JobsTypesResolve  {

    constructor(
        private jobsTypesService: JobsTypesService
    ) {

    }

    resolve() {
        return this.jobsTypesService.getCachedTypes();
    }

}