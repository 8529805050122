import { Component, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { PolygonSpecifications } from 'app/offer/offers';
import { JobsTypes, KeyValuePair } from 'app/shared/enums';
import { EnumsService } from 'app/shared/enums.service';

const noop = () => {
};

@Component({
  selector: 'app-poly-specs',
  templateUrl: './poly-specs.component.html',
  styleUrls: ['./poly-specs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PolySpecsComponent),
      multi: true
    }
  ]
})
export class PolySpecsComponent implements ControlValueAccessor {
  @Input() hoverEffect: boolean;
  @Input() withDelete = true;
  @Input() withDuplicate = true;
  @Input() disabledPolyCount: boolean;
  @Output() onChange = new EventEmitter<PolygonSpecifications>();
  @Output() duplicate = new EventEmitter<PolygonSpecifications>();
  @Output() delete =  new EventEmitter<PolygonSpecifications>();
  public polyTypes: Array<KeyValuePair>;
  public polyShapeTypes: Array<KeyValuePair>;
  private _innerValue: PolygonSpecifications = {} as PolygonSpecifications;

  constructor(private enums: EnumsService) {
    this.polyTypes = this.enums.getPolyTypes();
    this.polyShapeTypes = this.enums.getPolyShapeTypes();
    this.registerOnChange(() => {
      this.onChange.emit(this.value);
    });
  }
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  get value(): PolygonSpecifications {
    return this._innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    this._innerValue = v;
    this.onChangeCallback(v);
  }

  writeValue(value: PolygonSpecifications): void {
    this._innerValue = value;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onValueChange() {
    this.onChange.emit(this.value);
  }

  public get JobType(): typeof JobsTypes {
    return JobsTypes;
  }

  public onDuplicate(polySpec: PolygonSpecifications): void {
    this.duplicate.emit(polySpec)
  }

  public onDelete(polySpec: PolygonSpecifications): void {
    this.delete.emit(polySpec)
  }
}
