export interface Software {
    id: number;
    enum_id: number;
    software_name: string;
    software_image_url: string;
}

export interface SoftwareQueryData {
    software_enum: Array<Software>;
}

export enum SoftwareType {
    CREATION_SOFTWARE = 1,
    RENDERING_ENGINE = 2,
    EXPERTISE = 3,
}