import { NgModule } from '@angular/core';
import { ChatComponent } from './chat/chat.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CustomNotification } from './notifications/custom-notification';
import { CommonModule } from '@angular/common';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SpecificationsSliderComponent } from './specifications-slider/specifications-slider.component';
import { SimpleCarouselSliderComponent } from './simple-carousel-slider/simple-carousel-slider.component';
import { UsersAutocompleteComponent } from './users-autocomplete/users-autocomplete.component';
import { OpenUsersPipe } from './open-users.pipe';
import { RankingComponent } from './ranking/ranking.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ChatConversationComponent } from './chat-conversation/chat-conversation.component';
import { UserGroupsSelectComponent } from '../auth/user-groups-select/user-groups-select.component';
import { KeyValuePurePipe } from '../shared/key-value-pure.pipe';
import { ImagesMenuComponent } from './images-menu/images-menu.component';
import { MomentPipe } from '../shared/moment.pipe';
import { LinkifyPipe } from '../shared/linkify.pipe';
import { SupportComponent } from './support/support.component';
import { HomescreenTeaserComponent } from './homescreen-teaser/homescreen-teaser.component';
import { ScrollTopComponent } from '../shared/scroll-top/scroll-top.component';
import { FooterComponent } from './footer/footer.component';
import { CookiesComplianceComponent } from './cookies-compliance/cookies-compliance.component';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { RouterModule } from '@angular/router';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ExcludePipe } from 'app/shared/exclude.pipe';
import { OfferCreationFieldsComponent } from 'app/offer/offer-creation-fields/offer-creation-fields.component';
import { RetailersAutocompleteComponent } from './retailers-autocomplete/retailers-autocomplete.component';
import { PolySpecsComponent } from 'app/product/poly-specs/poly-specs.component';
import { ColorSamplingStatusComponent } from './color-sampling-status/color-sampling-status.component';
import { NumberCheckComponent } from './number-check/number-check.component';
import { SpecificationsInstanceComponent } from './specifications-instance/specifications-instance.component';
import { ArtistSoftwaresComponent } from 'app/auth/artist-softwares/artist-softwares.component';
import { OfferVariationsComponent } from 'app/offer/offer/offer-variations/offer-variations.component';
import { ButtonToggleGroupComponent } from './button-toggle-group/button-toggle-group.component';
import { ModelOpacityComponent } from '../job/model-opacity/model-opacity.component';
import { SliderComponent } from './slider/slider.component';
import { ColorSamplingResultComponent } from './color-sampling-result/color-sampling-result.component';
import { LoaderComponent } from './loader/loader.component';
import { ColorSamplingHelpModalComponent } from './color-sampling-help-modal/color-sampling-help-modal.component';
import { ToggleComponent } from './toggle/toggle.component';
import { ButtonComponent } from './button/button.component';
import { GeneralSpecsComponent } from './general-specs/general-specs.component';
import { CollisionStepIndicatorComponent } from './collision-step-indicator/collision-step-indicator.component';
import { CollisionPairingRowsComponent } from './collision-pairing-rows/collision-pairing-rows.component';
import { ShortNumberPipe } from './short-number.pipe';
import { ChipInputComponent } from './chip-input/chip-input.component';
import { EmojisComponent } from './emojis/emojis.component';
import { ImagesCompareComponent } from './images-compare/images-compare.component';
import { ComplexityLevelDialogComponent } from 'app/product/complexity-level-dialog/complexity-level-dialog.component';
import { ComplexityLevelComponent } from 'app/product/offer-creation-tool/complexity-level/complexity-level.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    ChatComponent,
    NotificationsComponent,
    CustomNotification,
    TextEditorComponent,
    SpecificationsSliderComponent,
    SimpleCarouselSliderComponent,
    UsersAutocompleteComponent,
    OpenUsersPipe,
    RankingComponent,
    FileUploadComponent,
    ProgressBarComponent,
    ChatConversationComponent,
    UserGroupsSelectComponent,
    ImagesMenuComponent,
    KeyValuePurePipe,
    MomentPipe,
    LinkifyPipe,
    SupportComponent,
    HomescreenTeaserComponent,
    ScrollTopComponent,
    FooterComponent,
    CookiesComplianceComponent,
    DropdownButtonComponent,
    AutocompleteComponent,
    ExcludePipe,
    OfferCreationFieldsComponent,
    ChipInputComponent,
    PolySpecsComponent,
    ColorSamplingStatusComponent,
    NumberCheckComponent,
    RetailersAutocompleteComponent,
    SpecificationsInstanceComponent,
    ArtistSoftwaresComponent,
    OfferVariationsComponent,
    ButtonToggleGroupComponent,
    ModelOpacityComponent,
    SliderComponent,
    ColorSamplingResultComponent,
    LoaderComponent,
    ColorSamplingHelpModalComponent,
    ToggleComponent,
    ButtonComponent,
    GeneralSpecsComponent,
    ShortNumberPipe,
    CollisionStepIndicatorComponent,
    CollisionPairingRowsComponent,
    EmojisComponent,
    ImagesCompareComponent,
    ComplexityLevelComponent,
    ComplexityLevelDialogComponent
  ],
  exports: [
    ChatComponent,
    NotificationsComponent,
    CustomNotification,
    TextEditorComponent,
    SpecificationsSliderComponent,
    SimpleCarouselSliderComponent,
    UsersAutocompleteComponent,
    OpenUsersPipe,
    FlexLayoutModule,
    MaterialModule,
    RankingComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadComponent,
    ProgressBarComponent,
    ChatConversationComponent,
    UserGroupsSelectComponent,
    ImagesMenuComponent,
    KeyValuePurePipe,
    MomentPipe,
    LinkifyPipe,
    SupportComponent,
    HomescreenTeaserComponent,
    ScrollTopComponent,
    FooterComponent,
    CookiesComplianceComponent,
    DropdownButtonComponent,
    AutocompleteComponent,
    ExcludePipe,
    OfferCreationFieldsComponent,
    ChipInputComponent,
    PolySpecsComponent,
    RetailersAutocompleteComponent,
    ColorSamplingStatusComponent,
    NumberCheckComponent,
    SpecificationsInstanceComponent,
    ArtistSoftwaresComponent,
    OfferVariationsComponent,
    ButtonToggleGroupComponent,
    ModelOpacityComponent,
    ToggleComponent,
    ButtonComponent,
    LoaderComponent,
    CollisionStepIndicatorComponent,
    CollisionPairingRowsComponent,
    SliderComponent,
    GeneralSpecsComponent,
    ShortNumberPipe,
    EmojisComponent,
    ImagesCompareComponent,
    ComplexityLevelComponent,
    ComplexityLevelDialogComponent
  ]
})
export class UiComponentsModule {
}
