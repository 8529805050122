export interface ResumableSubject {
    state: ResumableState;
    object?: ResumableCallback;
}

export interface ResumableCallback {
    message?: any;
    progress?: number;
}

export enum ResumableState {
    FILE_PROGRESS = 1,
    COMPLETE = 2,
    ERROR = 3,
}

export class ResumableCDN {
    bucket: string;
    compress: boolean;
    uploaded_file_url: string;
    uploaded_file_name: string;
    constructor() {
        this.bucket = "cdn.creators3d.com";
    }
}