import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'hexa-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // useExisting: SliderComponent,
      useExisting: forwardRef(() => SliderComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() min = 0;
  @Input() max = 1;
  @Input() step = 0.05;
  public input$ = new Subject<number>();
  private _value: number

  constructor() {
    this.input$.pipe(debounceTime(50)).subscribe((change: number) => this.value = change);
  }

  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
    this.onChange(this.value);
    this.onTouched(this.value);
  }

  onChange: any = () => {}
  onTouched: any = () => {}

  writeValue(val: number): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
