import { trigger, state, animate, style, transition, keyframes, query, group } from '@angular/animations';

// export const routerTransition = trigger('routerTransition', [
//     state('void', style({ position: 'relative' })),
//     state('*', style({ position: 'relative' })),
//     transition(':enter', [
//         // style({ transform: 'translateY(25%)', opacity: '0' }),
//         // animate('0.5s ease-out', style({ transform: 'translateY(0%)', opacity: '1' }))
//         style({ opacity: '0' }),
//         animate('0.7s', keyframes([
//             style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '0', transform: 'translate3d(0, 30vh, 0)', offset: 0 }),
//             style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '1', transform: 'translate3d(0, -20px, 0)', offset: 0.6 }),
//             style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '1', transform: 'translate3d(0, 10px, 0)', offset: 0.75 }),
//             style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '1', transform: 'translate3d(0, -5px, 0)', offset: 0.9 }),
//             style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '1', transform: 'translate3d(0, 0, 0)', offset: 1 })
//         ]))
//     ])
//     // ,
//     // transition(':leave', [
//     //     // style({ transform: 'translateY(0%)', opacity: '1' }),
//     //     // animate('0.5s ease-in', style({ transform: 'translateY(25%)', opacity: '0' }))
//     //     style({ opacity: '1' }),
//     //     animate('0.7s', keyframes([
//     //         style({ opacity: '1', transform: 'translate3d(0, 10px, 0)', offset: 0.2 }),
//     //         style({ opacity: '1', transform: 'translate3d(0, -20px, 0)', offset: 0.4 }),
//     //         style({ opacity: '1', transform: 'translate3d(0, -20px, 0)', offset: 0.45 }),
//     //         style({ opacity: '0', transform: 'translate3d(0, 30vh, 0)', offset: 1 })
//     //     ]))
//     // ])
// ]);


export const routerTransition = trigger('routerTransition', [
    transition('* <=> *', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
        // group([
        //     query(':enter', [
        //         style({ transform: 'translateX(100%)' }),
        //         animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
        //     ], { optional: true }),
        //     query(':leave', [
        //         style({ transform: 'translateX(0%)' }),
        //         animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
        //     ], { optional: true })
        // ])
        group([
            query(':enter', [
                style({ opacity: '0', filter: 'blur(12px)' }),
                animate('0.35s ease-out', style({ opacity: '1', filter: 'blur(0)' }))
            ], { optional: true }),
            query(':leave', [
                style({ opacity: '1', filter: 'blur(0)' }),
                animate('0.35s ease-in', style({ opacity: '0', filter: 'blur(12px)' }))
            ], { optional: true })
        ])
    ])
]);

// export function routerTransition() {
//     // return slideToLeft();
//     return slideToTop();
// }

// function slideToLeft() {
//     return trigger('routerTransition', [
//         state('void', style({ position: 'relative', width: '100%' })),
//         state('*', style({ position: 'relative', width: '100%' })),
//         transition(':enter', [  // before 2.1: transition('void => *', [
//             style({ transform: 'translateX(100%)' }),
//             animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
//         ]),
//         transition(':leave', [  // before 2.1: transition('* => void', [
//             style({ transform: 'translateX(0%)' }),
//             animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
//         ])
//     ]);
// }

// function slideToTop() {
//     return trigger('routerTransition', [
//         state('void', style({ position: 'relative' })),
//         state('*', style({ position: 'relative' })),
//         transition(':enter', [
//             // style({ transform: 'translateY(25%)', opacity: '0' }),
//             // animate('0.5s ease-out', style({ transform: 'translateY(0%)', opacity: '1' }))
//             style({ opacity: '0' }),
//             animate('0.7s', keyframes([
//                 style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '0', transform: 'translate3d(0, 30vh, 0)', offset: 0 }),
//                 style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '1', transform: 'translate3d(0, -20px, 0)', offset: 0.6 }),
//                 style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '1', transform: 'translate3d(0, 10px, 0)', offset: 0.75 }),
//                 style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '1', transform: 'translate3d(0, -5px, 0)', offset: 0.9 }),
//                 style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: '1', transform: 'translate3d(0, 0, 0)', offset: 1 })
//             ]))
//         ])
//         // ,
//         // transition(':leave', [
//         //     // style({ transform: 'translateY(0%)', opacity: '1' }),
//         //     // animate('0.5s ease-in', style({ transform: 'translateY(25%)', opacity: '0' }))
//         //     style({ opacity: '1' }),
//         //     animate('0.7s', keyframes([
//         //         style({ opacity: '1', transform: 'translate3d(0, 10px, 0)', offset: 0.2 }),
//         //         style({ opacity: '1', transform: 'translate3d(0, -20px, 0)', offset: 0.4 }),
//         //         style({ opacity: '1', transform: 'translate3d(0, -20px, 0)', offset: 0.45 }),
//         //         style({ opacity: '0', transform: 'translate3d(0, 30vh, 0)', offset: 1 })
//         //     ]))
//         // ])
//     ]);
// }