export interface Link {
    order: number;
    label: string;
    route?: string;
    type: LinkType;
    action?: string;
    side: string;
    cssClass?: string;
    icon?: string;
    count?: number;
    inMenu?: boolean;
    inBottomMenu?: boolean;
}

export enum LinkType {
    ANCHOR = 1,
    BUTTON = 2,
    EXTERNAL = 3
    // TOGGLE = 3,
}