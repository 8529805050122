import { Injectable } from '@angular/core';
// import {environment} from '../../environments/environment';
// import {BrowserTracing} from '@sentry/tracing';
// import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  private static SENTRY_PROJECT_DSN = 'https://41d4f164ad654ceeb796ae5846fb9b85@o323550.ingest.sentry.io/6729130';

  constructor() { }

  static init() {
    // Sentry.init({
    //   dsn: this.SENTRY_PROJECT_DSN,
    //   integrations: [
    //     new BrowserTracing({
    //       tracingOrigins: ['localhost', environment.sentry.tracingOrigins],
    //       routingInstrumentation: Sentry.routingInstrumentation,
    //     }),
    //   ],
    //   beforeSend(event: Sentry.Event) {
    //     // [Modify the event here]
    //     // delete event.tags;

    //     // return only viable data
    //     const {
    //       exception,
    //       level,
    //       event_id,
    //       timestamp,
    //       // request,
    //       breadcrumbs
    //     } = event;

    //     return {
    //       exception,
    //       level,
    //       event_id,
    //       timestamp,
    //       environment: event.environment, // 'environment' is a shadowed property here, hence the different format
    //       breadcrumbs
    //     };
    //   },

    //   // Set tracesSampleRate to 1.0 to capture 100%
    //   // of transactions for performance monitoring.
    //   // We recommend adjusting this value in production
    //   tracesSampleRate: environment.sentry.tracesSampleRate,
    // });
  }
}
