import { Injectable } from '@angular/core';
import { RestService } from 'app/communication/rest.service';
import { UtilsService } from 'app/shared/utils.service';
import { Resource, ResourceDetails } from './resource';
import { ResourcePolygonReduction } from './resource-polygon-reduction';

@Injectable({
  providedIn: 'root'
})
export class ResourcePolygonReductionService {

  constructor(
    private rest: RestService,
    private utils: UtilsService
  ) { }

  async reduce(config: ResourcePolygonReduction): Promise<Resource> {
    return await this.utils.observableToPromise(this.rest.polygonReduction('post', config));
  }

  async getResourceDetails(resource: Resource): Promise<ResourceDetails> {
    return new Promise(async (resolve, reject) => {
      if (resource && resource.id) {
        this.rest.resourceDetails('get', null, '/' + resource.id).subscribe(
          data => resolve(data as ResourceDetails)
        )
      }
    });
  }
}
