import { ElementRef } from "@angular/core";

export interface TutorialStage {
    title?: string;
    desc: string;
    tip?: string;
    selector?: string;
    selectorIndex?: number;
    element?: ElementRef;
    autoread?: boolean;
    fixMargin?: boolean;
    fixPadding?: boolean;
    top?: string;
    left?: string;
    onEnter?: Function;
    onExit?: Function;
    position?: 'above' | 'below';
    scrollTo?: 'center' | 'end' | 'nearest' | 'start';
    scrollToPrompt?: boolean;
}

export class TutorialOptions {
    public identifier?: string;
    public maxIdentifier?: number;
    constructor() {
        this.maxIdentifier = 1;
    }
}

export interface StorageContainer {
    identifiers: { [id: string]: IdentifiersData };
}

export interface IdentifiersData {
    counter: number;
}
