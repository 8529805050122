import { Component, Input, forwardRef } from '@angular/core';
import { IButtonToggle } from './button-toggle-group.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hexa-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // useExisting: ButtonToggleGroupComponent,
      useExisting: forwardRef(() => ButtonToggleGroupComponent),
      multi: true
    }
  ]
})
export class ButtonToggleGroupComponent<T> implements ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() buttons: IButtonToggle<T>[];
  private _selected: T;

  constructor() { }

  public get selected(): T {
    return this._selected;
  }

  public set selected(value: T) {
    this._selected = value;
    this.onChange(this.selected);
    this.onTouched(this.selected);
  }

  public onChange: any = () => {}
  public onTouched: any = () => {}

  public writeValue(val: T): void {
    this.selected = val;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
