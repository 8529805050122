import { Injectable } from '@angular/core';
import { RestService } from 'app/communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { Router } from '@angular/router';
import { NotificationType, Notification } from 'app/shared/notifications';
import { UtilsService } from 'app/shared/utils.service';
import { SurveyResponse, AllArtistsUsersSurveysQuery } from './survey';
import { GraphqlService } from 'app/communication/graphql.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  static NUM_OF_QUESTIONS = 7;
  static DECLINE_OFFER_SURVEY_ID = 8;
  static JOB_FEEDBACK_SURVEY_ID = 9;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private router: Router,
    private utils: UtilsService,
    private gql: GraphqlService
  ) { }

  async response(payload: SurveyResponse) {
    return new Promise((resolve, reject) => {
      this.rest.survey('post', payload).subscribe(
        resolve,
        err => {
          this.utils.httpErrorResponseHandler(err, 'failure updating response');
          reject();
        }
      );
    });
  }

  next(currentSurveyId: number) {
    currentSurveyId;
    if (SurveyService.NUM_OF_QUESTIONS <= currentSurveyId) {
      let data: Notification = {
        text: 'Thank you for your answers',
        type: NotificationType.Success,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      this.router.navigateByUrl('home');
    }
    else
      this.utils.forceRedirectTo('survey/' + ++currentSurveyId)
  }

  prev(currentSurveyId: number) {
    currentSurveyId;
    if (currentSurveyId > 1)
      this.utils.forceRedirectTo('survey/' + --currentSurveyId);
  }

  getQUestionById(id: number) {
    switch (id) {
      case 1: {
        return 'How would you rate the creators Job Offers prices (when 1 is Too Low and 5 is More Than Fair)';
      }
      case 2: {
        return 'How would you rate the Job Offers variety (when 1 is Very Low and 5 is Very High)';
      }
      case 3: {
        return 'How would you rate the Creators team performance (when 1 is Unprofessional and 5 is Very Professional)';
      }
      case 4: {
        return 'How would you rate the Creators team response time (when 1 is Very Slow and 5 is Very Fast)';
      }
      case 5: {
        return 'How would you rate the Creators onboarding (sign-up) experience (when 1 is Very Bad and 5 is Excellent)';
      }
      case 6: {
        return 'How likely are you to recommend Creators3D to your friends and colleagues (when 1 is Not Likely and 5 is Very Likely)';
      }
      case 7: {
        return 'How easy was your Job creation flow in terms of user experience on Creators3D (when 1 is Very Complex and 5 is Intuitive)';
      }
    }
  }

  allArtistsUsersSurveys(options: SurveyResponse): Promise<AllArtistsUsersSurveysQuery> {
    return new Promise((resolve, reject) => {
      this.gql.allArtistsUsersSurveys(options).subscribe(ans => {
        resolve(this.utils.deepCopyByValue(ans.data.allArtistsUsersSurveys));
      });
    });
  }
}
