import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CategoriesService } from './categories.service';
import { Category } from './category';

@Injectable()
export class CategoriesResolve  {

    constructor(
        private cService: CategoriesService
    ) { }

    resolve() {
        const cached = this.cService.getCachedCategories();
        if (cached && cached.length) {
            return cached;
        }
        return this.cService.getCategories() as Observable<Array<Category>>;
    }
}