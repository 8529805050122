<div class="footer-wrap {{auth.isloggedIn() ? 'has-bottom-nav' : ''}}" id="footer">
    <div fxLayout="row warp" fxFill [fxLayoutAlign]="'center end'" fxLayoutGap.lt-md="center center">
      <!-- === MAIN FOOTER SECTION === -->
      <div fxFlex="80%" fxFlex.lt-md="100%" [fxLayout]="'column'" fxLayout.gt-sm="row" [fxLayoutGap]="'48px'" fxLayoutGap.gt-md="90px"
        class="nav-wrap" fxFill>
        <div fxFlex="none" [fxShow]="true" fxShow.lt-md="false">
          <a [routerLink]="['home']" routerLinkActive="active" aria-label="logo">
            <img src="assets/home-page/hexa-logo.svg" class="hexa-logo-link">
          </a>
         <div class="social-media-links">
           <a class="social-link" href="{{hexaFacebookURL}}" target="_blank">
             <img src="assets/home-page/icons/facebook.svg">
           </a>
           <a class="social-link" href="{{hexaYoutubeURL}}" target="_blank">
             <img src="assets/home-page/icons/youtube.svg">
           </a>
         </div>
        </div>
        <div>
          <h3>MAIN PAGES</h3>
          <ul class="footer-nav">
            <li *ngIf="auth.isloggedIn() && isSU"><a [routerLink]="['/products']" routerLinkActive="active">Products</a></li>
            <li><a [routerLink]="['/offers']" routerLinkActive="active">Job Offers</a></li>
            <li *ngIf="auth.isloggedIn()"><a [routerLink]="['/jobs/list']" routerLinkActive="active">My Jobs</a></li>
          </ul>
        </div>
        <div>
          <h3>RESOURCES</h3>
          <ul class="footer-nav">
            <li><a [routerLink]="['/online-viewer']" routerLinkActive="active">Online Viewer</a></li>
            <li><a rel="noopener" href="https://gen.hexa3d.io/home?ref=creators3d_footer" target="_blank">Generative AI</a></li>
            <li><a [routerLink]="['/how-it-works']" routerLinkActive="active">How It Works</a></li>
            <li><a rel="noopener" href="https://blog.creators3d.com/" target="_blank">Blog</a></li>
          </ul>
        </div>
        <div fxFlex="1 1 auto">
          <h3>POLICY</h3>
          <ul class="footer-nav">
            <li><a [routerLink]="['/frequently-asked-questions']" routerLinkActive="active">Frequently Asked Questions </a>
            </li>
            <li><a [routerLink]="['/terms']" routerLinkActive="active">Terms And Conditions</a></li>
            <li><a [routerLink]="['/privacy']" routerLinkActive="active">Privacy Policy</a></li>
          </ul>
        </div>
      </div>

      <!-- === BOTTOM SECTION === -->
      <div fxFlex="80%" fxFlex.lt-md="100%" [fxLayout]="'row'" fxLayout.lt-md="row wrap" class="footer-bottom" fxLayoutAlign="space-between center" fxLayoutGap.lt-md="10px">

        <!-- Social Icons (Mobile) -->
        <div fxFlex="100%" fxLayoutAlign="center center" [fxShow]="false" fxShow.lt-md="true" class="social-media-links">
          <a class="social-link" href="{{hexaFacebookURL}}" target="_blank">
            <img src="assets/home-page/icons/facebook.svg">
          </a>
          <a class="social-link" href="{{hexaYoutubeURL}}" target="_blank">
            <img src="assets/home-page/icons/youtube.svg">
          </a>
        </div>

        <!-- Hexa Logo (Mobile) -->
        <div fxFlex.lt-md="calc(20%-10px)" [fxShow]="false" fxShow.lt-md="true" fxLayoutAlign="center center">
          <img src="assets/home-page/hexa-logo.svg" class="hexa-logo-link">
        </div>

        <!-- All Rights -->
        <div fxFlex="auto" fxFlex.lt-md="calc(60%-10px)" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center" class="all-rights">© 2022. All Rights Reserved.</div>

        <!-- Payoneer logo -->
        <div fxFlex="none" fxFlex.lt-md="calc(20%-10px)" fxLayoutAlign="end center" class="payoneer-payments">
          <span [fxShow]="true" fxShow.lt-md="false">Secure payments by </span><img src="assets/home-page/payoneer-logo.svg" class="payoneer-logo">
        </div>
      </div>
    </div>
</div>
