import { NativeResourceSet, GeneralResource, PolyShapeType } from '../offer/offers';
import { User } from '../auth/user';
import { DimensionsUnits } from '../shared/utils';
import { Software } from 'app/softwares/softwares';
import { ArtistsJobsType, ArtistsJobsResourcesCategoriesRanking } from './job';
import { CameraControlsState } from 'asset-adjustments';
import { ArtistsJobsItems } from 'app/product/product';
import { FormatsType } from '../shared/enums';
import { feedback_sub_types, feedback_types } from './feedback';

export interface Resource extends GeneralResource {
    job_item_id?: number;
    archive?: any;
    file?: string;
    approved?: number;
    artist_approved?: boolean;
    scale?: number;
    specular_intensity?: number;
    target_viewer?: string;
    viewer_version?: string;
    compress?: boolean;
    cam_change?: boolean;
    org_scene?: boolean;
    is_archived?: boolean;
    poly_count?: number;
    vert_count?: number;
    mesh_compress?: boolean;
    artists_resources_feedback?: Array<ArtistsResourcesFeedback>;
    artists_jobs_resources_source_files?: Array<ArtistsJobsResourcesSourceFile>;
    artists_jobs_resources_categories_ranking?: Array<ArtistsJobsResourcesCategoriesRanking>;
    artists_jobs_resources_alignments?: Array<ArtistsJobsResourcesAlignment>;
    artists_jobs_resources_geometry_accuracy?: Array<ArtistsJobsResourcesGeometryAccuracy>;
    artists_jobs_resources_color_comparisons?: Array<ArtistsJobsResourcesColorComparisonUI>;
    encrypt?: boolean;
    send_email?: boolean;
    source_resource_id?: number;
    buffer?: ArrayBuffer;
    quantize_texcoord_bits?: number;
    quantize_normal_bits?: number;
    uv_out_of_bounds?: boolean;
    uv_overlaps?: boolean;
    type?: any;
    // as_zip?: boolean;
    // as_gzip?: boolean;
    compression_type?: CompressionType;
    update_json_params?: boolean;
    target_resource_type?: number;
    sent_to_retailers?: boolean; // Whether the resource has been sent to the CMS or not
    target_poly_shape_type?: PolyShapeType;
    use_hexa_naming?: boolean;
    force?: boolean;
    src_images_resource_id?: number; // The server will use the images from the source resource to replace the textures from the uploaded model
    src_geometry_resource_id?: number; // The server will use the geometry from the source resource to replace the geometry from the uploaded model - Not implemented yet by the server!
    blender_glb_file_size?: number;
    remove_uv?: boolean;
    final_approval?: boolean; // In case the QS (or any other user) has approved the resource on secondary review final_approval = true
    artists_jobs_items?: Array<ArtistsJobsItems>;
    type_id?: number;
    poly_variation_name?: string;
    serial_number?: string;
    viewer_resource_type?: FormatsType;
    artists_jobs_resources_uv_layouts?: Array<ArtistsJobsResourcesUvLayout>;    
    diffuse_max_value?:  number;
    diffuse_min_value?:  number;
    resource_raw_url?: string;
}

export enum CompressionType {
    ZIP = 1,
    GZIP = 2,
    BROTLI = 3,
}

export interface ArtistsJobsResourcesUvLayout {
    id: number;
    ajr_id: number;
    uv_layout_url: string;
}

export interface ArtistsJobsResourcesAlignment {
    id?: number;
    artist_user_id?: number;
    created_at?: Date;
    resource_id: number;
    updated_at?: Date;
    url: string;
    config_json: CreatorsCameraControlsState;
    public: boolean;
    data_id: number; // the ID of the ArtistsItemData
    reuse_alignment?: object; // Reuse alignment -> item small image
    combineImagesMode: CombineImagesMode;
    artists_users?: Array<User>;
    rolesUI?: string;
}

export interface ArtistsJobsResourcesGeometryAccuracy {
    id: number;
    score: number;
    model_transform: any;
    url: string;
    request: AlignmentRequestUI;
    resource_id: number;
}

export interface ArtistsJobsResourcesGeometryAccuracyUI extends ArtistsJobsResourcesGeometryAccuracy, AlignmentRequestUI {
  image_url: string;
  passed: boolean;
}

export interface BaseArtistsJobsResourcesColorComparison {
    resource_id: number;
    artist_user_id: number;
    alignment_mode: CombineImagesMode;
    reference_sample_coordinates_x: number;
    reference_sample_coordinates_y: number;
    reference_sample_image_url: string;
    sample_size: number;
    resource_sample_coordinates_x: number;
    resource_sample_coordinates_y: number;
    resource_sample_image_url: string;
    config_json: CreatorsCameraControlsState;
    resource_render_image_url: string;
    comparison_delta_l: number;
    comparison_delta_ab: number;
    resource_sample_rgby_g: number;
    resource_sample_rgby_b: number;
    resource_sample_rgby_r: number;
    resource_sample_rgby_y: number;
    reference_sample_rgby_r: number;
    reference_sample_rgby_g: number;
    reference_sample_rgby_b: number;
    reference_sample_rgby_y: number;
}

export interface ArtistsJobsResourcesColorComparison extends BaseArtistsJobsResourcesColorComparison {
    id?: number;
    created_at?: Date;
    updated_at?: Date;
}

export interface ArtistsJobsResourcesColorComparisonUI extends ArtistsJobsResourcesColorComparison {
    isSuccess: boolean;
    brightnessFailed: boolean;
    hueFailed: boolean;
    issues: string;
}

export interface AlignmentRequest {
    width: number;
    height: number;
    points_2d: Array<Array<number>>; // [[1, 2], [3, 7]]
    points_3d: Array<Array<number>>; // [[1, 2, 1.2], [3, 7, 6.3]]
    generate_renders: boolean;
    resource_id: number; // int (The artists_jobs_resources ID)
    image_id: number; // int (The artists_items_data ID)
    upload_to_cdn: boolean;
}

export interface AlignmentRequestUI extends AlignmentRequest {
    colors: Array<string>;
}

export interface AlignmentRenderOptions {
    setPosition: boolean;
    generateRenders: boolean;
    animateOpacity: boolean;
    imgElement: HTMLImageElement;
    iframeElement: HTMLIFrameElement;
}

export interface AlignmentRenderResponseTransform {
    focal_length: number;
    rotation: Array<Array<number>>;
    // 0: (3) [0.9767223078013868, 0.03663776111056054, 0.21135564317978037]
    // 1: (3) [0.009436305272755493, 0.9770133556222663, -0.21296915053246146]
    // 2: (3) [-0.21429999903390692, 0.21000613656873068, 0.9539250143578074]
    // length: 3
    translation: Array<Array<number>>;
    // 0: [0.04336935661111341]
    // 1: [0.10214134665311347]
    // 2: [0.7067326318403845]
    // length: 3
}

export interface AlignmentRenderResponse {
    metadata: any;
    score: number;
    transform: AlignmentRenderResponseTransform;
    url: string;
}

export enum ALIGNMENT_STATE {
    IMAGE = 1,
    VIEWER = 2,
    TRANSITION = 3,
}

export enum ALIGNMENT_SECTION {
    CHOOSE = 1,
    MATCH = 2,
    COMPARE = 3,
}

export enum ALIGNMENT_ORIGIN {
    CAMERA_BUTTON = 1,
    ACCURACY_TEST = 2,
}
export interface ArtistsResourcesFeedback {
    title: string;
    feedback_type: number;
    feedback_sub_type: number;
    feedback_types: Array<feedback_types>;
    feedback_sub_types: Array<feedback_sub_types>;
    notes: string;
    screenshot: string;
    created_at: Date;
    artists_resources_feedback_comments?: Array<ArtistsResourceFeedbackComment>;
    artists_users?: Array<User>;
    opened_by: number;
    id: number;
    artist_user_id: number;
    resource_id: number;
    updated_at: Date;
    fixed: boolean;
    hidden: boolean;
    rank?: ArtistsJobsResourcesCategoriesRanking;
    whitelisted?: boolean;
    video_url?: string;
    transcription?: string;
    feedback_session_id?: number;
}

export interface ArtistsResourceFeedbackComment {
    comment: string;
    id?: number;
    artist_user_id: number;
    feedback_id: number;
    created_at?: Date;
    updated_at?: Date;
    artists_users: Array<User>;
    editMode: boolean;
    whitelisted?: boolean;
}

export interface FeedbackWrapData {
    feedback: ArtistsResourcesFeedback;
    images: Array<NativeResourceSet>;
    model: string;
    resources: Array<Resource>;
    currentModelIndex: number;
    // artistsJobItem: ArtistJobItem;
    artistsJobsTypes: Array<ArtistsJobsType>;
}

export interface ResourceDetails {
    original_files: { [id: string]: string; };
    viewer_files: { [id: string]: string; };
    poly_count: any;
    vert_count: any;
    dimensions: any;
    width: number;
    height: number;
    length: number;
    units: DimensionsUnits;
    isBiggerThanLimit?: boolean;
}

export interface Dimensions {
    width: number;
    height: number;
}

export interface AlignmentDimensions extends Dimensions {
    imgWidthDiff: number;
    imgHeightDiff: number;
}

export interface ResourceDimensions extends Dimensions {
    length: number;
    units: DimensionsUnits;
}

export interface ResourceWarning {
    type: ResourceWarningType;
    desc: string;
    level: ResourceWarningLevel;
    action?: Function;
    isActive?: boolean;
    videoSrc?: string;
    buttonText?: string;
}

export enum ResourceWarningType {
    Dimensions = 1,
    FileSize = 2,
    FixFeedbacks = 3,
    MissingSourceFiles = 4,
    UVsOverlap = 5,
    PolyCount = 6,
    PolyTypeMissing = 7,
    CantApproveResourceWithFeedbacks = 8,
    PolyShape = 9,
    NumOfMeshes = 10,
    Proportions = 11,
    TransparentMaterial = 12,
    NumOfMultipleUV = 13,
    OverlapMeshesNames = 14,
    NegativeMeshScale = 15,
    RedundantTextures = 16,
    MissingAlignments = 17,
    DiffuseThreshold = 18,
    MissingGeometryAccuracy = 19,
    MissingColorComparison = 20,
    MapNotAllowed = 21,
    FileTypeMismatch = 22,
    UvOutOfBounds = 23,
    TextureResolution = 24,
    TextureFormat = 25,
    TextureRequiaredTypes = 26,
    WannaShoes = 27,
    SingleUVSet = 28,
}

export enum ResourceWarningLevel {
    Warning = 1,
    Error = 2,
}

export enum ImagesFileTypes {
    ANY = 0,
    PNG = 1,
    JPG = 2,
}

export interface ArtistsJobsResourcesSourceFile {
    id: number;
    created_at: Date;
    updated_at: Date;
    resource_id: number;
    software_id: number;
    source_files_location: string;
    software_enum: Array<Software>;
}

export enum MediaTag {
    // IFRAME = 1,
    // VIDEO = 2,
    // IMAGE = 3,
    IMAGE = 1, // a regular image
    MODEL = 2, // an iframe with our viewer
    VIDEO = 3, // a link to a video source file
    IFRAME = 4, // not in use currently
}

export enum CombineImagesMode {
    SIDE_BY_SIDE = 1,
    MODEL_ON_TOP = 2,
    IMAGE_ON_TOP = 3,
    MODEL_ONLY = 4,
    IMAGE_ONLY = 5
}

export interface CombineImagesResponse {
    screenshot: string;
    pixelsDiffPercentage: number;
    pixelsDiffOpaquePercentage: number;
    colorAccuracy: number;
    opaqueColorAccuracy: number;
    deltaLAB: DeltaLAB;
    square?: PixelsSquare;
    origin: ALIGNMENT_ORIGIN;
}

export interface ImagePixelsDataSet {
    opaque: ImagePixelsData;
    mix: ImagePixelsData;
}

export interface ImagePixelsData {
    red: number;
    green: number;
    blue: number;
    avg: number;
    counter: number;
    transparent: number;
    transparentCounter: number;
    min: number;
    max: number;
}

export interface PixelsSquareBase {
    width: number;
    height: number;
    top: number;
    left: number;
}

export interface PixelsSquare extends PixelsSquareBase {
    fullWidth?: number;
    fullHeight?: number;
    // imgDim: Dimensions;
}
export interface CombineImageOptions {
    img1: HTMLImageElement;
    img2: HTMLImageElement;
    suffix: string;
    mode: CombineImagesMode;
    square?: PixelsSquare;
}

export interface CombineImageSourceOptions {
    imgPath1: string;
    imgPath2: string;
    suffix: string;
    mode: CombineImagesMode;
    square?: PixelsSquare;
}

export interface RGBY extends RGB {
    yellow: number;
}

export interface MinMaxMeanRGB {
    min: RGB;
    max: RGB;
    mean: RGB;
}

export interface RGB {
    red: number;
    green: number;
    blue: number;
}

export interface RGBYHex extends RGBY {
    redHeu: string;
    greenHeu: string;
    blueHeu: string;
    yellowHeu: string;
}

export interface LAB {
    lightness: number;
    channelA: number; // Green / Red
    channelB: number; // Blue / Yellow
    RGBY: RGBYHex;
    screenshot?: string;
}

export interface LABMinMax extends LAB {
    min: RGB;
    max: RGB;
}

export interface DeltaLAB {
    averageLightnessDelta: number;
    averageABDelta: number;
    resourceAverageAB: LAB;
    referenceAverageAB: LAB;
    channelADiff: number;
    channelBDiff: number;
    lightnessDiff: number;
    deltaE: number;
    resourceSampleImageUrl: string;
    referenceSampleImageUrl: string;
    resourceImageData: Uint8ClampedArray;
    referenceImageData: Uint8ClampedArray;
    // referenceSampleX: number;
    // referenceSampleY: number;
    // resourceSampleX: number;
    // resourceSampleY: number;
    resourcePixelsSquare: PixelsSquareBase;
    referencePixelsSquare: PixelsSquareBase;
    resourceMinRGB?: RGB;
    resourceMaxRGB?: RGB;
    referenceMinRGB?: RGB;
    referenceMaxRGB?: RGB;
}

export interface RGB {
    red: number;
    green: number;
    blue: number;
}

export interface GifOptions {
    ggwidth: number;
    ggheight: number;
    ggsampleInterval?: number;
    ggnumofframes?: number;
    gginterval?: number;
    ggrotatespeen?: number;
    ggtext?: string;
}

export interface PreviewOptions {
    width: number;
    height: number;
    create_images_by_tour: string;//'init_pos'
    compress: boolean;
}

// export interface CameraControlsStateData {
//     pixelsDiffPercentage: number;
//     pixelsDiffOpaquePercentage: number;
//     colorAccuracy: number;
//     opaqueColorAccuracy: number;
//     deltaLAB: DeltaLAB;
//     resourceSampleImageUrl?: string;
//     referenceSampleImageUrl?: string;
// }
export interface CreatorsCameraControlsState extends CameraControlsState {
    data?: CombineImagesResponse;
}

export enum ResourceMode {
    ADJUSTMENTS = 1,
    ALIGNMENT = 2,
    COLOR_SAMPLING = 3,
    DECIMATE_POLYGONS = 4,
}
