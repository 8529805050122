export enum UploadWizardStep {
    UPLOAD_TYPE = 0,
    RESOURCE_TYPE = 1,
    SOURCE_TYPE = 2,
    SOURCE_UPLOAD = 3,
    VIEWER_TYPE = 4,
    POLY_TYPE = 5,
    FILE_SELECT = 6,
}

export enum UPLOAD_TYPE {
    VIEWER = 1,
    SOURCE = 2,
    RENDER = 3,
    RENDER_SCENE = 4,
}

export class UploadWizardOptions {
    initStep?: UploadWizardStep;
    extra?: Record<string, unknown>
}
