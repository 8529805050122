import { Injectable } from '@angular/core';
import { RestService } from 'app/communication/rest.service';
import { EmojisList } from './emojis';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class EmojisService {
  private isFetching: boolean;
  private fetch: Observable<any>;
  public list: Array<EmojisList>;
  constructor(
    private rest: RestService
  ) {
    this.isFetching = false;
    // this.prefetch();
  }

  getAll(): Observable<Array<EmojisList>> {
    if (this.isFetching) return this.fetch;
    this.isFetching = true;
    this.fetch = this.rest.downloadFromCDN('/site/emojis.json').pipe(map(res => {
      this.list = res as Array<EmojisList>;
      Object.freeze(this.list);
      this.isFetching = false;
      return this.list;
    }));
    return this.fetch;
  }

  prefetch() {
    this.getAll().subscribe();
  }
}
