import { Injectable } from '@angular/core';
import { Software, SoftwareQueryData, SoftwareType } from './softwares';
import { GraphqlService } from 'app/communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { BroadcasterService } from 'ng-broadcaster';

@Injectable({
  providedIn: 'root'
})
export class SoftwaresService {
  private cachedSoftwares: Array<Software>;
  private cachedSoftwaresDictionary: { [id: number]: Software; };
  private isFetching: boolean;
  public CREATION_SOFTWARE: SoftwareType;
  public EXPERTISE: SoftwareType;
  public RENDERING_ENGINE: SoftwareType;
  constructor(
    private gql: GraphqlService,
    private broadcaster: BroadcasterService
  ) {
    this.CREATION_SOFTWARE = SoftwareType.CREATION_SOFTWARE;
    this.EXPERTISE = SoftwareType.EXPERTISE;
    this.RENDERING_ENGINE = SoftwareType.RENDERING_ENGINE;
    this.cachedSoftwaresDictionary = {};

  }

  fetch(): void {
    if (this.isFetching) return;
    this.isFetching = true;
    this.gql.softwares().subscribe(
      (c: ApolloQueryResult<SoftwareQueryData>) => {
        this.cachedSoftwares = c.data.software_enum;
        this.broadcaster.broadcast('onArtistsSoftwaresEnum', this.getCachedSoftwares());
        this.isFetching = false;
      }
    )
  }

  getSoftwaresDictionary(): { [id: number]: Software; } {
    if (!this.getCachedSoftwares())
      return null;
    this.getCachedSoftwares().forEach(s => this.cachedSoftwaresDictionary[s.id] = s);
    return this.cachedSoftwaresDictionary;
  }

  getCachedSoftwares() {
    if (!this.cachedSoftwares)
      this.fetch();
    return this.cachedSoftwares;
  }
}
