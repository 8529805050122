import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { JobQueryData } from '../job';
import { Observable } from 'rxjs';
import { JobService } from 'app/job/job.service';
import { GraphqlService } from '../../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { JobsFeedbacksService } from '../jobs-feedbacks.service';

@Injectable()
export class JobResolve  {

    constructor(
        private gql: GraphqlService,
        private jobService: JobService,
        private jobsFeedbacksService: JobsFeedbacksService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        let currentCached = this.jobService.getCurrent();
        if (currentCached && currentCached.id == route.params['id'])
            return null;
        if (this.jobsFeedbacksService.routeActive) {
            this.jobsFeedbacksService.afterNavigate(parseInt(route.params['id']));
            return null;
        }
        else
            return this.gql.job(parseInt(route.params['id'])) as Observable<ApolloQueryResult<JobQueryData>>;
    }
}
