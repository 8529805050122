import { Injectable } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { RestService } from 'app/communication/rest.service';
import { JobService } from 'app/job/job.service';
import { ArtistsJobsResourcesAlignment, ArtistsJobsResourcesColorComparison, ArtistsJobsResourcesColorComparisonUI } from 'app/job/resource';
import { AB_THRESHOLD, LIGHTNESS_THRESHOLD } from 'app/shared/enums';
import { EnumsService } from 'app/shared/enums.service';
import { PixelsService } from 'app/shared/pixels.service';
import { UtilsService } from 'app/shared/utils.service';
import { AssetAdjustmentsService, KeyValuePair } from 'asset-adjustments';
import {
  AdjustmentsData,
  AdjustmentsPreset,
  AdjustmentsPresetOptions,
  ArtistsUsersPresetType
} from './asset-adjustments';
import { AssetAdjustmentsHelperService } from './asset-adjustments-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ColorSamplingService {
  public pixelsTest: number;
  public selectedPreset: AdjustmentsPreset;
  public selectedPresetHasHdri: boolean;
  public positionTimeout: any;
  public fixed: boolean;
  public saved: boolean;
  private _alignmentPixels: Array<KeyValuePair>;
  private _alignment: ArtistsJobsResourcesAlignment;
  private _processing: boolean;
  private _testing: boolean;
  private _adjustmentsData: AdjustmentsData;
  private _session: string;
  private _instanceSession: string;
  constructor(
    private enums: EnumsService,
    public aAHS: AssetAdjustmentsHelperService,
    public aAS: AssetAdjustmentsService,
    private rest: RestService,
    private utils: UtilsService,
    private auth: AuthService,
    private jobService: JobService,
    public pixels: PixelsService
  ) {
    this._alignmentPixels = this.enums.getAlignmentPixels();
    this.pixelsTest = this._alignmentPixels[3].key;
  }

  get alignmentPixels() {
    return this._alignmentPixels;
  }

  get alignment() {
    return this._alignment;
  }

  set alignment(value: ArtistsJobsResourcesAlignment) {
    this._alignment = value;
  }

  get processing() {
    return this._processing;
  }

  set processing(value: boolean) {
    this._processing = value;
  }

  get testing() {
    return this._testing;
  }

  set testing(value: boolean) {
    this._testing = value;
  }

  get adjustmentsData() {
    return this._adjustmentsData;
  }

  set adjustmentsData(value: AdjustmentsData) {
    this._adjustmentsData = value;
  }

  get session() {
    if (!this._session)
      this._session = this.utils.generateUUID();
    return this._session;
  }

  get instanceSession() {
    if (!this._instanceSession)
      this._instanceSession = this.utils.generateUUID();
    return this._instanceSession;
  }

  resetInstanceSession() {
    delete this._instanceSession;
  }

  colorComparisonFartory(): ArtistsJobsResourcesColorComparison {
    const alignment = this.utils.deepCopyByValue(this.alignment) as ArtistsJobsResourcesAlignment;
    return {
      alignment_mode: alignment.combineImagesMode,
      artist_user_id: this.auth.user.id,
      comparison_delta_ab: alignment.config_json.data.deltaLAB.averageABDelta,
      comparison_delta_l: alignment.config_json.data.deltaLAB.averageLightnessDelta,
      config_json: alignment.config_json,
      reference_sample_coordinates_x: alignment.config_json.data.deltaLAB.referencePixelsSquare.left,
      reference_sample_coordinates_y: alignment.config_json.data.deltaLAB.referencePixelsSquare.top,
      resource_sample_coordinates_x: alignment.config_json.data.deltaLAB.resourcePixelsSquare.left,
      resource_sample_coordinates_y: alignment.config_json.data.deltaLAB.resourcePixelsSquare.top,
      resource_render_image_url: alignment.url,
      reference_sample_image_url: alignment.config_json.data.deltaLAB.referenceSampleImageUrl,
      resource_sample_image_url: alignment.config_json.data.deltaLAB.resourceSampleImageUrl,
      resource_id: alignment.resource_id,
      sample_size: alignment.config_json.data.square.width,
      resource_sample_rgby_r: alignment.config_json.data.deltaLAB.resourceAverageAB.RGBY.red,
      resource_sample_rgby_g: alignment.config_json.data.deltaLAB.resourceAverageAB.RGBY.green,
      resource_sample_rgby_b: alignment.config_json.data.deltaLAB.resourceAverageAB.RGBY.blue,
      resource_sample_rgby_y: alignment.config_json.data.deltaLAB.resourceAverageAB.RGBY.yellow,
      reference_sample_rgby_r: alignment.config_json.data.deltaLAB.referenceAverageAB.RGBY.red,
      reference_sample_rgby_g: alignment.config_json.data.deltaLAB.referenceAverageAB.RGBY.green,
      reference_sample_rgby_b: alignment.config_json.data.deltaLAB.referenceAverageAB.RGBY.blue,
      reference_sample_rgby_y: alignment.config_json.data.deltaLAB.referenceAverageAB.RGBY.yellow
    };
  }

  async save(): Promise<ArtistsJobsResourcesColorComparisonUI> {
    if (this.processing) {
      throw new Error('save in process');
    }

    this.processing = true;
    const payload = this.colorComparisonFartory();
    let cc = {} as ArtistsJobsResourcesColorComparisonUI;
    try {
      delete payload.config_json.data;
      cc = await this.utils.observableToPromise(this.rest.colorComparison('post', payload));
    } catch (err) {
      console.error(err.error);
      throw new Error('Failed to save');
    } finally {
      this.processing = false;
    }

    return cc;
  }

  public isSuccess(cc: ArtistsJobsResourcesColorComparison): boolean {
    return this.jobService.isColorComparisonValid(cc);
  }

  public getPresets(): Promise<AdjustmentsPreset[]> {
    const presetFilter: AdjustmentsPresetOptions = {
      type_id: ArtistsUsersPresetType.PUBLIC,
      retailer_sub_category_id: this.adjustmentsData?.retailerSubCategoryId,
      sub_category_id: this.adjustmentsData?.subCategoryId,
      retailer_id: this.adjustmentsData?.retailerId,
      job_id: this.adjustmentsData?.jobId,
      isAmazon: this.adjustmentsData?.isAmazon
    };

    return this.aAHS.getPresets(presetFilter);
  }

  public async applyPreset(preset: AdjustmentsPreset): Promise<void> {
    if (this.selectedPreset === preset) {
      return;
    }

    this.selectedPreset = preset;
    this.aAHS.currentPreset = preset;
    await this.aAHS.applyPreset();
    this.selectedPresetHasHdri = !!this.aAS.enums.getHDRIFull()[this.selectedPreset?.preset_json?.hdri?.type];
  }

  async applyDefaultScene() {
    if (!this.aAS.isViewerLoaded) {
      await this.aAS.broadcastViewerFullyLoaded();
    }
    this.aAS.adjustScene();
  }

  applyAlignmentPosition = async (): Promise<ArtistsJobsResourcesAlignment> => {
    return new Promise((resolve, reject) => {
      const alignment = this.alignment ||
      this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex]?.artists_jobs_resources_alignments[0];

      if (alignment) {
        const position = alignment.config_json;
        this.positionTimeout = setTimeout(() => {
          this.aAS.setControlsPosition(position);
          resolve(alignment);
          // this.setSelectedImage.emit(alignment.data_id);
        }, 100);
      }
      else
        resolve(alignment);
    });
  }

  setInstanceUI(cc: ArtistsJobsResourcesColorComparisonUI) {
    cc.isSuccess = this.isSuccess(cc);
    cc.brightnessFailed = cc.comparison_delta_l > LIGHTNESS_THRESHOLD;
    cc.hueFailed = cc.comparison_delta_ab > AB_THRESHOLD;
    if (cc.brightnessFailed && cc.hueFailed)
      cc.issues = 'brightness & hue';
    else if (cc.hueFailed)
      cc.issues = 'hue';
    else if (cc.brightnessFailed)
      cc.issues = 'brightness';
  }

  onDestroy() {
    this.pixels.sendPixel({
      event: 'colorTestSessionEnd',
      color_test_session: this.session
    });
    this.resetInstanceSession();
    delete this._session;
    this.fixed = false;
    this.saved = false;
  }
}
