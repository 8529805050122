import { Injectable, ElementRef } from '@angular/core';
import { TutorialStage, TutorialOptions, StorageContainer } from './tutorial';
import { BodyService } from 'app/shared/body.service';
import { Subscription, Subject } from 'rxjs';
import { TextToSpeechService } from 'app/shared/text-to-speech.service';
import { UtilsService } from 'app/shared/utils.service';
import { StorageService } from 'ng-storage-service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {
  static storageContainerID = '_walkthrough';
  static readText = '►';
  static stopReadText = '◼';
  private currentStep: number;
  private steps: Array<TutorialStage>;
  private parent: ElementRef;
  private prompt: ElementRef;
  private curtain: ElementRef;
  private element: ElementRef<HTMLElement>;
  private subs: Array<Subscription>;
  private storageContainer: StorageContainer;
  private options: TutorialOptions;
  private onEnd: Subject<boolean>;
  private maxIdentifierSurpass: boolean;
  constructor(
    private body: BodyService,
    private tts: TextToSpeechService,
    private utils: UtilsService,
    private storage: StorageService
  ) {
    this.subs = [];
    this.subs.push(this.body.onWindowSizeChange.subscribe(this.fixElementAndPrompt.bind(this)));
  }

  public setTutorial(steps: Array<TutorialStage>, options = new TutorialOptions()): Subject<boolean> {
    this.onEnd = new Subject<boolean>();
    this.options = options;
    this.initStorageContainer(false);
    if (this.options.identifier) {
      if (this.storageContainer.identifiers[options.identifier].counter >= options.maxIdentifier) {
        this.maxIdentifierSurpass = true;
        setTimeout(() => {
          this.onEnd.next(false);
        });
        return this.onEnd;
      }
    }
    this.steps = steps;
    this.currentStep = 0;
    this.deployStep(steps[this.currentStep]);
    return this.onEnd;
  }

  public next(): void {
    this.removeCurrentPrompt();
    this.clearCurrentElement();
    this.onExit();
    if (++this.currentStep < this.steps.length) {
      this.tts.cancel();
      this.deployStep(this.steps[this.currentStep]);
    } else {
      this.destroy(true);
    }
  }

  public prev(): void {
    if (this.currentStep > 0) {
      this.removeCurrentPrompt();
      this.clearCurrentElement();
      this.onExit();
      this.tts.cancel();
      this.deployStep(this.steps[--this.currentStep]);
    }
  }

  public destroy(incrementCounter: boolean): void {
    this.removeCurrentPrompt();
    this.removeCurrentCurtain();
    this.clearCurrentElement();
    this.onExit();
    this.tts.cancel();
    if (this.options && this.options.identifier && incrementCounter) {
      this.storageContainer.identifiers[this.options.identifier].counter++;
    }
    this.setStorageContainer();
    if (this.onEnd) {
      this.onEnd.next(!!!this.maxIdentifierSurpass);
    }
  }

  private initStorageContainer(force: boolean): void {
    if (!this.storageContainer || force) {
      this.storageContainer = this.storage.get(TutorialService.storageContainerID) as StorageContainer;
      if (typeof this.storageContainer !== 'object' || this.storageContainer === null) {
        this.storageContainer = {
          identifiers: {}
        };
      }
    }
    if (this.options.identifier) {
      if (!this.storageContainer.identifiers[this.options.identifier]) {
        this.storageContainer.identifiers[this.options.identifier] = {
          counter: 0
        };
      }
    }
  }

  private setStorageContainer(): void {
    if (typeof this.storageContainer === 'object') {
      this.storage.set(TutorialService.storageContainerID, this.storageContainer);
    }
  }

  private deployStep(step: TutorialStage): void {
    this.element = this.getElementByStage(step);
    if (!this.element || !this.element.nativeElement || !this.element.nativeElement.parentElement) {
      console.warn('TutorialService:  no element was provided');
      this.destroy(false);
      return;
    }
    this.parent = new ElementRef(this.element.nativeElement.parentElement);
    this.parent.nativeElement.style.position = 'relative';
    this.element.nativeElement.classList.add('tutorial-element');
    if (!this.curtain) {
      this.curtain = this.getCurtain();
      this.parent.nativeElement.appendChild(this.curtain.nativeElement);
    }
    this.parent.nativeElement.appendChild(this.getPrompt(step).nativeElement);
    this.fixElementAndPrompt();
    requestAnimationFrame(() => {
      this.body.scrollToElement(step.scrollToPrompt ? this.prompt : this.element, step.scrollTo ? step.scrollTo : 'center');
    });
    if (step.autoread && this.tts.isSupported) {
      this.toggleRead();
    }
    if (typeof step.onEnter === 'function') {
      step.onEnter();
    }
  }

  private getElementByStage(stage: TutorialStage): ElementRef {
    let element = stage.element;
    if (!element) {
      element = new ElementRef(document.querySelectorAll(stage.selector)[stage.selectorIndex || 0]);
    }
    return element;
  }

  private getCurtain(): ElementRef {
    const curtain = document.createElement('div');
    curtain.className = 'tutorial-curtain';
    return new ElementRef(curtain);
  }

  private fixElementAndPrompt(): void {
    if (this.element && this.element.nativeElement) {
      const elmStyle = this.prompt.nativeElement.style;
      const { width, height } = this.element.nativeElement.getBoundingClientRect();
      let top = -15
      let left = -20;

      if (this.parent.nativeElement && this.steps[this.currentStep].fixMargin) {
        const pcs = getComputedStyle(this.parent.nativeElement);
        const mb = this.utils.getFormattedDim(pcs.marginBottom);
        const ml = this.utils.getFormattedDim(pcs.marginLeft);

        if (mb.suffix === 'px') {
          top += mb.size;
        }
        if (ml.suffix === 'px') {
          left += ml.size;
        }
      }

      if (this.steps[this.currentStep].position === 'above') {
        if (this.steps[this.currentStep].top) {
          elmStyle.bottom = this.steps[this.currentStep].top;
        } else {
          elmStyle.bottom = top + 'px';
        }
      } else {
        if (this.steps[this.currentStep].top) {
          elmStyle.top = this.steps[this.currentStep].top;
        } else {
          elmStyle.top = top + 'px';
        }
      }

      if (this.steps[this.currentStep].left) {
        elmStyle.left = this.steps[this.currentStep].left;
      } else {
        elmStyle.left = left + 'px';
      }

      if (this.steps[this.currentStep].position === 'above') {
        elmStyle.paddingBottom = (height + 55) + 'px';
      } else {
        elmStyle.paddingTop = (height + 15) + 'px';
      }
      elmStyle.zIndex = '2005';
      let w = width;
      if (this.steps[this.currentStep].fixPadding) {
        const elmCStyle = getComputedStyle(this.prompt.nativeElement);
        const paddingLeft = this.utils.getFormattedDim(elmCStyle.paddingLeft);
        const paddingRight = this.utils.getFormattedDim(elmCStyle.paddingRight);
        if (paddingLeft && paddingLeft.size) {
          w += paddingLeft.size;
        }
        if (paddingRight && paddingRight.size) {
          w += paddingRight.size;
        }
      }
      elmStyle.width = w + 'px';
    }
  }

  private getPrompt(step: TutorialStage): ElementRef {
    this.prompt = new ElementRef(document.createElement('div'));
    this.prompt.nativeElement.className = 'tutorial-prompt';

    const inner = document.createElement('div');
    inner.className = 'tutorial-inner';
    this.prompt.nativeElement.appendChild(inner);


    if (step.title) {
      const title = document.createElement('h3');
      title.className = 'tutorial-title';
      title.textContent = step.title;
      inner.appendChild(title);
    }

    const desc = document.createElement('p');
    desc.className = 'tutorial-desc';
    desc.innerHTML = step.desc;
    inner.appendChild(desc);

    if (step.tip) {
      const tip = document.createElement('div');
      const tipHeader = document.createElement('div');
      const tipDesc = document.createElement('div');
      tip.className = 'tutorial-tip';
      tipHeader.className = 'tutorial-tip-header';
      tipHeader.innerText = 'TIP!';
      tipDesc.innerText = step.tip;
      tip.appendChild(tipHeader);
      tip.appendChild(tipDesc);
      inner.appendChild(tip);
    }

    if (this.currentStep > 0) {
      const prev = document.createElement('button');
      prev.className = 'tutorial-prev tutorial-btn';
      prev.addEventListener('click', this.prev.bind(this), false);
      prev.textContent = 'previous';
      inner.appendChild(prev);
    }

    const next = document.createElement('button');
    next.className = 'tutorial-next tutorial-btn';
    next.addEventListener('click', this.next.bind(this), false);
    next.textContent = this.currentStep < this.steps.length - 1 ? 'next' : 'done';
    inner.appendChild(next);
    requestAnimationFrame(() => {
      next.focus();
    })

    const footer = document.createElement('div');
    footer.className = 'tutorial-footer';
    inner.appendChild(footer);

    const steps = document.createElement('span');
    steps.className = 'tutorial-steps';
    steps.textContent = `step ${this.currentStep + 1} of ${this.steps.length}`;
    footer.appendChild(steps);

    const skip = document.createElement('span');
    skip.className = 'tutorial-skip';
    skip.textContent = `skip`;
    skip.addEventListener('click', this.destroy.bind(this, [false]), false);
    footer.appendChild(skip);

    if (this.tts.isSupported) {
      const read = document.createElement('button');
      read.className = 'tutorial-read';
      read.textContent = TutorialService.readText;
      read.addEventListener('click', this.toggleRead.bind(this), false);
      footer.appendChild(read);
    }
    return this.prompt;
  }

  private removeCurrentPrompt(): void {
    if (this.prompt) {
      this.parent.nativeElement.removeChild(this.prompt.nativeElement);
    }
    delete this.prompt;
  }

  private removeCurrentCurtain(): void {
    if (this.curtain) {
      this.curtain.nativeElement.classList.add('end');
      setTimeout(() => {
        this.curtain.nativeElement.parentElement.removeChild(this.curtain.nativeElement);
        delete this.curtain;
      }, 500);
    }
  }

  private clearCurrentElement(): void {
    if (this.element && this.element.nativeElement) {
      this.element.nativeElement.classList.remove('tutorial-element');
    }
    delete this.element;
  }

  private onExit(): void {
    if (this.steps && this.steps[this.currentStep] && typeof this.steps[this.currentStep].onExit === 'function') {
      this.steps[this.currentStep].onExit();
    }
  }

  private toggleRead(): void {
    if (!this.tts.isSupported) {
      return;
    }

    const step = this.steps[this.currentStep];
    if (step && this.parent && this.parent.nativeElement) {
      const elem = this.parent.nativeElement.querySelector('.tutorial-inner .tutorial-read');
      if (elem.classList.contains('active')) {
        this.tts.cancel();
        elem.textContent = TutorialService.readText;
        elem.classList.remove('active');
      } else {
        let say = step.title ? step.title + ' ' : '';
        say += this.utils.getTextContent(step.desc);
        this.tts.say(say).subscribe(() => elem.textContent = TutorialService.readText);
        elem.textContent = TutorialService.stopReadText;
        elem.classList.add('active');
      }
    }
  }
}
