import { AppRoutingModule } from './app.routing';
import { CanActivateAuthGuard } from './auth/can-activate-auth-guard.service';
import { CanActivateSuGuard } from './auth/can-activate-su-guard.service';
import { NgModule } from '@angular/core';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { JobTypeModule } from './job-type/job-type.module';
import { AppComponent } from './app.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { MainNavPipe } from './main-nav/main-nav.pipe';
import { BottomPipe } from './main-nav/bottom.pipe';
import { ChatService } from './ui-components/chat.service';
import { NotificationsService } from './ui-components/notifications.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ChatLinkResolve } from './shared/chat-link.resolve';
import { NotificationActionResolve } from './communication/notification-action/notification-action.resolve';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CategoriesService } from './categories/categories.service';
import { JobService } from './job/job.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RestService } from './communication/rest.service';
import { MessagesHandlerService } from 'messages-handler';
import { BodyService } from './shared/body.service';
import { RolesManagerService } from 'ng-role-based-access-control';
import { RolesHelperService } from './auth/roles-helper.service';
import { StorageService } from 'ng-storage-service';
import { CacheService } from './communication/cache.service';
import { EnumsService } from './shared/enums.service';
import { CombineImagesService } from './ui-components/combine-images.service';
import { WebsocketService } from './communication/websocket.service';
import { TextToSpeechService } from './shared/text-to-speech.service';
import { SwService } from './sw-helper/sw.service';
import { NotificationsWebsocketService } from './communication/notifications-websocket.service';
import { FeedbackService } from './job/feedback.service';
import { AuthService } from './auth/auth.service';
import { UtilsService } from './shared/utils.service';
import { MapperService } from './shared/mapper.service';
import { ComplexityGuideResolve } from './product/offer-creation-tool/complexity-guide-resolve';
import { CanActivateGuardPermissions } from './auth/can-activate-permissions-guard.service';
import { CollisionService } from './ui-components/collision.service';
import { ComplexityGuidesResolve } from './product/offer-creation-tool/complexity-guides-resolve';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { InMemoryCache, createHttpLink, ApolloLink, DefaultOptions } from '@apollo/client/core';
import { EndpointsService } from './communication/endpoints.service';
import { onError } from '@apollo/client/link/error';
import { CommonModule, DecimalPipe } from '@angular/common';
import { JobResolve } from './job/job/job.resolve';
import { SafeWindowPipe } from './shared/safe-window.pipe';
import { OfferService } from './offer/offer.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { UserProfileResolve } from './auth/user-profile-wrap/user-profile.resolve';
import { UsersManagerIdResolve } from './auth/users-manager/users-manager-id.resolve';
import { UserGroupsResolve } from './auth/user-groups/user-groups.resolve';
import { ArtistJobsResolve } from './auth/artist-profile/user-profile.resolve';
import { SocialResolve } from './auth/social/social.resolve';
import { EndPoints } from './communication/endpoints';
import { HeaderScrollStateDirective } from './ui-components/header-scroll-state.directive';
import { ConfirmResolve } from './auth/confirm/confirm.resolve';
import { PasswordRecoveryResolve } from './auth/password-recovery/password-recovery.resolve';
// import { RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    MainNavPipe,
    BottomPipe,
    HeaderScrollStateDirective
  ],
  imports: [
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),
    HttpClientModule,
    UiComponentsModule,
    AppRoutingModule,
    ApolloModule,
    JobTypeModule,
    BrowserModule,
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MaterialModule,
    // RecaptchaModule,
    // RecaptchaV3Module
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory:
        (httpLink: HttpLink) => {
          let endpoints = new EndpointsService();
          let endpoint = endpoints.getEndpointDomain(EndPoints.GRAPH);
          let storage = new StorageService();

          const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
              graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
              );
            if (networkError) {
              if (networkError.toString().indexOf('code 401') > -1) {
                storage.remove('user');
                storage.remove('token');
                window.location.pathname = 'login';
              }
            }
          })

          const auth = new ApolloLink((operation: any, forward: any) => {
            const token = storage.get('token');
            operation.setContext({
              headers: {
                Authorization: token ?? ''
              }
            });
            return forward(operation);
          });

          const hLink = createHttpLink({
            uri: endpoint
          });

          const defaultOptions = {
            watchQuery: {
              fetchPolicy: 'network-only',
              errorPolicy: 'ignore',
            },
            query: {
              fetchPolicy: 'network-only',
              errorPolicy: 'all',
            },
          } as DefaultOptions;

          return {
            cache: new InMemoryCache(),
            link: ApolloLink.from([errorLink, auth, hLink]),
            defaultOptions: defaultOptions
          };
        },
      deps: [HttpLink],
    },
    CanActivateAuthGuard,
    CanActivateSuGuard,
    CanActivateGuardPermissions,
    NotificationsService,
    ChatService,
    CategoriesService,
    JobService,
    JobResolve,
    SafeWindowPipe,
    OfferService,
    RestService,
    BodyService,
    CacheService,
    AuthService,
    MapperService,
    EnumsService,
    WebsocketService,
    TextToSpeechService,
    SwService,
    NotificationsWebsocketService,
    DecimalPipe,
    BroadcasterService,
    MessagesHandlerService,
    RolesManagerService,
    RolesHelperService,
    StorageService,
    UtilsService,
    CombineImagesService,
    FeedbackService,
    ChatLinkResolve,
    NotificationActionResolve,
    ComplexityGuideResolve,
    CollisionService,
    ComplexityGuidesResolve,
    UserProfileResolve,
    UsersManagerIdResolve,
    UserGroupsResolve,
    ArtistJobsResolve,
    SocialResolve,
    ConfirmResolve,
    PasswordRecoveryResolve,
    // ReCaptchaV3Service,
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: environment.captchaSiteKey
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private meta: Meta) { 
    this.meta.addTag({ httpEquiv: 'Content-Security-Policy', content: environment.csp });
  }
}
