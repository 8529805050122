<div>
  <div fxLayout="row" class="flex-wrap">
    <div fxFlex *ngFor="let software of artists_users_software">
      <div *ngIf="softwaresDictionary" class="item" (click)="onChange(software)"
           [ngClass]="{'selected': software.is_qualified, 'disabled': !software.is_valid, 'pointer': software.is_valid}">
        <div class="img-wrap"></div>
        <img [src]="softwaresDictionary[software.software_id]?.software_image_url || '//cdn.creators3d.com/hotlink-ok/icons/software.png'"
            [alt]="software.software_name">
        <div class="label text-center">
          {{softwaresDictionary[software.software_id].software_name}}
        </div>
      </div>
    </div>
  </div>
  <div class="space-top" *ngIf="!autosave && user">
    <button (click)="save()" mat-raised-button color="primary">
      save
    </button>
  </div>
</div>
