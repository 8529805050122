<div class="wrap">
    <div class="images pos-rel">
        <img [src]="img1" alt="" />
        <img [src]="img2" alt="" class="pos-abs" [ngStyle]="{'opacity': opacity}" />
    </div>
    <div class="slider-wrap pos-rel">
        <mat-slider min="0" max="1" step="0.1" discrete>
            <input [(ngModel)]="opacity" (valueChange)="changeValue($event)" (input)="changeValue($event.target.value)" matSliderThumb>
        </mat-slider>
    </div>
</div>
