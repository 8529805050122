import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class CanActivateAuthGuard  {
    constructor(private router: Router, private auth: AuthService) { }
    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (!this.auth.isloggedIn()) {
            let path = location.pathname;
            if (!path)
                path = '';
            this.router.navigate(['/login', path]);
            return false;
        }
        return true;
    }
}
