<div class="wrap">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
        <div fxFlex="70">
            <div class="ml-header pos-rel">
                <b>Mesh Library</b>
                <span class="separate bg-color-accent-end"></span>
                <span *ngFor="let bc of meshLibService.breadcrumbs; let i = index"
                    (click)="meshLibService.navToState(bc)" class="pointer breadcrumb">
                    <span class="va-middle">{{bc.value}}</span>
                    <mat-icon *ngIf="i < meshLibService.breadcrumbs.length - 1" class="va-middle gray11">
                        keyboard_arrow_right
                    </mat-icon>
                </span>
                <button [disabled]="meshLibService.breadcrumbs.length < 2" mat-icon-button (click)="meshLibService.back()" aria-label="back" class="back pos-abs">
                    <mat-icon>arrow_back_ios</mat-icon>
                    <span class="txt">Back</span>
                </button>
            </div>
            <div *ngIf="meshLibService.state == MeshLibraryState.PARTS">
                <div *ngIf="meshLibService.partPreview">
                    <iframe class="preview box-shadow white-radial-gradient" [src]="meshLibService.partPreview" data-hj-allow-iframe=""
                        frameborder="0"></iframe>
                </div>
            </div>
            <div *ngIf="meshLibService.state == MeshLibraryState.SUB_CATEGORY" class="sub-category">
                <div class="build">
                    <h2>Build Your 3D Toolkit</h2>
                    <div>
                        Choose the 3D mesh parts you think you may need for your job.<br>
                        You can choose as many parts as you wish.<br>
                        Start by choosing the category.
                    </div>
                </div>
                <div class="libraries">
                    <div *ngFor="let ml of meshLibService.meshLibraries" class="item space-top pointer"
                        (click)="meshLibService.currentMeshLibrary = ml">
                          <img [src]="ml.artists_meshes[0].thumbnail_url" alt="preview" class="invert-box-content"> 
                        <span class="desc">{{ml.name}}</span>
                        <mat-icon class="va-super">keyboard_arrow_right</mat-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="meshLibService.state == MeshLibraryState.SECTIONS">
                <div class="parts flex-wrap" fxLayout="row" fxLayoutAlign="start stretch">
                    <div *ngFor="let part of meshLibService.currentMeshLibrary.artists_meshes" fxFlex="33.3%"
                        class="pos-rel item">
                        <div class="pos-abs functions">
                            <button mat-icon-button (click)="meshLibService.currentMeshLibrariesPart = part">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>
                            <mat-checkbox [(ngModel)]="meshLibService.selectedPartsDictionary[part.id]"></mat-checkbox>
                        </div>
                        <img [src]="part.thumbnail_url" alt="preview" (load)="$event.target.style.opacity=1" class="invert-box-content">
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="30" class="body-bg summary">
            <h3 class="parts-added">
                Mesh Library Parts Added ({{meshLibService.selectedParts.length}})
            </h3>
            <ul class="normalize selected" *ngIf="meshLibService.selectedParts.length">
                <li *ngFor="let p of meshLibService.selectedParts; let i = index" class="alt-bg pos-rel fade-in">
                    {{p.name}}
                    <button mat-icon-button (click)="meshLibService.removePart(i)" class="pos-abs">
                        <mat-icon>delete</mat-icon>
                    </button>
                </li>
            </ul>
            <mat-divider class="divider"></mat-divider>
            <div *ngIf="meshLibService.timeUnits" class="time">
                <div class="timer">
                    <mat-icon>timer</mat-icon>
                </div>
                <div class="timer-desc">
                    <b>time saved:</b>
                    <div>
                        <span *ngIf="meshLibService.timeUnits.days">{{meshLibService.timeUnits.days}} Days</span>
                        <span *ngIf="meshLibService.timeUnits.days && meshLibService.timeUnits.hours">, </span>
                        <span *ngIf="meshLibService.timeUnits.hours">{{meshLibService.timeUnits.hours}} Hours</span>
                        <span *ngIf="meshLibService.timeUnits.minutes && meshLibService.timeUnits.hours">, </span>
                        <span *ngIf="meshLibService.timeUnits.minutes">{{meshLibService.timeUnits.minutes | number : '1.0'}} Minutes</span>
                        <span *ngIf="!meshLibService.timeUnits.days && !meshLibService.timeUnits.hours && !meshLibService.timeUnits.minutes">0 Minutes</span>
                        <!-- ({{meshLibService.percentage | number : '1.0-1'}}%) -->
                    </div>
                </div>
            </div>
            <div class="terms space-top">
                <div class="copyright">
                    <mat-icon>copyright</mat-icon>
                </div>
                <div class="desc">
                    Creators retains all copyrights to all models that you work on or store on your personal device at
                    any time. Read the <a href="/terms" target="_blank">terms and conditions</a> for more information on your rights when working for
                    Creators.
                </div>
                <div class="text-center space-top">
                    <mat-checkbox [(ngModel)]="terms">I agree to the terms and conditions</mat-checkbox>
                </div>
            </div>
            <div class="space-top">
                <button mat-raised-button (click)="meshLibService.downloadAll()" aria-label="download all"
                    class="big w-100" [disabled]="!terms || !meshLibService.selectedParts?.length"
                    color="primary">{{getDownLoadFileButtonName()}}
                </button>
            </div>
        </div>
    </div>
</div>