import { Pipe, PipeTransform } from '@angular/core';
import { Link } from './link';

@Pipe({
  name: 'mainNav'
})
export class MainNavPipe implements PipeTransform {

  transform(value: Array<Link>, side?: string, inMenu?: boolean, haveIcon?: boolean): any {
    if (side)
      value = value.filter(item => item.side == side);
    if (typeof inMenu === 'boolean')
      value = value.filter(item => item.inMenu == inMenu);
    else
      value = value.filter(item => typeof item.inMenu === 'undefined');
    if (typeof haveIcon === 'boolean') {
      if (haveIcon)
        value = value.filter(item => item.icon);
      else
        value = value.filter(item => !item.icon);
    }
    return value;
  }

}
