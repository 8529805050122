import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { EmojisService } from '../emojis.service';

@Component({
  selector: 'app-emojis',
  templateUrl: './emojis.component.html',
  styleUrls: ['./emojis.component.scss']
  // ,
  // encapsulation: ViewEncapsulation.Native
})
export class EmojisComponent implements OnInit {
  // public list: Array<string>;
  public currentIndex: number;
  @Output('on-update') onUpdate: EventEmitter<string>;
  constructor(
    public emojisService: EmojisService
  ) {
    this.onUpdate = new EventEmitter<string>();
    this.currentIndex = 0;
    // this.list = ['👍', '😊', '❤', '🤣', '😀', '😥', '😡', '🤔', '😪'];
    // this.emojisService.getAll().subscribe(res => {
    //   debugger;
    // });
  }

  ngOnInit() {
  }

  send(emoji: string) {
    this.onUpdate.emit(emoji);
  }

  onIndexChange(index: number) {
    this.currentIndex = index;
  }
}
