import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RestService } from '../../communication/rest.service';
import { User } from '../user';
import { Observable } from 'rxjs';

@Injectable()
export class UsersManagerIdResolve  {

    constructor(private rest: RestService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return parseInt(route.params['id']) as number;
    }
}