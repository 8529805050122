import { Injectable, ElementRef } from '@angular/core';
import { FormattedDim, TimeUnits, ViewerUrlParams, IntersectionObserverOptions, MinMidMax, ScriptsFetcherType, ScriptsFetcher } from './utils';
import { StorageService } from 'ng-storage-service';
import { Subject, Observable } from 'rxjs';
import { EnumsService } from './enums.service';
import { Notification, NotificationType } from './notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common'
import { ImagesFileTypes, ResourceDimensions, MediaTag, Dimensions, ArtistsJobsResourcesSourceFile } from 'app/job/resource';
import * as moment from 'moment';
import { RestService } from 'app/communication/rest.service';
import { RenderType,GeneralResource, KeyValuePoly, PolygonSpecifications, PolyShapeType, PolyType } from 'app/offer/offers';
import { ArtistsItemsResourcesType } from 'app/job/job';
import { ThreeColor } from 'app/ui-components/asset-adjustments';
import { HttpErrorResponse } from '@angular/common/http';
import { CategoryDefaults } from 'app/categories/category';
import { EndpointsService } from 'app/communication/endpoints.service';
import { FormatsType } from './enums';
import { CustomRequestOptions } from 'app/communication/custom-request';
import { GraphqlService } from 'app/communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { UserQueryData, User } from 'app/auth/user';
import { RenderingEngine } from 'asset-adjustments';
import { EndPoints } from '../communication/endpoints';
import {SoftwaresService} from "../softwares/softwares.service";
// import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  static ARTIST_ACTIVE_JOBS_STATS = ["In Progress", "Job Has Feedback"];
  static ARTIST_ACTIVE_TOTAL_JOBS_STATS = ["In Progress", "Job Pending Review", "Job Has Feedback", "Pending Secondary Review", "Pending Source Files"];
  public onSrcFilePast: Subject<string>;
  private _scriptsFetcher: { [id: number]: ScriptsFetcher };
  constructor(
    private storage: StorageService,
    private enums: EnumsService,
    private broadcaster: BroadcasterService,
    private router: Router,
    private decimalPipe: DecimalPipe,
    // private htmlToCanvasService: HtmlToCanvasService,
    private rest: RestService,
    private endpoints: EndpointsService,
    private gql: GraphqlService,
    private software: SoftwaresService,
    // private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this._scriptsFetcher = {};
    this.onSrcFilePast = new Subject<string>();
  }

  async fetchScript(src: string, type: ScriptsFetcherType) {
    return new Promise(async (resolve, reject) => {
      if (!this._scriptsFetcher[type]) {
        this._scriptsFetcher[type] = {
          loaded: false,
          promise: [resolve],
          type
        };
        await this.loadScript(src);
        this._scriptsFetcher[type].loaded = true;
        this._scriptsFetcher[type].promise.forEach(f => f());
        return;
      }
      if (this._scriptsFetcher[type].loaded) {
        resolve(null);
        return;
      }
      this._scriptsFetcher[type].promise.push(resolve);
    });
  }

  async fetchCss(src: string, type: ScriptsFetcherType) {
    return new Promise(async (resolve, reject) => {
      if (!this._scriptsFetcher[type]) {
        this._scriptsFetcher[type] = {
          loaded: false,
          promise: [resolve],
          type
        };
        await this.loadStylesheet(src);
        this._scriptsFetcher[type].loaded = true;
        this._scriptsFetcher[type].promise.forEach(f => f());
        return;
      }
      if (this._scriptsFetcher[type].loaded) {
        resolve(null);
        return;
      }
      this._scriptsFetcher[type].promise.push(resolve);
    });
  }

  public getFormattedDim(value: string): FormattedDim {
    if (!value) return null;

    value = String(value);

    var returnBysuffix = (val, suffix): FormattedDim => {
      return {
        size: parseFloat(val.substring(0, val.indexOf(suffix))),
        suffix: suffix
      }
    }

    if (value.indexOf('%') > -1)
      return returnBysuffix(value, '%');
    if (value.indexOf('px') > -1)
      return returnBysuffix(value, 'px');
    if (value.indexOf('em') > -1)
      return returnBysuffix(value, 'em');
    if (value.indexOf('rem') > -1)
      return returnBysuffix(value, 'rem');
    if (value.indexOf('pt') > -1)
      return returnBysuffix(value, 'pt');
    if (value == 'auto')
      return returnBysuffix(value, '');
  }

  public objectifierGet(name: string, create: boolean, context: any) {
    return this.Objectifier().get(name, create, context);
  }

  public objectifierSet(name: string, value: any, context: any) {
    return this.Objectifier().set(name, value, context);
  }

  public objectifierExists(name: string, context: any) {
    return this.Objectifier().get(name, false, context) !== undefined;
  }

  /**
   * Return true if the country is blocked
   * @param countryCode
   */
  public isBlockedCountry(countryCode: string): boolean {
    switch (countryCode) {
      case 'IL':
        return true;
      default:
        return false;
    }
  }

  /**
   * Return the country name by provided country code
   * @param countryCode
   */
  public getCountryNameByCode(countryCode: string): string {
    const countryArray = this.enums.getCounries();
    const country = countryArray.find((item) => countryCode === item.key);
    return country?.value;
  }

  /**
   * Returns user from local storage
   */
  public getUserFromStorage(): User {
    return this.storage.get('user');
  }

  public getIconByUrl(url: string) {
    var icon = 'insert_drive_file';
    if (url) {
      url = url.toLowerCase();
      ['.png', '.jpg', '.jpeg', '.jfif', '.exif', '.tiff', '.gif', '.bpm', '.ppm', '.pgm', '.pbm', '.pnm', '.svg'].forEach((ext) => {
        if (url.indexOf(ext) > -1) {
          icon = 'photo';
          return false;
        }
      });
    }
    return icon;
  }

  public getParamsFromUrl(url: string): any {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  public setUrlParam(url: string, name: string, value: string): string {
    if (!url || url.indexOf('?') == -1) return url;
    let paramsString = url.substring(url.indexOf('?') + 1);
    let searchParams = new URLSearchParams(paramsString);
    // if (isNaN(parseInt(value)))
    //   value = null;
    if (typeof value === 'number' || typeof value === 'string')
      searchParams.set(name, value);
    else
      searchParams.delete(name);
    return url.substring(0, url.indexOf('?') + 1) + searchParams.toString();
  }

  public getUrlParam(url: string, name: string): string {
    if (!url || url.indexOf('?') == -1) return url;
    let paramsString = url.substring(url.indexOf('?') + 1);
    let searchParams = new URLSearchParams(paramsString);
    return searchParams.get(name);
  }

  public getPath(url: string): string {
    if (!url) return null;
    var loc = new URL(url);
    return loc.pathname;
  }

  public fromObjectToQuerystring(obj: any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  public dataURLtoFile(dataurl: string, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  public getDomain(url: string): string {
    let hostname: string;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    }
    else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  public stripScripts(s) {
    var div = document.createElement('div');
    div.innerHTML = s;
    var scripts = div.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
  }

  public getTextContent(html: string) {
    var div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent;
  }

  public guessTimezone() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) { }
    return null;
  }

  public fixImages(html: string): string {
    try {
      let div = document.createElement('div');
      div.innerHTML = html;
      return div.innerHTML.replace(/"\\&quot;/g, "'").replace(/\\&quot;"/g, "'");
    }
    catch (e) {
      return html;
    }
  }

  public isAboveTS(present: number, past: number, maxMS: number) {
    return (present - past > maxMS);
  }

  public getFileExtension(filename) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : filename;
  }

  public playSound(src: string) {
    const play = () => {
      const a = new Audio(src);
      a.volume = 0.25;
      a.play();
    };
    if (!this.storage.isSupported()) {
      play();
      return;
    }
    let getLast = () => {
      let lastSound = this.storage.get(src);
      if (typeof lastSound !== 'number')
        lastSound = 0;
      return lastSound;
    }

    let last = getLast();
    last++;
    this.storage.set(src, last);
    setTimeout(() => {
      last = this.storage.get(src);
      last--;
      this.storage.set(src, last);
      if (last == 0)
        play();
    }, 500);
  }

  public getSrcFromFilePast(file) {
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.onSrcFilePast.next(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  public getViewerTypesByFormatType(key: number) {
    let vt = this.enums.getViewerTypes();
    vt.splice(vt.findIndex(i => i.key == 'browser'), 1);
    if (key == 5) { // only B4W can display blend files
      return vt.splice(vt.findIndex(i => i.key == 'b4w'), 1);
    }
    if (key == 6 || key == 7) { // only 3JS can display GLTF files
      return vt.splice(vt.findIndex(i => i.key == '3JS'), 1);
    }
    if (key == 9 || key == 10 || key == 11) // image or video
      return [];
    return vt;
  }

  public copyClipboard(str: string): boolean {
    let container = document.createElement('input');
    container.className = 'copy-clipboard';
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.opacity = '0.05';
    container.value = str;
    document.body.appendChild(container);

    container.select();

    try {
      var successful = document.execCommand('copy');
      if (successful) {
        let data: Notification = {
          text: 'copied successfully',
          type: NotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      }

    } catch (err) {
      console.log('unable to copy clipboard');
    }
    document.body.removeChild(container);
    return successful;
  }

  private Objectifier() {
    var objectifier = function (splits, create, context) {
      var result = context || window;
      for (var i = 0, s; result && (s = splits[i]); i++) {
        result = (s in result ? result[s] : (create ? result[s] = {} : undefined));
      }
      return result;
    };

    return {
      // Creates an object if it doesn't already exist
      set: function (name: string, value: any, context: any) {
        var splits = name.split('.'), s = splits.pop(), result = objectifier(splits, true, context);
        return result && s ? (result[s] = value) : undefined;
      },
      get: function (name: string, create: boolean, context: any) {
        return objectifier(name.split('.'), create, context);
      },
      exists: function (name: string, context: any) {
        return this.get(name, false, context) !== undefined;
      }
    };
  }

  public arrayBufferToBase64(buffer: ArrayBuffer, callback: Function, contentType = 'application/octet-binary') {
    var blob = new Blob([buffer], { type: contentType });
    var reader = new FileReader();
    reader.onload = function (evt) {
      var dataurl = evt.target['result'] as string;
      callback(dataurl.substr(dataurl.indexOf(',') + 1));
    };
    reader.readAsDataURL(blob);
  }

  public base64ToArrayBuffer(base64: string): any {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }

  public equals(x: any, y: any) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
      if (!x.hasOwnProperty(p)) continue;
      // other properties were tested using x.constructor === y.constructor

      if (!y.hasOwnProperty(p)) return false;
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if (x[p] === y[p]) continue;
      // if they have the same strict value or identity then they are equal

      if (typeof (x[p]) !== "object") return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if (!this.equals(x[p], y[p])) return false;
      // Objects and Arrays must be tested recursively
    }

    for (p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
      // allows x[ p ] to be set to undefined
    }
    return true;
  }

  public safeParse(val: any): any {
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  }

  public parseToArr(val, returnDefault = false): Array<any> {
    if (val instanceof Array) return val;
    let res = [];
    if (typeof val === 'string' && val) {
      try {
        res = JSON.parse(val);
        if (!returnDefault && res.length == 0)
          res = null;
      } catch (e) {
        res = val.split(',');
      }
    }
    if (!(res instanceof Array)) {
      res = [res];
    } else if (!(res instanceof Array) && !(val instanceof Array)) {
      res = [val];
    }
    return res;
  }

  public deepCopyByValue(src: any, onlyInextensible = false): any {
    try {
      if (onlyInextensible && Object.isExtensible(src))
        return src;
      return JSON.parse(JSON.stringify(src));
    } catch (e) {
      return null;
    }
  }

  public deepCopy(src, dest?) {
    if (typeof src !== "object" || src === null || typeof src === 'number' || typeof src === 'boolean' || typeof src === 'string')
      return src;
    if (src instanceof Array) {
      dest = dest || [];
      for (let i = 0; i < src.length; i++) {
        dest[i] = this.deepCopy(src[i]);
      }
    } else {
      dest = dest || {};
      for (let key in src) {
        dest[key] = this.deepCopy(src[key]);
      }
    }
    return dest;
  }

  public getURLSearchParamsFromObj(obj: any): URLSearchParams {
    var params = new URLSearchParams();
    for (let key in obj)
      params.append(key, obj[key]);
    return params;
  }

  public forceRedirectTo(url: string) {
    this.router.navigateByUrl('/blank', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl(url));
  }

  public getSafeDate(date: any): Date {
    if (date instanceof Date) return date;
    if (typeof date === 'string') {
      return new Date(date);
    }
    return null;
  }

  public getMaxPossibleDate(): Date {
    return new Date(8640000000000000);
  }

  public getMinPossibleDate(): Date {
    return new Date('1700-01-01');
  }

  public getMinutesFromMS(ms: number): number {
    return ms / 60000;
  }

  public getHoursFromMS(ms: number): number {
    return ms / (1000 * 60 * 60);
  }

  public getMSFromHours(ms: number): number {
    return ms * (1000 * 60 * 60);
  }

  public getTimeUnitsFromHours(hours: number): TimeUnits {
    let tu = new TimeUnits();

    const now = moment(new Date());
    let later = new Date();
    later.setHours(later.getHours() + hours);
    const expiration = moment(later);

    // get the difference between the moments
    const diff = expiration.diff(now);
    //express as a duration
    const diffDuration = moment.duration(diff);

    tu.days = diffDuration.days();
    tu.hours = diffDuration.hours();
    if (!tu.days && !tu.hours)
      tu.minutes = hours * 60;
    else
      tu.minutes = diffDuration.minutes();
    return tu;
  }

  public getTimeUnitsFromMS(duration: number): TimeUnits {
    let tu = new TimeUnits();

    let milliseconds = (duration % 1000) / 100,
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60))); // we calc days as hours so don't use % 24
    // hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    tu.hours = hours;
    tu.minutes = minutes;
    tu.seconds = seconds;
    tu.ms = milliseconds;
    return tu;
  }

  getTimespan(date1: Date, date2: Date): number {
    date1 = this.getSafeDate(date1);
    date2 = this.getSafeDate(date2);
    let diff = date2.getTime() - date1.getTime();
    return diff;
  }

  dateMillisecondsFormat(date: string): Date {
    let formated = new Date(date);
    if (formated.getTime()) {// eliminate 1970-01-01 if zerro
      return formated;
    }
    return null;
  }

  public getAverageRGB(imgPath, ignoreValue): Observable<any> {
    const observable = new Observable<any>((observer) => { });

    let blockSize = 5, // only visit every 5 pixels
      canvas = document.createElement('canvas'),
      context = canvas.getContext && canvas.getContext('2d'),
      data, width, height,
      i = -4,
      length,
      rgb = { r: 0, g: 0, b: 0 },
      count = 0;

    let isIgnore = (r, g, b) => {
      if (!ignoreValue || ignoreValue.length == 0) return false;
      for (let i = 0; i < ignoreValue.length; i++) {
        if (r == ignoreValue[i][0]
          && g == ignoreValue[i][1]
          && b == ignoreValue[i][2])
          return true;
      }
      return false;
    };

    let onImageLoad = (img) => {
      let imgEl = img.srcElement;
      height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
      width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

      context.drawImage(imgEl, 0, 0);

      data = context.getImageData(0, 0, width, height);

      length = data.data.length;

      while ((i += blockSize * 4) < length) {
        if (isIgnore(data.data[i], data.data[i + 1], data.data[i + 2])) continue;
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
      }

      // ~~ used to floor values
      rgb.r = ~~(rgb.r / count);
      rgb.g = ~~(rgb.g / count);
      rgb.b = ~~(rgb.b / count);
      return { message: rgb };
      // messageService.sendNgMessage('onImageRGB' + sufix, { message: rgb });
    };
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = onImageLoad;
    img.src = imgPath;
    return observable;
  }

  public getClientId() {
    let client_id = this.storage.get('client_id');
    if (!client_id) {
      client_id = this.generateUUID();
      this.storage.set('client_id', client_id);
    }
    return client_id;
  }

  public generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  public roundDown(num: number, decimal = 1): number {
    const dec = 10 * decimal;
    return Math.round(num * dec) / dec;
  }

  public getNormalizedUnitsTime(hours: number): string {
    if (!hours) return hours + ' hours';
    let res = hours;
    if (hours > 24) {
      res /= 24;
      // if (res > 30) {
      //   // if (res > 365) {
      //   //   res /= 30;
      //   //   return Math.floor(res / 12) + ' years and ' + (res - 30) + ' months'
      //   // }
      //   return Math.floor(res / 30) + ' months and ' + Math.floor((res / 30) - 30) + ' days'
      // }
      return this.decimalPipe.transform(res, '1.0-1') + ' days'
    }
    return this.decimalPipe.transform(res, '1.0-1') + ' hours'
  }

  public getFileTypeBy(t: ImagesFileTypes): string {
    switch (t) {
      case (ImagesFileTypes.ANY): {
        return null;
      }
      case (ImagesFileTypes.JPG): {
        return 'image/jpeg';
      }
      case (ImagesFileTypes.PNG): {
        return 'image/png';
      }
    }
  }

  public removeApostropheFromArrays(a: any) {
    Object.keys(a).forEach(i => {
      if (a[i] instanceof Array && typeof a[i][0] === 'string')
        for (let j = 0; j < a[i].length; j++) {
          a[i][j] = a[i][j].replace(/"/g, '');
        }
    });
  }

  public blobToFile(theBlob: Blob, fileName: string): File {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }

  public sameDay(d1: Date, d2: Date) {
    if (typeof d1 === 'string')
      d1 = new Date(d1);
    if (typeof d2 === 'string')
      d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  public closest(elem: Element, selector: string) {
    if (selector.indexOf('.') == 0) {
      if (elem.classList.contains(selector.substring(1)))
        return elem;
    }
    if (selector.indexOf('#') == 0) {
      if (elem.getAttribute('id') == selector.substring(1))
        return elem;
    }
    if (elem.tagName && elem.tagName.toLowerCase() == selector.toLowerCase())
      return elem;
    if (elem.parentElement)
      return this.closest(elem.parentElement, selector);
    return null;
  }

  getMinMidMax(dimOrg: ResourceDimensions): MinMidMax {
    let dim = this.deepCopyByValue(dimOrg) as ResourceDimensions;
    // make sure we don't have any equal values
    const heightDif = 0.00000001, lengthDif = 0.00000002;
    dim.height += heightDif;
    dim.length += lengthDif;
    let res = {} as MinMidMax;
    let arr = [dim.width, dim.height, dim.length].sort((a, b) => a - b);
    const setKVP = (key: string, value: number, order: string) => {
      if (value == dim[key]) {
        if (key == 'height')
          value -= heightDif;
        else if (key == 'length')
          value -= lengthDif;
        res[order] = { key, value };
      }
    }
    setKVP('width', arr[0], 'min');
    setKVP('height', arr[0], 'min');
    setKVP('length', arr[0], 'min');

    setKVP('width', arr[1], 'mid');
    setKVP('height', arr[1], 'mid');
    setKVP('length', arr[1], 'mid');

    setKVP('width', arr[2], 'max');
    setKVP('height', arr[2], 'max');
    setKVP('length', arr[2], 'max');
    // for (let i = 0; i < arr.length; i++) {
    //   setKVP('width', arr[i], 'min');
    //   setKVP('height', arr[i], 'min');
    //   setKVP('length', arr[i], 'min');
    // }
    // for (let i = 0; i < arr.length; i++) {
    //   setKVP('width', arr[i], 'mid');
    //   setKVP('height', arr[i], 'mid');
    //   setKVP('length', arr[i], 'mid');
    // }
    // for (let i = 0; i < arr.length; i++) {
    //   setKVP('width', arr[i], 'max');
    //   setKVP('height', arr[i], 'max');
    //   setKVP('length', arr[i], 'max');
    // }
    return res;

    // if (arr[0] == dim.width) {

    // }

    // if (dim.width < dim.height) {
    //   if (dim.width < dim.length) {
    //     res.min = { key: 'width', value: dim.width };
    //   }
    //   else {
    //     res.min = { key: 'height', value: dim.height };
    //   }
    // }
    // else {
    //   if (dim.height < dim.length) {
    //     res.min = { key: 'height', value: dim.height };
    //   }
    //   else {
    //     res.min = { key: 'length', value: dim.length };
    //   }
    // }
    // return res;
  }

  convertToCM(dim: ResourceDimensions): ResourceDimensions {
    // let target = {} as ResourceDimensions;
    // Object.assign(target, dim);
    // switch (target.units) {
    //   case 2: {
    //     target.width /= 2.54;
    //     target.height /= 2.54;
    //     target.length /= 2.54;
    //     target.units = 1;
    //     break;
    //   }
    // }
    // return target;
    if (!dim) return dim;
    switch (dim.units) {
      case 1: {
        dim.width *= 2.54;
        dim.height *= 2.54;
        dim.length *= 2.54;
        dim.units = 2;
        break;
      }
    }
    return dim;
  }

  getRenderType(resourcesTypes: Array<ArtistsItemsResourcesType>): RenderType {
    let res = null as RenderType;
    if (resourcesTypes && resourcesTypes.length) {
      if (resourcesTypes.find(i => i.resource_type_id == 11)) {
        if (resourcesTypes.find(i => i.resource_type_id == 10) || resourcesTypes.find(i => i.resource_type_id == 9)) {
          res = RenderType.IMAGES_AND_VIDEO;
        }
        else {
          res = RenderType.VIDEO;
        }
      }
      else if (resourcesTypes.find(i => i.resource_type_id == 10) ||
        resourcesTypes.find(i => i.resource_type_id == 9)) {
        res = RenderType.IMAGES;
      }
    }
    return res;
  }

  public getEffectiveType() {
    if ((navigator as any).connection && (navigator as any).connection['effectiveType'])
      return (navigator as any).connection['effectiveType'];
    return null;
  }

  public getMediaTagByResource(resource: GeneralResource): MediaTag {
    let mediaTag = MediaTag.MODEL;
    if (resource) {
      if (resource.resource_type == FormatsType.PNG
        || resource.resource_type == FormatsType.JPG
        || resource.resource_type == FormatsType.TIFF)
        mediaTag = MediaTag.IMAGE;
      else if (resource.resource_type == FormatsType.MP4) {
        mediaTag = MediaTag.VIDEO;
      }
    }
    return mediaTag;
  }

  public getSourceFileSoftware(artistJobResourceSourceFile: ArtistsJobsResourcesSourceFile): string {
    // return `${artistJobResourceSourceFile?.software_id} ${JSON.stringify(this.software.getSoftwaresDictionary())}`;
    return this.software.getSoftwaresDictionary()[artistJobResourceSourceFile?.software_id]?.software_name || '';
  }

  spaceCamelcase(str: string): string {
    if (!str) return str;
    let rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    let res = str.replace(rex, '$1$4 $2$3$5');
    return res;
  }

  ucFirst(str: string) {
    if (!str) return str;
    str = str.charAt(0).toUpperCase() + (str.length > 1 ? str.slice(1) : '');
    return str;
  }

  requestIdleCallback(callback: any) {
    if (window['requestIdleCallback'])
      window['requestIdleCallback'](callback);
    else
      requestAnimationFrame(callback as FrameRequestCallback);
  }

  removeFromStrageIfContains(contains: string) {
    try {
      Object.keys(localStorage).forEach(key => {
        if (key && key.indexOf(contains) > -1) {
          this.storage.remove(key);
        }
      });
    }
    catch (e) { }
  }

  public getKeyValuePolyByPolySpecs(specs: Array<PolygonSpecifications>, defaults?: boolean, defRestrictions?: { [id: number]: CategoryDefaults }): Array<KeyValuePoly> {
    const res = [];
    const polyTypesDictionary = this.enums.getPolyTypesDictionary();
    const polyShapeTypesDictionary = this.enums.getPolyShapeTypesDictionary();

    if (specs && specs.length) {
      specs.forEach(s => {
        const current = this.deepCopyByValue(polyTypesDictionary[s.poly_type]) as KeyValuePoly;
        if (!current) {
          throw new Error('poly_type is null!');
        }
        current.value.shapeType = s.poly_shape_type || PolyShapeType.TRIANGULAR;
        current.value.shapeName = polyShapeTypesDictionary[current.value.shapeType].value.name;
        current.value.min = s.min_poly_count;
        current.value.max = s.max_poly_count;
        current.value.variationName = s.variation_name;
        current.value.serialNumber = s.serial_number;
        current.value.jobType = s.job_type;
        res.push(current);
      });
    }
    if (!res.length && defaults) {
      res.push({
        key: PolyType.MID,
        value: {
          name: polyTypesDictionary[PolyType.MID].value.name,
          shapeName: polyShapeTypesDictionary[PolyShapeType.TRIANGULAR].value.name,
          shapeType: PolyShapeType.TRIANGULAR,
          icon: polyTypesDictionary[PolyType.MID].value.icon,
          // min: 50000,
          // max: 500000
        }
      } as KeyValuePoly);
    }
    if (defRestrictions && Object.keys(defRestrictions).length) {
      res.forEach(i => {
        if (i.value) {
          const defRestriction = defRestrictions[i.key];
          if (defRestriction && defRestriction.value) {
            if (!i.value.min && !i.value.min) {
              i.value.min = defRestriction.value.min;
              i.value.max = defRestriction.value.max;
            }
            if (!i.value.maxSize)
              i.value.maxSize = defRestriction.value.maxSize;
          }
        }
      });
    } else {
      // add fallback if there are no default for this sub-category
      res.forEach(i => {
        if (i.value) {
          const defRestriction = {
            value: {
              min: 500,
              max: 80000,
              maxSize: 3.5
            }
          } as CategoryDefaults;
          if (!i.value.min && !i.value.min) {
            i.value.min = defRestriction.value.min;
            i.value.max = defRestriction.value.max;
          }
          if (!i.value.maxSize && i.key !== PolyType.HIGH) {
            i.value.maxSize = defRestriction.value.maxSize;
          }
        }
      });
    }
    return res;
  }

  getColor(color: ThreeColor): string {
    let getP = (obtained: number) => {
      let res = (Math.round(obtained * 255)).toString(16);
      if (res.length < 2)
        res = '0' + res;
      return res;
    }
    if (color) {
      return '#' + getP(color.r) + getP(color.g) + getP(color.b);
    }
  }

  multipleDownloads(urls: Array<string>, name: string) {
    let counter = 0;
    urls.forEach(url => {
      var link = document.createElement("a");
      link.setAttribute('download', name ? counter ? name + `_${counter}` : name : '');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
      // let iframe = document.createElement('iframe');
      // iframe.style.visibility = 'collapse';
      // document.body.append(iframe);
      // setTimeout(() => iframe.remove(), 2000);
      // iframe.contentDocument.write(
      //   `<form action="${url.replace(/\"/g, '"')}" method="GET"></form>`
      // );
      // iframe.contentDocument.forms[0].submit();
    });
  }

  getErrorMessage(err: HttpErrorResponse, fallbackMsg = '') {
    let msg = err && err.error ? err.error : fallbackMsg;
    if (msg && msg.error && msg.error.message)
      msg = msg.error.message;
    if (msg && msg.indexOf && msg.indexOf('Reason: ') == 0) {
      msg = msg.substring(8);
    }
    return msg;
  }

  httpErrorResponseHandler(err: HttpErrorResponse, fallbackMsg?: string, duration?: number) {
    // let msg = err && err.error ? err.error : fallbackMsg;
    // if (msg && msg.error && msg.error.message)
    //   msg = msg.error.message;
    // if (msg && msg.indexOf && msg.indexOf('Reason: ') == 0) {
    //   msg = msg.substring(8);
    // }
    let data: Notification = {
      text: this.getErrorMessage(err, fallbackMsg),
      type: NotificationType.Error,
      action: 'OK',
      duration: duration
    }
    this.broadcaster.broadcast('notifyUser', data);
  }

  public notifyUser(b: Notification) {
    this.broadcaster.broadcast('notifyUser', b);
  }

  getTotalImagesSize(files: { [id: string]: string; }, imagesSuffix = ['.png', '.jpg', '.jpeg'], ignoreFor = []): number {
    let size = 0;
    // const imagesSuffix = ['.png', '.jpg', '.jpeg'];

    const isInIgnore = (name: string) => {
      for (let i = 0; i < ignoreFor.length; i++) {
        if (name.indexOf(ignoreFor[i]) > -1)
          return true;
      }
      return false;
    }

    for (let i in files) {
      imagesSuffix.forEach(s => {
        if (i.toLowerCase().indexOf(s) > -1 && !isInIgnore(i)) {
          let fileSize = this.getFilsSizeInMB(files[i]);
          if (!isNaN(fileSize * 1))
            size += fileSize;
        }
      });
    }
    return size;
  }

  getFilsSizeInMB(fileDesc: string) {
    let fileArr = fileDesc.split(' ');
    let fileSize = parseFloat(fileArr[0]);
    if (!isNaN(fileSize * 1)) {
      const lower = fileArr[1].toLowerCase();
      if (lower === 'kib')
        fileSize /= 1000;
      else if (lower == 'kb')
        fileSize /= 1024;
      if (lower === 'mib')
        fileSize *= 1.024;
      else if (lower === 'b')
        fileSize /= (1024 * 1024);
    }
    return fileSize;
  }

  getCleanViewerURL(viewerUrl: string, exportedQuery: any): string {
    for (let i in exportedQuery) {
      if (i != 'load' && i != 'decrypt' && i != 'autorotate' && i != 'webp' && i != 'compressed' && i != 'gzip' && i != 'br' && i != 'json-data' && i != 'config')
        viewerUrl = this.setUrlParam(viewerUrl, i, exportedQuery[i]);
    }
    return viewerUrl;
  }

  removeForbiddenViewerURL(viewerUrl: string): string {
    let params = this.getParamsFromUrl(viewerUrl);
    for (let i in params) {
      if (i == 'embed-loader' || i == 'min-distance' || i == 'max-distance' || i == 'auto-adjust' || i == 'dummyVar' || i == 'all-loaders' || i == 'frame-id')
        viewerUrl = this.setUrlParam(viewerUrl, i, null);
    }
    return viewerUrl;
  }

  cancelEvent(e?: Event): boolean {
    if (!e)
      e = window.event;
    try {
      e.preventDefault();
    } catch (e) { }
    try {
      e.stopPropagation();
    } catch (e) { }
    try {
      e.stopImmediatePropagation();
    } catch (e) { }
    return false;
  }

  buildViewerUrl(modelUrl: string, params = new ViewerUrlParams()) {
    return this.endpoints.getEndpointDomain(EndPoints.THREE_JS_VIEWER) + `?load=${encodeURIComponent(modelUrl)}${params.rotate ? '&autorotate=true' : ''}${params.exposure ? `&exp=${params.exposure}` : ''}`;
  }

  getResourceJsonParams(params: any, targetResourceType: FormatsType) {
    if (params) {
      params.scene = params.scene || {};
      if (params && params.materialManipulations) {
        params.scene.materialManipulations = params.materialManipulations;
        delete params.materialManipulations;
      }
      params.scene.materialManipulations = this.getCleanMaterialManipulations(params.scene.materialManipulations, targetResourceType);
    }
    return params;
  }

  getCleanMaterialManipulations(materialManipulations: any, targetResourceType: FormatsType): any {
    // in case we want to save a glTF file we don't need those manipulations, the format supports those natively
    if ((targetResourceType == FormatsType.GLB || targetResourceType == FormatsType.glTF) && materialManipulations) {
      for (let i in materialManipulations) {
        for (let j in materialManipulations[i]) {
          // if (j !== 'color' && j !== 'alphaTest' && j !== 'transparent' && j !== 'castShadow' && j !== 'receiveShadow')
          if (j !== 'castShadow' && j !== 'receiveShadow' && j !== 'reflectivity' && j !== 'type' && j !== 'clearCoat' && j !== 'clearCoatRoughness' && j !== 'envMap')
            delete materialManipulations[i][j];
          // delete params.materialManipulations[i].color;
          // delete params.materialManipulations[i].alphaTest;
          // delete params.materialManipulations[i].transparent;
        }

      }
    }
    return materialManipulations;
  }

  // removeViewerForbiddenParams(viewerUrl: string): string {
  //   viewerUrl = this.setUrlParam(viewerUrl, 'min-distance', null);
  //   viewerUrl = this.setUrlParam(viewerUrl, 'max-distance', null);
  //   return viewerUrl;
  // }

  // isSafari(): boolean {
  //   return !!window['safari'];
  //   // return this._isSafari;
  // }

  // isInViewport(elem: Element) {
  //   var bounding = elem.getBoundingClientRect();
  //   return (
  //     bounding.top >= 0 &&
  //     bounding.left >= 0 &&
  //     bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //     bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // };

  async convertToJpeg(base64: string, encoderOptions = 0.92): Promise<string> {
    return new Promise((resolve: Function, reject: Function) => {
      if (base64.indexOf('data:image/jpeg;base64') === 0 || base64.indexOf('data:image/jpg;base64') === 0)
        resolve(base64);
      else {
        const length = base64.length;
        let c = document.createElement('canvas');
        const ctx = c.getContext('2d');
        const img = new Image();
        img.onload = () => {
          c.width = img.width;
          c.height = img.height;
          ctx.fillStyle = '#fff';  /// set white fill style
          ctx.fillRect(0, 0, c.width, c.height);
          ctx.drawImage(img, 0, 0);
          document.body.appendChild(c);
          base64 = c.toDataURL('image/jpeg', encoderOptions);
          resolve(base64);
          c.parentElement.removeChild(c);
        };
        img.src = base64;
        c.style.position = 'absolute';
        c.style.top = '0';
        c.style.left = '0';
        c.style.opacity = '0.01';
        c.style.zIndex = '99999999';
      }
    });
  }

  async getUrlFromBase64(base64: string, bucket = 'cdn.creators3d.com', fileName?: string): Promise<string> {
    return new Promise(async (resolve: Function, reject: Function) => {
      let fileUploadRequest = new FormData();
      const isImage = base64.indexOf('data:image/') === 0;
      const name = fileName ? fileName : isImage ? base64.indexOf('data:image/png') === 0 ? 'image.png' : 'image.jpeg' :
        `file.${base64.substring(base64.indexOf('/') + 1,  base64.indexOf(';'))}`;
      fileUploadRequest.append('file', this.dataURLtoFile(base64, name));
      fileUploadRequest.append('compress', isImage ? 'true' : 'false');
      fileUploadRequest.append('bucket', bucket);
      let options = new CustomRequestOptions();
      options.showLoading = false;
      this.rest.afterCdn('post', fileUploadRequest, '', options).subscribe(
        data => {
          if (data?.url)
            // resolve(data.url.replace('https://cdn.hexa3d.io/', 'https://img-cdn.azureedge.net/'));
            resolve(data.url.replace('https://cdn.hexa3d.io/', 'https://himg-cdn.com/'));
          else
            reject();
        },
        () => {
          reject();
        }
      )
    });
  }


  //   /**
  //    *
  //    * @param base64
  //    * @param fileName
  //    * @param fileType
  //    * @param bucket
  //    * @returns
  //    */
  //   public upload64BitImage(base64: string, fileName: string, fileType: string, bucket = 'cdn.creators3d.com'): Observable<any> {
  //     let fileUploadRequest = new FormData();
  //     fileUploadRequest.append('file', new File([base64], fileName, { type: fileType }));
  //     fileUploadRequest.append('compress', 'true');
  //     fileUploadRequest.append('bucket', bucket);
  //     let options = new CustomRequestOptions();
  //     return this.rest.afterCdn('post', fileUploadRequest, '', options);
  //   }

  async reconstruction(subCatId: number, imageUrl: string) {
    return new Promise((resolve, reject) => {
      let payload = {
        sub_category_id: subCatId,
        url: imageUrl
      }
      this.rest.reconstruction('post', payload).subscribe(
        (obj) => {
          resolve(obj);
        },
        err => this.httpErrorResponseHandler(err, 'failure reconstructing model from image')
      )
    });
  }

  ValidateEmail(mail: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  async onViewportVisibility(el: ElementRef, options: IntersectionObserverOptions) {
    return new Promise((resolve: Function, reject: Function) => {
      if (window.IntersectionObserver) {
        const once = options.once;
        delete options.once;
        let observer = new IntersectionObserver(e => {
          let entry = e instanceof Array ? e[0] : e;
          if (entry.isIntersecting) {
            if (once) {
              // let observerAny = observer as any;
              // observerAny.unobserve();
              observer.disconnect();
            }
            resolve();
          }
        }, options);
        observer.observe(el.nativeElement);
      }
      else {
        console.warn('IntersectionObserver is not supported')
      }
    });
  }

  async observableToPromise(o: Observable<any>): Promise<any> {
    return new Promise((resolve: Function, reject: Function) => {
      o.subscribe(res => resolve(res), err => reject(err));
    });
  }

  // atoi(str: string): number {
  //   let sum = 0;
  //   for (let i = 0; i < str.length; i++) {
  //     sum += str.charCodeAt(i);
  //   }
  //   return sum;
  // }

  async loadScript(url: string, successCB?: Function) {
    return new Promise((resolve: Function, reject: Function) => {
      if (document.querySelector(`script[src="${url}"]`)) {
        resolve();
        return;
      }
      let script = document.createElement('script');
      script.src = url;
      script.type = 'text/javascript';
      script.addEventListener('load', () => {
        resolve();
      }, false);
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  }

  async loadStylesheet(url: string) {
    return new Promise((resolve: Function, reject: Function) => {
      var head = document.getElementsByTagName('head')[0];
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = url;
      link.media = 'all';
      head.appendChild(link);
      var img = new Image();
      img.onerror = () => {
        resolve(null);
      };
      img.onload = () => {
        resolve(null);
      };
      img.src = url;
    });
  }

  async getUser(userId: number): Promise<User> {
    let u = await this.observableToPromise(this.gql.user(userId)) as ApolloQueryResult<UserQueryData>;
    return u.data.artists_users;
  }

  // async getLocation(): Promise<GeolocationPosition> {
  async getLocation(): Promise<any> {
    return new Promise((resolve: Function, reject: Function) => {
      // navigator.geolocation.getCurrentPosition((gp: GeolocationPosition) => {
      navigator.geolocation.getCurrentPosition((gp: any) => {
        resolve(gp);
        // }, (err: GeolocationPositionError) => {
      }, (err: any) => {
        reject(err);
      });
    });
  }

  textToHTML(text: string): HTMLDivElement {
    let div = document.createElement('div');
    div.innerHTML = text;
    return div;
  }

  async setTimeout(ms?: number) {
    return new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  getImageDim(url: string): Promise<Dimensions> {
    return new Promise((resolve: any, reject: any) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
      img.onerror = reject;
      img.src = url;
    });
  }

  getRenderingEngine(url: string): RenderingEngine {
    return parseInt(this.getUrlParam(url, 'engine') || '1') as RenderingEngine;
  }

  rgbToHex(rgb: number) {
    let hex = Number(rgb.toFixed(0)).toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  }

  throw(msg: string): never {
    throw new Error(msg);
  }

  removeNullProperties(obj, isIncludeEmpty = false) {
    for (let key in obj) {
      if (obj[key] === null || (isIncludeEmpty && obj[key].trim() === "")) {
        delete obj[key];
      }
      if (Array.isArray(obj[key])) {
        obj[key] = this.filterNullPropInArray(obj[key]);
        if (obj[key] && obj[key].length === 0) {
          delete obj[key];
        }
      }
    }
    return obj;;
  }

  filterNullPropInArray(arr) {
    if (typeof (arr.length && arr[0]) === 'object')
      return arr.filter(obj => {
        return Object.values(obj).some(value => value !== null);
      });
    else
      return arr;
  }

  // getRecaptchaToken(name: string): Promise<string> {
  //   return new Promise(async (resolve, reject) => {
  //     // this.recaptchaV3Service.execute(name).subscribe((token: string) => {
  //     this.recaptchaV3Service.execute(name).subscribe((token: string) => {
  //       resolve(token);
  //     }, (err: any) => {
  //       reject(err);
  //     });
  //   });
  // }
}
