import { Resource, ResourceDimensions } from './resource';
import { NativeResourceSet, ItemPricing, RendersSpecification, RendersAngles, PolygonSpecifications, KeyValuePoly } from '../offer/offers';
import { User, UserAvailabilityStatus } from '../auth/user';
import { ListRowsResponse } from '../communication/custom-request';
import { ArtistsJobInvoice } from '../payments/invoice';
import { Product, ResourceType } from '../product/product';
import { ArtistsGroupUser } from '../auth/group';
import {JobsTypes, Priority, QaModes} from 'app/shared/enums';
import { ArtistsItemsSubCategoriesAssociation, AttachmentFileType } from 'app/categories/category';
import {artists_pause_reasons} from "./feedback";

export interface Job {
    id?: number;
    opened_by: number;
    notes: string;
    delivered_at: Date;
    status_changed_at: Date;
    artists_users: Array<User>;
    artists_jobs_invoices: Array<ArtistsJobInvoice>;
    was_offer: boolean;
    created_at?: Date;
    artist_user_id?: number;
    batch_id?: number;
    updated_at?: Date;
    price: number;
    status: string;
    allow_rank_bonus: boolean;
    allow_time_bonus: boolean;
    is_archived: boolean;
    countdown_active: boolean;
    artists_jobs_tags: Array<ArtistJobTag>;
    artists_jobs_items: Array<ArtistJobItem>;
    artists_jobs_pricing: Array<ItemPricing>;
    artists_jobs_types: Array<ArtistsJobsType>;
    artists_jobs_renders_specifications: Array<ArtistsJobsRendersSpecification>;
    artists_jobs_private_comments: Array<ArtistsJobsPrivateComments>;
    artists_jobs_subscribers: Array<ArtistsJobsSubscriber>;
    // due_date: any;
    hours_to_complete: number;
    org_htc: number;
    counter_updated_at: Date;
    paid: boolean;
    same_mesh: boolean;
    // cms_url?: string;
    source_job?: number;
    source_offer?: number;
    source_offer_uuid?: string;
    // job_type?: number;
    // group_name?: string;
    // parents: JobParent;
    manager_id: number;
    fix: boolean;
    fix_reason: number;
    sticky_type: number;
    mini_job: boolean;
    reopen: boolean;
    opened_texture: boolean;
    is_paused: boolean;
    paused_at: Date;
    pause_reason: string;
    pause_reason_id?: number;
    unattended_comment: boolean;
    fm_active?: boolean;
    // is_poc: boolean;
    items?: Array<Product>; // products to add
    UI?: JobUI;
    uiInit?: boolean;
    bypass_dimensions?: boolean;
    bypass_geometry_accuracy?: boolean;
    bypass_color_comparison?: boolean;
    quality_supervisor?: number;
    sorted_by?: string;
    feedbacker_id?: number;
    is_creation_tool?: boolean;
    force_score_doesnt_affect_rank?: boolean;
    current_feedback_session_id?: number;
    feedback_session_open?: boolean;
    source_file_match?: boolean;
    job_qa_mode?: QaModes;
}

export interface JobUI {
    isChecked?: boolean;
    jobImages?: Array<NativeResourceSet>;
    currentTotalPrice?: number;
    userBonus: number;
    isMulti: boolean;
    dueDate: Date;
    isGeometry: boolean;
    isTexture: boolean;
    isRender: boolean;
    isHighPoly: boolean;
    priority: Priority;
    // pauseReason: KeyValuePair;
    pauseReason: artists_pause_reasons;
    finalPrice?: number;
    // items?: Array<number>;
}

export interface ArtistsJobsPrivateComments {
    id: number;
    job_id: number;
    artist_user_id: number;
    comment: string;
    private: boolean;
    created_at: Date;
    updated_at: Date;
    is_copied: boolean;
    artists_users: Array<User>;
}

export interface ArtistsUsers extends User {
    artists_groups_users?: Array<ArtistsGroupUser>;
}

export interface CachedJob extends Job {
    isCached?: boolean;
}

// export interface JobChildren {
//     artistsjobitems: Array<ArtistJobItem>;
//     // artistsjobsitemscategoriesranking
// }

// export interface JobParent {
//     artistsusers: Array<User>;
// }

export interface ArtistJobTag {
    id?: number;
    tag: string;
    is_predefined: boolean;
    updated_at: string;
    created_at: string,
    job_id: number,
}

export enum PredefinedJobTags {
    // HighFeedbackComplexity = 'high_feedback_complexity',
    // MediumFeedbackComplexity = 'medium_feedback_complexity',
    // LowFeedbackComplexity = 'low_feedback_complexity',
    ExtraTime = 'extra_time_added',
    // UnderProduction = 'under_production',
    // Before1Review = 'before_first_review',
    // Resolved = 'resolved',
    // HighPotential = 'high_potential',
    // MediumPotential = 'medium_potential',
    // LowPotential = 'low_potential',
    // LowestPotential = 'lowest_potential',
    Auto360 = 'auto_360',
    // NewbieAssist = 'newbie_assist',
    ReviewJobForReopen = 'review_job_for_reopen',
    // PriceManyFeedbacks = 'price_many_feedbacks',
    // PriceLackDetails = 'price_lack_details',
    // PriceIntersections = 'price_intersections',
    // PriceBrokenMesh = 'price_broken_mesh',
    // PriceScaleModel = 'price_scale_model',
    // PriceCorrectProportions = 'price_correct_proportions',
    GeometryIssues = 'geometry_issues',
    Auto360Checked = 'auto_360_checked',
    TestFailed = 'test_failed',
    TestPassed = 'test_passed',
    TestNotCompleted = 'test_not_completed',
}

export interface JobManagerChange {
    managerId: number;
    status: UserAvailabilityStatus;
    notes: string;
}

export interface ArtistJobItem {
    created_at?: Date;
    id?: number;
    item_id: number;
    job_id: number;
    updated_at?: Date;
    cms_url?: string;
    artists_items: Array<ArtistsItem>;
    artists_jobs: Array<Job>;
    artists_jobs_resources: Array<Resource>;
    // artists_jobs_items_categories_ranking: Array<ArtistJobItemCategoryRanking>;
    artists_jobs_items_resources_types?: Array<ArtistsJobsItemsResourcesType>;
    artists_jobs_renders: Array<ArtistsJobsRender>;
    artists_jobs_items_polygon_specifications: Array<ArtistsJobsItemsPolygonpecification>;
    artist_approved: boolean;
    UI: ArtistJobItemUI;
    is_dummy: boolean;
}

export interface ArtistsJobsItemsPolygonpecification extends PolygonSpecifications {
    job_item_id?: number;
}

export interface ArtistJobItemUI {
    hasApproved: boolean; // Does this item has an approved resource (at least one)
    hasFinalApproved: boolean; // Does this item has a final approved resource (at least one)
    hasCMS: boolean; // Does this item has a resource that has been sent to the CMS (at least one)
    polyTypes?: Array<KeyValuePoly>;
}

// export interface ArtistJobItemChildren {
//     artistsitem: Array<ArtistsItem>;
//     artistsjobsresource: Array<Resource>;
//     artistsjobsitemscategoriesranking: ArtistJobItemCategoryRanking;
// }

export interface ArtistsItem extends ResourceDimensions {
    // batch_id: number;
    category_id: number;
    created_at: Date;
    id: number;
    // name: string;
    name: string;
    product_id: number;
    price: number;
    public: boolean;
    // sub_category_id: number;
    tags: string;
    updated_at: Date;
    // format: string;
    notes: string;
    dimensions: string;
    // width: number;
    // height: number;
    // length: number;
    // units: DimensionsUnits;
    material: string;
    due_date: Date;
    complexity: number;
    // retailer_name?: string;
    // resource_types: string;
    // resourceTypes: Array<number>;
    serial_number: string;
    priority: Priority;
    artists_items_data: Array<ArtistsItemData>;
    artists_items_downloads: Array<ArtistsItemDownload>;
    artists_items_specs?: Array<ArtistsItemSpec>;
    artists_jobs_items?: Array<ArtistJobItem>;
    artists_items_sub_categories_associations?: Array<ArtistsItemsSubCategoriesAssociation>;
    artists_items_identifiers?: Array<ArtistItemIdentifier>;
    // artists_items_resources_types: Array<ProductResourceType>;
    // artists_offers_items_resources_types?: Array<ArtistsOffersItemsResourcesType>;
    // artists_jobs_items_resources_types?: Array<ArtistsJobsItemsResourcesType>;
    smallImagesUrls?: Array<string>;
    products: Array<Product>;
    // children: ArtistsItemDataParent;
}

export interface ArtistsItemsResourcesType {
    id: number;
    resources_types: Array<ResourceType>;
    resource_type_id: number;
}

export interface ArtistsJobsItemsResourcesType extends ArtistsItemsResourcesType {
    job_item_id: number;
}

// export interface ArtistsItemDataParent {
//     artistsitemdatas: Array<ArtistsItemData>;
//     smallImagesUrls?: Array<string>;
//     artistsitemdownloads: Array<ArtistsItemDownload>;
//     artistsitemspecs?: Array<ArtistsItemSpec>;
// }

export interface ArtistsItemSpecUI extends ArtistsItemSpec {
    isImage?: boolean;
    fileExtension?: string;
    icon?: string;
    mandatory: boolean;
    isOffer: boolean;
    referenceId: number;
}

export interface ArtistsItemSpec {
    attachment: string;
    created_at: Date;
    id: number;
    item_id: number;
    title: string;
    updated_at: Date;
    sort_index: number;
    attachment_type: AttachmentFileType;
    job_type: JobsTypes;
    source_table: string;
    text: string;
}

export interface ArtistsItemDownload {
    created_at: Date;
    id: number;
    item_id: number;
    updated_at: Date;
    url: string;
    sort_index: number;
    attachment_type: AttachmentFileType;
    job_type: JobsTypes;
}

export interface ArtistsItemData {
    created_at: Date;
    id: number;
    item_id: number;
    updated_at: Date;
    url: string;
    small_image_url?: string;
    sort_index: number;
}

export interface JobFeedback {
    screenshot: string,
    feedback_type: number,
    title: string,
    notes: string,
    resource_id: number
}

export interface ColorCorrectionParams {
    brightness?: number;
    contrast?: number;
    exposure?: number;
    saturation?: number;
}

export interface JobsQueryData {
    allJobs: ListRowsResponse;
}

export interface JobQueryData {
    artists_jobs: Job;
}

export interface JobsFilterOptions {
    is_desc: boolean;
    order_by: string;
    status: Array<string>;
    priority: Array<number>;
    artist_user_id: Array<number>;
    retailer_id: Array<number>;
    serial_number: Array<string>;
    paid: boolean;
    name: Array<string>;
    offset: number;
    limit: number;
    complexity: Array<number>;
    is_archived: boolean;
    is_paused: boolean;
    uploaded_resources: boolean;
    reopen: boolean;
    manager_id: Array<number>;
    pause_reason_id: Array<number>;
}

export interface JobAudit {
    job_id: number;
    user_id: number;
    reference_table: string;
    reference_id: number;
    action_type: AuditActionMethod;
    field_name: string;
    old_value: any;
    new_value: any;
    created_at: Date;
    comments?: string;
    artists_users: Array<User>;
    // users: Array<User>;
}

export enum AuditActionMethod {
    CREATE = 1,
    UPDATE = 2,
    DELETE = 3,
}

export interface JobAuditQueryData {
    artists_jobs_audit: Array<JobAudit>;
}

export interface BallCourt {
    hours: number;
    isArtistSide: boolean;
    percentage: number;
}

export interface ArtistsJobsType {
    type_id: JobsTypes;
}

export interface ArtistsJobsRendersSpecification extends RendersSpecification {
    job_id: number;
    artists_jobs_renders_specifications_angles: Array<ArtistsJobsRendersSpecificationsAngle>;
}

export interface ArtistsJobsRendersSpecificationsAngle extends RendersAngles {

}

export enum RenderStatus {
    PENDING = 0,
    IN_PROGRESS = 1,
    DONE = 2,
    ERROR = 3,
}

export enum RenderType {
    IMAGE = 1,
    VIDEO = 2,
}

export interface ArtistsJobsRender {
    id: number;
    created_at: Date;
    updated_at: Date;
    job_item_id: number;
    software_id: number;
    render_type: RenderType;
    scene_url: string;
    hdr_url: string;
    frames: number;
    counter: number;
    status: RenderStatus;
    log: string;
    artists_jobs_resources_categories_ranking: Array<ArtistsJobsResourcesCategoriesRanking>;
}

export interface ArtistsJobsResourcesCategoriesRanking {
    rank: number;
    artist_user_id: number;
    id: number;
    created_at: Date;
    updated_at: Date;
    resource_id: number;
    parent_category_id: number;
    category_id: number;
    UI?: ArtistsJobsResourcesCategoriesRankingUI;
}

export interface ArtistsJobsResourcesCategoriesRankingUI {
    categoryDescription: string;
    parentDescription: string;
}

export class jobsPriceQueryData {
    jobsPrice: jobsPriceQuery;
}

export class jobsPriceQuery {
    price: number;
}

export interface JobAuditUIGroup {
    created_at: Date;
    items: Array<JobAuditUI>;
}

export interface JobAuditUI {
    created_at: Date;
    acronym: string;
    fullName: string;
    action: string;
    avatarUrl: string;
    onClickArgs: Array<any>;
    iconName: string;
    comments?: string;
    counter?: number;
}

export interface ArtistsJobsSubscriber {
    id: number;
    job_id: number;
    email: string;
}

export interface JobOverdraft {
    offerUuid: string;
    jobName: string;
}

export interface ArtistItemIdentifier {
    serial_number: string
    variation_id?: string
}

export interface IJobVariation {
    id: number;
    name: string;
    imgUrl: string;
    resources: { id: number }[];
    approved: boolean;
    final_approval: boolean;
    sent_to_retailers: boolean;
}

export enum StickyType {
    NONE = null,
    MONO = 0,
    RETAIL = 1,
    ARTIST_GROUP = 2
}

export enum NavigationOrigin {
    SWIPE = 1,
    LEFT_BUTTON = 2,
    RIGHT_BUTTON = 3,
}

export interface SliderNavigationEvent {
    origin: NavigationOrigin;
    index: number;
}