import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { GroupsQuery } from '../group';
import { GraphqlService } from 'app/communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable()
export class UserGroupsResolve  {

    constructor(private gql: GraphqlService) { }

    resolve() {
        return this.gql.artistsGroups({ limit: 100, offset: 0 }) as Observable<ApolloQueryResult<GroupsQuery>>;
    }
}
