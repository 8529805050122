<div class="complexity-level">
    <table *ngIf="complexityTable" class="complexity-level-table">
        <tr *ngFor="let complexity of complexityTable; let i = index" class="complexity-level-imgs-row" [ngClass]="{'complexity-level-row-active': i === selectedRow}" (click)="chooseRow(i, complexity);">
            <div class="complexity-level-index">{{i + 1}} ({{getCalcPrice(complexity)}}$)</div>
            <th *ngFor="let item of complexityTable[i].imgArray, let j = index" class="complexity-level-cell" [ngClass]="{'complexity-level-cell-active': item === selectedItem}" (click)="chooseItem(item, i + 1)">
                <mat-icon class="complexity-level-cell-enlarge-icon" (click)="openImage(i,j)">crop_free</mat-icon>
                <img [src]="item.image" alt="complexity image" class="complexity-level-cell-image-wrapper" loading="lazy">
                <div class="complexity-level-cell-text" [title]="item.label">{{(item.label) ? item.label: ''}}</div>
            </th>
        </tr>
    </table>
</div>
