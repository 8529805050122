import { Resource, PreviewOptions, GifOptions } from 'app/job/resource';
import { UtilsService } from 'app/shared/utils.service';
import { MessageRequest, MessagesHandlerService } from 'messages-handler';
import { Subject } from 'rxjs';
import { RestService } from 'app/communication/rest.service';
import { MediaFormat, ViewerCommunicator } from 'hexa-viewer-communicator';
import { environment } from 'environments/environment';

export class PreviewGeneratorFactory {
  private animatedGifOrigins: { [id: string]: boolean };
  private previewOrigins: { [id: string]: boolean };
  private previewIframe: HTMLElement;
  private gifIframe: HTMLElement;
  public onPreviewReady: Subject<string>;
  public onGifReady: Subject<string>;
  constructor(
    private mhService: MessagesHandlerService,
    private utils: UtilsService,
    private rest: RestService,
    private bucket: string
  ) {
    this.onPreviewReady = new Subject<string>();
    this.onGifReady = new Subject<string>();
    this.previewOrigins = {};
    this.animatedGifOrigins = {};
  }

  async createPreview(res: Resource): Promise<string> {
    if (!res.viewer_url) return;
    let resource = res.viewer_url;

    let options = {
      width: 500,
      height: 500,
      compress: true,
      create_images_by_tour: 'init_pos'
    } as PreviewOptions;
    let origin = this.utils.getDomain(resource);
    if (resource.indexOf('//') == 0)
      origin = '//' + origin;
    else if (resource.indexOf('https') == 0)
      origin = 'https://' + origin;
    else if (resource.indexOf('http') == 0)
      origin = 'http://' + origin;
    else
      console.warn('URL for preview is:' + resource + ' and has no protocol!');

    const queryString = this.utils.fromObjectToQuerystring(options) + '&igreferrer=' + encodeURIComponent(window.location.protocol + '//' + window.location.host);

    this.initOnPreviewDone(origin);

    var iframe = document.createElement('iframe');
    if (resource.indexOf('?') > -1)
      iframe.src = resource + '&' + queryString;
    else
      iframe.src = resource + '?' + queryString;
    this.previewIframe = iframe;
    iframe.style.zIndex = '1';
    iframe.style.opacity = '0.05';
    iframe.style.position = 'fixed';
    let height = options.height ? options.height : 1000;
    iframe.style.width = options.width ? options.width + 'px' : '1000px';
    iframe.style.height = options.height ? options.height + 'px' : '1000px';
    iframe.style.bottom = -1 * (height - 2) + 'px';
    document.getElementsByTagName('body')[0].appendChild(iframe);
    return new Promise((resolve, reject) => {
      this.onPreviewReady.subscribe((preview) => {
        resolve(preview);
      }, reject);
    });
  }

  async createGif(res: Resource): Promise<string> {
    if (!res.viewer_url) return;
    let options = {
      ggwidth: 200,
      ggheight: 200
    } as GifOptions;
    let resource = res.viewer_url;
    let origin = this.utils.getDomain(resource);
    if (resource.indexOf('//') == 0)
      origin = '//' + origin;
    else if (resource.indexOf('https') == 0)
      origin = 'https://' + origin;
    else if (resource.indexOf('http') == 0)
      origin = 'http://' + origin;
    else
      console.warn('URL for gif is:' + resource + ' and has no protocol!');

    const queryString = this.utils.fromObjectToQuerystring(options) + '&ggreferrer=' + encodeURIComponent(window.location.protocol + '//' + window.location.host) + '&gifgen=1';

    this.initOnAnimatedGifDone(origin);
    var iframe = document.createElement('iframe');
    if (resource.indexOf('?') > -1)
      iframe.src = resource + '&' + queryString;
    else
      iframe.src = resource + '?' + queryString;
    this.gifIframe = iframe;
    iframe.style.zIndex = '1';
    iframe.style.opacity = '0.05';
    iframe.style.position = 'fixed';
    let height = options.ggheight ? options.ggheight : 200;
    iframe.style.width = options.ggwidth ? options.ggwidth + 'px' : '200px';
    iframe.style.height = options.ggheight ? options.ggheight + 'px' : '200px';
    iframe.style.bottom = -1 * (height - 2) + 'px';
    document.getElementsByTagName('body')[0].appendChild(iframe);
    return new Promise((resolve, reject) => {
      this.onGifReady.subscribe((gif) => {
        resolve(gif);
      }, reject);
    });
  }

  createVideo(res: Resource, width: number, height: number): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      await this.utils.loadScript(environment.viewerScript);
      const vc = new ViewerCommunicator();
      let viewerURL = res.viewer_url;
      viewerURL = this.utils.setUrlParam(viewerURL, 'server', environment.viewerServer);
      viewerURL = this.utils.setUrlParam(viewerURL, 'animate-enter', 'false');
      const hexaViewer = await vc.createInstanceFromUrl(viewerURL);
      const wrapper = document.createElement('div');
      wrapper.style.cssText = `
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      opacity: 0.01;
      z-index: -9999999;
      max-width: 100%;
      `;
      wrapper.appendChild(hexaViewer);
      hexaViewer.style.cssText = `
      width: ${width}px;
      height: ${height}px;
      display: inline-block;
      `;
      document.body.appendChild(wrapper);
      const blob = await vc.getScreenshotsSequence({
        format: MediaFormat.VIDEO,
        numOfFrames: 96
      }) as Blob;
      vc.destroy();
      wrapper.remove();
      resolve(blob);
    });
  }

  private initOnPreviewDone(origin: string) {
    if (this.previewOrigins[origin]) return;
    this.previewOrigins[origin] = true;
    let obj = {
      actions: ['create_images_by_tour'],
      invoke: 'onPreview',
      scope: this,
      options: {
        apply: true
      }
    } as MessageRequest;
    this.mhService.onPostMessageEvent(obj, origin);
  }

  private initOnAnimatedGifDone(origin: string) {
    if (this.animatedGifOrigins[origin]) return;
    this.animatedGifOrigins[origin] = true;
    let obj = {
      actions: ['gifgen'],
      invoke: 'onGifGen',
      scope: this,
      options: {
        apply: true
      }
    } as MessageRequest;
    this.mhService.onPostMessageEvent(obj, origin);
  }

  private async onGifGen(msg: any) {
    if (msg && msg.image) {
      this.gifIframe.parentNode.removeChild(this.gifIframe);
      const gif = await this.uploadFile(msg.image, 'preview.gif');
      this.onGifReady.next(gif);
    }
  }

  private async onPreview(msg: any) {
    if (msg && msg.data && msg.data.images) {
      if (this.previewIframe.parentNode)
        this.previewIframe.parentNode.removeChild(this.previewIframe);
      const preview = await this.uploadFile(msg.data.images[0], 'preview.png');
      this.onPreviewReady.next(preview);
    }
  }

  private async uploadFile(base64: string, name: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let fileUploadRequest = new FormData();
      fileUploadRequest.append('file', this.utils.dataURLtoFile(base64, name));
      fileUploadRequest.append('compress', 'true');
      fileUploadRequest.append('bucket', this.bucket);
      this.rest.afterCdn('post', fileUploadRequest).subscribe(
        data => {
          if (data?.url) {
            resolve(data.url);
          }
          else {
            reject();
          }
        },
        err => {
          reject();
        }
      );
    });
  }
}
