<div class="color-sampling-help-modal scrollbar">
  <div class="color-sampling-help-modal__title">Received a wrong result?</div>

  <div class="color-sampling-help-modal__sub-title">
    To fine-tune the colors in your diffuse map,<br>
    we recommend to:
  </div>


  <div class="color-sampling-help-modal__bullets">
    <div class="color-sampling-help-modal__bullet" *ngFor="let bullet of bullets; let i = index">
      <div class="color-sampling-help-modal__bullet__title">{{ i + 1 }}. {{ bullet.title }}</div>
      <div class="color-sampling-help-modal__bullet__description">{{ bullet.description }}</div>
    </div>
  </div>

  <div class="color-sampling-help-modal__example">
    <img src="assets/color_sampling_example.png" alt="color-sampling-example">
  </div>

  <div class="color-sampling-help-modal__button">
    <hexa-button color="primary" (click)="onClose()">CLOSE</hexa-button>
  </div>

  <button class="color-sampling-help-modal__close" mat-icon-button [autofocus]="false" color="accent" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
