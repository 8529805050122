import { Injectable, ElementRef } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';
import { Subject } from 'rxjs';
import { RestService } from '../communication/rest.service';
import { CombineImageOptions, CombineImagesMode, CombineImageSourceOptions, CombineImagesResponse, DeltaLAB, Dimensions, ImagePixelsDataSet, LAB, LABMinMax, MinMaxMeanRGB, RGB, RGBYHex } from 'app/job/resource';
import { UtilsService } from '../shared/utils.service';

@Injectable()
export class CombineImagesService {
  public onImagePortion: Subject<string>;
  private resolve: Function;
  constructor(
    private broadcaster: BroadcasterService,
    private rest: RestService,
    private utils: UtilsService
  ) {
    this.onImagePortion = new Subject<string>();
  }

  private RGBtoLAB(r: number, g: number, b: number): LAB {
    const yellow = (g + b) / 2;
    const RGBY = { red: r, redHeu: `#${this.utils.rgbToHex(r)}0000`, green: g, greenHeu: `#00${this.utils.rgbToHex(r)}00`, blue: b, blueHeu: `#0000${this.utils.rgbToHex(r)}`, yellow: yellow, yellowHeu: `#${this.utils.rgbToHex(r)}${this.utils.rgbToHex(g)}00` } as RGBYHex;
    r /= 255;
    g /= 255;
    b /= 255;
    let x: number, y: number, z: number;
    r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

    x = (x > 0.008856) ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116;
    y = (y > 0.008856) ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116;
    z = (z > 0.008856) ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116;

    return { lightness: (116 * y) - 16, channelA: 500 * (x - y), channelB: 200 * (y - z), RGBY };
    // return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
  }

  getDeltaE(labA: LAB, labB: LAB): number {
    var deltaL = labA.lightness - labB.lightness;
    var deltaA = labA.channelA - labB.channelA;
    var deltaB = labA.channelB - labB.channelB;
    var c1 = Math.sqrt(labA.channelA * labA.channelA + labA.channelB * labA.channelB);
    var c2 = Math.sqrt(labB.channelA * labB.channelA + labB.channelB * labB.channelB);
    var deltaC = c1 - c2;
    var deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    var sc = 1.0 + 0.045 * c1;
    var sh = 1.0 + 0.015 * c1;
    var deltaLKlsl = deltaL / (1.0);
    var deltaCkcsc = deltaC / (sc);
    var deltaHkhsh = deltaH / (sh);
    var i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
  }

  getPixelsMean(data: Uint8ClampedArray): MinMaxMeanRGB {
    const mean = { red: 0, green: 0, blue: 0 } as RGB,
      min = { red: 255, green: 255, blue: 255 } as RGB,
      max = { red: 0, green: 0, blue: 0 } as RGB;
    for (let i = 0; i < data.length; i += 4) {
      mean.red += data[i];
      mean.green += data[i + 1];
      mean.blue += data[i + 2];
      min.red = Math.min(min.red, data[i]);
      min.green = Math.min(min.green, data[i + 1]);
      min.blue = Math.min(min.blue, data[i + 2]);
      max.red = Math.max(min.red, data[i]);
      max.green = Math.max(min.green, data[i + 1]);
      max.blue = Math.max(min.blue, data[i + 2]);
    }
    mean.red /= (data.length / 4);
    mean.green /= (data.length / 4);
    mean.blue /= (data.length / 4);
    return { min, max, mean };
  }

  private async _getDeltaLAB(a: LAB, b: LAB): Promise<DeltaLAB> {
    let delta = {} as DeltaLAB;
    delta.averageLightnessDelta = Math.sqrt(Math.pow(b.lightness - a.lightness, 2));
    delta.averageABDelta = Math.sqrt(Math.pow(b.channelA - a.channelA, 2) + Math.pow(b.channelB - a.channelB, 2));
    delta.deltaE = this.getDeltaE(a, b);
    if (a.screenshot)
      delta.referenceSampleImageUrl = a.screenshot;
    if (b.screenshot)
      delta.resourceSampleImageUrl = b.screenshot;
    return delta;
  }

  private async getDeltaLAB(options: CombineImageOptions, img1Dim: Dimensions, img2Dim: Dimensions): Promise<DeltaLAB> {
    let canvas1 = document.createElement('canvas'), canvas2 = document.createElement('canvas');
    canvas1.id = 'canvas1_image';
    canvas1.classList.add('debug-canvas');
    canvas2.id = 'canvas2_model';
    canvas2.classList.add('debug-canvas');
    canvas1.width = options.square.fullWidth;
    canvas1.height = options.square.fullHeight;
    canvas1.style.position = 'fixed';
    canvas1.style.top = '30px';
    canvas1.style.left = '0';
    canvas1.style.zIndex = '9999999';
    canvas2.width = options.square.fullWidth;
    canvas2.height = options.square.fullHeight;
    canvas2.style.position = 'fixed';
    canvas2.style.top = '30px';
    canvas2.style.right = '0';
    canvas2.style.zIndex = '9999999';
    const context1 = canvas1.getContext('2d'), context2 = canvas2.getContext('2d');
    document.body.appendChild(canvas1);
    document.body.appendChild(canvas2);
    const wrh = options.img1.width / options.img1.height;
    let newWidth = canvas1.width;
    let newHeight = newWidth / wrh;
    if (newHeight > canvas1.height) {
      newHeight = canvas1.height;
      newWidth = newHeight * wrh;
    }
    const referenceSampleX = newWidth < canvas1.width ? ((canvas1.width - newWidth) / 2) : 0;
    const referenceSampleY = newHeight < canvas1.height ? ((canvas1.height - newHeight) / 2) : 0;

    context1.drawImage(options.img1, referenceSampleX, referenceSampleY, newWidth, newHeight);
    const dx = (options.square.fullWidth - img2Dim.width) / 2;
    const dy = (options.square.fullHeight - img2Dim.height) / 2;
    context2.drawImage(options.img2, 0, 0, img2Dim.width, img2Dim.height, dx, dy, img2Dim.width, img2Dim.height);

    let debugCanvas1 = document.createElement('canvas'), debugCanvas2 = document.createElement('canvas');
    let debugContext1 = debugCanvas1.getContext('2d'), debugContext2 = debugCanvas2.getContext('2d');
    debugCanvas1.id = 'debugCanvas1';
    debugCanvas2.id = 'debugCanvas2';

    debugCanvas1.width = options.square.width;
    debugCanvas1.height = options.square.height;
    debugCanvas1.style.position = 'fixed';
    debugCanvas1.style.top = '0';
    debugCanvas1.style.left = '0';
    debugCanvas1.style.zIndex = '9999999';
    debugCanvas1.classList.add('debug-canvas');
    // debugCanvas1.style.opacity = '0.01';
    // debugCanvas1.style.transform = 'scale(0.1)';
    debugCanvas2.width = options.square.width;
    debugCanvas2.height = options.square.height;
    debugCanvas2.style.position = 'fixed';
    debugCanvas2.style.top = '0';
    debugCanvas2.style.right = '0';
    debugCanvas2.style.zIndex = '9999999';
    debugCanvas2.classList.add('debug-canvas');
    // debugCanvas2.style.opacity = '0.01';
    // debugCanvas2.style.transform = 'scale(0.1)';
    document.body.appendChild(debugCanvas1);
    document.body.appendChild(debugCanvas2);
    let imageData1 = context1.getImageData(options.square.left, options.square.top, options.square.width, options.square.height);
    let imageData2 = context2.getImageData(options.square.left, options.square.top, options.square.width, options.square.height);
    debugContext1.putImageData(imageData1, 0, 0);
    debugContext2.putImageData(imageData2, 0, 0);
    const lab1 = this.getAverageLAB(imageData1.data);
    const lab2 = this.getAverageLAB(imageData2.data);
    lab1.screenshot = await this.utils.getUrlFromBase64(debugCanvas1.toDataURL());
    lab2.screenshot = await this.utils.getUrlFromBase64(debugCanvas2.toDataURL());
    const deltaLAB = await this._getDeltaLAB(lab1, lab2);
    deltaLAB.referenceMinRGB = lab1.min;
    deltaLAB.referenceMaxRGB = lab1.max;
    deltaLAB.resourceMinRGB = lab2.min;
    deltaLAB.resourceMaxRGB = lab2.max;
    deltaLAB.resourcePixelsSquare = {
      width: options.square.width,
      height: options.square.height,
      top: options.square.top,
      left: options.square.left
    };
    deltaLAB.referencePixelsSquare = {
      width: newWidth,
      height: newHeight,
      top: referenceSampleY,
      left: referenceSampleX
    };
    deltaLAB.referenceImageData = debugContext1.getImageData(0, 0, canvas1.width, canvas1.height).data;
    deltaLAB.resourceImageData = debugContext2.getImageData(0, 0, canvas2.width, canvas2.height).data;
    deltaLAB.referenceAverageAB = lab1;
    deltaLAB.resourceAverageAB = lab2;
    deltaLAB.channelADiff = deltaLAB.resourceAverageAB.channelA - deltaLAB.referenceAverageAB.channelA;
    deltaLAB.channelBDiff = deltaLAB.resourceAverageAB.channelB - deltaLAB.referenceAverageAB.channelB;
    deltaLAB.lightnessDiff = deltaLAB.resourceAverageAB.lightness - deltaLAB.referenceAverageAB.lightness;
    canvas1.remove();
    canvas2.remove();
    debugCanvas1.remove();
    debugCanvas2.remove();
    return deltaLAB;
  }

  private getAverageLAB(data: Uint8ClampedArray): LABMinMax {
    const meanMinMax = this.getPixelsMean(data);
    const lab = this.RGBtoLAB(meanMinMax.mean.red, meanMinMax.mean.green, meanMinMax.mean.blue);
    return {
      channelA: lab.channelA,
      channelB: lab.channelB,
      lightness: lab.lightness,
      RGBY: lab.RGBY,
      min: meanMinMax.min,
      max: meanMinMax.max
    };
  }

  private async onImagesLoaded(options: CombineImageOptions) {
    let pixelsDiff = null, pixelsDiffOpaque = null, colorAccuracy = null, opaqueColorAccuracy = null, deltaLAB: DeltaLAB;
    let img1Dim = {
      width: options.img1.width,
      height: options.img1.height
    };
    let img2Dim = {
      width: options.img2.width,
      height: options.img2.height
    };
    if (options.square)
      deltaLAB = await this.getDeltaLAB(options, this.utils.deepCopyByValue(img1Dim), this.utils.deepCopyByValue(img2Dim));
    let ratio: number;
    if (options.img1.height < options.img2.height) {
      ratio = options.img2.height / options.img1.height;
      img2Dim.width /= ratio;
      img2Dim.height /= ratio;
    }
    else {
      ratio = options.img1.height / options.img2.height;
      img1Dim.width /= ratio;
      img1Dim.height /= ratio;
      ratio = options.img2.height / options.img1.height;
    }
    let canvas = document.createElement('canvas');
    // canvas.id = 'combine-images-canvas-' + suffix;
    if (options.mode == CombineImagesMode.SIDE_BY_SIDE)
      canvas.width = img1Dim.width + img2Dim.width;
    else
      canvas.width = Math.max(img1Dim.width, img2Dim.width);
    canvas.height = Math.max(img1Dim.height, img2Dim.height);
    document.body.appendChild(canvas);
    var context = canvas.getContext('2d');

    let pixels1: Uint8ClampedArray, pixels2: Uint8ClampedArray;

    if (options.mode == CombineImagesMode.SIDE_BY_SIDE) {
      if (img1Dim.height < img2Dim.height)
        context.drawImage(options.img1, 0, (img2Dim.height / 2) - (img1Dim.height / 2), img1Dim.width, img1Dim.height);
      else
        context.drawImage(options.img1, 0, 0, img1Dim.width, img1Dim.height);
      if (img1Dim.height > img2Dim.height)
        context.drawImage(options.img2, img1Dim.width, (img1Dim.height / 2) - (img2Dim.height / 2), img2Dim.width, img2Dim.height);
      else
        context.drawImage(options.img2, img1Dim.width, 0, img2Dim.width, img2Dim.height);
      // if (options.square) {
      //   // const lab1 = this.getAverageLAB(context.getImageData(options.square.left, options.square.top, options.square.width, options.square.height).data);
      //   // const lab2 = this.getAverageLAB(context.getImageData(options.square.left + img1Dim.width, options.square.top + img1Dim.height, options.square.width + img1Dim.width, options.square.height + img1Dim.height).data);
      //   deltaLAB = this.getDeltaLAB(options, img1Dim, img2Dim);
      // }
      if (deltaLAB) {
        pixels1 = deltaLAB.referenceImageData;
        pixels2 = deltaLAB.resourceImageData;
        // Delete those before sending to server or data warehouse
        delete deltaLAB.referenceImageData;
        delete deltaLAB.resourceImageData;
      }
    }
    else {
      let canvas1 = document.createElement('canvas');
      let canvas2 = document.createElement('canvas');
      canvas1.classList.add('debug-canvas');
      canvas2.classList.add('debug-canvas');
      // canvas1.id = 'canvas1_image';
      // canvas2.id = 'canvas2_model';
      canvas1.width = canvas2.width = canvas.width;
      canvas1.height = canvas2.height = canvas.height;
      let context1 = canvas1.getContext('2d'), context2 = canvas2.getContext('2d');
      document.body.appendChild(canvas1);
      document.body.appendChild(canvas2);

      if (img1Dim.width < img2Dim.width) {
        context.drawImage(options.img1, (img2Dim.width / 2) - (img1Dim.width / 2), 0, img1Dim.width, img1Dim.height);
        context1.drawImage(options.img1, (img2Dim.width / 2) - (img1Dim.width / 2), 0, img1Dim.width, img1Dim.height);
      }
      else {
        context.drawImage(options.img1, 0, 0, img1Dim.width, img1Dim.height);
        context1.drawImage(options.img1, 0, 0, img1Dim.width, img1Dim.height);
      }
      img2Dim.width = options.img2.width;
      img2Dim.height = options.img2.height;
      if (img1Dim.width > img2Dim.width) {
        context.drawImage(options.img2, (img1Dim.width / 2) - (img2Dim.width / 2), (img1Dim.height / 2) - (img2Dim.height / 2), img2Dim.width, img2Dim.height);
        context2.drawImage(options.img2, (img1Dim.width / 2) - (img2Dim.width / 2), (img1Dim.height / 2) - (img2Dim.height / 2), img2Dim.width, img2Dim.height);
      }
      else {
        context.drawImage(options.img2, (canvas.width / 2) - (img2Dim.width / 2), (img1Dim.height / 2) - (img2Dim.height / 2), img2Dim.width, img2Dim.height);
        context2.drawImage(options.img2, (canvas.width / 2) - (img2Dim.width / 2), (img1Dim.height / 2) - (img2Dim.height / 2), img2Dim.width, img2Dim.height);
      }
      try {
        pixels1 = context1.getImageData(0, 0, canvas1.width, canvas1.height).data;
        pixels2 = context2.getImageData(0, 0, canvas2.width, canvas2.height).data;
      } catch (e) {
        console.warn(e);
      }
      canvas1.parentElement.removeChild(canvas1);
      canvas2.parentElement.removeChild(canvas2);
    }
    let bothEmptyCounter = 0, onlyOneEmptyCounter = 0, bothNotEmptyCounter = 0, relevantPixels = 0;
    const isEmpty = (r: number, g: number, b: number, a: number, opaqueOnly = false) => {
      if (!a) return true;
      if (r === 255 && g === 255 && b === 255) return true;
      // https://stackoverflow.com/questions/11601302/html5-canvas-to-png-zeroes-all-channels-when-alpha-transparent
      if (r === 0 && g === 0 && b === 0) return true;
      if (opaqueOnly && a !== 255) return true;
      return false;
    };

    if (pixels1) {
      // pixels diff
      for (let i = 0; i < pixels1.length; i += 4) {
        if (isEmpty(pixels1[i], pixels1[i + 1], pixels1[i + 2], pixels1[i + 3]) &&
          isEmpty(pixels2[i], pixels2[i + 1], pixels2[i + 2], pixels2[i + 3])) {
          // Do nothing, pixel isn't relevant
        }
        else {
          relevantPixels++;
          if ((isEmpty(pixels1[i], pixels1[i + 1], pixels1[i + 2], pixels1[i + 3]) &&
            !isEmpty(pixels2[i], pixels2[i + 1], pixels2[i + 2], pixels2[i + 3])) ||
            (!isEmpty(pixels1[i], pixels1[i + 1], pixels1[i + 2], pixels1[i + 3]) &&
              isEmpty(pixels2[i], pixels2[i + 1], pixels2[i + 2], pixels2[i + 3])))
            onlyOneEmptyCounter++;
          else {
            bothNotEmptyCounter++;
          }
        }
      }

      // // pixelsDiff = 100 * (1 - onlyOneEmptyCounter / ((pixels1.length / 4) - bothEmptyCounter));
      // pixelsDiff = 100 * (onlyOneEmptyCounter / bothEmptyCounter);
      // let numOfRelevantPixels = ((pixels1.length / 4) - bothEmptyCounter);
      // pixelsDiff = (bothNotEmptyCounter / onlyOneEmptyCounter) * 100;
      // pixelsDiff = 100 - (onlyOneEmptyCounter / bothNotEmptyCounter);
      // console.log(pixelsDiff);
      pixelsDiff = 100 * (bothNotEmptyCounter / relevantPixels);
      // console.log(pixelsDiff);

      // pixels diff opaque
      bothEmptyCounter = 0, onlyOneEmptyCounter = 0, bothNotEmptyCounter = 0, relevantPixels = 0;
      for (let i = 0; i < pixels1.length; i += 4) {
        // if (pixels1[i] < 250 || pixels1[i + 1] < 250 || pixels1[i + 2] < 250 || pixels1[i + 3] != 255) debugger;
        if (isEmpty(pixels1[i], pixels1[i + 1], pixels1[i + 2], pixels1[i + 3], true) &&
          isEmpty(pixels2[i], pixels2[i + 1], pixels2[i + 2], pixels2[i + 3]), true) {
          // Do nothing, pixel isn't relevant
        }
        else {
          relevantPixels++;
          if ((isEmpty(pixels1[i], pixels1[i + 1], pixels1[i + 2], pixels1[i + 3], true) &&
            !isEmpty(pixels2[i], pixels2[i + 1], pixels2[i + 2], pixels2[i + 3], true)) ||
            (!isEmpty(pixels1[i], pixels1[i + 1], pixels1[i + 2], pixels1[i + 3], true) &&
              isEmpty(pixels2[i], pixels2[i + 1], pixels2[i + 2], pixels2[i + 3], true))) {
            onlyOneEmptyCounter++;
          }
          else {
            bothNotEmptyCounter++;
          }
        }
      }
      // pixelsDiffOpaque = (bothNotEmptyCounter / onlyOneEmptyCounter) * 100;
      // pixelsDiffOpaque = 100 - (onlyOneEmptyCounter / bothNotEmptyCounter);
      // console.log(pixelsDiffOpaque);
      pixelsDiffOpaque = 100 * (bothNotEmptyCounter / relevantPixels);

      // console.log(pixelsDiffOpaque);
      const id1 = this.getImageData(pixels1);
      const id2 = this.getImageData(pixels2);
      // The lower the better:
      colorAccuracy = Math.abs((id1.mix.avg / id1.mix.counter) - (id2.mix.avg / id2.mix.counter));
      if (colorAccuracy > 0.0) {
        colorAccuracy = 1 - colorAccuracy / 255;
        colorAccuracy = 100 - (colorAccuracy * 100);
      }
      else
        colorAccuracy = 100;
      opaqueColorAccuracy = Math.abs((id1.opaque.avg / id1.opaque.counter) - (id2.opaque.avg / id2.opaque.counter));
      if (opaqueColorAccuracy === 0.0)
        opaqueColorAccuracy = 100;
      // if (options.square) {
      //   const lab1 = this.getAverageLAB(context1.getImageData(options.square.left, options.square.top, options.square.width, options.square.height).data);
      //   const lab2 = this.getAverageLAB(context2.getImageData(options.square.left, options.square.top, options.square.width, options.square.height).data);
      //   deltaLAB = this._getDeltaLAB(lab1, lab2);
      // }
    }
    canvas.parentElement.removeChild(canvas);
    const message = {
      screenshot: canvas.toDataURL('image/png'),
      pixelsDiffPercentage: pixelsDiff,
      colorAccuracy,
      opaqueColorAccuracy,
      pixelsDiffOpaquePercentage: pixelsDiffOpaque,
      deltaLAB,
      square: options.square
    } as CombineImagesResponse;
    this.broadcaster.broadcast('onCombineImage' + options.suffix, { message });
    this.resolve(message);
  }

  getImageData(pixelData: Uint8ClampedArray): ImagePixelsDataSet {
    let avg = 0, counter = 0, red = 0, green = 0, blue = 0, transparent = 0, transparentCounter = 0, min = 255, max = 0;
    let opaqueAvg = 0, opaqueCounter = 0, opaqueRed = 0, opaqueGreen = 0, opaqueBlue = 0, opaqueTransparent = 0, opaqueTransparentCounter = 0, opaqueMin = 255, opaqueMax = 0;
    for (let i = 0; i < pixelData.length; i += 4) {
      let opacity = pixelData[i + 3];
      // ignore 100% opacity and 100% white pixels
      if (opacity > 0 && pixelData[i] < 255 && pixelData[i + 1] < 255 && pixelData[i + 2] < 255) {
        counter++;
        if (pixelData[i] || pixelData[i + 1] || pixelData[i + 2]) {
          transparent += opacity;
          transparentCounter++;
        }
        red += (opacity / 255) * pixelData[i];
        green += (opacity / 255) * pixelData[i + 1];
        blue += (opacity / 255) * pixelData[i + 2];
        let current = ((opacity / 255) * (pixelData[i] + pixelData[i + 1] + pixelData[i + 2]) / 3);
        if (current > max)
          max = current;
        if (current < min)
          min = current;
        avg += current;
      }
      if (opacity == 255 && pixelData[i] < 255 && pixelData[i + 1] < 255 && pixelData[i + 2] < 255) {
        opaqueCounter++;
        if (pixelData[i] || pixelData[i + 1] || pixelData[i + 2]) {
          opaqueTransparent += opacity;
          opaqueTransparentCounter++;
        }
        opaqueRed += (opacity / 255) * pixelData[i];
        opaqueGreen += (opacity / 255) * pixelData[i + 1];
        opaqueBlue += (opacity / 255) * pixelData[i + 2];
        let current = ((opacity / 255) * (pixelData[i] + pixelData[i + 1] + pixelData[i + 2]) / 3);
        if (current > opaqueMax)
          opaqueMax = current;
        if (current < opaqueMin)
          opaqueMin = current;
        opaqueAvg += current;
      }
    }
    return {
      mix: {
        red, green, blue, avg, counter, transparent, transparentCounter, min, max
      },
      opaque: {
        red: opaqueRed, green: opaqueGreen, blue: opaqueBlue, avg: opaqueAvg, counter: opaqueCounter, transparent: opaqueTransparent, transparentCounter: opaqueTransparentCounter, min: opaqueMin, max: opaqueMax
      }
    };
  }

  public getCombineImage(options: CombineImageSourceOptions): Promise<CombineImagesResponse> {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      let img1 = new Image(), img2 = new Image(), img1HasLoaded = false, img2HasLoaded = false;
      img1.setAttribute('crossOrigin', 'anonymous');
      img1.crossOrigin = 'anonymous';
      img2.setAttribute('crossOrigin', 'anonymous');
      img2.crossOrigin = 'anonymous';

      let onImageLoaded = () => {
        if (img1HasLoaded && img2HasLoaded)
          this.onImagesLoaded({ img1, img2, suffix: options.suffix, mode: options.mode, square: options.square });
      }

      img1.onload = (e) => {
        img1HasLoaded = true;
        onImageLoaded();
      };
      img2.onload = () => {
        img2HasLoaded = true;
        onImageLoaded();
      };

      img1.setAttribute('src', options.imgPath1);
      img2.setAttribute('src', options.imgPath2);
    });
  }

  public getImagePortion(imgObj: ElementRef, newWidth: number, newHeight: number, startX: number, startY: number, ratio = 1, widthNaturalRatio = 1, heightNaturalRatio = 1) {
    /* the parameters: - the image element - the new width - the new height - the x point we start taking pixels - the y point we start taking pixels - the ratio */
    // set up canvas for thumbnail
    var tnCanvas = document.createElement('canvas');
    var tnCanvasContext = tnCanvas.getContext('2d');
    tnCanvas.width = newWidth;
    tnCanvas.height = newHeight;
    try {
      /* now we use the drawImage method to take the pixels from our bufferCanvas and draw them into our thumbnail canvas */
      tnCanvasContext.drawImage(imgObj.nativeElement, startX * widthNaturalRatio, startY * heightNaturalRatio, newWidth * widthNaturalRatio, newHeight * heightNaturalRatio, 0, 0, newWidth, newHeight);
      tnCanvas.style.position = 'fixed';
      tnCanvas.style.bottom = '2px';
      tnCanvas.style.left = '474px';
      tnCanvas.style.zIndex = '-1000';
      tnCanvas.id = 'tnCanvas';
      document.body.appendChild(tnCanvas);
      requestAnimationFrame(() => {
        this.onImagePortion.next(tnCanvas.toDataURL());
        tnCanvas.parentElement.removeChild(tnCanvas);
      });
    }
    catch (e) {
      if (e.code == 18)
        // Tainted canvas
        // fallback with base64
        this.convertImageFallback(imgObj.nativeElement.src, newWidth, newHeight, startX, startY, ratio);
    }
  }

  private convertImageFallback(image, newWidth: number, newHeight: number, startX: number, startY: number, ratio = 1) {
    var obj = {
      image_url: image,
      compress: false
    };
    this.rest.imageBase64Convert('POST', obj).subscribe(data => {
      let img = new ElementRef(document.createElement('img'));
      img.nativeElement.onload = () => {
        this.getImagePortion(img, newWidth, newHeight, startX, startY, ratio)
        // this.imageBase64 = 'data:image/png;base64,' + data;
        // this.convertImageReady();
      }
      img.nativeElement.src = 'data:image/png;base64,' + data;

    }, () => {

    });
  }
}
