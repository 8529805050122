import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Retailer, RetailersFilterOptions } from '../../shared/retailers';
import { GraphqlService } from '../../communication/graphql.service';

@Component({
  selector: 'app-retailers-autocomplete',
  templateUrl: './retailers-autocomplete.component.html',
  styleUrls: ['./retailers-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RetailersAutocompleteComponent implements OnInit, OnDestroy {
  @Input('clear-on-select') clearOnSelect: boolean;
  @Input() placeholder: string;
  @Output('retailer-change') retailerChange: EventEmitter<Retailer>;
  @ViewChild('filterInput') filterInput: ElementRef;
  @Input('panelWidth') panelWidth: string | number;
  public retailers: Array<Retailer>;
  public retailerCtrl: FormControl;
  public filteredRetailers: Observable<Array<Retailer>>;
  private sub: Subscription;
  // private hotFix: { [id: number]: number};
  constructor(
    private gql: GraphqlService
  ) {
    this.retailers = [];
    this.retailerCtrl = new FormControl();
    this.retailerChange = new EventEmitter<Retailer>();
    // this.hotFix = {};
  }

  ngOnInit() {
    this.placeholder = this.placeholder || '';
    this.sub = this.retailerCtrl.valueChanges.subscribe(this.fetch.bind(this));
    this.subscribeToCtrl();
  }

  subscribeToCtrl() {
    this.filteredRetailers = this.retailerCtrl.valueChanges
    .pipe(
      startWith(''),
      map(retailer => retailer ? this.filterRetailers(retailer) : this.clear())
    );
  }

  clear(): Array<Retailer> {
    this.retailerChange.emit(null);
    return this.retailers.slice();
  }

  fetch() {
    if (this.filterInput.nativeElement.value) {
      let options = {
        name: this.filterInput.nativeElement.value,
        limit: 10,
        offset: 0
      } as RetailersFilterOptions;
      this.gql.retailerForQuery(options).subscribe(
        obj => {
          this.retailers = obj.data.allRetailers.rows;
          this.subscribeToCtrl();
        }
      )
    }
    else {
      this.retailers = [];
    }
  }

  filterRetailers(name: string) {
    return this.retailers.filter(retailer =>
      retailer.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  onRetailerChange(retailer: Retailer) {
    // the hotfix is preventing from retailers to be emitted twice (looks like a Material bug)
    // to remove the hotfix (if the bug has been solved) just replace this code:
    this.retailerChange.emit(retailer);
    // with the fiest 'if' statement here:
    // if (retailer) {
    //   if (!this.hotFix[retailer.retailer_id])
    //     this.hotFix[retailer.retailer_id] = 1;
    //   else
    //     this.hotFix[retailer.retailer_id]++;
    //   if (this.hotFix[retailer.retailer_id] > 1)
    //     this.hotFix[retailer.retailer_id] = 0;
    //   else
    //     this.retailerChange.emit(retailer);
    // }
    if (this.clearOnSelect) {
      this.retailerCtrl.setValue('');
      this.retailers = [];
      this.filteredRetailers = Observable.create([]);
      this.subscribeToCtrl();
      setTimeout(() => {
        this.filterInput.nativeElement.blur();
      }, 0);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
