import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Directive({ selector: '[headerScrollState]' })

export class HeaderScrollStateDirective {

  private lastScroll = 0;
  private isScrolledDown = false;
  private homePageScrollThreshold = 50;
  private isItHomePage = false;
  constructor(private el: ElementRef, private router: Router) {
    router.events.subscribe((event) => (event instanceof NavigationEnd) && this.setHeaderStyleByRoute());
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {

    const SCROLL_TOP = e.srcElement.scrollingElement.scrollTop;
    const HOME_PAGE_TRIGGER = this.homePageScrollThreshold;

    if (!this.isItHomePage && this.isScrolledDown === SCROLL_TOP > this.lastScroll) return this.lastScroll = SCROLL_TOP;
    if (this.isItHomePage && ((this.isScrolledDown && SCROLL_TOP > HOME_PAGE_TRIGGER) || (!this.isScrolledDown && SCROLL_TOP < HOME_PAGE_TRIGGER))) return;

    this.isScrolledDown = SCROLL_TOP > this.lastScroll;
    this.lastScroll = SCROLL_TOP;

    if (this.isScrolledDown) this.setScrollDownStyle();
    else this.setScrollUpStyle();
  }

  setHeaderStyleByRoute() {
    this.isItHomePage = this.router.url.includes('home');

    if (this.isItHomePage) {
      document.body.style.paddingTop = '0';
      this.el.nativeElement.classList.add('home-page-header');
    } else {
      document.body.style.paddingTop = getComputedStyle(document.body).getPropertyValue('--header-height').trim();
      this.el.nativeElement.classList.remove('home-page-header');
    }
  }

  setScrollDownStyle() {
    this.el.nativeElement.classList.add('scrolled-state');
  }

  setScrollUpStyle() {
    this.el.nativeElement.classList.remove('scrolled-state');
  }
}
