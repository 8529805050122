import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-number-check',
  templateUrl: './number-check.component.html',
  styleUrls: ['./number-check.component.scss']
})
export class NumberCheckComponent implements OnInit {
  @Input() num: string;
  @Input() checked: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
