import { Injectable, Injector, NgZone } from '@angular/core';
import { InfiniteScroll, InfiniteScrollDefaults, FieldType } from 'app/shared/infinite-scroll';
import { Product, ProductFilterOptions, ResourceType, ProductUi, IProductService, SimilarResponse, SimilarRequest, SIMILAR_REQUEST_MODE, ProductData } from './product';
import { FormBuilder, FormControl } from '@angular/forms';
import { KeyValuePair } from 'app/shared/enums';
import { EnumsService } from 'app/shared/enums.service';
import { Retailer } from 'app/shared/retailers';
import { Subject } from 'rxjs';
import { UtilsService } from 'app/shared/utils.service';
import { Params } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';
import { Sort } from '@angular/material/sort';
import { ResourceTypesService } from './resource-types.service';
import { ProductUtilsService } from './product-utils.service';
import { GraphqlService } from 'app/communication/graphql.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimilarItemsCompareDialogComponent } from './similar-items-compare-dialog/similar-items-compare-dialog.component';
import { RestService } from 'app/communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { Notification, NotificationType } from 'app/shared/notifications';
import { Job } from 'app/job/job';
import { SelectedCategories, Category, ProductSubCategory } from 'app/categories/category';

@Injectable({
  providedIn: 'root'
})
export class SimilarFactoryService extends InfiniteScroll implements IProductService {
  static ARRAY_FIELD_NAMES = ['retailer_id', 'cs_id', 'serial_number', 'tags', 'category_id', 'sub_category_id', 'viewer_resource_type'];
  static DEFAULT_FILTER = {
    limit: 24, offset: 0, is_desc: false, order_by: 'due_date', is_archived: false, cs_id: [1]
  } as ProductFilterOptions;
  public status: Array<KeyValuePair>;
  public cachedRetailers: { [id: string]: Retailer };
  public viewerResourceTypes: Array<number>;
  public allFormats: Array<ResourceType>;
  public allFormatsDictionary: { [id: number]: ResourceType };
  public matSortActive: string;
  public quickViewItem: Product;
  public quickViewClass: string;
  public currentItem: Product;
  public currentSimilarItems: Array<Product>;
  public onToggleQuickView: Subject<null>;
  public editItem: Product;
  public REQUESTED: SIMILAR_REQUEST_MODE;
  public APPROVED: SIMILAR_REQUEST_MODE;
  public sourceJob: Job;
  public selectedProductsIds: { [id: number]: boolean };
  public categories: Array<Category>;
  public subCategories: Array<ProductSubCategory>;
  public categoriesDictionary: { [id: number]: Category; };
  public subCategoriesDictionary: { [id: number]: ProductSubCategory; };
  public parentCategories: Array<Category>;
  public isAllSelected: boolean;
  public lastQuery: any;
  public oldSimilar: boolean;
  // private subs: Array<Subscription>;
  private _selectedProducts: Array<Product>;
  private _currentItems: Array<Product>;
  private _fetching: boolean;
  private similarItemsCompareDialog: MatDialogRef<SimilarItemsCompareDialogComponent, any>;
  public currentMode: string;
  private _offerCreationMode: boolean;
  private currentItemOrg: Product;
  private currentItemsOrg: { [id: number]: Product };
  public distractorImageIDs: Array<number>;
  private nonNeighborProductIDs: Array<number>;
  private _selectedCount: number;
  constructor(
    private injector: Injector,
    private gql: GraphqlService,
    private enums: EnumsService,
    private utils: UtilsService,
    private ngZone: NgZone,
    private productUtils: ProductUtilsService,
    private resourceTypesService: ResourceTypesService,
    private dialog: MatDialog,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private formBuilder: FormBuilder
  ) {
    super({
      chunkSize: SimilarFactoryService.DEFAULT_FILTER.limit,
      rootRoute: '/products/similar',
      graphFunction: 'products',
      defaults: {
        is_desc: SimilarFactoryService.DEFAULT_FILTER.is_desc,
        order_by: SimilarFactoryService.DEFAULT_FILTER.order_by
      } as InfiniteScrollDefaults
    }, injector);
    this.fieldTypes = {
      'retailer_id': FieldType.NumbersArray,
      'serial_number': FieldType.StringsArray,
      'tags': FieldType.StringsArray,
      'category_id': FieldType.NumbersArray,
      'sub_category_id': FieldType.NumbersArray,
      'cs_id': FieldType.NumbersArray,
      'name': FieldType.StringsArray,
      'viewer_resource_type': FieldType.NumbersArray,
      'is_archived': FieldType.Boolean,
      'date_from': FieldType.Number,
      'date_to': FieldType.Number,
      'date_range': FieldType.String,
      'price_from': FieldType.Number,
      'price_to': FieldType.Number,
      'similar_approved_max_score': FieldType.Number,
      'similar_requested_max_score': FieldType.Number
    };
    this.filter = this.formBuilder.group({
      is_desc: false,
      order_by: 'due_date',
      cs_id: [[]],
      // rids: [[]],
      serial_number: [[]],
      tags: [[]],
      category_id: [[]],
      sub_category_id: [[]],
      viewer_resource_type: [[]],
      name: [[]],
      is_archived: false,
      retailer_id: [[]],
      date_from: null,
      date_to: null,
      date_range: null,
      similar_approved_max_score: null,
      similar_requested_max_score: null
    });

    this._selectedCount = 0;
    this.REQUESTED = SIMILAR_REQUEST_MODE.requested;
    this.APPROVED = SIMILAR_REQUEST_MODE.approved;
    this.currentMode = SIMILAR_REQUEST_MODE[SIMILAR_REQUEST_MODE.requested];
    // this.subs = [];
    this.cachedRetailers = {};
    this.onToggleQuickView = new Subject<null>();
    this.status = this.enums.getProductStatus();
    this.matSortActive = SimilarFactoryService.DEFAULT_FILTER.order_by;
    this.selectedProductsIds = {};
    this.currentItemsOrg = {};
    this._selectedProducts = [];
    this.distractorImageIDs = [];
    this.nonNeighborProductIDs = [];
    // this.subs.push(this.resourceTypesService.getResourceTypes().subscribe(
    //   this.onAllFormats.bind(this)
    // ));
    this.isAllSelected = false;
  }

  get selectedCount() {
    return this._selectedCount;
  }

  get offerCreationMode() {
    return this._offerCreationMode;
  }

  set offerCreationMode(value: boolean) {
    this._offerCreationMode = value;
  }

  get fetching() {
    return this._fetching;
  }
  get selectedProducts() {
    return this._selectedProducts;
  }
  get currentItems() {
    return this._currentItems;
  }

  getCurrentMode(): SIMILAR_REQUEST_MODE {
    return SIMILAR_REQUEST_MODE[this.currentMode];
  }

  async setCurrentMode(value: SIMILAR_REQUEST_MODE, ignore = false) {
    this.currentMode = SIMILAR_REQUEST_MODE[value];
    this.currentSimilarItems = [];
    if (this._currentItems && !ignore)
      await this.jumpTo();
  }

  initFilter() {
    super.initFilter();
    this.ignoreChanges = true;
    this.filter.addControl('order_by', new FormControl(SimilarFactoryService.DEFAULT_FILTER.order_by));
    this.filter.addControl('is_desc', new FormControl(SimilarFactoryService.DEFAULT_FILTER.is_desc));
    this.filter.addControl('retailer_id', new FormControl([]));
    this.filter.addControl('serial_number', new FormControl([]));
    this.filter.addControl('tags', new FormControl([]));
    this.filter.addControl('category_id', new FormControl([]));
    this.filter.addControl('sub_category_id', new FormControl([]));
    this.filter.addControl('cs_id', new FormControl([SimilarFactoryService.DEFAULT_FILTER.cs_id]));
    this.filter.addControl('name', new FormControl([]));
    this.filter.addControl('viewer_resource_type', new FormControl([]));
    this.filter.addControl('is_archived', new FormControl(SimilarFactoryService.DEFAULT_FILTER.is_archived));
    this.filter.addControl('date_range', new FormControl('created_at'));
    this.filter.addControl('date_from', new FormControl(null));
    this.filter.addControl('date_to', new FormControl(null));
    this.filter.addControl('similar_requested_max_score', new FormControl(null));
    this.filter.addControl('similar_approved_max_score', new FormControl(null));
    this.ignoreChanges = false;
  }

  onItemsChange(forceRefresh) {
    // var t0 = performance.now();
    super.onItemsChange(forceRefresh);
    this.mapUI(this.items);
    // this.broadcaster.broadcast('detectChanges');
    // var t1 = performance.now();
    // console.log("onItemsChange took " + (t1 - t0) + " milliseconds.");
  }

  getProdutUI(item: Product): ProductUi {
    return this.productUtils.getProdutUI(item);
  }

  mapUI(items: Array<Product>) {
    items.forEach((p) => {
      if (!p.UI)
        p.UI = this.productUtils.getProdutUI(p);
    });
  }

  public setStateFromURL(params: Params) {
    this.initFilter();
    super.setStateFromURL(params);
    let filter = this.getSearchQuery(this.getFilterFromQueryParams(params));
    this.ignoreChanges = true;
    for (let i in filter) {
      if (i != 'limit' && i != 'offset') {

        if (SimilarFactoryService.ARRAY_FIELD_NAMES.find(a => a == i) && i != 'serial_number' && i != 'tags') {
          if (filter[i].map)
            this.filter.controls[i].setValue(filter[i].map(e => parseFloat(e)));
          else
            this.filter.controls[i].setValue([filter[i]]);
        }
        else if (i == 'serial_number' || i == 'tags' || i == 'name') {
          if (filter[i].map)
            this.filter.controls[i].setValue(filter[i].map(e => String(e)));
          else
            this.filter.controls[i].setValue(String(filter[i]).split(','));
        }
        else if (i == 'date_from' || i == 'date_to') {
          this.filter.controls[i].setValue(new Date(filter[i] * 1));
        }
        else
          this.filter.controls[i].setValue(filter[i]);
      }
    }
    if (this.filter.controls['order_by'].value) {
      this.initialSortField = this.filter.controls['order_by'].value;
    }
    if (typeof this.filter.controls['is_desc'].value === 'boolean') {
      this.initialSortDir = this.filter.controls['is_desc'].value ? 'desc' : 'asc';
    }
    if (this.filter.controls.viewer_resource_type.value)
      this.viewerResourceTypes = this.filter.controls.viewer_resource_type.value;
    else
      delete this.viewerResourceTypes;

    this.lastQuery = this.utils.deepCopyByValue(this.getSearchQuery());
    this.ignoreChanges = false;
  }

  setRetailerToCache(retailer: Retailer) {
    if (this.cachedRetailers[retailer.id])
      Object.assign(this.cachedRetailers[retailer.id], retailer);
    else
      this.cachedRetailers[retailer.id] = this.utils.deepCopyByValue(retailer);
  }

  clearRetailersCache() {
    this.cachedRetailers = {};
  }

  onViewerResourceTypesChange(evt: MatRadioChange) {
    this.viewerResourceTypes = evt.value;
    this.filter.controls.viewer_resource_type.setValue(this.viewerResourceTypes);
  }

  sortData(sort: Sort) {
    this.ignoreChanges = true;
    this.filter.controls['order_by'].setValue(sort.active);
    this.ignoreChanges = false;
    this.filter.controls['is_desc'].setValue(sort.direction == 'desc');
  }

  onAllFormats(resourceTypes: Array<ResourceType>) {
    this.allFormats = resourceTypes || this.resourceTypesService.getCachedTypes();
    this.allFormatsDictionary = {};
    this.allFormats.forEach(rt => {
      this.allFormatsDictionary[rt.id] = rt;
    });
    this.productUtils.allFormatsDictionary = this.allFormatsDictionary;
  }

  setProdutsUI() {
    this.items.forEach((item: Product) => {
      if (!item.UI)
        item.UI = this.getProdutUI(item);
    })
  }

  public getProductsFromProductQuery(rows: Array<Product>): Array<Product> {
    return this.productUtils.getProductsFromProductQuery(rows);
  }

  windowScroll80() {
    this.scrollOffset = this.items.length;
    if (!this.lastQuery)
      this.initPagination();
    this.searchByQuery();
  }

  public toggleEnabled(item: Product, prefix: string, state?: boolean) {
    if (typeof state === 'boolean')
      item[prefix + 'enabled'] = state ? 1 : 0;
    else
      item[prefix + 'enabled'] = item[prefix + 'enabled'] == 1 ? 0 : 1;
  }

  async jumpTo(selectedProduct?: Product) {
    return new Promise((resolve, reject) => {
      this._fetching = true;
      const options = {
        mode: this.currentMode,
        // action: SIMILAR_REQUEST_ACTION[SIMILAR_REQUEST_ACTION.refresh],
        distractor_image_ids: this.distractorImageIDs,
        non_neighbor_product_ids: this.nonNeighborProductIDs,
        num_neighbors: 25, // TODO infinite loading - requiares server support for how many to skip (offset)
        query_product_ids: this._currentItems?.map(i => i.id),
        old_similar: this.oldSimilar
        // product_group: this._currentItems.map(i => i.id)
      } as SimilarRequest;
      this.rest.similarItems('post', options).subscribe(
        async (items: SimilarResponse) => {
          let id = [];
          if (items.error) {
            let data: Notification = {
              text: 'Algo Error - ' + items.error,
              type: NotificationType.Error,
              action: 'OK'
            };
            this.broadcaster.broadcast('notifyUser', data);
            return;
            // }
          }
          if (!items.neighbor_product_id_list?.length) {
            let data: Notification = {
              text: 'Algo return 0 neighbors',
              type: NotificationType.Error,
              action: 'OK'
            };
            this.broadcaster.broadcast('notifyUser', data);
            return;
          }
          id.push(items.query_product_id);
          items.neighbor_product_id_list.forEach(n => id.push(n));
          let productFilterOptions = {
            id
          } as ProductFilterOptions;
          this.currentItem = this.utils.deepCopyByValue(this.currentItemOrg);
          // this.sortImagesDataByIds(arr, this.currentItem);
          this.currentItem = this.currentItems.find((item) => item.id === items.query_product_id);
          this.sortImagesDataByIds(items.query_image_ids, this.currentItem);
          if (selectedProduct && this.currentMode === SIMILAR_REQUEST_MODE[SIMILAR_REQUEST_MODE.requested])
            this.sortImagesDataByIds(items.query_image_ids, selectedProduct);
          let pCopy = this._currentItems.find(p => p.id == this.currentItem.id)
          if (!pCopy)
            this._currentItems.push(this.currentItem);
          else
            Object.assign(pCopy, this.currentItem);
          const getProducts = () => {
            this.gql.products(productFilterOptions).subscribe(
              obj => {
                this.currentSimilarItems = [];
                const rows = this.utils.deepCopyByValue(obj.data.allProducts.rows) as Array<Product>;
                this.mapUI(rows);
                for (let i = 0; i < items.neighbor_product_id_list.length; i++) {
                  // const product = rows.find(p => p.id === items.neighbor_product_id_list[i]);
                  // if (product) {
                  //   this.sortImagesDataByIds(items.neighbor_image_ids_list[i], product);
                  //   this.currentSimilarItems.push(product);
                  // }
                  const product = rows.find(p => p.id == items.neighbor_product_id_list[i]);
                  if (product) {
                    this.sortImagesDataByIds(items.neighbor_image_ids_list[i], product);
                    this.currentSimilarItems.push(product);
                  }
                  else
                    console.error(`Similar items has recommended a product (id = ${items.neighbor_product_id_list[i]}) that the API couldn't return`);
                }
                resolve(null);
              },
              err =>
                this.utils.httpErrorResponseHandler(err, 'failure fetching products for similar items'),
              () => this._fetching = false
            );
          };
          getProducts();
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure fetching similar items'),
        () => this._fetching = false
      );
    });
  }

  removeFromBundle(product: Product) {
    product.UI.checked = false;
    for (let i = 0; i < this._currentItems.length; i++) {
      if (this._currentItems[i].id == product.id) {
        this._currentItems.splice(i, 1);
        break;
      }
    }
    if (this._currentItems.length === 0) {
      this.currentItem = null;
      this.currentItemOrg = null;
    }
    this.refreshSelectedCount();
  }

  // the ID of the image to remove (the image's product is included in the product_group)
  removeDistractor(imageId: number) {
    this.distractorImageIDs.push(imageId);
    this.jumpTo();
    // const options = {
    //   // action: 'remove_distractor',
    //   // product_id: pid,
    //   // image_id: imageId
    //   mode: this.currentMode,
    //   action: SIMILAR_REQUEST_ACTION[SIMILAR_REQUEST_ACTION.remove_distractor],
    //   product_group: this._currentItems.map(i => i.id),
    //   image_id: imageId
    // } as SimilarRequest;
    // this.rest.similarItems('post', options).subscribe(
    //   this.jumpTo.bind(this),
    //   err => this.utils.httpErrorResponseHandler(err, 'failure removing similar items distractor')
    // )
  }

  // the ID of the product to remove (this product is not included in the product_group)
  async removeNeighbor(productId: number) {
    this.nonNeighborProductIDs.push(productId);
    await this.jumpTo();
    // const options = {
    //   // action: 'remove_neighbor',
    //   // product_id: productId
    //   mode: this.currentMode,
    //   action: SIMILAR_REQUEST_ACTION[SIMILAR_REQUEST_ACTION.remove_neighbor],
    //   product_group: this._currentItems.map(i => i.id),
    //   product_id: productId
    // } as SimilarRequest;
    // this.rest.similarItems('post', options).subscribe(
    //   this.jumpTo.bind(this),
    //   err => this.utils.httpErrorResponseHandler(err, 'failure removing similar items distractor')
    // );
  }

  async getProductById(productId: number): Promise<Product> {
    return new Promise((resolve, reject) => {
      if (this.currentItemsOrg[productId]) {
        resolve(this.currentItemsOrg[productId]);
        return;
      }
      let o = {
        id: [productId]
      } as ProductFilterOptions;
      this.gql.products(o).subscribe(
        p => {
          let product = this.utils.deepCopyByValue(p.data.allProducts.rows[0]);
          this.mapUI([product]);
          this.currentItemsOrg[product.id] = product;
          resolve(product);
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure fetching product number ' + productId)
      )
    });
  }

  async mergeNeighbor(product: Product) {
    product.UI.checked = true;
    this._currentItems.push(await this.getProductById(product.id));
    this.jumpTo();
    this.refreshSelectedCount();
  }

  /**
   * Set the initial value of the item for similar items request
   * @param item
   */
  public setInitialItem(item: Product): void {
    this.currentItem = this.utils.deepCopyByValue(item);
    this.currentItemOrg = this.utils.deepCopyByValue(this.currentItem);
    this._currentItems = [this.currentItem];
  }

  /**
   * Fetch similar items by the given item
   * @param item
   */
  async fetchSimilarItems(item: Product, sendToJump = true) {
    item.UI.checked = true;
    if (!this.currentItem) {
      this.setInitialItem(item);
    }
    else {
      if (this.currentItem.id != item.id && !this._currentItems.find(p => p.id == item.id))
        this._currentItems.push(await this.getProductById(item.id));
    }
    this.refreshSelectedCount();
    await this.jumpTo(sendToJump ? item : null);
  }

  /**
   * Open the panel with similar items
   * @param item
   * @param event
   */
  async toggleQuickView(item: Product, event: MouseEvent) {
    await this.fetchSimilarItems(item);
    this.openSimilarItemsDialog();
  }

  private openSimilarItemsDialog() {
    this.similarItemsCompareDialog = this.dialog.open(
      SimilarItemsCompareDialogComponent,
      {
        width: (window.innerWidth - 20) + 'px',
      });
    this.similarItemsCompareDialog.afterClosed().subscribe((open: boolean) => {
      this.currentMode = SIMILAR_REQUEST_MODE[SIMILAR_REQUEST_MODE.requested];
      this._offerCreationMode = false;
      // this.clearSelections();
    });
  }

  clearSelections() {
    delete this.currentItem;
    delete this.currentItemOrg;
    this._currentItems = [];
    this._selectedProducts = [];
    this.selectedProductsIds = {};
    this.items?.forEach((p: Product) => {
      if (p.UI) p.UI.checked = false;
    });
    this.refreshSelectedCount();
  }

  getStatusById(id: number): KeyValuePair {
    return this.productUtils.getStatusById(id);
  }

  // setSelected(): void {
  //   this.selectedItems = this.items.filter(i => i.UI.checked);
  //   if (this.selectedItems.length)
  //     this.selectedItemsPolySpecs = this.utils.deepCopyByValue(this.selectedItems[0].products_polygon_specifications);
  //   // this.setTotalPrice();
  // }

  refreshSelectedCount() {
    if (this.items) {
      this._selectedCount = this.items.filter((p: Product) => p.UI && p.UI.checked).length;
      if (this._selectedCount > 0) {
        this.isAllSelected = false;
      }
    }
  }

  sortImagesDataByIds(ids: Array<number>, product: Product) {
    let productsData = [];
    ids.forEach(id => {
      if (id) {
        let current = product.products_data.find(pd => pd ? pd.id == id : false);
        // if (!productsData.find(i => i.id == id)) {
        productsData.push(current);
        // }
      }
      else {
        productsData.push(null);
      }
    });
    product.products_data = productsData;
  }

  closeModal() {
    this.similarItemsCompareDialog.close();
    // this.currentMode = SIMILAR_REQUEST_MODE[SIMILAR_REQUEST_MODE.requested];
  }

  public createFromSimilar(obj: any): Promise<Product> {
    return new Promise((resolve, reject) => {
      this.rest.createFromSimilar('post', obj).subscribe(
        (data: Product) => {
          // this.item.id = data.id;
          // this.item.retailer_id = data.retailer_id;
          // this.item.status = data.status;
          resolve(data);
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure adding resources to product')
      );
    });
  }

  async sendToCMS(product: Product) {
    if (!confirm(`Are you sure you want to use existing resources to deliver ${this._currentItems.length} products?`)) return;
    let resources = product.products_resources.map(pr => pr.id);
    this._fetching = true;
    let done = false;
    this._currentItems.forEach(async p => {
      let obj = {
        id: p.id,
        resources
      };
      const product = await this.createFromSimilar(obj);
      p.status = product.status;
      if (!done) {
        done = true;
        this._fetching = false;
        let data: Notification = {
          text: `${this._currentItems.length} products has been delivered`,
          type: NotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        this.closeModal();
        this.onDestroy();
      }
    });
  }

  refreshSelected() {
    this._selectedProducts = [];
    if (this.currentSimilarItems.length) {
      for (let i of Object.keys(this.selectedProductsIds)) {
        // TODO Remove this:
        if (!this.currentSimilarItems.find(p => p.id == parseInt(i))) {
          console.warn(i, this.currentSimilarItems);
          return;
        }
        if (this.selectedProductsIds[i])
          this._selectedProducts.push(this.currentSimilarItems.find(p => p.id == parseInt(i)));
      }
    }
    // this.mapUI(this._selectedProducts);
  }

  onCategoriesChange(selected: SelectedCategories) {
    this.ignoreChanges = true;
    this.filter.controls['category_id'].setValue(selected.categories.map(c => c.id));
    this.ignoreChanges = false;
    this.filter.controls['sub_category_id'].setValue(selected.subCategories.map(c => c.id));
  }

  async setCategory(category: Category, item: Product) {
    return new Promise(async (resolve, reject) => {
      if (category) {
        if (item.category_id && item.category_id != category.id)
          delete item.products_sub_categories_associations[0];
        item.category_id = category.id;
        item.renderCount = !item.renderCount ? 1 : item.renderCount + 1;
        item.products_sub_categories_associations = [];
        await this.saveProduct(item);
        resolve({});
      }
    });
  }

  setSubCategory(subcategory: ProductSubCategory, item: Product, index: number) {
    if (subcategory) {
      item.products_sub_categories_associations[index].sub_category_id = subcategory.id;
      if (!item.products_sub_categories_associations[index].products_sub_categories)
        item.products_sub_categories_associations[index].products_sub_categories = [];

      item.products_sub_categories_associations[index].products_sub_categories[0] = {
        category_id: item.category_id
      } as ProductSubCategory;

      if (item.id == this.currentItem.id) {
        this.currentItem.products_sub_categories_associations = item.products_sub_categories_associations;
      }
      for (let i = 0; i < this._currentItems.length; i++) {
        if (this._currentItems[i].id == item.id) {
          this._currentItems[i].products_sub_categories_associations = item.products_sub_categories_associations;
          break;
        }
      }
      this.saveProduct(item);
    }
  }

  async saveProduct(item: Product, successMsg?: string) {
    return new Promise((resolve, reject) => {
      let query = '/' + item.id;
      this.rest.product('put', item, query).subscribe(
        res => {
          if (successMsg) {
            let data: Notification = {
              text: successMsg,
              type: NotificationType.Success,
              action: 'OK'
            }
            this.broadcaster.broadcast('notifyUser', data);
          }
          if (item.id == this.currentItem.id) {
            this.currentItem.category_id = item.category_id;
          }
          for (let i = 0; i < this._currentItems.length; i++) {
            if (this._currentItems[i].id == item.id) {
              this._currentItems[i].category_id = item.category_id;
              break;
            }
          }
          resolve({})
        },
        err => {
          this.utils.httpErrorResponseHandler(err, 'failure saving item');
          reject();
        }
      );
    });
  }

  pin(product: Product, image: ProductData) {
    let exec = (p: Product, save: boolean) => {
      if (!p) return;
      for (let i = 0; i < p.products_data.length; i++) {
        if (image.id == p.products_data[i].id)
          p.products_data[i].sort_index = 0;
        else
          p.products_data[i].sort_index = null;

        if (image.id === p.UI.images[i].id) {
          p.UI.images[i].sort_index = 0
        } else {
          p.UI.images[i].sort_index = null
        }
      }
      //Sort an array asc so that null values always come last
      p.UI.images = p.UI.images.sort((a, b) => (+(a.sort_index === null) - +(b.sort_index === null) || +(a.sort_index > b.sort_index) || -(a.sort_index < b.sort_index)));
      if (save) {
        this.saveProduct(p);
      }
    }
    if (this.currentItemOrg.id == product.id) {
      exec(this.currentItemOrg, true);
      exec(this.currentItem, false);
    }
    else {
      exec(this.currentItemsOrg[product.id], true);
      exec(this._currentItems.find(p => p.id == product.id), false);
    }
    exec(this.items.find(p => p.id == product.id), false);
    exec(product, false);
  }

  isCheckboxDisabled(item: Product) {
    return !!!this.currentItem;
  }

  onCheckboxChenge(item: Product) {
    if (item.UI.checked)
      this.mergeNeighbor(item);
    else
      this.removeFromBundle(item);
    this.refreshSelectedCount();
  }

  onDestroy() {
    this.currentItem = null;
    this.selectedProductsIds = {};
    this.currentItemsOrg = {};
    this._selectedProducts = [];
    delete this.currentItemOrg;
    this.distractorImageIDs = [];
    this.nonNeighborProductIDs = [];
  }
}
