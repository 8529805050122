import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ChangeDetectorRef, HostListener, AfterViewChecked } from '@angular/core';
import { RolesManagerService, Role } from 'ng-role-based-access-control';
import { BroadcasterService } from 'ng-broadcaster';
import { User } from '../auth/user';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Link, LinkType } from './link';
import { Subscription, Observable, filter } from 'rxjs';
import { EndpointsService } from '../communication/endpoints.service';
import { NotificationsService } from '../ui-components/notifications.service';
import { ChatService } from '../ui-components/chat.service';
import { MatSidenav } from '@angular/material/sidenav';
import { BodyService } from '../shared/body.service';
import { NavPermissions, StateForRoles, DefaultViewForRole } from './main-nav';
import { RolesHelperService } from '../auth/roles-helper.service';
import { ShareService } from 'app/share/share.service';
import { PixelsService } from 'app/shared/pixels.service';
import { MatDialog } from '@angular/material/dialog';
import { ReferralLinkDialogComponent } from '../auth/referral/referral-link-dialog/referral-link-dialog.component';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  providers: [EndpointsService],
  encapsulation: ViewEncapsulation.None
})
export class MainNavComponent implements OnInit, OnDestroy {
  @ViewChild('start') sidenav: MatSidenav;

  public links: Array<Link>;
  public isNotificationsSidebarToggle: boolean;
  public ANCHOR: LinkType;
  public BUTTON: LinkType;
  public EXTERNAL: LinkType;
  public isDarkTheme: Observable<boolean>;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private onRolesFetched: Subscription;
  private onUserRefreshed: Subscription;
  private onScrollDirChangeSub: Subscription;
  private onDeployLinksSub: Subscription;
  private statesForRoles: Array<StateForRoles>;
  private defaultViewForRole: Array<DefaultViewForRole>;
  public overThenNinetyNine = '99+';

  constructor(private roles: RolesManagerService, private broadcaster: BroadcasterService,
    public auth: AuthService, private router: Router, //private endpoints: EndpointsService,
    private notificationsService: NotificationsService, private chatService: ChatService,
    public body: BodyService, private rolesHelper: RolesHelperService,
    private pixels: PixelsService,
    private dialog: MatDialog,
    private shareService: ShareService,
    private changeDetector: ChangeDetectorRef) {
    this.links = [];
    this.ANCHOR = LinkType.ANCHOR;
    this.BUTTON = LinkType.BUTTON;
    // this.TOGGLE = LinkType.TOGGLE;
    this.init();
    // this.renderCounter = 0;
    this.rolesHelper.fetchRoles();
    // this.isProd = this.endpoints.getEndpointDomain('creators') == 'https://a.creators3d.com';
    this.notificationsService.onNotification.subscribe(() => {
      this.deployLinks(this.auth.user);
    });
    this.notificationsService.onSidebarToggle.subscribe(state => {
      this.isNotificationsSidebarToggle = state;
      this.deployLinks(this.auth.user);
    });
    this.chatService.onSidebarToggle.subscribe(() => {
      this.deployLinks(this.auth.user);
    });
    this.chatService.onDeployLinks.subscribe(() => {
      this.deployLinks(this.auth.user);
    });
  }

  ngOnInit() {
    this.onRolesFetched = this.rolesHelper.onRolesFetched.subscribe(
      data => this.deployLinks(this.auth.user)
    );
    this.onUserRefreshed = this.broadcaster.on('userRefreshed').subscribe(
      data => this.deployLinks(this.auth.user)
    );
    this.onLogin = this.broadcaster.on<User>('onLogin').subscribe(
      user => this.deployLinks(user as User)
    );
    this.onLogout = this.broadcaster.on<User>('onLogout').subscribe(
      user => {
        this.deployLinks();
        this.router.navigateByUrl('login');
      }
    );
    this.onDeployLinksSub = this.broadcaster.on('deployLinks').subscribe(
      () => this.deployLinks(this.auth.user)
    );
    // this.deployLinks();
  }

  init() {
    this.statesForRoles = [
      {
        state: {
          name: 'jobs/list'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist Admin',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'job'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist Admin',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'roles-management'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'offers'
        },
        permissions: [
          {
            permissionName: 'View Items Offers',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist With Offers',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'offer'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist Admin',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'users-manager'
        },
        permissions: [
          {
            permissionName: 'Create Users',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'rank-manager'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'products'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'similar'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'products'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'analytics'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'categories-manager'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      // {
      //   state: {
      //     name: 'invoices'
      //   },
      //   permissions: [
      //     {
      //       permissionName: 'View Invoices',
      //       visibleInNavbar: true
      //     },
      //     {
      //       roleName: 'View Only',
      //       visibleInNavbar: true
      //     },
      //     {
      //       roleName: 'Artist',
      //       visibleInNavbar: true
      //     },
      //     {
      //       roleName: 'Artist Admin',
      //       visibleInNavbar: true
      //     }
      //   ]
      // },
      {
        state: {
          name: 'payments/accounts'
        },
        permissions: [
          {
            permissionName: 'View Invoices',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist Admin',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'uploads'
        },
        permissions: [
          {
            roleName: 'View Only',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist',
            visibleInNavbar: true
          },
          {
            roleName: 'Artist Admin',
            visibleInNavbar: true
          },
          {
            roleName: 'Super User',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'broadcast'
        },
        permissions: [
          {
            permissionName: 'Send Broadcast',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'groups'
        },
        permissions: [
          {
            permissionName: 'View Groups',
            visibleInNavbar: true
          },
          {
            roleName: 'View Only',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'tagging'
        },
        permissions: [
          {
            permissionName: 'Tag Products',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'mesh-libraries'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'invite-friends'
        },
        permissions: [
          {
            roleName: 'Artist Referral',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'complexity-manager'
        },
        permissions: [
          {
            roleName: 'Super User',
            visibleInNavbar: true
          }
        ]
      },
      {
        state: {
          name: 'offer-creation-tool'
        },
        permissions: [
          {
            roleName: 'Data Tagger',
            visibleInNavbar: true
          }
        ]
      }
    ];

    // this.rolesHierarchy = [
    //   {
    //     roleName: 'Super User',
    //     roleNames: ['Super User']
    //   }
    // ];
  }

  deployLinks(user?: User) {
    this.links = [];
    // this.links.push({ order: 0, label: 'CREATORS 3D', route: 'home', type: this.ANCHOR, side: 'left', cssClass: 'logo' });
    // this.links.push({ order: 21, label: 'blog', route: 'https://blog.creators3d.com/', type: this.EXTERNAL, side: 'left' });
    this.links.push({ order: 20, label: 'how it works', route: 'how-it-works', type: this.ANCHOR, side: 'left' });
    this.links.push({ order: 54, label: 'offers', route: 'offers', type: this.ANCHOR, side: 'left', inBottomMenu: true, icon: 'business_center' });

    this.links.push({ order: 2, label: 'job offers', route: 'offers', type: this.ANCHOR, side: 'left' });
    if (this.shareService.available) {
      this.links.push({ order: 56, label: '', type: this.BUTTON, action: 'share', side: 'left', cssClass: 'mat-icon-button', inBottomMenu: true, icon: 'share' });
      this.links.push({ order: 5, label: '', type: this.BUTTON, action: 'share', side: 'right', cssClass: 'mat-icon-button', icon: 'share' });
    }
    if (user && this.auth.isloggedIn()) {
      this.links.push({ order: 7, label: '', type: this.BUTTON, action: 'toggleDarkTheme', side: 'right', cssClass: 'mat-icon-button ' + (this.body.isDarkTheme ? 'active' : ''), icon: 'brightness_3' });
      var username = user.name ? user.name : user.email;
      if (username && username.indexOf(" ") > -1)
        username = username.split(' ')[0];

      // this.links.push({ order: 5, label: 'hello, ' + username, route: 'user-profile/' + user.id, type: this.ANCHOR, side: 'right' });
      // this.links.push({ order: 55, label: '', type: this.ANCHOR, route: 'user-profile/' + user.id, action: 'profile', side: 'right', cssClass: 'mat-icon-button', icon: 'account_circle' });
      this.links.push({ order: 55, label: '', type: this.ANCHOR, route: `artist/${user.id}/${user.name}`, action: 'profile', side: 'right', cssClass: 'mat-icon-button', icon: 'account_circle' });
      this.links.push({ order: 6, label: '', type: this.BUTTON, action: 'toggleExpand', side: 'right', cssClass: 'mat-icon-button rotate hide-sm ' + (this.body.getExpandMode() ? 'active' : ''), icon: 'expand' });
      this.links.push({ order: 55, label: '', type: this.BUTTON, action: 'toggleNotifications', side: 'right', cssClass: 'mat-icon-button toggle-notifications' + (this.isNotificationsSidebarToggle ? ' arrow' : ''), icon: this.notificationsService.unred > 0 ? 'notifications_active' : 'notifications', count: this.notificationsService.unred, inBottomMenu: true });
      this.links.push({ order: 57, label: '', type: this.BUTTON, action: 'toggleChat', side: 'right', cssClass: 'mat-icon-button', icon: 'message', count: this.chatService.totalUnread, inBottomMenu: true });
      // this.links.push({ order: 58, label: 'logout', type: this.BUTTON, action: 'logout', side: 'right' });
      this.links.push({ order: 59, label: '', type: this.BUTTON, action: 'logout', side: 'right', cssClass: 'mat-icon-button', icon: 'power_settings_new' });
      // this.links.push({ order: 5, label: 'hello ' + username, action: 'navToEditUser', type: this.BUTTON });
      // this.links.push({ order: 1, label: 'home', route: 'home', type: this.ANCHOR, side: 'left' });
      // if(this.canUserNavToState(user.roles, 'invite-friends').visibleInNavbar) {
      //   this.links.push({order: 1, label: 'invite a friend, earn 30$', type: this.BUTTON, side: 'left', inMenu: true, action: 'getReferralLink', cssClass: 'friend'});
      // }
      if (this.canUserNavToState(user.roles, 'jobs/list').visibleInNavbar) {
        this.links.push({ order: 53, label: 'jobs', route: 'jobs/list', type: this.ANCHOR, side: 'left', inBottomMenu: true, icon: 'list' });
        this.links.push({ order: 4, label: 'my jobs', route: 'jobs/list', type: this.ANCHOR, side: 'left' });
      }
      if (this.canUserNavToState(user.roles, 'roles-management').visibleInNavbar)
        this.links.push({ order: 5, label: 'roles', route: 'roles-management', type: this.ANCHOR, side: 'left', inMenu: true });
      // if (this.canUserNavToState(user.roles, 'offers').visibleInNavbar) {
      //   this.links.push({ order: 54, label: 'offers', route: 'offers', type: this.ANCHOR, side: 'left', inBottomMenu: true, icon: 'business_center' });
      //   this.links.push({ order: 2, label: 'job offers', route: 'offers', type: this.ANCHOR, side: 'left' });
      // }
      if (this.canUserNavToState(user.roles, 'users-manager').visibleInNavbar)
        this.links.push({ order: 6, label: 'users', route: 'users-manager', type: this.ANCHOR, side: 'left', inMenu: true });
      if (this.canUserNavToState(user.roles, 'analytics').visibleInNavbar)
        this.links.push({ order: 12, label: 'analytics', route: 'analytics', type: this.ANCHOR, side: 'left', inMenu: true });
      if (this.canUserNavToState(user.roles, 'categories-manager').visibleInNavbar)
        this.links.push({ order: 7, label: 'categories', route: 'categories-manager', type: this.ANCHOR, side: 'left', inMenu: true });
      // if (this.canUserNavToState(user.roles, 'invoices').visibleInNavbar) {
      //   this.links.push({ order: 8, label: 'invoices', route: 'invoices', type: this.ANCHOR, side: 'left', inMenu: true });
      // }
      if (this.canUserNavToState(user.roles, 'payments/accounts').visibleInNavbar) {
        if (user.payoneer_approved)
          this.links.push({ order: 9, label: 'payments', route: `payments/payout/${user.id}`, type: this.ANCHOR, side: 'left', inMenu: true });
        else
          this.links.push({ order: 9, label: 'payments', route: `payments/accounts/${user.id}`, type: this.ANCHOR, side: 'left', inMenu: true });
      }
      if (this.canUserNavToState(user.roles, 'uploads').visibleInNavbar) {
        this.links.push({ order: 12, label: 'uploads', route: 'uploads', type: this.ANCHOR, side: 'left', inMenu: true });
      }

      if (this.canUserNavToState(user.roles, 'tagging').visibleInNavbar) {
        this.links.push({ order: 200, label: 'tagging', route: 'tagging/products', type: this.ANCHOR, side: 'left', inMenu: true });
      }

      if (this.canUserNavToState(user.roles, 'offer-creation-tool').visibleInNavbar) {
        this.links.push({ order: 200, label: 'offer creation tool', route: 'products/create-offer', type: this.ANCHOR, side: 'left', inMenu: true });
      }

      if (this.canUserNavToState(user.roles, 'complexity-manager').visibleInNavbar && this.rolesHelper.doesUserHasPermission('Complexity Manager')) {
        this.links.push({ order: 200, label: 'complexity manager', route: 'products/complexity-manager', type: this.ANCHOR, side: 'left', inMenu: true });
      }

      if (this.canUserNavToState(user.roles, 'mesh-libraries').visibleInNavbar) {
        this.links.push({ order: 201, label: 'mesh libraries', route: 'mesh-libraries', type: this.ANCHOR, side: 'left', inMenu: true });
      }


      if (this.canUserNavToState(user.roles, 'groups').visibleInNavbar)
        this.links.push({ order: 10, label: 'groups', route: 'groups', type: this.ANCHOR, side: 'left', inMenu: true });
      if (this.canUserNavToState(user.roles, 'broadcast').visibleInNavbar)
        this.links.push({ order: 11, label: 'broadcast notifications', route: 'broadcast', type: this.ANCHOR, side: 'left', inMenu: true });

      // if (this.canUserNavToState(user.roles, 'multiple-upload').visibleInNavbar)
      //   this.links.push({ order: 6, label: 'multiple upload', route: 'multiple-upload', type: this.ANCHOR, side: 'left' });
      if (this.canUserNavToState(user.roles, 'products').visibleInNavbar)
        this.links.push({ order: 1, label: 'products', route: 'products', type: this.ANCHOR, side: 'left' });
      if (this.canUserNavToState(user.roles, 'similar').visibleInNavbar)
        this.links.push({ order: 12, label: 'similar items', route: 'products/similar', type: this.ANCHOR, side: 'left', inMenu: true });

      if (this.canUserNavToState(user.roles, 'rank-manager').visibleInNavbar)
        this.links.push({ order: 13, label: 'trust level manager', route: 'rank/manager', type: this.ANCHOR, side: 'left', inMenu: true });

      // if (this.notificationsService.unred > 0 || this.chatService.totalUnread > 0)
      //   this.body.setTitle(false);
      // else
      //   this.body.setTitle(true);
    }
    else {
      this.links.push({ order: 2, label: 'login', route: 'login', type: this.ANCHOR, side: 'right' });
      this.links.push({ order: 1, label: 'sign up', route: 'onboarding', type: this.ANCHOR, side: 'right', cssClass: 'sign-up-btn' });
    }
    this.links = this.links.sort((a: Link, b: Link) => {
      if (a.order < b.order) {
        return -1;
      } else if (a.order > b.order) {
        return 1;
      } else {
        return 0;
      }
    });
    this.changeDetector.detectChanges();
  }

  // check if a user can nav to state
  canUserNavToState(userRoles: Array<Role>, stateName: string): NavPermissions {
    if (!this.rolesHelper.isInit()) { // roles config is not initialized!
      this.rolesHelper.fetchRoles();
    };

    let navStateResult: NavPermissions = {
      canNav: false,
      visibleInNavbar: false
    };

    if (!userRoles)
      return navStateResult;
    let statePermissions = this.statesForRoles.find(x => x.state.name == stateName);
    if (!statePermissions) return navStateResult;

    for (let r of userRoles) {
      let stateForRole = statePermissions.permissions.find(x => (x.roleName ? x.roleName.toLowerCase() : x.roleName) == r.name.toLowerCase());
      if (stateForRole) {
        navStateResult.canNav = true;
        navStateResult.visibleInNavbar = stateForRole.visibleInNavbar;
        return navStateResult;
      }
    }

    statePermissions.permissions.forEach(p => {
      if (this.roles.doesRolesHasPermission(userRoles, p.permissionName)) {
        navStateResult.canNav = true;
        navStateResult.visibleInNavbar = p.visibleInNavbar
        return false;
      }
    });

    return navStateResult;
  }

  linkClick(action: string) {
    switch (action) {
      case 'logout': {
        this.logout();
        break;
      }
      case 'register': {
        this.router.navigateByUrl('onboarding');
        break;
      }
      case 'profile': {
        this.router.navigateByUrl('user-profile/' + this.auth.user.id);
        break;
      }
      case 'share': {
        this.pixels.sendPixel({
          event: 'click',
          button_name: "share",
          button_position: "upper_pane"
        });
        this.shareService.post();
        break;
      }
      default: {
        this[action]();
      }
    }
    if (this.sidenav)
      this.sidenav.close();
  }

  anchorClick(url: string) {
    if (url.indexOf('onboarding') > -1) {
      this.pixels.sendPixel({
        event: 'click',
        button_name: "sign_up",
        button_position: "upper_pane"
      });
    } else if (url.indexOf('login') > -1) {
      this.pixels.sendPixel({
        event: 'click',
        button_name: "login",
        button_position: "upper_pane"
      });
    }
  }

  /**
   * Open the referral link popup
   */
  public getReferralLink(): void {
    const userData = this.auth.getUser();
    this.sendOnReferralLinkPopupEvent();
    this.dialog.open(
      ReferralLinkDialogComponent,
      {
        data: userData
      });
  }

  /**
   * Sending the event when referral link popup was opened
   */
  private sendOnReferralLinkPopupEvent(): void {
    this.pixels.sendPixel({
      event: 'popup_view',
      popup_name: 'refer_a_friend'
    });
  }

  // get default state by role (the current user's home page)
  getDefaultStateByRole(userRoles: Array<Role>) {
    if (userRoles) {
      for (let r of userRoles.sort((a, b) => {
        if (a.id > b.id)
          return 1;
        if (a.id < b.id)
          return -1;
        return 0;
      })) {
        return this.defaultViewForRole[r.id]
      }
    }
  }

  logout() {
    if (!confirm('Are you sure you want to Sign-off?')) return;
    this.auth.logout();
    this.router.navigateByUrl('login');
  }

  toggleNotifications() {
    this.notificationsService.toggleSidebar(!this.notificationsService.getSidebarState());
  }

  toggleChat() {
    this.chatService.toggleSidebar(!this.chatService.getSidebarState());
  }

  toggleExpand() {
    this.body.toggleExpand();
    this.deployLinks(this.auth.user);
  }

  toggleDarkTheme() {
    this.body.isDarkTheme = !this.body.isDarkTheme;
    this.deployLinks(this.auth.user);
  }

  ngOnDestroy(): void {
    this.onLogin.unsubscribe();
    this.onLogout.unsubscribe();
    this.onRolesFetched.unsubscribe();
    this.onUserRefreshed.unsubscribe();
    this.onScrollDirChangeSub.unsubscribe();
    this.onDeployLinksSub.unsubscribe();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
}
