import { Injectable } from '@angular/core';
import { Job, ArtistJobItem, ArtistsItemData } from '../job/job';
import { Offer, NativeResourceSet, OfferResource } from '../offer/offers';
import { UtilsService } from './utils.service';
import { Resource, ArtistsResourcesFeedback, ArtistsResourceFeedbackComment, MediaTag } from '../job/resource'
import { Product, ProductsResourcesFeedback, ProductsResourcesFeedbackComment } from '../product/product';
import {ICON_3D, ICON_3D_WITH_SOURCE} from './enums';

@Injectable()
export class MapperService {

  constructor(private utils: UtilsService) { }

  public getJobItemImages(artistsJobItem: ArtistJobItem): Array<NativeResourceSet> {
    const jobImages = [] as Array<NativeResourceSet>;
    if (artistsJobItem.artists_items && artistsJobItem.artists_items.length) {
      for (const artistsItem of artistsJobItem.artists_items) {
        if (artistsItem.artists_items_data && artistsItem.artists_items_data.length) {
          for (const artistsItemData of artistsItem.artists_items_data) {
            jobImages.push({
              big: artistsItemData.url,
              small: artistsItemData.small_image_url,
              type: MediaTag.IMAGE,
              sort_index: artistsItemData.sort_index,
              id: artistsItemData.id
            });
          }
        }
      }
    }
    return jobImages;
  }

  public getJobImages(job: Job): Array<NativeResourceSet> {
    let jobImages: NativeResourceSet[] = [];
    const artistsJobItems = job.artists_jobs_items;

    if (artistsJobItems instanceof Array && artistsJobItems.length > 0) {
      for (const artistsJobItem of artistsJobItems) {
        jobImages = jobImages.concat(this.getJobItemImages(artistsJobItem));
      }
    }
    jobImages.forEach(i => {
      if (i.sort_index === null) {
        i.sort_index = i.type === MediaTag.MODEL ? 1 : jobImages.length;
      }
    });
    jobImages.sort((a: NativeResourceSet, b: NativeResourceSet) => {
      if (a.sort_index < b.sort_index) {
        return -1;
      } else if (a.sort_index > b.sort_index) {
        return 1;
      } else {
        return 0;
      }
    });
    return jobImages;
  }

  public getImagesFromOffer(offer: Offer, artistsOffersItemsIndex?: number): Array<NativeResourceSet> {
    const images: NativeResourceSet[] = [];
    let artistsItemData: ArtistsItemData[] = [];
    let artistsOffersResources: OfferResource[] = [];

    if (typeof artistsOffersItemsIndex === 'number') {
      artistsItemData = offer.artists_offers_items[artistsOffersItemsIndex].artists_items[0].artists_items_data;
      artistsOffersResources = offer.artists_offers_items[artistsOffersItemsIndex].artists_offers_resources;
    } else {
      offer.artists_offers_items.forEach(aoi => {
        artistsItemData = artistsItemData.concat(aoi.artists_items[0].artists_items_data);
        artistsOffersResources = artistsOffersResources.concat(aoi.artists_offers_resources);
      });
    }
    let hasSort = false;
    if (artistsItemData && artistsItemData.length) {
      for (const aid of artistsItemData) {
        images.push({
          id: aid.id,
          item_id: offer.artists_offers_items[artistsOffersItemsIndex].artists_items[0].id,
          small: aid.small_image_url,
          big: aid.url,
          type: MediaTag.IMAGE,
          sort_index: typeof aid.sort_index === 'number' ? aid.sort_index : artistsItemData.length
        });
        if (typeof aid.sort_index === 'number') {
          hasSort = true;
        }
      }
    }
    if (artistsOffersResources && artistsOffersResources.length) {
      if (!hasSort && images.length) {
        for (let i = 0; i < images.length; i++) {
          images[0].sort_index = i * 2;
        }
      }
      artistsOffersResources.forEach(aor => {
        const artistsOffersResource = {
          big: aor.viewer_url,
          small: this.isOfferResourceHaveSourceFile(aor) ? ICON_3D_WITH_SOURCE : ICON_3D,
          type: this.utils.getMediaTagByResource(aor),
          viewer_type: aor.viewer_type,
          sort_index: 1,
          id: aor.id,
          description: this.getOfferResourceDescription(aor)
        };
        // in case we have a geometry file we want to reduce exposure
        if (artistsOffersResource.type === MediaTag.MODEL && artistsOffersResource.big.indexOf('.glb') === -1) {
          artistsOffersResource.big = this.utils.setUrlParam(artistsOffersResource.big, 'exp', '0.2');
        }
        images.push(artistsOffersResource);
      });
    }
    images.sort((a: NativeResourceSet, b: NativeResourceSet) => {
      return a.sort_index - b.sort_index;
    });
    return images;
  }

  private getOfferResourceDescription(aor: OfferResource): string {
    if (this.isOfferResourceHaveSourceFile(aor)) {
      return `Contains a ${this.utils.getSourceFileSoftware(aor.artists_jobs_resources_source_files[0])} source file`;
    }
    return null;
  }

  private isOfferResourceHaveSourceFile(aor: OfferResource): boolean {
    return !!aor.artists_jobs_resources_source_files?.length;
  }

  public getFeedbacksFromJob(job: Job): Array<ArtistsResourcesFeedback> {
    let feedbacks = [] as Array<ArtistsResourcesFeedback>;
    const artistsJobItems: ArtistJobItem[] = this.utils.objectifierGet('artists_jobs_items', false, job);
    if (artistsJobItems) {
      for (const aji of artistsJobItems) {
        const artistsJobsResource: Resource[] = this.utils.objectifierGet('artists_jobs_resources', false, aji);
        if (artistsJobsResource) {
          feedbacks = feedbacks.concat(this.getFeedbacksFromResources(artistsJobsResource));
        }
      }
    }
    return feedbacks;
  }

  public getFeedbacksFromResources(resources: Array<Resource>): Array<ArtistsResourcesFeedback> {
    const feedbacks: Array<ArtistsResourcesFeedback> = [];

    if (resources) {
      for (const ajr of resources) {
        const artistsResourceFeedbacks: ArtistsResourcesFeedback[] = this.utils.objectifierGet('artists_resources_feedback', false, ajr);
        if (artistsResourceFeedbacks) {
          for (const feedback of artistsResourceFeedbacks) {
            feedbacks.push(feedback);
          }
        }
      }
    }
    feedbacks.sort((a, b) => {
      const c = new Date(a.created_at).getTime();
      const d = new Date(b.created_at).getTime();
      return d - c;
    });
    return feedbacks;
  }

  getProductImages(product: Product): Array<NativeResourceSet> {
    const res = [] as Array<NativeResourceSet>;
    if (product.products_data instanceof Array && product.products_data.length) {
      product.products_data = this.utils.deepCopyByValue(product.products_data);
      product.products_data.forEach(pd => {
        res.push({
          id: pd.id,
          big: pd.url,
          small: pd.small_image_url,
          sort_index: pd.sort_index,
          type: MediaTag.IMAGE
        });
      });
    } else if (product.products_resources instanceof Array && product.products_resources.length) {
      product.products_resources.forEach(pd => {
        res.push({
          big: pd.resource_small,
          small: pd.resource_big,
          type: MediaTag.IMAGE
        });
      });
    }
    res.map(i => i.sort_index = i.sort_index === null ? res.length : i.sort_index);
    res.sort((a: NativeResourceSet, b: NativeResourceSet) => a.sort_index - b.sort_index);

    return res;
  }

  artistsFeedbackCommentToProductsFeedbacksComment(artistsFeedback: ArtistsResourceFeedbackComment[]): ProductsResourcesFeedbackComment[] {
    const arr: ProductsResourcesFeedbackComment[] = [];

    if (artistsFeedback && artistsFeedback.length) {
      artistsFeedback.forEach(c => {
        arr.push({
          comment: c.comment,
          created_at: c.created_at,
          feedback_id: c.feedback_id,
          updated_at: c.updated_at,
          user_id: c.artist_user_id
        } as ProductsResourcesFeedbackComment);
      });
    }
    return arr;
  }

  productsFeedbackToArtistsFeedbacks(artistsFeedback: ProductsResourcesFeedback): ArtistsResourcesFeedback {
    const res = {
      created_at: artistsFeedback.created_at,
      updated_at: artistsFeedback.updated_at,
      feedback_type: artistsFeedback.feedback_type,
      feedback_sub_type: artistsFeedback.feedback_sub_type,
      fixed: artistsFeedback.fixed,
      id: artistsFeedback.id,
      notes: artistsFeedback.notes,
      opened_by: artistsFeedback.opened_by,
      resource_id: artistsFeedback.resource_id,
      screenshot: artistsFeedback.screenshot,
      title: artistsFeedback.title,
      artists_users: artistsFeedback.users,
      artists_resources_feedback_comments: this.productsFeedbackCommentToArtistsFeedbacksComment(artistsFeedback.products_resources_feedback_comments)
    } as ArtistsResourcesFeedback;

    return res;
  }

  productsFeedbackCommentToArtistsFeedbacksComment(artistsFeedback: ProductsResourcesFeedbackComment[]): ArtistsResourceFeedbackComment[] {
    const arr: ArtistsResourceFeedbackComment[] = [];

    if (artistsFeedback && artistsFeedback.length) {
      artistsFeedback.forEach(c => {
        arr.push({
          comment: c.comment,
          created_at: c.created_at,
          feedback_id: c.feedback_id,
          updated_at: c.updated_at
        } as ArtistsResourceFeedbackComment);
      });
    }

    return arr;
  }
}
