export interface MeshLibrary {
    id: number;
    sub_category_id: number;
    description: string;
    minutes_to_model: number;
    image_url: string;
    mesh_libraries_parts: Array<ArtistMesh>;
}

export interface ArtistMesh {
    id: number,
    library_id: number,
    thumbnail_url: string,
    geometry_url: string,
    name: string,
    is_archived: boolean
}

export enum MeshLibraryState {
    SUB_CATEGORY = 1,
    SECTIONS = 2,
    PARTS = 3
}

export interface MeshLibraryWrapper {
    subCatdesc: string;
    meshLibraries: Array<ArtistLibrary>;
    selectedMesh: ArtistMesh;
}

export interface AllLibraries {
    allLibraries: Array<ArtistLibrary>
}

 
export interface ArtistLibrary {
    id: number,
    name: string,
    is_archived: boolean,
    artists_meshes: Array<ArtistMesh>,
    selectedCategories: Array<string>,
    selectedSubCategories: Array<string>,
    selectedCategoriesName: Array<string>,
    selectedSubCategoriesName: Array<string>,
    products_sub_categories: Array<ProductSubCategory>
}

export interface ProductSubCategory {
    id: number;
    category_id: number;
    description: string;
}