import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProductFilterOptions } from '../product';
import { GraphqlService } from '../../communication/graphql.service';

@Injectable()
export class ComplexityGuidesResolve  {

    constructor(private gql: GraphqlService) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        const options: ProductFilterOptions = {
            limit: 1000
        }
        return this.gql.complexityGuides(options);
    }
}
