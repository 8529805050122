<div class="color-sampling-result">
  <div class="color-sampling-result__title">
    <div class="disp-flex">
      Result
      <div *ngIf="testSucceeded" class="color-sampling-result--success">
        <mat-icon>check</mat-icon>
        Test Succeeded
      </div>
    </div>
    <a class="color-sampling-result__help hexa-link" (click)="openHelp()">Need help?</a>
  </div>

  <div class="color-sampling-result__content-container scrollbar" [ngClass]="{'color-sampling-result__content-container--no-result': !sample}">
    <div class="color-sampling-result__status"
         [ngClass]="{'color-sampling-result__status--success': sampleStatus === SampleStatus.success,
                     'color-sampling-result__status--failed': sampleStatus === SampleStatus.failed}">
      {{ sampleStatusText[sampleStatus] }}
    </div>

    <div class="color-sampling-result__result">
      <div class="color-sampling-result__result__description">
        <ng-container [ngSwitch]="sampleStatus">
          <ng-container *ngSwitchCase="SampleStatus.success" [ngTemplateOutlet]="descriptionSuccess"></ng-container>
          <ng-container *ngSwitchCase="SampleStatus.failed" [ngTemplateOutlet]="descriptionFailed"></ng-container>
          <ng-container *ngSwitchDefault [ngTemplateOutlet]="descriptionPlaceholder"></ng-container>
        </ng-container>
      </div>

      <div class="color-sampling-result__tiles-container">
        <div class="color-sampling-result__tile">
          <img *ngIf="referenceSampleImageUrl" [src]="referenceSampleImageUrl" alt="reference render">
          <div class="color-sampling-result__tile__label">reference</div>
        </div>
        <div class="color-sampling-result__tile">
          <img *ngIf="resourceSampleImageUrl" [src]="resourceSampleImageUrl" alt="resource render">
          <div class="color-sampling-result__tile__label">model</div>
        </div>
      </div>
    </div>

    <div *ngIf="sampleStatus === SampleStatus.failed || colorSamplingService.fixed" class="space-top-double">
      <mat-accordion>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Try to Fix
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Let's try to fix the model by changing the color (diffuse) map.
          </p>
          <button mat-raised-button class="thin uppercase lbr" color="primary" (click)="fix()" [ngClass]="{'active': fixing}">
            fix
          </button>
          <div *ngIf="colorSamplingService.fixed">
            <p>
              If you like the result please hit the "save fix" button. You cannot save your test result until saving the fix or reverting back.
            </p>
            <button mat-raised-button class="thin uppercase lbr" color="primary" (click)="saveFix()" [ngClass]="{'active': saving}">
              save fix
            </button>
            <p>
              You can revert to the original texture.
            </p>
            <button mat-raised-button class="thin uppercase lbr" color="primary" (click)="revert()" [ngClass]="{'active': reverting}">
              revert
            </button>
          </div>
          
          <ng-container *ngIf="colorSamplingService.aAS.compressedFiles?.started || jobService.exportProgress">
            <div class="pos-rel progress-wrap">
              <div
                *ngIf="colorSamplingService.aAS.compressedFiles?.started && !jobService.exportProgress">
                <div class="blink text-center text2 pos-rel export-progress">
                  <div class="in files">
                    compressed {{colorSamplingService.aAS.compressedFiles.ended}} /
                    {{colorSamplingService.aAS.compressedFiles.started}}
                    PNG images
                  </div>
                </div>
                <app-progress-bar
                  [percentage]="colorSamplingService.aAS.compressedFiles.ended / colorSamplingService.aAS.compressedFiles.started * 100">
                </app-progress-bar>
              </div>
              <div *ngIf="jobService.exportProgress == 100" class="blink text-center text2 pos-rel export-progress">
                <div class="in">
                  processing . . .
                </div>
              </div>
              <app-progress-bar *ngIf="jobService.exportProgress" [percentage]="jobService.exportProgress">
              </app-progress-bar>
            </div>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Color Table
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="sample?.deltaLAB">
            <table class="colors-table">
              <tr>
                <th></th>
                <th class="weight-normal">
                  Red
                </th>
                <th class="weight-normal">
                  Green
                </th>
                <th class="weight-normal">
                  Blue
                </th>
                <th class="weight-normal">
                  Yellow
                </th>
              </tr>
              <tr>
                <td>
                  Model:
                </td>
                <td class="val" [ngStyle]="{'border-color': sample.deltaLAB.resourceAverageAB.RGBY.redHeu}">
                  {{sample.deltaLAB.resourceAverageAB.RGBY.red | number : '1.0-1'}}
                </td>
                <td class="val" [ngStyle]="{'border-color': sample.deltaLAB.resourceAverageAB.RGBY.greenHeu}">
                  {{sample.deltaLAB.resourceAverageAB.RGBY.green | number : '1.0-1'}}
                </td>
                <td class="val" [ngStyle]="{'border-color': sample.deltaLAB.resourceAverageAB.RGBY.blueHeu}">
                  {{sample.deltaLAB.resourceAverageAB.RGBY.blue | number : '1.0-1'}}
                </td>
                <td class="val" [ngStyle]="{'border-color': sample.deltaLAB.resourceAverageAB.RGBY.yellowHeu}">
                  {{sample.deltaLAB.resourceAverageAB.RGBY.yellow | number : '1.0-1'}}
                </td>
              </tr>
              <tr>
                <td>
                  Reference:
                </td>
                <td class="val" [ngStyle]="{'border-color': sample.deltaLAB.referenceAverageAB.RGBY.redHeu}">
                  {{sample.deltaLAB.referenceAverageAB.RGBY.red | number : '1.0-1'}}
                </td>
                <td class="val" [ngStyle]="{'border-color': sample.deltaLAB.referenceAverageAB.RGBY.greenHeu}">
                  {{sample.deltaLAB.referenceAverageAB.RGBY.green | number : '1.0-1'}}
                </td>
                <td class="val" [ngStyle]="{'border-color': sample.deltaLAB.referenceAverageAB.RGBY.blueHeu}">
                  {{sample.deltaLAB.referenceAverageAB.RGBY.blue | number : '1.0-1'}}
                </td>
                <td class="val" [ngStyle]="{'border-color': sample.deltaLAB.referenceAverageAB.RGBY.yellowHeu}">
                  {{sample.deltaLAB.referenceAverageAB.RGBY.yellow | number : '1.0-1'}}
                </td>
              </tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <hexa-loader *ngIf="colorSamplingService.testing"></hexa-loader>
  </div>
</div>

<ng-template #descriptionPlaceholder>
  Result description will appear here
</ng-template>

<ng-template #descriptionSuccess>
  Well done, the color sample you submitted passed the test.
</ng-template>

<ng-template #descriptionFailed>
  <div *ngIf="brightnessFailMessage" class="color-sampling-result__result__description__fail">
    <img src="assets/sun.svg">
    <span>{{ brightnessFailMessage }}</span>
  </div>
  <div *ngIf="hueFailMessage" class="color-sampling-result__result__description__fail">
    <img src="assets/paint-bucket.svg">
    <span>{{ hueFailMessage }}</span>
  </div>
</ng-template>

<div class="color-sampling-result space-top" *ngIf="savedTests.length">
  <div class="color-sampling-result__title">
    <div class="disp-flex">
      Saved Tests
    </div>
  </div>
  <div class="color-sampling-result__content-container scrollbar logs">
  <div *ngFor="let t of savedTests; let last = last;" [ngClass]="{'space-bottom': !last}">
    <div class="parent w-100">
      <div>
        <span [ngClass]="{'hexa-success-color': t.isSuccess, 'danger-color': !t.isSuccess}" class="bold">
          {{t.isSuccess ? 'Success' : 'Failed'}}
        </span>
      </div>
      <div class="text-center">
        <img [src]="t.resource_sample_image_url" alt="reference">
      </div>
      <div class="text-center">
        <img [src]="t.reference_sample_image_url" alt="model">
      </div>
      <div class="text">
        {{t.issues}}
      </div>
      <div class="text-center text">reference</div>
      <div class="text-center text">model</div>
    </div>
  </div>
</div>