<div class="wrap" [ngClass]="{'drop': dropMode, 'dragging': isDragging}">
  <!-- (drag)="onDrag($event)" dragstart="onDragStart($event)" dragend="onDragEnd($event)" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)" -->
  <input type="file" [disabled]="disabled" [multiple]="miltiple" [accept]="accept" (change)="onFilesChange($event)"
    #fileInput title="&nbsp;" name="upload_files">
  <button mat-icon-button class="file-upload-button" [ngClass]="{'round': round}" type="button" color="accent" [disabled]="disabled"
    [ngStyle]="btnStyle" (click)="onClick()">
    <!-- (click)="fileInput.click()" -->
    <!-- <mat-icon>attach_file</mat-icon> -->
    <i *ngIf="icon" class="material-icons default-color file-upload-button-icon">{{icon}}</i>
    <span *ngIf="text" class="text default-color file-upload-button-title">{{text}}</span>
  </button>
  <span *ngIf="msg" class="msg default-color">{{msg}}</span>
</div>