import { Injectable, Injector } from '@angular/core';
import { Job, JobManagerChange, JobsFilterOptions } from './job';
import { FormControl } from '@angular/forms';
import { KeyValuePair, JobsTypes } from '../shared/enums';
import { User, UserAvailabilityStatus, UsersFilterOptions } from '../auth/user';
import { Retailer, RetailerBatch } from '../shared/retailers';
import { SortDirection, PagingFilter } from '../shared/paging';
import { EnumsService } from '../shared/enums.service';
import { JobService } from './job.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../shared/utils.service';
import { MatSelect } from '@angular/material/select';
import { MatRadioChange } from '@angular/material/radio';
import { InfiniteScroll, FieldType, InfiniteScrollDefaults } from 'app/shared/infinite-scroll';
import { Params } from '@angular/router';
import { InvoiceJob, Invoice } from 'app/payments/invoice';
import { Notification, NotificationType } from 'app/shared/notifications';
import { RestService } from 'app/communication/rest.service';
import { RolesHelperService } from 'app/auth/roles-helper.service';
import { AuthService } from 'app/auth/auth.service';
import { Category, CategoryNode, ProductSubCategory, SelectedCategories } from 'app/categories/category';
import { CategoriesService } from 'app/categories/categories.service';
import { GraphqlService } from 'app/communication/graphql.service';
import { PixelArtistsEvents } from 'app/shared/pixels';
import { PixelsService } from 'app/shared/pixels.service';
import { Subscription } from 'rxjs';
import { Group } from '../auth/group';

@Injectable({
  providedIn: 'root'
})
export class JobsService extends InfiniteScroll {
  static DEFAULT_FILTER = {
    limit: 24, offset: 0, is_desc: false, order_by: 'updated_at'
  } as JobsFilterOptions;
  static SORT_BY_DATE = 'status';
  public status: Array<KeyValuePair>;
  public priorities: Array<KeyValuePair>;
  public categories: Array<Category>;
  public subCategories: Array<ProductSubCategory>;
  public parentCategories: Array<Category>;
  public categoriesDictionary: { [id: number]: Category; };
  public artistsDictionary: { [id: number]: User; };
  public retailersDictionary: { [id: number]: Retailer; };
  public viewAllArtists: boolean;
  public lastQuery: JobsFilterOptions;
  public items: Array<Job>;
  public availabilityStatus: Array<KeyValuePair>;
  private sorts = SortDirection;
  private cardVisibilityIds: { [id: number]: boolean };
  private lastFilter: any;
  private subs: Array<Subscription>;
  private firstRun: boolean;
  public selectedItems: { [id: number]: Job; };
  public counter: number;
  public selectedArtistsItems: { [id: number]: InvoiceJob };
  public paymentsMethods: Array<KeyValuePair>;
  public initialized: boolean;
  public jobType: JobsTypes;
  public restoreTree: number;
  public matSortActive: string;
  public firstQuery: boolean;
  public retailerForBatch: Retailer;
  public selectedbatches: Array<RetailerBatch> = [];
  public viewAsArtist: boolean;
  public pauseReasons: Array<KeyValuePair>;
  private _currentMaxPriorityAvailable: number;
  private _hasDateBeforePriority: boolean;
  private selectedNode: { id: number, type: number, sub_categories: Array<number> };
  private _waitForManagerChange: boolean;
  private _onLogin: Subscription;
  private _onLogout: Subscription;
  constructor(
    private enums: EnumsService,
    private jobService: JobService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private rest: RestService,
    // private ngZone: NgZone,
    private rolesHelper: RolesHelperService,
    private auth: AuthService,
    private injector: Injector,
    private categoriesService: CategoriesService,
    private pixels: PixelsService,
    private gql: GraphqlService
  ) {
    super({
      chunkSize: JobsService.DEFAULT_FILTER.limit,
      rootRoute: '/jobs/list',
      graphFunction: 'jobs',
      defaults: {
        is_desc: JobsService.DEFAULT_FILTER.is_desc,
        order_by: JobsService.DEFAULT_FILTER.order_by
      } as InfiniteScrollDefaults
    }, injector);
    // utils, router, formBuilder, gql, broadcaster, body, ngZone);
    this.fieldTypes = {
      'retailer_id': FieldType.NumbersArray,
      'batch_id': FieldType.NumbersArray,
      'status': FieldType.StringsArray,
      'serial_number': FieldType.StringsArray,
      'tags': FieldType.StringsArray,
      'category_id': FieldType.NumbersArray,
      'sub_category_id': FieldType.NumbersArray,
      'complexity': FieldType.NumbersArray,
      'name': FieldType.StringsArray,
      'artist_user_id': FieldType.NumbersArray,
      'group_name': FieldType.StringsArray,
      'public': FieldType.Boolean,
      'paid': FieldType.Boolean,
      'is_archived': FieldType.Boolean,
      'is_paused': FieldType.Boolean,
      'manager_id': FieldType.NumbersArray,
      'hidden_feedback': FieldType.BooleanNullable,
      'type_id': FieldType.NumbersArray,
      'priority': FieldType.NumbersArray,
      'opened_texture': FieldType.Boolean,
      'quality_supervisor': FieldType.NumbersArray,
      'fix': FieldType.Boolean,
      'mini_job': FieldType.Boolean,
      'uploaded_resources': FieldType.Boolean,
      'reopen': FieldType.Boolean,
      'poly_type': FieldType.NumbersArray,
      'resource_type_id': FieldType.NumbersArray,
      'pause_reason_id': FieldType.NumbersArray
    };
    this.subs = [];
    this.cardVisibilityIds = {};
    this.restoreTree = 0;
    this._currentMaxPriorityAvailable = 0;
    this.artistsDictionary = {};
    this.retailersDictionary = {};
    // this.jobScopes = this.enums.getJobScopes();
    this.status = this.enums.getJobStatus();
    this.priorities = this.enums.getPriorities();
    this.paymentsMethods = this.enums.getPaymentsMethod();
    this.availabilityStatus = this.enums.getUserAvailabilityStatus(this.rolesHelper.isQualitySupervisorLogedin());
    this.matSortActive = JobsService.DEFAULT_FILTER.order_by;
    this.initUser();
    this.firstRun = true;
    this.jobType = null;
  }

  get waitForManagerChange() {
    return this._waitForManagerChange;
  }

  get currentMaxRankAvailable() {
    return this._currentMaxPriorityAvailable;
  }

  get hasDateBeforePriority() {
    return this._hasDateBeforePriority;
  }

  get isAdmin() {
    return this.rolesHelper.isAdminLogedin();
  }
  get isSU() {
    return this.rolesHelper.isSULogedin();
  }

  initUser() {
    if (this._onLogin) {
      this._onLogin.unsubscribe();
      delete this._onLogin;
    }
    this.viewAsArtist = true;
    if (this.rolesHelper.isJobManager()) {
      this.subs.push(this.broadcaster.on('onJobManagerChange').subscribe(this.onJobManagerChange.bind(this)));
      if (this.auth.user)
        this.syncUserStatus(this.auth.user.availability_status);
    }
    else if (this.isAdmin) {
      this.viewAsArtist = false;
    }
    if (this.auth.isloggedIn()) {
      if (!this._onLogout) {
        this._onLogout = this.broadcaster.on('onLogout').subscribe(() => {
          this._onLogin = this.broadcaster.on('onLogin').subscribe(this.initUser.bind(this));
          this._onLogout.unsubscribe();
          delete this._onLogout;
        });
      }
    }
    else
      this._onLogin = this.broadcaster.on('onLogin').subscribe(this.initUser.bind(this));
  }
  // setDefaultsByUser() {
  //   if (!this.filter.controls.artist_user_id.value.length)
  //     this.filter.controls.artist_user_id.setValue((this.isAdmin && !this.isSU) && this.auth.user ? [] : [this.auth.user.id]);
  //   if (!this.filter.controls.complexity.value.length)
  //     this.filter.controls.complexity.setValue(this.jobService.getDefaultComplexity());
  // }

  onSelectItemTree(node: CategoryNode) {
    this.selectedNode = { id: node.item.id, type: node.item.type, sub_categories: [] };
  }

  onViewAsArtist(val?) {
    if(val){
      this.viewAsArtist = val.checked;
    }
    this.initFilter();
  }

  initFilter() {
    super.initFilter();
    this.ignoreChanges = true;
    this.toggleIgnoreChanges(true);
    this.filter.addControl('name', new FormControl([]));
    this.filter.addControl('order_by', new FormControl(!this.viewAsArtist && this.isPriorityUser() ? 'feedback_order' : JobsService.DEFAULT_FILTER.order_by));
    this.filter.addControl('is_desc', new FormControl(!this.viewAsArtist && this.isPriorityUser() ? true : JobsService.DEFAULT_FILTER.is_desc));
    this.filter.addControl('complexity', new FormControl([]));
    this.filter.addControl('artist_user_id', new FormControl((!this.viewAsArtist && this.isAdmin && this.auth.user) ? [] : [this.auth.user.id]));
    this.filter.addControl('group_name', new FormControl([]));
    this.filter.addControl('status', new FormControl([]));
    this.filter.addControl('priority', new FormControl([]));
    this.filter.addControl('serial_number', new FormControl([]));
    this.filter.addControl('retailer_id', new FormControl([]));
    this.filter.addControl('batch_id', new FormControl([]));
    this.filter.addControl('paid', new FormControl(null));
    this.filter.addControl('is_archived', new FormControl(false));
    this.filter.addControl('is_paused', new FormControl(!this.viewAsArtist && this.rolesHelper.doesUserHasPermission('Manage Jobs') && this.auth.user ? false : null));
    this.filter.addControl('category_id', new FormControl(null));
    this.filter.addControl('sub_category_id', new FormControl(null));
    this.filter.addControl('manager_id', new FormControl((!this.viewAsArtist && this.rolesHelper.doesUserHasPermission('Manage Jobs') && this.auth.user) ? [this.auth.user.id] : []));
    this.filter.addControl('quality_supervisor', new FormControl([]));
    this.filter.addControl('hidden_feedback', new FormControl(null));
    this.filter.addControl('type_id', new FormControl(null));
    this.filter.addControl('opened_texture', new FormControl(null));
    this.filter.addControl('is_poc', new FormControl(null));
    this.filter.addControl('fix', new FormControl(null));
    this.filter.addControl('mini_job', new FormControl(null));
    this.filter.addControl('tags', new FormControl(null));
    this.filter.addControl('uploaded_resources', new FormControl(null));
    this.filter.addControl('reopen', new FormControl(null));
    this.filter.addControl('poly_type', new FormControl([]));
    this.filter.addControl('resource_type_id', new FormControl([]));
    this.filter.addControl('pause_reason_id', new FormControl([]));
    if (this.auth.user.availability_status)
      this.syncUserStatus(this.auth.user.availability_status);

    this.filter.valueChanges.subscribe((data) => {
      delete this._hasDateBeforePriority;
      if (!this.ignoreChanges && self.event && self.event.isTrusted) {
        for (const i in this.lastFilter) {
          if (this.lastFilter[i] !== data[i]) {
            let filterData = data[i];
            if (i === 'sub_category_id') {
              this.selectedNode.sub_categories = data[i];
              filterData = this.selectedNode;
            }
            this.pixels.sendPixel({
              event: 'filter',
              filter_name: i,
              //    event_value: data[i],
              filter_value: filterData
            });
          }
        }
      }
      this.lastFilter = this.utils.deepCopyByValue(this.filter.getRawValue());
    });
    // this.filter.controls.complexity.setValue(this.jobService.getDefaultComplexity());
    // if (!this.auth.user)
    //   this.onUserRefreshed = this.broadcaster.on('userRefreshed').subscribe(this.setDefaultsByUser.bind(this));
    if (this.isSU || this.isPriorityUser()) {
      if (this.fixSearch() && !this.firstRun) {
        this.searchByQuery(true);
      }
      this.firstQuery = true;
    }
    this.firstRun = false;
    // if (this.isPriorityUser()) {
    //   const priority = 'ai.priority';
    //   this.filter.controls.order_by.setValue(priority);
    //   this.matSortActive = priority;
    //   this.filter.controls.priority.setValue([Priority.HIGHEST]);
    //   this.filter.controls.hidden_feedback.setValue(null);
    //   if (this.rolesHelper.isQualitySupervisorLogedin())
    //     this.filter.controls.status.setValue(['Pending Secondary Review']);
    //   else
    //     this.filter.controls.status.setValue(['Job Pending Review']);
    //   let rank = {} as MatSelect;
    //   this.onMaxRankChange(rank);
    //   this.hasPriorityJobs = true;
    // }
    if (this.filter.controls.artist_user_id.value.length > 0 && !this.artistsDictionary[this.filter.controls.artist_user_id.value]) {
      this.fetchUsers(this.filter.controls.artist_user_id.value);
    }

    this.lastQuery.status = this.filter.controls.status.value;
    this.lastQuery.priority = this.filter.controls.priority.value;
    this.toggleIgnoreChanges(false);
    this.lastFilter = this.utils.deepCopyByValue(this.filter.getRawValue());
  }

  setRetailerForBatch() {
    let retailers = this.filter.controls.retailer_id.value;
    this.retailerForBatch = {} as Retailer;
    if (retailers.length === 1) {
      this.retailerForBatch.id = retailers[0];
    }
  }

  fixSearch(): boolean {
    let changed = false;
    // if (!this.filter || !this.filter.controls.status)
    //   return changed;
    if (this.isPriorityUser()) {
      // const priority = 'ai.priority', isDesc = true;
      let priority = 'feedback_order', isDesc = true;
      let status = 'Job Pending Review';
      if (this.rolesHelper.isQualitySupervisorLogedin()){
        status = 'Pending Secondary Review';
        //priority = 'ai.priority';
      }
      this.toggleIgnoreChanges(true);
      if (this.filter.controls.order_by.value != priority) {
        this.filter.controls.order_by.setValue(priority);
        this.matSortActive = priority;
        changed = true;
      }
      if (this.filter.controls.is_desc.value != isDesc) {
        this.filter.controls.is_desc.setValue(isDesc);
        changed = true;
      }
      if (this.filter.controls.status.value.length !== 1 || (this.filter.controls.status.value.length === 1 && this.filter.controls.status.value[0] !== status)) {
        this.filter.controls.status.setValue([status]);
        changed = true;
      }
      if (!this.viewAsArtist && this.rolesHelper.isQualitySupervisorLogedin() && (this.filter.controls.quality_supervisor.value.length !== 1 || (this.filter.controls.quality_supervisor.value.length === 1 && this.filter.controls.quality_supervisor.value[0] !== this.auth.user.id))) {
        this.filter.controls.quality_supervisor.setValue([this.auth.user.id]);
        changed = true;
      }
      if (!this.viewAsArtist && this.rolesHelper.doesUserHasPermission('Manage Jobs') && (this.filter.controls.manager_id.value.length !== 1 || (this.filter.controls.manager_id.value.length === 1 && this.filter.controls.manager_id.value[0] !== this.auth.user.id))) {
        this.filter.controls.manager_id.setValue([this.auth.user.id]);
        changed = true;
      }
      if (this.filter.controls.artist_user_id.value[0] === this.auth.user.id) {
        this.viewAsArtist = true;
        changed = true;
      }
      // if (this.filter.controls.poly_type.value) {
      //   this.filter.controls.poly_type.setValue([]);
      //   changed = true;
      // }
      this.syncUserStatus(this.auth.user.availability_status);
    }
    if (this.isSU) {
      this.toggleIgnoreChanges(true);
      const priority = 'updated_at', isDesc = true;
      if (this.matSortActive != priority) {
        this.filter.controls.order_by.setValue(priority);
        this.matSortActive = priority;
        changed = true;
      }
      if (this.filter.controls.is_desc.value != isDesc) {
        this.filter.controls.is_desc.setValue(isDesc);
        changed = true;
      }
    }
    // if (changed)
    //   this.searchByQuery(true);
    this.toggleIgnoreChanges(false);
    return changed;
  }

  toggleIgnoreChanges(state: boolean) {
    this.ignoreChanges = state;
  }

  isPriorityUser(): boolean {
    return this.isAdmin && !this.isSU;
  }

  initJobsView(jobs: Array<Job>, itemsCount: number) {
    this.items = this.utils.deepCopyByValue(jobs);
    this.itemsCount = itemsCount;
    this.mapUI(this.items);
  }

  windowScroll80() {
    this.scrollOffset = this.items.length;
    if (!this.lastQuery)
      this.initPagination();
    this.searchByQuery();
  }

  afterItemsChanged(forceRefresh: boolean) {
    super.afterItemsChanged(forceRefresh);
  }

  onItemsChange(forceRefresh: boolean) {
    // If a FM is
    if (!this.viewAsArtist && this.rolesHelper.isJobManager() && this.items.length > 1) {
      this.items = [this.items[0]];
    }
    this._currentMaxPriorityAvailable = 0;
    delete this._hasDateBeforePriority;
    // var t0 = performance.now();
    super.onItemsChange(forceRefresh);
    this.mapUI(this.items);
    // this.broadcaster.broadcast('detectChanges');
    // var t1 = performance.now();
    // console.log("onItemsChange took " + (t1 - t0) + " milliseconds.");
  }

  afterFirstSearch() {
    if ((this.isSU || this.isPriorityUser()) && this.firstQuery && !this.itemsCount) {
      if (this.fixSearch())
        this.searchByQuery(true);
      this.firstQuery = false;
    }
    // if (this.isPriorityUser() && this.hasPriorityJobs && !this.itemsCount) {
    //   this.filter.controls.order_by.setValue(JobsService.DEFAULT_FILTER.order_by);
    //   this.matSortActive = 'due_date';
    //   this.filter.controls.priority.setValue([]);
    //   this.hasPriorityJobs = false;
    // }
  }

  onCategoriesChange(selected: SelectedCategories) {
    this.toggleIgnoreChanges(true);
    this.filter.controls['category_id'].setValue(selected.categories.map(c => c.id));
    this.toggleIgnoreChanges(false);
    this.filter.controls['sub_category_id'].setValue(selected.subCategories.map(c => c.id));
  }

  // onCategoryChange(category: Category, evt?: MatSelectChange) {
  //   if (category) {
  //     let exist = this.filter.controls.category_id.value || [];
  //     const parents = this.categoriesService.getParentsByCategoriesId([category.id]);
  //     if (exist.find(i => i == category.id)) return;
  //     if (!Object.keys(parents).length) return;
  //     for (let i in parents) {
  //       exist = exist.concat(parents[i].children);
  //     }
  //     exist = Array.from(new Set(exist));
  //     this.toggleIgnoreChanges(true);
  //     this.filter.controls.category_id.setValue(exist);
  //     this.initPagination();
  //     this.searchByQuery(true);
  //     this.toggleIgnoreChanges(false);
  //     this.setParentCategoriesNames();
  //   }
  // }

  mapUI(items: Array<Job>) {
    items.forEach((job) => {
      if (!job.UI)
        this.jobService.mapUI(job, false);
      if (!job.is_paused) {
        this._currentMaxPriorityAvailable = Math.max(this._currentMaxPriorityAvailable, job.UI.priority);
        if (typeof this._hasDateBeforePriority === 'undefined')
          this._hasDateBeforePriority = job.sorted_by == JobsService.SORT_BY_DATE;
      }
    });
  }

  getSortDirKeys(): Array<string> {
    let keys = Object.keys(this.sorts);
    return keys.slice(keys.length / 2);
  }

  onArtistChange(artist: User) {
    if (artist) {
      this.artistsDictionary[artist.id] = artist;
      let artists = this.filter.controls.artist_user_id.value;
      if (!(artists instanceof Array))
        artists = [];
      if (artists.filter(r => r == artist.id).length > 0)
        return;
      artists.push(artist.id);
      this.filter.controls.artist_user_id.setValue(artists);
    }
  }

  public onGroupChange(group: Group): void {
    if (group) {
      let groups: string[] = this.filter.controls.group_name.value;

      if (!Array.isArray(groups)) {
        groups = [];
      }

      groups.push(group.group_name);
      this.filter.controls.group_name.setValue(groups);
    }
  }

  onManagerChange(manager: User) {
    if (manager) {
      this.artistsDictionary[manager.id] = manager;
      let managers = this.filter.controls.manager_id.value;
      if (!(managers instanceof Array))
        managers = [];
      if (managers.filter(r => r == manager.id).length > 0)
        return;
      managers.push(manager.id);
      this.filter.controls.manager_id.setValue(managers);
    }
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer) {
      let retailers = this.filter.controls.retailer_id.value;
      if (!(retailers instanceof Array))
        retailers = [];
      if (retailers.filter(r => r == retailers.id).length > 0)
        return;
      retailers.push(retailer.id);
      this.filter.controls.retailer_id.setValue(retailers);
      this.retailersDictionary[retailer.id] = retailer;

      this.setRetailerForBatch();
    }
  }

  removeArtist(aid: number) {
    let artists = this.filter.controls.artist_user_id.value;
    if (!(artists instanceof Array))
      artists = [];
    artists.splice(artists.indexOf(aid), 1);
    this.filter.controls.artist_user_id.setValue(artists);
  }

  public removeGroup(group: string): void {
    let groups: string[] = this.filter.controls.group_name.value;

    if (!Array.isArray(groups)) {
      groups = []
    } else {
      groups.splice(groups.indexOf(group), 1);
    }

    this.filter.controls.group_name.setValue(groups);
  }

  removeManager(mid: number) {
    let managers = this.filter.controls.manager_id.value;
    if (!(managers instanceof Array))
      managers = [];
    managers.splice(managers.indexOf(mid), 1);
    this.filter.controls.manager_id.setValue(managers);
  }

  removeRetailer(rid: number) {
    let retailers = this.filter.controls.retailer_id.value;
    if (!(retailers instanceof Array))
      retailers = [];
    retailers.splice(retailers.indexOf(rid), 1);
    this.filter.controls.retailer_id.setValue(retailers);

    this.setRetailerForBatch();
  }

  sortData(sort) {
    // if (this.hasPriorityJobs) {
    //   let obj: Notification = {
    //     text: 'Finish all your HIGHEST PRIORITY jobs first!',
    //     type: NotificationType.Info,
    //     action: 'OK'
    //   }
    //   this.broadcaster.broadcast('notifyUser', obj);
    //   return;
    // }
    this.toggleIgnoreChanges(true);
    this.filter.controls['order_by'].setValue(sort.active);
    this.toggleIgnoreChanges(false);
    this.filter.controls['is_desc'].setValue(sort.direction == 'desc');
    this.pixels.sendPixel({
      event: 'sort',
      sort_order: sort.direction,
      button_name: sort.active
    });
  }

  onMaxRankChange(rank: MatSelect) {
    if (rank) {
      let arr = [];
      if (typeof rank.value === 'number') {
        for (let i = 0; i <= rank.value; i++) {
          arr.push(i);
        }
      }
      this.filter.controls.complexity.setValue(arr);
    }
    else
      this.filter.controls.complexity.setValue(null);
  }

  setStateFromURL(params?: Params) {
    this.initFilter();
    super.setStateFromURL(params);
    if (this.filter.controls.type_id.value && this.filter.controls.type_id.value.length)
      this.jobType = this.filter.controls.type_id.value[0];
    // if (this.rolesHelper.doesUserHasPermission('Manage Jobs')) {
    //   this.filter.controls.manager_id.setValue([this.auth.user.id]);
    //   // if (this.hasPriorityJobs)
    //   //   this.filter.controls.hidden_feedback.setValue(null);
    //   // else
    //   //   this.filter.controls.hidden_feedback.setValue(true);
    // }
    // else if (this.isSU) {
    //   this.filter.controls.hidden_feedback.setValue(false);
    // }
    // if (!this.rolesHelper.doesUserHasPermission('Manage Jobs') && this.isSU) {
    //     this.filter.controls.hidden_feedback.setValue(false);
    // }
    // if (this.rolesHelper.isQualitySupervisorLogedin()) {
    //   this.filter.controls.quality_supervisor.setValue([this.auth.user.id]);
    // }
    let uids = [];
    if (this.filter.controls.artist_user_id.value) {
      for (let aid of this.filter.controls.artist_user_id.value) {
        if (!this.artistsDictionary[aid])
          uids.push(aid);
      }
    }
    if (this.filter.controls.manager_id.value) {
      for (let aid of this.filter.controls.manager_id.value) {
        if (!this.artistsDictionary[aid])
          uids.push(aid);
      }
    }
    if (uids.length)
      this.fetchUsers(uids);
    // this.setParentCategoriesNames();

    this.setRetailerForBatch();

    if (this?.filter?.controls?.batch_id?.value.length > 0) {
      this.filter.controls.batch_id?.value.forEach(b => {
        this.selectedbatches.push({ batch_id: b });
      });

    }
  }

  fetchUsers(uids: Array<number>) {
    if (this.rolesHelper.doesUserHasPermission('View Users')) {
      let options = {
        show_all: true,
        id: uids
      } as UsersFilterOptions;
      this.gql.users(options).subscribe(
        res => {
          if (res.data.allArtistsUsers)
            res.data.allArtistsUsers.rows.forEach(user => {
              this.artistsDictionary[user.id] = user;
            })
        }
      );
    }
  }

  getSearchQuery(options?: PagingFilter): any {
    let res = super.getSearchQuery(options);
    if (!this.isAdmin && this.auth.user) {
      // if (!res.artist_user_id)
      //   res.artist_user_id = [];
      // res.artist_user_id.push(this.auth.user.id);
      this.artistsDictionary[this.auth.user.id] = this.auth.user;
    }
    return res;
  }

  emitSelectedItems() {
    this.selectedArtistsItems = {};
    for (let i in this.selectedItems) {
      const auid = this.selectedItems[i].artists_users[0].id;
      if (!this.selectedArtistsItems[auid])
        this.selectedArtistsItems[auid] = {
          invoice: {} as Invoice,
          jobs: [] as Array<Job>
        };
      this.selectedArtistsItems[auid].jobs.push(this.selectedItems[i]);
      if (typeof this.selectedItems[i].price === 'number') {
        if (typeof this.selectedArtistsItems[auid].invoice.amount !== 'number')
          this.selectedArtistsItems[auid].invoice.amount = 0;
        this.selectedArtistsItems[auid].invoice.amount += this.selectedItems[i].price;
      }
    }
    // this.onSelectedItemsChange();
    // this.selectedItemsChange.emit(this.selectedItems);
    this.counter++;
  }

  hasSelectedItems(): boolean {
    return !!(this.selectedItems && Object.keys(this.selectedItems).length > 0);
  }

  toggleItem(e: any, item: Job) {
    if (item.UI.isChecked)
      this.selectedItems[item.id] = item;
    else
      delete this.selectedItems[item.id];
    this.emitSelectedItems();
  }

  removeSelectedItems(e: any, item: Job) {
    item.UI.isChecked = false;
    this.toggleItem(e, item);
  }

  removeAllSelectedItems(e, artistId: number) {
    for (let i in this.selectedArtistsItems[artistId]) {
      if (this.selectedArtistsItems[artistId] && this.selectedArtistsItems[artistId].jobs) {
        this.selectedArtistsItems[artistId].jobs.forEach(j => {
          j.UI.isChecked = false;
          this.toggleItem(e, j);
        });
      }
    }
  }

  removeValueFromCategories(parendCategory: string) {
    let value = this.filter.controls.category_id.value;
    if (!(value instanceof Array))
      value = [];
    const relatedCategories = this.categoriesService.getCachedCategories().filter(i => i.parent_description == parendCategory);
    for (let i = 0; i < value.length; i++) {
      if (relatedCategories.find(j => j.id == value[i]))
        value.splice(i--, 1);
    }
    this.toggleIgnoreChanges(true);
    this.filter.controls.category_id.setValue(value);
    this.initPagination();
    this.searchByQuery(true);
    this.toggleIgnoreChanges(false);
    // this.setParentCategoriesNames();
  }

  // searchByQuery(forceRefresh = false, navigateByQuery = true): Observable<any> | Promise<any> | any {
  //   if (forceRefresh)
  //     this._currentMaxPriorityAvailable = 0;
  //   return super.searchByQuery(forceRefresh, navigateByQuery);
  // }

  onJobTypeChange(evt: MatRadioChange) {
    this.jobType = evt.value;
    this.filter.controls.type_id.setValue(this.jobType ? [this.jobType] : []);
  }



  saveMass(reopen: boolean) {
    let payload = {
      values: {
        reopen: reopen
      },
      where: {
        id: Object.keys(this.selectedItems).map(i => parseInt(i))
      }
    };
    this.rest.jobs('put', payload).subscribe(
      () => {
        let obj: Notification = {
          text: 'All jobs saved',
          type: NotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', obj);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure saving jobs')
    )
  }

  /**
   * The method creates the card visibility event
   * @param jobId - the Id of the card
   * @param cardPosition - the position index of the card on jobs page
   */
  onCardVisibility(jobId: number, cardPosition: number) {
    if (!this.cardVisibilityIds[jobId]) {
      this.cardVisibilityIds[jobId] = true;
      let pixelobj: PixelArtistsEvents = {
        event: 'card_visible',
        reference_id: jobId,
        scrollY: window.scrollY,
        card_type: 'job',
        offer_id: jobId,
        card_position: cardPosition
      };

      this.pixels.sendPixel(pixelobj);
    }
  }

  private syncUserStatus(status: UserAvailabilityStatus) {
    if (!status || !this.rolesHelper.isFeedbackMaster()) return;
    if (status == UserAvailabilityStatus.ONLINE || status == UserAvailabilityStatus.OFF_WORK) {
      this.viewAsArtist = false;
      if (this.rolesHelper.isJobManager())
        this.scrollLimit = 1;
      else
        this.scrollLimit = JobsService.DEFAULT_FILTER.limit;
    }
    else {
      this.viewAsArtist = true;
      this.scrollLimit = JobsService.DEFAULT_FILTER.limit;
    }
  }

  async changeUserStatus(status: UserAvailabilityStatus) {
    this._waitForManagerChange = true;
    await this.auth.changeUserStatus(status, this.auth.user);
    this.syncUserStatus(status);
    // this.fixSearch();
    // if (this.fixSearch())
    //   this.searchByQuery(true);
    this.onViewAsArtist();
    this._waitForManagerChange = false;
  }

  async onJobManagerChange(data: JobManagerChange) {
    if (data.managerId == this.auth.user.id) {
      this.syncUserStatus(this.auth.user.availability_status);
      if (!this.viewAsArtist)
        this.fixSearch();
    }
    this.searchByQuery(true);
    this._waitForManagerChange = false;
  }

  onPolyChange(val: string) {
    if (!val) {
      return;
    }

   
    if (val) {
      this.filter.controls.poly_type.setValue(val);
    }
  }

  onFormatChange(val: string) {
    if (!val) {
      return;
    }

   
    if (val) {
      this.filter.controls.resource_type_id.setValue(val);
    }
  }

  onDestroy() {
    this.firstQuery = true;
    this.items = [];
    this.itemsCount = 0;
    this._currentMaxPriorityAvailable = 0;
    delete this._hasDateBeforePriority;
    this.cardVisibilityIds = {};
    this.subs.forEach(s => s.unsubscribe());
    this.firstRun = true;
    // if (this.onUserRefreshed)
    //   this.onUserRefreshed.unsubscribe();
  }
}
