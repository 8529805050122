import { User } from "../auth/user";
import { NativeResourceSet, ItemPricing, PolygonSpecifications, PolyType } from "../offer/offers";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Retailer } from "../shared/retailers";
import { KeyValuePair, KeyValueAnyPair, FormatsType } from "../shared/enums";
import { ArtistsItem } from "../job/job";
import { DimensionsUnits } from "../shared/utils";
import { TaggingProjectsProduct, TaggingProjectsDataValue } from "app/tagging/tagging";
import { ProductSubCategory } from "app/categories/category";
import { Subject } from "rxjs";
import { AdjustmentsPreset } from "app/ui-components/asset-adjustments";
import { MediaTag, Resource } from "app/job/resource";
import { BundleCompareState } from "./offer-creation-tool/offer-creation-tool.interface";

export class Product {
    project_id?: number;
    brand?: string;
    category_id?: number;
    category?: string;
    color?: string;
    complexity?: number;
    estimated_geometry_hours?: number;
    // texture_complexity?: number;
    // geometry_complexity?: number;
    created_at?: Date;
    cs_id?: number;
    cs_render_id?: number;
    currency?: string;
    description?: string;
    dimensions?: string;
    width?: number;
    height?: number;
    length?: number;
    units?: DimensionsUnits;
    due_date?: Date;
    enabled?: number; // 0/1
    ic?: boolean;
    id: number;
    is_archived?: boolean;
    material?: string;
    mobile_enabled?: number; // 0/1
    name: string;
    c_name?: string;
    ppc_id?: number;
    price?: number;
    quantity?: number;
    reject_reason?: string;
    reject_id?: number;
    // resource_types: string;//"[1,2,5]",
    retailer_category_id?: number;
    retailer_id?: number;
    retailer_sub_category_id?: number;
    serial_number?: string;
    signature?: number;
    status?: string;
    status_id?: number;
    // sub_category_id?: number;
    sub_category?: string;
    updated_at?: Date;
    visible?: boolean;
    mobile_traffic?: number;
    desktop_traffic?: number;
    priority?: number;
    is_poc?: boolean;
    process_type?: number;
    products_data: Array<ProductData>;
    products_resources?: Array<ProductResource>;
    products_specs?: Array<ProductItemSpec>;
    products_tags?: Array<ProductTag>;
    products_urls?: Array<ProductUrl>;
    products_audit?: Array<ProductAudit>;
    artists_items?: Array<ArtistsItem>;
    retailers?: Array<Retailer>;
    products_categories?: Array<ProductCategory>;
    products_sub_categories_associations?: Array<ProductsSubCategoriesAssociation>;
    // products_sub_categories: Array<any>;
    products_resources_types?: Array<ProductResourceType>;
    products_renders_requests?: Array<ProductsRendersRequest>;
    products_polygon_specifications?: Array<ProductsPolygonSpecifications>;
    tagging_projects_products?: Array<TaggingProjectsProduct>;
    products_sub_categories_suggestions?: Array<ProductsSubCategoriesSuggestion>;
    products_identifiers?: Array<ProductIdentifier>;
    notes?: string;
    // ir: boolean;// item reviewd for machine learning
    // modeling_price: number;
    UI?: ProductUi;
    resources?: Array<number>;
    renderCount?: number;
    similar_approved_max_score?: number;
    similar_requested_max_score?: number;
    constructor() {
        this.retailers = [];
        this.products_data = [];
        this.products_resources = [];
        this.products_specs = [];
        this.products_tags = [];
        this.products_urls = [];
        this.products_audit = [];
        this.products_resources_types = [];
    }
}

export interface ProductsSubCategoriesSuggestion {
    user_id: number;
    sub_category_id: number;
    confidence: number;
    automated: boolean;
}

export interface ProductsPolygonSpecifications extends PolygonSpecifications {
    product_id: number;
}

export interface ProductsRendersRequest {
    id: number;
    status_id: ProductsRendersRequestStatus;
    creator_status_id?: number;

}

export enum ProductsRendersRequestStatus {
    REQUESTED = 1,
    IN_PROGRESS = 2,
    DELIVERED = 3,
    APPROVED = 8,
    CLIENT_FEEDBACK = 4
}

export interface ProductUi {
    images: Array<NativeResourceSet>;
    smallImages: Array<string>;
    checked: boolean;
    // grayedOut: boolean;
    safeUrl: SafeResourceUrl;
    resourceTypes: Array<number>;
    jobType: Array<number>;
    // downloads: Array<string>;
    similarItems: Array<SimilarItem>;
    artists_pricing: ItemPricing;
    productsResourcesTypes: Array<ResourceType>;
    CMSLink: string;
    relatedJobs: Array<KeyValueAnyPair>;
    bundleCompareState?: BundleCompareState;
}

export interface ProductTag {
    tag: string;
    confidence: number;
    product_id?: number;
}

export interface ProductData {
    created_at: Date;
    id: number;
    product_id: number;
    scan_tags: boolean;
    small_image_url: string;
    updated_at: Date;
    url: string;
    sort_index?: number;
    // contains_whole_product: boolean;
    // is_product_only: boolean;
    // comments: string;
    tagging_projects_data_values: Array<TaggingProjectsDataValue>;

}


export interface ProductResource {
    ajr_id: number;
    created_at: Date;
    id: number;
    product_id: number;
    resource_big: string;
    resource_default: string;
    resource_info: string;
    resource_mobile_order?: number;
    resource_order?: number;
    resource_small?: string;
    resource_text: string;
    resource_tour?: string;
    resource_type: MediaTag; // MediaTag (1: image, 2: 3D model, 3: video, 4: iframe)
    resource_ar_url?: string;
    resource_arcore_url?: string;
    viewer_resource_type: FormatsType;
    // resource_video: string;
    updated_at: Date;
    resource_enabled: number;// 0 or 1
    artists_jobs_resources: Array<Resource>;
    products_resources_feedback: Array<ProductsResourcesFeedback>;
    // products_resources_source_files: Array<ProductsResourcesSourceFile>;
    // products_resources_files_details: Array<ProductsResourcesFileSDetail>;
    // assets_details: Array<AssetsDetails>;
    resource_poly_type: PolyType;
    json_params?: string;
    viewer_type?: string;
    isChecked?: boolean;
}

export interface ArtistsJobsResources {
    id: number;
    approved: number;
    artists_jobs_items: Array<ArtistsJobsItems>;
}

export interface ArtistsJobsItems {
    id: number;
    item_id: number;
    job_id: number;
    artists_jobs: Array<ArtistsJobs>
}

export interface ArtistsOffers {
    id: number;
    status: string;
    is_artist_contractor: boolean;
}

export interface ArtistsJobs {
    artists_offers: ArtistsOffers;
    id: number;
    status: string;
}
export interface  ProductsResourcesFeedback {
    products_resources: ProductResource;
    id: number;
    created_at: Date;
    updated_at: Date;
    resource_id: number;
    feedback_type: number;
    feedback_sub_type: number;
    opened_by: number;
    title: string;
    screenshot: string;
    notes: string;
    fixed: boolean;
    products_resources_feedback_comments: Array<ProductsResourcesFeedbackComment>;
    users: Array<User>;
    automated_copy_to_artists_succeeded?: boolean;
}

export interface ProductsResourcesFeedbackComment {
    id: number;
    created_at: Date;
    updated_at: Date;
    feedback_id: number;
    user_id: number;
    comment: string;
    users: Array<User>;
}

export interface ProductAudit {
    id: number;
    product_id: number;
    user_id: number;
    reference_table: string;
    reference_id: number;
    action_type: ProductAuditActionMethod;
    field_name: string;
    old_value: any;
    new_value: any;
    created_at: Date;
    users: Array<User>;
}

export interface ProductAuditUIGroup {
    created_at: Date;
    items: Array<ProductAuditUI>;
}

export interface ProductAuditUI {
    created_at: Date;
    acronym: string;
    fullName: string;
    action: string;
    avatarUrl: string;
    onClickArgs: Array<any>;
}

export enum ProductAuditActionMethod {
    CREATE = 1,
    UPDATE = 2,
    DELETE = 3,
}

export interface ProductUrl {
    created_at?: Date;
    id?: number;
    path_id?: number;
    product_id?: number;
    updated_at?: Date;
    url: string;
}

export interface ProductItemSpec {
    created_at: Date;
    id: number;
    item_id: number;
    spec_key: string;
    spec_value: string;
    updated_at: Date;
}

export enum PrimeStatus {
    ALL = 0,
    ORDERS = 1,
    APPROVED = 2,
    DELETED = 3,
}

export interface ProductFilterOptions {
    cs_render_id?:  Array<number>;
    limit?: number;
    offset?: number;
    retailer_id?: Array<number>;
    batch_id?: number;
    status_id?: Array<number>; // CMS status id
    cs_id?: Array<number>; // creators status id
    enabled?: number; // 0/1
    mobile_enabled?: number; // 0/1
    serial_number?: Array<string>;
    tags?: Array<string>;
    category_id?: Array<number>;
    sub_category_id?: Array<number>;
    is_archived?: boolean;
    is_desc?: boolean;
    order_by?: string;
    date_range?: string;
    date_from?: number;
    date_to?: number;
    name?: Array<string>;
    project_id?: Array<string>;
    missing_project_id?: number | Array<number>;
    review_counter?: Array<number>;
    id?: Array<number>;
    contains_render_request?: boolean;
    notes?: string;
    sort_index?: Array<number>;
}

export interface AllProducts {
    rows: Array<Product>;
    count: number;
}

export interface PrimeStatusData {
    primeStatus: PrimeStatus;
    statusList: Array<KeyValuePair>;
    sum: number;
}

export interface ProductQuery {
    data: ProductQueryData;
}

export interface ProductQueryData {
    allProducts: AllProducts;
}

export interface TaggingProductsQueryData {
    allTaggingProducts: AllProducts;
}

export interface SingleProductQuery {
    data: SingleProductQueryData;
}

export interface SingleProductQueryData {
    products: Product;
}

export interface ProductAuditQuery {
    data: ProductAuditQueryData;
}

export interface ProductAuditQueryData {
    products_audit: Array<ProductAudit>;
}

export interface FeedbackQuery {
    data: FeedbackQueryData;
}

export interface FeedbackQueryData {
    products_resources_feedback: ProductsResourcesFeedback;
}

export interface ProductMassEdit {
    values: Product;
    where: ProductFilterOptions;
}

export interface ProductRequest {
    batch: boolean;
    public: boolean;
    batch_public: boolean;
    created_by: number;
    artist_user_id: number;
    notes: string;
    due_date: number;
    items: Array<ProductRequestItem>;
}

export interface ProductRequestItem {
    id: number;
    notes: string;
    downloads: Array<string>;
    // due_date: number;
    resource_types: string;
    price: number;
    resources: Array<number>;
    complexity: number;
    job_type: number;
}

export interface SimilarItem extends Product {
    match_count: number;
    confidence_avg: number;
}

export interface ProductResourceType {
    id: number;
    resources_types: Array<ResourceType>;
    resource_type_id: number;
    product_id?: number;
    item_id?: number;
}

export interface ResourceType {
    resource_name: string;
    id: number;
    created_at: Date;
    updated_at: Date;
    is_deprecated: boolean;
    type?: string;
}

export interface ProductsDefaultsResourcesTypesQueryData {
    products_defaults_resources_types: Array<ProductResourceType>;
}

export interface ProductsResourcesTypesQueryData {
    resources_types: Array<ResourceType>;
}

export interface SourceFileType {
    description: string;
    id: number;
}

export interface SourchFilesTypesQueryData {
    source_files_types: Array<SourceFileType>;
}

export interface ProductsSubCategoriesAssociation {
    id: number;
    created_at: Date;
    updated_at: Date;
    sub_category_id: number;
    product_id: number;
    products: Array<Product>;
    artists_items: Array<ArtistsItem>;
    products_sub_categories: Array<ProductSubCategory>;
}

export interface ProductCategory {
    id: number;
    parent_id: number;
    created_at: Date;
    updated_at: Date;
    parent_description: string;
    description: string;
    artists_users_presets: Array<AdjustmentsPreset>;
}

// export interface ProductCategoryOptions {
//     avg_price: boolean;
// }

export interface ProductCategoryData {
    productsCategories: Array<ProductCategory>;
}

export interface CategoryPrice {
    type_id: number;
    average: number;
}

export interface CategoryPriceData {
    categories_pricing: Array<CategoryPrice>;
}

export interface ISubCategoryComplexity {
    id: number;
    created_at: any;
    updated_at: any;
    sub_category_id: number;
    complexity_image_id: number;
    complexity_level: number;
}

export interface ISubCategoryComplexityImage {
    id: any;
    created_at: any;
    updated_at: any;
    image_url: string;
}

export interface ISubCategoryComplexityImageData {
    complexityImages: Array<ISubCategoryComplexityImage>;
}

export interface ISubCategoryComplexitiesData {
    complexitiesArray: Array<ISubCategoryComplexity>;
}

export interface ProductsPricingkData {
    products_pricing: Array<ProductsPricingkDataInner>;

}

export interface ProductsPricingkDataInner {
    type_id: number,
    average: number;
}

export interface IProductService {
    toggleEnabled(item: Product, prefix: string, state?: boolean): void;
    toggleQuickView(item: Product, event: MouseEvent): void;
    refreshSelectedCount(): void;
    getStatusById(id: number): KeyValuePair;
    isCheckboxDisabled(item: Product): boolean;
    onCheckboxChenge(item: Product): void;
    onToggleQuickView: Subject<null>;
    editItem: Product;
    quickViewItem: Product;
    quickViewClass: string;
    lastQuery: ProductFilterOptions;
    // currentItems: Array<Product>;
    currentSimilarItems: Array<Product>;
}

export interface SimilarResponse {
    query_product_id: number; // The original product ID
    query_image_ids: Array<number>; // The IDs of the query_product_id (original) product
    neighbor_product_id_list: Array<number>; // The IDs of the neighbors products
    neighbor_image_ids_list: Array<Array<number>>; // Arrays of the IDs of the images. Items are related to neighbor_product_id_list be indices
    cover_image_ids: Array<number>;
    cover_product_ids: Array<number>;
    // neighbor_ids_list: Array<Array<Array<number>>>; // similar items (products) with images [[[product_id, image_id]]]
    // product_group: Array<KeyValuePair>; // the products to create a single offer as similar items
    // query_ids: Array<Array<number>>; // the product with it's images [[product_id, image_id]]
    error: string; // In case the request didn't succeeded an error message will be populated
}

export interface SimilarRequest {
    mode: string, // SIMILAR_REQUEST_MODE
    query_product_ids: Array<number>; // An array of product IDs we want to query when the first item is the original product
    distractor_image_ids: Array<number>; // An array of images IDs to remove
    non_neighbor_product_ids: Array<number>; // An array if product IDs that we don't want to get back in related to the requested query_product_ids
    num_neighbors: number; // The maximum number of neighbors to get in neighbor_product_id_list and neighbor_image_ids_list
    old_similar: boolean; // Use the old code
    // action: string; // SIMILAR_REQUEST_ACTION
    // product_group: Array<number>; // An array of the same_mesh products
    // image_id?: number; // The id of the image that we want to remove
    // product_id?: number; // The id of the product that we want to remove
    // project?: string, // SIMILAR_REQUEST_MODE
}

export enum SIMILAR_REQUEST_MODE {
    requested = 1, // bundle
    approved = 2, // repository
}

// export enum SIMILAR_REQUEST_ACTION {
//     refresh = 1,
//     remove_distractor = 2,
//     remove_neighbor = 3,
//     update = 4,
// }

export interface ProductIdentifier {
    id: number;
    serial_number: string;
    variation_id: string;
    identifier_type: string;
    retailer_id: number;
    product_id: number;
    created_at: Date;
    updated_at: Date;
}

export enum ResourceDimension {
    TWO = '2d',
    THREE = '3d',
}

export interface VerifyBundleState {
    product: Product;
    type: BundleCompareState;
    inventoryIndex: number;
    bundleIndex: number;
}
