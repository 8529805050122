export const MAX_NUMBER_OF_COMPLEXITIES = 10;
export const MAX_NUMBER_OF_IMAGES = 5;

export interface ComplexityGuideQuery {
  allComplexityGuides: ComplexityGuideQueryData;
}

export interface ComplexityGuideQueryData {
  rows: Array<IComplexityGuide>;
  count: number;
}

export interface IComplexityGuide {
  name: string;
  id: number;
  products_complexity_sets: Array<IProductComplexity>;
  products_complexity_guides_sub_categories: Array<ProductsComplexityGuidesSubCategory>;
}

export interface IComplexityGuideUI extends IComplexityGuide {
  categoryNames?: Array<string>;
}

export interface IProductsComplexitySetsImages {
  id: number;
  complexity_set_id?: number;
  image: string;
  label: string;
}

export interface IComplexityImage extends IProductsComplexitySetsImages {
  complexityLevel?: number;
  price?: number;
}

export interface IComplexitySelection {
  image: IComplexityImage;
  guide: IComplexityGuide;
}

export interface IGeometryComplexitySummery {
  selection: IComplexitySelection;
  price: number;
  complexity: number;
}


export interface IComplexity {
  complexityLevel?: number;
  imgArray?: IComplexityImage[];
  label?: string;
  complexityPrice?: number | string;
  id?: number;
}

export interface IProductComplexity {
  id: number;
  complexity_level: number;
  price: number;
  complexity_guide_id: number;
  products_complexity_sets_images: IComplexityImage[];
}

export interface ProductsComplexityGuidesSubCategory {
  id: number;
  sub_category_id: number;
  complexity_guide_id: number;
}

export interface IComplexityGuideOptions {
  limit?: number;
  offset?: number;
  id?: Array<number>;
  name?: Array<string>;
}

export interface ISubCategoriesComplexityGuidesOptions  {
  limit?: number;
  offset?: number;
  sub_category_id: Array<number>;
}

export enum OfferCreationState {
  CATEGORY,
  COMPLEXITY,
  CREATE_BUNDLE,
  VERIFY_BUNDLE,
  ADD_INVENTORY_ITEMS,
  INVENTORY_COMPARE
}

export enum BundleCompareState {
  INITIAL,
  NOT_RELEVANT,
  SIMILAR_SHAPE,
  SAME_SHAPE,
  SAME_SHAPE_AND_COLOR,
}

export const BUNDLE_COMPARE_VALUE_OPTIONS: {[key in BundleCompareState]: string} = {
  [BundleCompareState.INITIAL]: undefined,
  [BundleCompareState.NOT_RELEVANT]: 'not_relevant',
  [BundleCompareState.SIMILAR_SHAPE]: 'similar_shape',
  [BundleCompareState.SAME_SHAPE]: 'same_shape',
  [BundleCompareState.SAME_SHAPE_AND_COLOR]: 'same_shape_color',
}
