<div class="wrap pos-rel">
    <button mat-icon-button color="accent" mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
    <h2 mat-dialog-title class="text-center">Get Your Work Done Quicker</h2>
    <mat-dialog-content class="scrollbar">
        <p>
            Try our new feature and save up to 30% of your work<br>time, plus reduce job feedback.
        </p>
        <p>
            Our Mesh Libraries help you by providing some parts of<br>the 3D model. Simply download the Mesh Library
            to<br>
            get started.
        </p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button aria-label="open mesh library" class="big w-100" color="primary" (click)="openML()">Open the Mesh Library</button>
    </mat-dialog-actions>
</div>