<div class="wrap pos-rel">
    <button mat-icon-button color="accent" class="close center-wrapper" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <mat-dialog-content class="scrollbar">
        <h3 class="title text-center w-100">important requirements reminder</h3>
        <app-specifications-slider [specs]="specs"></app-specifications-slider>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button (click)="close(true)" color="primary">continue</button>
    </mat-dialog-actions>
</div>