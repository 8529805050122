import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { TopAssets } from '../top-assets';
import { RestService } from 'app/communication/rest.service';

@Injectable()
export class TopAssetsResolve  {

    constructor(
        private rest: RestService
    ) { }

    resolve() {
        return this.rest.topOffersJobs('get', null, '?limit=3') as Observable<TopAssets>;
    }
}