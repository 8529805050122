import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class PasswordRecoveryResolve  {

    constructor() { }

    resolve(route: ActivatedRouteSnapshot) {
        return route.params['token'];
    }
}