import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeWindow'
})
export class SafeWindowPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(url: string, suffix?: string): any {
    if (url) {
      if (suffix)
        url += suffix;
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
      
  }

}
